<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <input type="hidden" :value="validacion">
            <div class="form-row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    
                    <div class="form-group form-row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <h6 class="mb-1" style="color: #006098;"> {{ $getCleanDate(ComentarioPago.Fecha,true) }} - {{ComentarioPago.NombreCompleto }}</h6>
                            <textarea placeholder=" Coloque sus Observaciones" v-model="ComentarioPago.Comentario" class="form-control" cols="12" rows="3" readonly></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </CLoader>
</template>

<script>
import CTablita from "../../../../components/CTablita";
import CSinRegistros from "../../../../components/CSinRegistros";

const EmitEjecuta = "seccionComentarioPagos";

export default {
    name: "ComentariosPagos",
    props:["poBtnSave","pSitio"],
    components:{
        CTablita,
        CSinRegistros
    },
    data() {
        return {
            ConfigLoad:{
                ShowLoader: true,
                ClassLoad:  false,
            },
            Emit: this.poBtnSave.EmitSeccion,
            pagoPrestamo: {
                IdPrestamo: 0,
                IdPrestamosPago: 0,
            },
            ComentarioPago: {
                IdPrestamosPago: 0,
                IdPrestamo: 0,
                IdUsuario: 0,
                TipoComentario: '',
                Comentario: '',
                Fecha: '',
                IdU: 0,
                NombreCompleto: '',
            },
            Sitio: 'Pagos'
        }
    },

    methods: {
        recoverylistComentarios()
        {   
            let Id = 0;

            if(this.Sitio != 'Pagos') {
                Id = this.pagoPrestamo.IdPrestamosPago2;
            }else {
                Id = this.pagoPrestamo.IdPrestamosPago
            }

            this.$http.get("recoverycomentariopagos",{
                params:{
                    IdPrestamosPago: Id,
                    IdPrestamo: this.pagoPrestamo.IdPrestamo,
                }
            }).then((res) => {
                this.ComentarioPago = res.data.data;

            }).finally(() => {
                this.ConfigLoad.ShowLoader = false;
            });
        },
    },
    created() {
        this.bus.$off('Save_' + this.Emit);
        this.bus.$off('Recovery_' + this.Emit);
    },
    mounted() {
        this.bus.$on('Recovery_' + this.Emit, (obj) => {
            this.ConfigLoad.ShowLoader = true;

            if (obj !== '') {
                this.pagoPrestamo = obj;
                this.recoverylistComentarios();
            }
        });
    },
    computed: {
        validacion()
        {
            if(this.pSitio != undefined){
                this.Sitio = this.pSitio
            }
            
            return this.Sitio;
        }
    }
}
</script>
