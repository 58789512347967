<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <label for="Comentario">Comentario</label>
                            <textarea v-model="objComentario.Comentario" :disabled="blockText" class="form-control" placeholder="Ingresar un comentario" rows="5"></textarea>
                            <CValidation v-if="this.errorvalidacion.Comentario" :Mensaje="'*'+errorvalidacion.Comentario[0]"/>
                        </div>
                    </div>
                </div>
            </form>
        </template>
    </CLoader>
</template>

<script>
import CLoader from "../../../../components/CLoader";
import CValidation from "../../../../components/CValidation";
export default {
    name: "ComentarioPrestamos",
    props: ['poBtnSave'],
    components: {
        CLoader,
        CValidation
    },
    data() {
        return {
            Emit:               this.poBtnSave.EmitSeccion,
            errorvalidacion:    [],
            blockText: false,
            ConfigLoad:{
                ShowLoader: true,
                ClassLoad:  false,
            },
            objComentario: {
                IdPrestamo:  '',
                TipoComentario: 'Otro',
                Comentario: ''
            },

        }
    },
    methods: {
        async Guardar() {
            this.errorvalidacion      = [];
            this.poBtnSave.toast      = 0;
            this.poBtnSave.DisableBtn = true;
            this.$http.post('addcomentariosprestamos',this.objComentario).then((res)=>{
                this.EjecutaConExito(res);
            }).catch(err=>{
                this.EjecutaConError(err);
            });
        },
        EjecutaConExito(res) {
            this.poBtnSave.DisableBtn = false;
            this.poBtnSave.toast      = 1;
            this.bus.$emit('CloseModal_'+this.Emit);
            this.bus.$emit('List_'+this.Emit);
        },
        EjecutaConError(err) {
            this.poBtnSave.DisableBtn = false;

            if(err.response.data.error){
                this.errorvalidacion = err.response.data.error;
                this.poBtnSave.toast = 2;
            } else {
                this.$toast.error(err.response.data.message);
            }
        },

        Limpiar() {
            this.objComentario = {
                IdPrestamo:  '',
                TipoComentario: 'Otro',
                Comentario: ''
            };

            this.errorvalidacion = [];
        },
    },
    created() {

        this.Limpiar();
        this.poBtnSave.toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$off('Save_'+this.Emit);
        this.ConfigLoad.ShowLoader = true;

    },
    mounted() {

        this.bus.$on('Recovery_'+this.Emit,(id)=> {

            this.bus.$on('Save_'+this.Emit,()=> {
                this.Guardar();
            });


            if (this.objComentario != undefined && this.objComentario != '') {
                this.objComentario.IdPrestamo = id;
            }

            this.ConfigLoad.ShowLoader = false;

        });
    }
}
</script>

<style scoped>

</style>
