<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">

                            <div class="form-group row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <h3>Cobranza: {{objSuplencia.NombreCompleto}}</h3>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <h3>Ruta: {{objSuplencia.NombreRuta}}</h3>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <label for="IdSucursal">Suplencia</label>
                                    <select id="IdSucursal" v-model="objRuta.IdUsuario" class="form-control form-select">
                                        <option :value="0">--Seleccionar--</option>
                                        <option v-for="(item, index) in ListaSuplentes" :key="index" :value="item.IdUsuario" >
                                            {{ item.NombreCompleto }}
                                        </option>
                                    </select>
                                    <CValidation v-if="this.errorvalidacion.IdUsuario" :Mensaje="'*'+errorvalidacion.IdUsuario[0]"/>
                                </div>
                            </div>

                        </div><!--fin col-12-->
                    </div>
                </div>
            </form>
        </template>
    </CLoader>
</template>

<script>

export default {
    name:  "FormSuplencia",
    props: ['poBtnSave'],
    data() {
        return {
            ConfigLoad:{
                ShowLoader: true,
                ClassLoad:  false,
            },
            objSuplencia: {},
            objRuta:{
                IdRuta: 0,
                IdUsuario: 0,
                IdUsuarioOld: 0,
            },
            segurity: {},
            Emit: this.poBtnSave.EmitSeccion,
            errorvalidacion: [],
            ListaSuplentes: [],
        }
    },
    methods: {
        async Guardar()
        {
            this.errorvalidacion = [];
            this.poBtnSave.toast = 0; 
            this.poBtnSave.DisableBtn = true;

            this.$swal(
            {
                title: '<h2 style="font-size:22px; font-weight: 700;">¿Estas seguro que desea generar una suplencia?</h2>',
                text: 'Recuerde que la cobranza del día quedara reflejada para el suplente',
                icon: 'warning',
                showCancelButton:    true,
                confirmButtonText:   'Continuar',
                cancelButtonText:    'Cancelar',
                showCloseButton:     true,
                showLoaderOnConfirm: true,
                confirmButtonColor:  '#0096ED',
                cancelButtonColor:   '#b92e27',
            }).then((result) => {
                if(result.value) {
                    this.$http.post(
                        'setSuplencia', this.objRuta
                    ).then((res)=>{                 
                        this.EjecutaConExito(res);
                    }).catch(err=>{   
                        this.EjecutaConError(err);
                    });
                } else {
                    this.poBtnSave.DisableBtn = false;
                }
            });
        },
        EjecutaConExito(res)
        {
            this.poBtnSave.DisableBtn = false;  
            this.poBtnSave.toast      = 1;
            this.bus.$emit('CloseModal_'+this.Emit);
            this.bus.$emit('List_seccionRutas');
        },
        EjecutaConError(err)
        {
            this.poBtnSave.DisableBtn = false;
            
            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.poBtnSave.toast = 2;
            } else {
                this.$toast.error(err.response.data.message);
            }
        },
        Recuperar()
        {
            this.$http.get("getUsuariosMismaRuta", {
				params: {
					IdRuta: this.objRuta.IdRuta,
				},
			})
			.then((res) => {
                this.ListaSuplentes = res.data.data;
                this.segurity       = res.data.segurity;

                // POR LOS PERMISOS
                if(!this.segurity.BtnSave){
                    this.poBtnSave.ShowBtnSave = false;
                }
                else if(this.segurity.BtnSave){
                    this.poBtnSave.ShowBtnSave = true;
                }
            }).finally(()=>{
                this.ConfigLoad.ShowLoader = false;
            });
        },
        Limpiar()
        {
            this.objRuta = {
                IdRuta: 0,
                IdUsuario: 0,
                IdUsuarioOld: 0,
            };
            this.errorvalidacion = [];
        },
        async ListaSucursales()
        {
            await this.$http.get("sucursales", {
                params: {
                    simple: 1
                },
            })
            .then((res) => {
                this.ListaSucursalesArr = res.data.data;
            });
        },
    },
    created() {
        this.poBtnSave.toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(item)=> {
            this.ConfigLoad.ShowLoader = true;    
            this.poBtnSave.DisableBtn  = false;

            this.bus.$off('Save_'+this.Emit);
            this.bus.$on('Save_'+this.Emit,()=> {
                this.Guardar();
            });

            this.Limpiar();
            this.objSuplencia = item;
            this.objRuta.IdUsuarioOld = item.IdUsuario;
                
            if(item.IdRuta!='') {
                this.objRuta.IdRuta = item.IdRuta;
                this.Recuperar();
            } else {
                this.ConfigLoad.ShowLoader = false;
            }
        });
    },
}   
</script>