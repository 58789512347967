<template>
    <div>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                <div class="box-imagen">
                    <div class="avatar-upload image-button">
                        <label class="" style="text-align: left;">
                            Evidencia 1
                        </label>
                        <div class="avatar-preview">
                            <div :id="'imagePreview_1'" :style="'background-image: url('+RutaEviCan+CancelacionEntrega.Imagen1+');'" :src="RutaEviCan+CancelacionEntrega.Imagen1"></div>
                        </div>

                        <div class="icons-button-2">
                            <button type="button" @click="previewImagen(RutaEviCan,CancelacionEntrega.Imagen1)">
                                <i class="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">

                <div class="box-imagen">
                    <div class="avatar-upload image-button">
                        <label class="" style="text-align: left;">
                            Evidencia 2
                        </label>
                        <div class="avatar-preview">
                            <div :id="'imagePreview_2'" :style="'background-image: url('+RutaEviCan+CancelacionEntrega.Imagen2+');'" :src="RutaEviCan+CancelacionEntrega.Imagen2"></div>
                        </div>

                        <div class="icons-button-2">
                            <button type="button" @click="previewImagen(RutaEviCan,CancelacionEntrega.Imagen2)">
                                <i class="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                <div class="form-group">
                    <label>Motivo de Cancelacion de Entrega</label>
                    <textarea class="form-control" rows="3" readonly> {{ CancelacionEntrega.MotivoCancelacionEntrega }} </textarea>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                <fieldset class="mt-2">
                    <legend class="col-form-label">&nbsp;Ubicación&nbsp;</legend>

                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <h6 v-if="!this.isReady && this.bndUbicacion" style="color: #006098;"> Se ha cancelado desde el sistema.</h6>
                            <CMapa v-if="this.isReady" :pConfigMapa="ConfigMapa" :oLatLng="coordenadas" />
                        </div>
                    </div>

                </fieldset>
            </div>
        </div>





    </div>
</template>

<script>
import CMapa from "../../../../components/CMapa";
export default {
    name:  "viewEvidenciaCancelacionEntrega",
    props: ['pCancelacion','poBtnSave'],
    components: {
        CMapa
    },
    data() {
        return {
            Emit:           this.poBtnSave.EmitSeccion,
            RutaEviCan: '',
            CancelacionEntrega: {
                IdPrestamoxCancelacion: 0,
                IdPrestamo: 0,
                IdUsuario: 0,
                MotivoCancelacionEntrega: 0,
                Imagen1: 0,
                Imagen2: 0,
                TipoCancelacion: 0,
                created_at: 0,
                NombreCompleto: '',
                LatitudEntrega: '',
                LongitudEntrega: ''
            },
            isReady: false,
            bndUbicacion: false,
            ConfigMapa:{
                ShowBuscador: false
            },
            coordenadas: {
                Lat: 20.9673271,
                Lng: -89.6249853,
            },
        }
    },

    methods: {
        previewImagen(ruta,file) {
            window.open(ruta+file,'Nueva Ventana',"width=600,height=800");
        }
    },

    created() {
        this.bus.$off('Save_' + this.Emit);

        this.bus.$off('Recovery_' + this.Emit);
        this.bus.$on('Recovery_' + this.Emit, (obj) => {

            if (obj !== '') {

                this.CancelacionEntrega = obj;


                this.RutaEviCan = obj.pRutaImg;

                if(this.CancelacionEntrega.LatitudEntrega !== undefined && this.CancelacionEntrega.LatitudEntrega !== '' && this.CancelacionEntrega.LatitudEntrega !== "undefined"){
                    this.coordenadas.Lat = parseFloat(this.CancelacionEntrega.LatitudEntrega);

                    if (this.CancelacionEntrega.LongitudEntrega !== undefined && this.CancelacionEntrega.LongitudEntrega !== '' && this.CancelacionEntrega.LongitudEntrega !== "undefined") {
                        this.coordenadas.Lng = parseFloat(this.CancelacionEntrega.LongitudEntrega);
                        this.isReady = true;
                    }

                }else {
                    this.coordenadas.Lat    = 0;
                    this.coordenadas.Lng    = 0;
                    this.isReady            = false;
                    this.bndUbicacion       = true;
                }


            }

        });

    },

    mounted() {

    }
}
</script>
