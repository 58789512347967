<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="form-row">
                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <h3>Empleados</h3>
                                    <draggable id="div1" class="droppable bg-feed" :list="ListaEmpleados" group="rutas">
                                        <div class="list-group-item" v-for="(element, index) in ListaEmpleados" :key="element.NombreCompleto">
                                            <div class="form-row">
                                                <div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
                                                    {{ index+1 }}
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                                    {{ element.NombreCompleto}}
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                    <b>{{' ('+element.NombrePerfil+')'}}</b>
                                                </div>
                                            </div>
                                            <!-- {{ index+1 }} {{ element.NombreCompleto}}<b>{{' ('+element.NombrePerfil+')'}}</b> -->
                                        </div>
                                    </draggable>
                                </div>

                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <h3>Asignados</h3>
                                    <draggable id="div2" class="droppable" :list="ListaAsignados" group="rutas">
                                        <div class="list-group-item" v-for="(element, index) in ListaAsignados" :key="element.NombreCompleto">
                                            <div class="form-row">
                                                <div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
                                                    <div class="form-check">
                                                        <input class="form-check-input mxinp" type="radio" v-model="element.TipoCobrador" name="exampleRadios" value="Cobratario" @change="EjecutaCambio(element)"> 
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
                                                    {{ index+1 }}
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                    {{ element.NombreCompleto}}
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                    <b>{{' ('+element.NombrePerfil+')'}}</b>
                                                </div>
                                            </div>
                                            <!-- <div class="form-check">
                                                <input class="form-check-input mxinp" type="radio" v-model="element.TipoCobrador" name="exampleRadios" value="Cobratario" @change="EjecutaCambio(element)">
                                                <div class="form-check-label" for="exampleRadios1">
                                                {{ index+1 }} {{ element.NombreCompleto}}<b>{{' ('+element.NombrePerfil+')'}}</b>
                                                </div>
                                            </div> -->

                                        </div>
                                    </draggable>
                                </div>
                            </div>
                        </div><!--fin col-12-->
                    </div>
                </div>
            </form>
        </template>
    </CLoader>
</template>

<script>

    export default {
        name:  "FormAsig",
        props: ['poBtnSave'],
        data() {
            return {
                Emit:            this.poBtnSave.EmitSeccion,
                errorvalidacion: [],
                ListUsersArr:    [],
                ListaEmpleados:  [],
                ListaAsignados:  [],
                objRuta:         {},
                ConfigLoad:{
                    ShowLoader: true,
                    ClassLoad:  false,
                },
                segurity: {},
                Cobrador: 0,
            }
        },
        methods: {
            async Guardar()
            {
                /* let band3 = 0;
                let msg3 = '';

                $.each(this.ListaAsignados, function(key, value){
                    if(value.IdPerfil == 4){
                        msg3 = "Solo puede agregar un gestor a la cobranza de la ruta";
                        band3++;
                    }
                });
				if(band3>1){ this.$toast.warning(msg3); return false; } */

                this.errorvalidacion = [];
                this.poBtnSave.toast = 0;
                this.poBtnSave.DisableBtn = true;

                let objSend = {
                    IdRuta:  this.objRuta.IdRuta,
                    arreglo: this.ListaAsignados,
                    Cobrardor: this.Cobrador,
                };

                this.$http.post('rutasxusuarios',
                    objSend
                ).then((res)=>{
                    this.EjecutaConExito(res);
                }).catch(err=>{
                    this.EjecutaConError(err);
                });
            },
            EjecutaConExito(res) {
                this.poBtnSave.DisableBtn = false;
                this.poBtnSave.toast      = 1;
                this.bus.$emit('CloseModal_'+this.Emit);
                this.bus.$emit('List_seccionRutas');
            },
            EjecutaConError(err) {
                this.poBtnSave.DisableBtn = false;

                if(err.response.data.errors){
                    this.errorvalidacion = err.response.data.errors;
                    this.poBtnSave.toast = 2;
                } else {
                    this.$toast.error(err.response.data.message);
                }
            },
            Recuperar()
            {
                this.$http.get("rutasxusuariosinner", {
                    params: {
                        IdRuta: this.objRuta.IdRuta
                    },
                }).then( (res) => {
                    this.ListaAsignados = res.data.data;
                    this.segurity       = res.data.segurity;

                    this.ListaAsignados.forEach((value, index) => {
                        const newArray = this.ListUsersArr.filter(function(item){
                            if(item.IdUsuario == value.IdUsuario){
                                return '';
                            } else {
                                return item;
                            }
                        });

                        if(value.TipoCobrador == 'Cobratario'){
                            this.Cobrador = value.IdUsuario;
                        }

                        this.ListUsersArr = newArray;
                    });

                    this.ListaEmpleados = this.ListUsersArr;

                    // POR LOS PERMISOS
                    if(!this.segurity.BtnSave){
                        this.poBtnSave.ShowBtnSave = false;
                    }
                    else if(this.segurity.BtnSave){
                        this.poBtnSave.ShowBtnSave = true;
                    }
                }).finally(()=>{
                    this.ConfigLoad.ShowLoader = false;
                });
            },
            Limpiar() {
                this.errorvalidacion = [];
            },
            async ListaUsuarios()
            {
                await this.$http.get("rutasxusuariosUsers",)
                .then((res) => {
                    this.ListUsersArr = res.data.data;
                    this.ListUsersArr.forEach((value, index) => {
                        value.NombrePerfil = value.perfil.Nombre;
                        value.TipoCobrador = 'Suplente';
                    });
                });
            },
            EjecutaCambio(objElement)
            {
                this.ListaAsignados.forEach((value, index) => {
                    if(value.IdUsuario == objElement.IdUsuario){
                        value.TipoCobrador = 'Cobratario';
                    } else {
                        value.TipoCobrador = 'Suplente';
                    }
                });
                this.Cobrador = objElement.IdUsuario;
            }
        },
        created() {
            this.poBtnSave.toast = 0;
            this.ListaUsuarios();

            this.bus.$off('Recovery_'+this.Emit);
            this.bus.$on('Recovery_'+this.Emit,(item)=> {

                this.ConfigLoad.ShowLoader = true;
                this.poBtnSave.DisableBtn  = false;

                this.bus.$off('Save_'+this.Emit);
                this.bus.$on('Save_'+this.Emit,()=> {
                    this.Guardar();
                });

                this.Limpiar();

                this.objRuta = item;
                this.Recuperar();
            });
        },
    }

</script>
<style scoped>
.mxinp{
    transform: scale(1.3);
}
</style>