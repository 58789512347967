<template>
    <div>
        <CList @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
			<template slot="Filtros">
                <label  class="mr-1">Tipo</label>
				<select v-model="Filtro.TipoEquipo" class="form-control form-select mr-2" @change="Lista">
                    <option value="0">--Seleccionar--</option>
					<option value="1">Vehículo</option>
					<option value="2">Teléfono</option>
					<option value="3">Linea Telefonica</option>
                </select>
                <label  class="mr-1">Estatus</label>
				<select v-model="Filtro.EstatusEquipo" class="form-control form-select mr-2" @change="Lista">
                    <option value="">--Seleccionar--</option>
					<option value="A">Activo</option>
					<option value="B">Inactivo</option>

                </select>

                <button @click="getPdfListado()" type="button" title="Imprimir Listado" class="btn btn-primary btn-sm">
                    <i class="far fa-print"></i>
                </button>
            </template>

            <template slot="header">
                <th class="td-sm"></th>
                <th>#</th>
                <th>Nombre</th>
                <th>Tipo</th>
                <th width="11%">
                    <div class="row">
                        <div class="col-12">&bullet; N° Economico /</div>
                        <div class="col-12">&bullet; Imei /</div>
                        <div class="col-12">&bullet; Teléfono /</div>
                    </div>
                </th>
                <th>Modelo</th>
                <th>Marca</th>
                <th>Color</th>
                <th>Asignado a</th>
                <th class="text-center">Acciones</th>
            </template>

            <template slot="body">
                <tr v-for="(lista,index) in ListaArrayRows" :key="index" >
                    <td class="td-sm"></td>
                    <td>{{ $getNumItem(index) }}</td>
                    <td><small>{{lista.Nombre}}</small></td>
                    <td>
                        <template v-if="lista.TipoEquipo == 1">Vehículo</template>
                        <template v-else-if="lista.TipoEquipo == 2">Teléfono</template>
                        <template v-else>Linea</template>
                    </td>
                    <td >
                        <template v-if="lista.TipoEquipo == 1"># {{lista.NumeroEconomico}}</template>
                        <template v-else-if="lista.TipoEquipo == 2">{{lista.Imei}}</template>
                        <template v-else>{{lista.Telefono}}</template>
                    </td>
                    <td>{{lista.Modelo}}</td>
                    <td>{{lista.Marca}}</td>
                    <td>{{lista.Color}}</td>
                    <td><small>{{lista.Usuario}}</small></td>
                    <td class="text-center">
                        <CBtnAccion :pShowBtnEdit="true" :pShowBtnDelete="true" :pIsModal="true" :pId="lista.IdEquipo" :pEmitSeccion="ConfigList.EmitSeccion" :segurity="segurity">
							<template slot="btnaccion">
                                <button type="button" v-if="segurity.ViewSection" @click="VerBitacora(lista);" v-b-tooltip.hover.Top title="Ver Bitacora" class="btn btn-icon btn-warning mr-1">
                                    <i class="fas fa-history"></i>
                                </button>

                                <button type="button" v-if="segurity.ViewSection" @click="DesasignarPersonal(lista);" v-b-tooltip.hover.Top title="Desasignar Personal" class="btn btn-icon btn-success" :disabled="(lista.IdUsuario>0)?false:true">
                                    <i class="fas fa-user-slash"></i>
                                </button>
                            </template>
						</CBtnAccion>
                    </td>
                </tr>
                <CSinRegistro :pContIF="ListaArrayRows.length" :pColspan="10"></CSinRegistro>
            </template>
        </CList>

        <CModal :pConfigModal="ConfigModal" :poBtnSave="oBtnSave">
			<template slot="Form">
				<Form :poBtnSave="oBtnSave"></Form>
			</template>
		</CModal>
    </div>
</template>

<script>

    import Form       from '@/views/catalogos/equipos/Form.vue';
    import Configs    from '@/helpers/VarConfig.js';
    const EmitEjecuta =    'seccionEquipos';

    export default {
        name: 'ListaEquipos',
        components: {
            Form
        },
        data() {
            return {
                counterField: 1,
                ConfigList:{
                    Title:         'Listado Equipos',
                    IsModal:       true,
                    ShowLoader:    true,
                    BtnReturnShow: false,
                    EmitSeccion:   EmitEjecuta,
                },
                Filtro:{
                    Nombre:      '',
                    Pagina:      1,
                    Entrada:     25,
                    TotalItem:   0,
                    Placeholder: 'Buscar..',
                    TipoEquipo:  '0',
                    EstatusEquipo: ''
                },
                ConfigModal:{
                    ModalTitle:  "Formulario Equipos",
                    ModalSize:   'lg',
                    EmitSeccion:  EmitEjecuta,
                },
                oBtnSave: {
                    toast:       0,
                    IsModal:     true,
                    DisableBtn:  false,
                    EmitSeccion: EmitEjecuta,
                },
                segurity:       {},
                obj:            {},
                ListaArrayRows: [],
                ListaHeader:    [],
            }
        },
        methods: {
            async Lista(){
                this.ConfigList.ShowLoader = true;

                await this.$http.get('equipos', {
                    params:{
                        TxtBusqueda: this.Filtro.Nombre,
                        Entrada:     this.Filtro.Entrada,
                        Pag:         this.Filtro.Pagina,
                        TipoEquipo:  this.Filtro.TipoEquipo,
                        EstatusEquipo:  this.Filtro.EstatusEquipo,
                    }
                }).then( (res) => {
                    this.Filtro.Pagina    	= res.data.data.current_page;
					this.Filtro.TotalItem 	= res.data.data.total;
                    this.ListaArrayRows     = res.data.data.data;
                    this.segurity           = res.data.segurity;
                    this.$setStartItem();


                }).finally(()=>{
                    this.ConfigList.ShowLoader = false;
                });
            },
            Eliminar(Id){
                this.$swal(Configs.configEliminar).then((result) => {
                    if(result.value) {
                        this.$http.delete('equipos/'+Id)
                        .then( (res) => {
                            this.$swal(Configs.configEliminarConfirm);
                            this.Lista();
                        })
                        .catch( err => {
                            this.$toast.error(err.response.data.message);
                        });
                    }
                });
            },
            VerBitacora(lista){
                this.$router.push({name:'bitacoraequipos',params:{pObjEquipo:lista}});
            },
            DesasignarPersonal(lista)
            {
                this.$swal({
                    title: 'Desasignar Equipo?',
                    text: 'Esta acción deasignara el equipo al empleado, ¿desea continuar?',
                    icon: 'warning',
                    confirmButtonText: 'Continuar',
                    cancelButtonText: 'Cancelar',
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonColor: '#0096ED',
                    cancelButtonColor: '#b92e27',
                }).then((result) =>
                {
                    if(result.value)
                    {
                        this.$http.get('desasignarEquipoUser', {
                            params:{
                                IdUsuario: lista.IdUsuario,
                                IdEquipo: lista.IdEquipo,
                                IdEquipoxUsuario: lista.IdEquipoxUsuario,
                            }
                        }).then( (res) => {
                            this.$swal('Equipo Desasignado','','success');
                            this.Lista();
                        }).catch( err => {
                            this.$toast.error(err.response.data.message);
                        });
                    }
                });
            },
            getPdfListado(){
                let urlPdf = `${this.$http.defaults.baseURL}/getPdflistadoequipamento?TxtBusqueda=${this.Filtro.Nombre}&TipoEquipo=${this.Filtro.TipoEquipo}&EstatusEquipo=${this.Filtro.EstatusEquipo}`;
                window.open(urlPdf,'_blank');

            },
        },
        created() {
            this.bus.$off('Delete_'+EmitEjecuta);
            this.bus.$off('List_'+EmitEjecuta);
        },
        mounted() {
            this.Lista();
            this.bus.$on('Delete_'+EmitEjecuta,(Id)=>
            {
                this.Eliminar(Id);
            });

            this.bus.$on('List_'+EmitEjecuta,()=>
            {
                this.Lista();
            });
        },
    }

</script>
