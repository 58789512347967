<template>
	<div>
		<CList @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
			<template slot="header">
				<th class="td-sm"></th>
				<th>#</th>
				<th>Nombre</th>
                <th>Puesto</th>
				<th>Perfil</th>
				<th class="text-center">Acciones</th>
			</template>

			<template slot="body">
				<tr v-for="(lista, index) in ListaArrayRows" :key="index">
					<td class="td-sm"></td>
					<td>{{ $getNumItem(index) }}</td>
					<td>{{ lista.NombreCompleto }}</td>
                    <td>{{ lista.puesto.Nombre }}</td>
					<td>{{ lista.perfil.Nombre }}</td>
					<td class="text-center">
						<CBtnAccion :pShowBtnEdit="false" :pShowBtnDelete="false" :pIsModal="false" :pId="lista.IdUsuario" :pEmitSeccion="ConfigList.EmitSeccion" :segurity="segurity">
							<template slot="btnaccion">
								<template v-if="segurity.ViewNextDay">
									<button type="button" v-b-tooltip.hover.Top title="Ir a Día Siguiente" @click="DiaSiguiente(lista)" class="btn btn-primary btn-icon mr-1" >
										<i class="fas fa-calendar"></i>
									</button> 
								</template>
								
								<template v-if="segurity.ViewCorte">
									<button type="button" v-b-tooltip.hover.Top title="Ir a Corte del Dia" @click="CorteDelDia(lista)" class="btn btn-primary btn-icon" >
										<i class="fas fa-hand-holding-usd"></i>
									</button>
								</template>
							</template>
						</CBtnAccion>
					</td>
				</tr>
				<CSinRegistro :pContIF="ListaArrayRows.length" :pColspan="6" ></CSinRegistro>
			</template>
		</CList>
	</div>
</template>

<script>
	const EmitEjecuta = "seccionCobratarios";

	export default {
		name:  "Listacobratarios",
        props: ['sFiltros'],
		components: {},
		data() {
			return {
                counterField: 	1,
				ListaArrayRows: [],
				segurity: 		{},
				obj: 			{},
				ConfigList: {
                    ShowTitleFirst: false,
					Title: 			'Corte del dia - Cobratarios',
					IsModal: 		false,
					ShowLoader: 	true,
					BtnReturnShow:  false,
					EmitSeccion: 	EmitEjecuta,
					GoRoute: 		'',
					BtnNewShow:     false,
				},
				Filtro: {
					Nombre: 	 "",
					Pagina: 	 1,
					Entrada: 	 25,
					TotalItem:   0,
					Placeholder: "Buscar..",
				},
			};
		},
		methods: {
			async Lista() {
				this.ConfigList.ShowLoader = true;

				await this.$http.get("getUsuariosCobranza", {
					params: {
						TxtBusqueda: this.Filtro.Nombre,
						Entrada: 	 this.Filtro.Entrada,
						Pag: 		 this.Filtro.Pagina
					},
				})
				.then((res) => {
					this.Filtro.Pagina    = res.data.data.current_page;
					this.Filtro.TotalItem = res.data.data.total;
					this.ListaArrayRows   = res.data.data.data;
                    this.segurity         = res.data.segurity;
					this.$setStartItem();

				}).finally(() => {
					this.ConfigList.ShowLoader = false;
					this.$saveFilters('SET');
				});
			},
			DiaSiguiente(item) {
				this.$router.push({name:'diasiguiente',params:{objUser:item}});
			},
			CorteDelDia(item) {
				this.$router.push({name:'cortedeldia',params:{objUser:item}});
			},
		},
		created() {            
			
			if(this.sFiltros===undefined) {
                this.$saveFilters('GET');
            }

			this.bus.$off("List_" + EmitEjecuta);
		},
		mounted() {
			this.Lista();

			this.bus.$on("List_" + EmitEjecuta, () => {
				this.Lista();
			});

		},
	};

</script>
