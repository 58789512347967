<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">

                            <div class="form-group row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <label for="Nombre">Fecha</label>
                                    <v-date-picker :masks="masks" :popover="{ visibility: 'focus' }" locale="es" v-model="objInhabil.Fecha">
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <input class="form-control cal" placeholder="0000-00-00" :value="inputValue" v-on="inputEvents" readonly/>
                                        </template>
                                    </v-date-picker>
                                    <CValidation v-if="this.errorvalidacion.Fecha" :Mensaje="'*'+errorvalidacion.Fecha[0]"/>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <label for="Porcentaje">Comentario</label>
                                    <textarea v-model="objInhabil.Comentario" rows="2" maxlength="100" class="form-control"></textarea>
                                </div>
                            </div>

                        </div><!--fin col-12-->
                    </div>
                </div>
            </form>
        </template>
    </CLoader>
</template>

<script>
import moment from 'moment';

export default {
    name:  "FormDiaInhabil",
    props: ['poBtnSave'],
    components:{
    },
    data() {
        return {
            ListaSucursalesArr: [],
            errorvalidacion: [],
            Emit: this.poBtnSave.EmitSeccion,
            ConfigLoad:{
                ShowLoader: true,
                ClassLoad: false,
            },
            objInhabil:{
                IdInhabil: 0,
                Fecha: '',
                Anio: '',
                Comentario: ''
            },
            masks: {
                input: "YYYY-MM-DD",
            },
        }
    },
    methods: {
        async Guardar()
        {
            this.errorvalidacion      = [];
            this.poBtnSave.toast      = 0; 
            this.poBtnSave.DisableBtn = true;

            if(this.objInhabil.Fecha!=''){
                this.objInhabil.Fecha = moment(this.objInhabil.Fecha).format('YYYY-MM-DD');
            }

            if(this.objInhabil.IdInhabil == 0) {
                this.$http.post('diasinhabiles',this.objInhabil).then((res)=>{                 
                    this.EjecutaConExito(res);
                }).catch(err=>{   
                    this.EjecutaConError(err);
                });
            } else {
                this.$http.put(
                    'diasinhabiles/'+this.objInhabil.IdInhabil,
                    this.objInhabil 
                ).then((res)=>{                 
                    this.EjecutaConExito(res);
                }).catch(err=>{   
                    this.EjecutaConError(err);
                });
            }
        },
        EjecutaConExito(res)
        {
            this.poBtnSave.DisableBtn = false;  
            this.poBtnSave.toast = 1;

            this.bus.$emit('CloseModal_'+this.Emit);
            this.bus.$emit('List_'+this.Emit);
        },
        EjecutaConError(err)
        {
            this.poBtnSave.DisableBtn = false;
            
            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.poBtnSave.toast = 2;
            } else {
                this.$toast.error(err.response.data.message);
            }
        },
        Recuperar()
        {
            this.$http.get(
                "diasinhabiles/"+this.objInhabil.IdInhabil
            ).then((res) => {
                this.objInhabil = res.data.data;

                let Fecha1 = this.objInhabil.Fecha.replace(/-/g,'\/');
                this.objInhabil.Fecha = Fecha1;

            }).finally(()=>{
                this.ConfigLoad.ShowLoader = false;
            });
        },
        Limpiar()
        {
            this.objInhabil = {
                IdInhabil: 0,
                Fecha: '',
                Anio: '',
                Comentario: ''
            };
        },
    },
    created() {
        this.poBtnSave.toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(Id)=>
        {
            this.ConfigLoad.ShowLoader = true;    
            this.poBtnSave.DisableBtn  = false;

            this.bus.$off('Save_'+this.Emit);
            this.bus.$on('Save_'+this.Emit,()=>
            {
                this.Guardar();
            });

            this.Limpiar();
                
            if(Id!='') {
                this.objInhabil.IdInhabil = Id;
                this.Recuperar();
            } else {
                this.ConfigLoad.ShowLoader = false;
            }
        });
    },
}
</script>