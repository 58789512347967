<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">

                            <div class="form-group row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <label for="TipoFamiliar">Tipo de Familiar</label>
                                    <input v-model="objTipoFamiliar.TipoFamiliar" class="form-control"/>
                                </div>
                            </div>

                        </div><!--fin col-12-->
                    </div>
                </div>
            </form>
        </template>
    </CLoader>
</template>

<script>
import moment from 'moment';

export default {
    name:  "FormTipoFamiliar",
    props: ['poBtnSave'],
    components:{
    },
    data() {
        return {
            ListaSucursalesArr: [],
            errorvalidacion: [],
            Emit: this.poBtnSave.EmitSeccion,
            ConfigLoad:{
                ShowLoader: true,
                ClassLoad: false,
            },
            objTipoFamiliar:{
                IdTipoFamiliar: 0,
                TipoFamiliar: '',
            },
            masks: {
                input: "YYYY-MM-DD",
            },
        }
    },
    methods: {
        async Guardar()
        {
            this.errorvalidacion      = [];
            this.poBtnSave.toast      = 0;
            this.poBtnSave.DisableBtn = true;



            if(this.objTipoFamiliar.IdTipoFamiliar === 0) {
                this.$http.post('tiposfamiliares',this.objTipoFamiliar)
                    .then((res)=>{
                        this.EjecutaConExito(res);
                    }).catch(err=>{
                        this.EjecutaConError(err);
                    });
            } else {
                this.$http.put('tiposfamiliares/'+this.objTipoFamiliar.IdTipoFamiliar, this.objTipoFamiliar)
                    .then((res)=>{
                        this.EjecutaConExito(res);
                    }).catch(err=>{
                        this.EjecutaConError(err);
                    });
            }
        },
        EjecutaConExito(res)
        {
            this.poBtnSave.DisableBtn = false;
            this.poBtnSave.toast = 1;

            this.bus.$emit('CloseModal_'+this.Emit);
            this.bus.$emit('List_'+this.Emit);
        },
        EjecutaConError(err)
        {
            this.poBtnSave.DisableBtn = false;

            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.poBtnSave.toast = 2;
            } else {
                this.$toast.error(err.response.data.message);
            }
        },
        Recuperar()
        {
            this.$http.get("tiposfamiliares/"+this.objTipoFamiliar.IdTipoFamiliar)
                .then((res) => {
                    this.objTipoFamiliar = res.data.data;

            }).finally(()=>{
                this.ConfigLoad.ShowLoader = false;
            });
        },
        Limpiar()
        {
            this.objTipoFamiliar = {
                IdTipoFamiliar: 0,
                TipoFamiliar: '',
            };
        },
    },
    created() {
        this.poBtnSave.toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(Id)=>
        {
            this.ConfigLoad.ShowLoader = true;
            this.poBtnSave.DisableBtn  = false;

            this.bus.$off('Save_'+this.Emit);
            this.bus.$on('Save_'+this.Emit,()=>
            {
                this.Guardar();
            });

            this.Limpiar();

            if(Id!='') {
                this.objTipoFamiliar.IdTipoFamiliar = Id;
                this.Recuperar();
            } else {
                this.ConfigLoad.ShowLoader = false;
            }
        });
    },
}
</script>
