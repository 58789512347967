import ClientesActivos     		 from "../views/modulos/crm/clientes/List.vue";
import FormularioClientesActivos from "../views/modulos/crm/clientes/FormActivos.vue";
import FormDetallePrestamo 	     from '../views/modulos/crm/clientes/FormPrestamo.vue';


const VarRutasClientes = [
	{
		path: "/ClientesActivos",
		name: "clientesActivos",
		component: ClientesActivos,
		props: true,
	},
	{
		path: "/FormularioClientesActivos",
		name: "formularioclientesactivos",
		component: FormularioClientesActivos,
		props: true,
	},
	{
		path: '/formdetalleprestamo',
		name: 'formdetalleprestamo',
		component: FormDetallePrestamo,
		props: true,
	}

];

export default {
	VarRutasClientes,
};
