<template>
    <CList :pConfigList="ConfigList" :segurity="segurity">
        <template slot="Filtros">
            <div class="form-group mx-sm-3">
                <label class="mr-1">Ordenar:</label>
                <select @change="EjecutaAsc" v-model="Orden" class="form-control form-select">
                    <option value="ASC">Ascendente</option>
                    <option value="DESC">Descendente</option>
                </select>
            </div>
        </template>
        <template slot="bodyForm">
            <EstadoCuentaPrestamo :pIdPrestamo="obj.IdPrestamo"/>
        </template>
    </CList>
</template>

<script>
import EstadoCuentaPrestamo from "@/views/modulos/crm/clientes/EstadoCuentaPrestamo.vue";
import Template from '../../template/Template.vue';
const EmitEjecuta = "seccionCobratarios";

export default {
    name: "PrestamoEstadoCuenta",
    props:['objPrestamo','pSitio'],
    components: {
        EstadoCuentaPrestamo
    },
    data() {
        return {
            ListaArrayRows: [],
            segurity: {},
            obj: {},
            Orden: 'ASC',
            Sitio: '',
            ConfigList: {
                ShowTitleFirst: false,
                Title: "Prestamo: ",
                IsModal: false,
                BtnNewShow: false,
                TypeBody: "Form",
                ShowLoader: true,
                BtnReturnShow: true,
                EmitSeccion: EmitEjecuta,
                ShowEntradas: false,
                ShowSearch: false,
                ShowPaginador: false,
            },
        };
    },
    methods: {
        Regresar() {
            let Ruta = 'diasiguiente';
            if(this.Sitio == 'Corte'){
                Ruta = 'cortedeldia';
            }
            this.$router.push({name: Ruta,params:{}});
        },
        EjecutaAsc()
        {
            this.bus.$emit('CargaDeNuevo',this.Orden)
        }
    },
    created() {
        this.bus.$off("EmitReturn");

        if(this.objPrestamo !==undefined){
            sessionStorage.setItem('ObjPrestamo',JSON.stringify(this.objPrestamo));
        }

        this.obj = JSON.parse(sessionStorage.getItem('ObjPrestamo'));
        this.ConfigList.Title = this.ConfigList.Title+' '+this.obj.Folio;

        if(this.pSitio !==undefined){
            sessionStorage.setItem('rutaSitio',JSON.stringify(this.pSitio));
        }

        this.Sitio = JSON.parse(sessionStorage.getItem('rutaSitio'));

    },
    mounted() {
        this.bus.$on("EmitReturn", () => {
            this.Regresar();
        });
    },
};
</script>
