<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
                <div class="container-fluid">
                    <template v-if="TipoComentario == 'Comentario'">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                                <div class="form-group form-row">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <label><b>Fecha:&nbsp;</b></label>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <label class="text-dark">{{objLiquidacion.FechaComentario}}</label>
                                    </div>
                                </div>

                                <div class="form-group form-row">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <label><b>Total Pagado:&nbsp;</b></label>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <label class="text-dark">{{$formatNumber(objLiquidacion.TotalPagado,'$')}}</label>
                                    </div>
                                </div>

                                <div class="form-group form-row">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <label><b>Total Multas:&nbsp;</b></label>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <label class="text-dark">{{objLiquidacion.MultaSinPagar}}</label>
                                    </div>
                                </div>

                                <div class="form-group form-row">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <label><b>Bonificacion:&nbsp;</b></label>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <label class="text-dark">{{$formatNumber(objLiquidacion.Bonificacion,'$')}}</label>
                                    </div>
                                </div>

                                <div class="form-group form-row">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <label><b>Total para Liquidar:&nbsp;</b></label>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <label class="text-dark">{{$formatNumber(objLiquidacion.TotalxLiquidar,'$')}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                                <div class="form-group form-row">
                                
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <span style="color:transparent;">invisible</span>
                                    </div>
                                </div>


                                <div class="form-group form-row">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <label><b>Saldo Pendiente:&nbsp;</b></label>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <label class="text-dark">{{$formatNumber(objLiquidacion.SaldoPendiente,'$')}}</label>
                                    </div>
                                </div>

                                <div class="form-group form-row">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <label><b>Monto Multas:&nbsp;</b></label>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <label class="text-dark">{{$formatNumber(objLiquidacion.MontoMulta,'$')}}</label>
                                    </div>
                                </div>

                                <div class="form-group form-row">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <label><b>Sub Total:&nbsp;</b></label>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <label class="text-dark">{{$formatNumber(objLiquidacion.SubTotal,'$')}}</label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <label for="Comentario">Comentario</label>
                                <textarea v-model="objLiquidacion.ComentarioLiquidacion" class="form-control" placeholder="Ingresar un comentario" rows="5"></textarea>
                                <CValidation v-if="this.errorvalidacion.ComentarioLiquidacion" :Mensaje="'*'+errorvalidacion.ComentarioLiquidacion[0]"/>
                            </div>
                        </div>
                    
                    </template>
                    <template v-else>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <label for="Comentario">Comentario</label>
                                <textarea v-model="objLiquidacion.ComentarioBitacora" disabled class="form-control" placeholder="Ingresar un comentario" rows="5"></textarea>
                                <CValidation v-if="this.errorvalidacion.ComentarioLiquidacion" :Mensaje="'*'+errorvalidacion.ComentarioLiquidacion[0]"/>
                            </div>
                        </div>
                    </template>
                    
                </div>
            </form>
        </template>
    </CLoader>
</template>

<script>
import Template from '../../../template/Template.vue';

    export default {
  components: { Template },
        name:  "FormComentarioLiquidacion",
        props: ['poBtnSave'],
        data() {
            return {
                Emit:               this.poBtnSave.EmitSeccion,
                errorvalidacion:    [],
                ConfigLoad:{
                    ShowLoader: true,
                    ClassLoad:  false,
                },
                objLiquidacion:{
                    IdLiquidacionxPrestamo: 0,
                    ComentarioLiquidacion:  '',
                },
                TipoComentario:''
            }
        },
        methods: {
            async Guardar() {
                this.errorvalidacion      = [];
                this.poBtnSave.toast      = 0; 
                this.poBtnSave.DisableBtn = true;

                this.$http.put('liquidacioncomentarios/'+this.objLiquidacion.IdLiquidacionxPrestamo,this.objLiquidacion).then((res)=>{                 
                    this.EjecutaConExito(res);
                }).catch(err=>{   
                    this.EjecutaConError(err);
                });
            },
            EjecutaConExito(res) {
                this.poBtnSave.DisableBtn = false;  
                this.poBtnSave.toast      = 1;
                this.bus.$emit('CloseModal_'+this.Emit);
                this.bus.$emit('List_'+this.Emit);
            },
            EjecutaConError(err) {
                this.poBtnSave.DisableBtn = false;
                
                if(err.response.data.errors){
                    this.errorvalidacion = err.response.data.errors;
                    this.poBtnSave.toast = 2;
                } else {
                    this.$toast.error(err.response.data.message);
                }
            },
            Recuperar() {
                this.$http.get("liquidacioncomentarios/"+this.objLiquidacion.IdLiquidacionxPrestamo).then( (res) => {
                    this.objLiquidacion = res.data.data;
                }).finally(()=>{
                    this.ConfigLoad.ShowLoader = false;
                });
            },
            Recuperar2() {
                this.$http.get("getbitacoracomentario/"+this.objLiquidacion.IdLiquidacionxPrestamo).then( (res) => {
                    this.objLiquidacion = res.data.data;
                }).finally(()=>{
                    this.ConfigLoad.ShowLoader = false;
                });
            },
            Limpiar() {
                this.objLiquidacion = {
                    IdLiquidacionxPrestamo: 0,
                    ComentarioLiquidacion:  ''
                };
                this.errorvalidacion = [];
            },
        },
        created() {
            this.poBtnSave.toast = 0;

            this.bus.$off('Recovery_'+this.Emit);
            this.bus.$on('Recovery_'+this.Emit,(obj)=> {
                this.ConfigLoad.ShowLoader = true;    
                this.poBtnSave.DisableBtn  = false;

                this.bus.$off('Save_'+this.Emit);
                this.bus.$on('Save_'+this.Emit,()=> {
                    this.Guardar();
                });

                this.Limpiar();
                this.objLiquidacion = obj
                
                
                    
                if(this.objLiquidacion.IdLiquidacionxPrestamo!='')  {
                    this.TipoComentario = obj.Tipo;

                    if(obj.Tipo == 'Comentario') {
                        this.Recuperar();
                    }else {
                        
                        this.Recuperar2();
                    }
                    
                } else {
                    this.ConfigLoad.ShowLoader = false;
                }

            });
        },
    }
    
</script>