<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <label for="password">Contraseña</label>
                            <input v-model="objLiquidacion.Password" :type="type" placeholder="Ingrese su contraseña" class="form-control">
                            <button @click="ToggleShow" class="button btn-password-formulario-recovery" type="button" id="button-addon2">
                                <i class="far icono-password" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </template>
    </CLoader>
</template>

<script>

    export default {
        name:  "FormComentarioLiquidacion",
        props: ['poBtnSave'],
        data() {
            return {
                showPassword:       false,
                type:               'password',
                Emit:               this.poBtnSave.EmitSeccion,
                errorvalidacion:    [],
                ConfigLoad:{
                    ShowLoader: true,
                    ClassLoad:  false,
                },
                objLiquidacion:{
                    Password:  '',
                },
            }
        },
        methods: {
            async Guardar() {
                this.errorvalidacion      = [];
                this.poBtnSave.toast      = 0; 
                this.poBtnSave.DisableBtn = true;

                this.$http.post('usersVerifyPassword', this.objLiquidacion).then((res) =>{                 
                    this.EjecutaConExito(res);
                }).catch(err=>{   
                    this.EjecutaConError(err);
                });
            },
            EjecutaConExito(res) {
                this.poBtnSave.DisableBtn = false;  
                this.$toast.success('Las credenciales del usuario validas');
                this.bus.$emit('pVisible',res.data.visible);
                this.bus.$emit('CloseModal_'+this.Emit);
                this.bus.$emit('List_'+this.Emit);
            },
            EjecutaConError(err) {
                this.poBtnSave.DisableBtn = false;
                
                if(err.response.data.errors){
                    this.errorvalidacion = err.response.data.errors;
                    this.poBtnSave.toast = 2;
                } else {
                    this.$toast.error(err.response.data.message);
                }
            },
            ToggleShow() {

                if (this.showPassword = !this.showPassword) {                    
                    this.type = 'text'
                } else {
                    this.type = 'password'
                }
                
            },
            Limpiar() {
                this.objLiquidacion = {
                    Password: '',
                };
            },
        },
        created() {
            this.poBtnSave.toast = 0;

            this.bus.$off('Recovery_'+this.Emit);
            this.bus.$on('Recovery_'+this.Emit,(obj)=> {
                this.ConfigLoad.ShowLoader = false;    
                this.poBtnSave.DisableBtn  = false;

                this.bus.$off('Save_'+this.Emit);
                this.bus.$on('Save_'+this.Emit,()=> {
                    this.Guardar();
                });

                this.Limpiar();

                this.objLiquidacion = obj;

            });
        },
    }
    
</script>