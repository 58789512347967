<template>
	<div>
		<CList @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
			<template slot="Filtros">
				<select id="IdSucursal" v-model="Filtro.IdSucursal" @change="Lista();" v-show="ShowCmbSucursales" class="form-control form-select mr-2">
					<option v-for="(item, index) in ListaSucursalesArray" :key="index" :value="item.IdSucursal" >
						{{ item.Nombre }}
					</option>
				</select>

				<button type="button" v-b-tooltip.hover.Top title="Recargar" @click="Lista()" class="btn btn-primary btn-sm mr-2">
					<i class="fas fa-redo"></i>
				</button>
			</template>
			<template slot="header">
                <th class="td-sm"></th>
                <th>#</th>
                <th>Nombre Cliente</th>
                <th>Teléfono</th>
                <th>Monto Solicitado</th>
                <th>Fecha Registro</th>
                <th>Estatus</th>
                <th class="text-center">Acciones</th>
			</template>

			<template slot="body">
				<tr v-for="(lista, index) in ListaArrayRows" :key="index">
					<td class="td-sm"></td>
                    <td>{{ $getNumItem(index) }}</td>
                    <td><b>{{ lista.NombreCompleto }}</b></td>
                    <td>{{ lista.Telefono }}</td>
                    <td>{{ $formatNumber(lista.MontoSolicitud,'$') }}</td>
                    <td>{{ $getCleanDate(lista.FechaPrestamo,false) }}</td>
					<td>
						<b-badge pill variant="primary">{{ lista.EstatusP }}</b-badge>
					</td>
					<td class="text-center">
						<CBtnAccion :pShowBtnEdit="false" :pShowBtnDelete="false" :pIsModal="false" :pId="lista.IdCliente" :pEmitSeccion="ConfigList.EmitSeccion" :pGoRoute="ConfigList.GoRoute" :segurity="segurity">
							<template slot="btnaccion">
                                <button type="button" v-b-tooltip.hover.Top title="Editar" class="btn btn-primary btn-icon mr-1" @click="editarPrestamo(lista)">
                                    <i class="fas fa-pencil"></i>
                                </button>

								<button type="button" v-b-tooltip.hover.Top title="Aprobar" @click="Preautorizar(lista)" class="btn btn-success btn-icon mr-1">
									<i class="fas fa-check"></i>
								</button>
								<button type="button" v-b-tooltip.hover.Top title="Rechazar" @click="Rechazo(lista)" class="btn btn-danger btn-icon">
									<i class="fas fa-ban"></i>
								</button>
                            </template>
						</CBtnAccion>
					</td>
				</tr>
				<CSinRegistro :pContIF="ListaArrayRows.length" :pColspan="8"></CSinRegistro>
			</template>
		</CList>

		<CModal :pConfigModal="ConfigModal" :poBtnSave="oBtnSave">
			<template slot="Form">
				<FormMotivoRechazo :poBtnSave="oBtnSave"></FormMotivoRechazo>
			</template>
		</CModal>
	</div>
</template>

<script>

	import FormMotivoRechazo from "@/views/modulos/crm/prestamos/FormRechazo.vue";
	import Configs 	 	  	 from "@/helpers/VarConfig.js";
	const  EmitEjecuta 	  	 = 	  "seccionPrestamo";

	export default {
		name:  "ListaPrestamos",
        props: ['sFiltros'],
		components: {
			FormMotivoRechazo
		},
		data() {
			return {
                counterField: 	1,
				ListaArrayRows: [],
				ListaHeader: 	[],
				segurity: 		{},
				obj: 	  		{},
				ShowCmbSucursales: false,
				ListaSucursalesArray: [],

				ConfigList: {
					TitleFirst:    'Menús Prestamos',
					Title: 		   'Listado Préstamos',
					IsModal:       false,
					ShowLoader:    true,
					BtnNewShow:    false,
                    BtnReturnShow: true,
                    TypeBody:      "List",
					//GoRoute: 	   'formularioprestamos',
					//GoRoute: 	   'prospectosdetalles',
					EmitSeccion:   EmitEjecuta,
				},
				Filtro: {
                    Nombre: 	 '',
					Pagina: 	 1,
					Entrada: 	 25,
					TotalItem: 	 0,
					Placeholder: 'Buscar..',
					IdSucursal: 0
				},
				oBtnSave: {
					toast: 		 0,
					IsModal: 	 true,
					DisableBtn:  false,
					EmitSeccion: EmitEjecuta,
				},
				ConfigModal: {
					ModalTitle: 'Motivo de Rechazo',
					ModalSize:  'md',
					EmitSeccion: EmitEjecuta,
				},
			};
		},
		methods: {
			ListaSucursales() {
                this.$http.get("sucursales", {
                    params: {
                        simple: 1
                    },
                })
                    .then((res) => {
                        let response = res.data.dataSuc;
                        this.ListaSucursalesArray = res.data.data;

                        if(response.EsAdmin == 0)
                        {
                            this.Filtro.IdSucursal = response.IdSucursal;

                        }
                        else
                        {
                            this.ShowCmbSucursales = true;

                            if(this.ListaSucursalesArray.length>0)
                            {
                                this.Filtro.IdSucursal = this.ListaSucursalesArray[0].IdSucursal;
                            
                            }
                        }
                    }).finally(()=>{
                        this.Lista();
                });
            },

			async Lista() {
                this.ConfigList.ShowLoader = true;

                await this.$http.get("prospectosxprestamos", {
                    params: {
                        TxtBusqueda: 	 this.Filtro.Nombre,
                        Entrada: 	 	 this.Filtro.Entrada,
                        Pag: 		 	 this.Filtro.Pagina,
                        IdSucursal: 	 this.Filtro.IdSucursal,
                        Estatus: 		 'Activo',
                        EstatusPrestamo: 'Pendiente',
                        isCliente:		  1,
                    },

                }).then((res) => {
                    this.Filtro.Pagina    = res.data.data.current_page;
                    this.Filtro.TotalItem = res.data.data.total;
                    this.ListaArrayRows   = res.data.data.data;
					this.segurity         = res.data.segurity;
                    this.$setStartItem();
                }).finally(() => {
                    this.ConfigList.ShowLoader = false;
                });
			},
			Preautorizar(item) {
				this.$swal(Configs.configAutorizarPrestamo).then((result) => {
					if (result.value) {

                        this.errorvalidacion = [];

                        let request = {
                            IdCliente: item.IdCliente,
                            IdPrestamo: item.IdPrestamo,
                            Operacion: 'AceptoPrestamo',
                            MotivoRechazo: ''
                        };

                        this.$http.post('prospestoPrestamoEtapas', request).then( (res) => {
                            this.$toast.success('Información Guardada');
                            this.Lista();

                        }).catch((err) => {
                            this.$toast.error('No se pudo actulizar la información');
                        });

					}
				});
			},
			Rechazo(Element) {
				this.bus.$emit('NewModal_'+EmitEjecuta, Element);
			},
			Timer() {
				this.IntervalTime = setInterval(
					function () {
						this.Lista("");
					}.bind(this),300000
				);
			},
			Regresar() {
				this.$router.push({name: 'menuprestamos',params:{}});
			},

            editarPrestamo(item){
                this.$router.push({name: 'prospectosdetalles',params:{Id: item.IdCliente, pOrigen: "PRESTAMOS" }});
            }
		},
		created() {

			this.bus.$off("Delete_" + EmitEjecuta);
			this.bus.$off("List_" + EmitEjecuta);
			this.bus.$off("EmitReturn");
		},
		mounted() {
			this.ListaSucursales();
			//this.Timer();

			this.bus.$on("List_" + EmitEjecuta, () => {
				this.Lista();
			});

			this.bus.$on("EmitReturn", () => {
				this.Regresar();
			});

		},
		destroy() {
			//this.Timer();
		}
	};

</script>
