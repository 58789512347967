<template>
    <div>
        <CList :p-config-list="ConfigList">
            <template slot="bodyForm">
                <div class="container-flex justify-content-center">
                    <div v-if="VerificaApartadosVisible('CLAVEPENDIENTES')" class="container-box m-2">
                        <router-link :to="{name:'prestamos'}" class="color_text">
                            <div class="flexbox-01 text-center">
                                <i class="fad fa-user-clock"></i>
                            </div>
                            <p class="text-center text-box">Pendientes</p>
                        </router-link>
                    </div>

                    <div v-if="VerificaApartadosVisible('CLAVEAUTORIZADOS')" class="container-box m-2">
                        <router-link :to="{name:'autorizados'}" class="color_text">
                            <div class="flexbox-01 text-center">
                                <i class="fad fa-check-double"></i>
                            </div>
                            <p class="text-center text-box">Autorizados</p>
                        </router-link>
                    </div>

                    <div v-if="VerificaApartadosVisible('CLAVEPORENTREGAR')" class="container-box m-2">
                        <router-link :to="{name:'porentregar'}" class="color_text">
                            <div class="flexbox-01 text-center">
                                <i class="fad fa-hand-holding-usd"></i>
                            </div>
                            <p class="text-center text-box">Entregas</p>
                        </router-link>
                    </div>

                    <div v-if="VerificaApartadosVisible('CLAVERECHAZADOS')" class="container-box m-2">
                        <router-link :to="{name:'rechazados'}" class="color_text">
                            <div class="flexbox-01 text-center">
                                <i class="fad fa-ban"></i>
                            </div>
                            <p class="text-center text-box">Rechazados</p>
                        </router-link>
                    </div>
                </div>
            </template>
        </CList>
    </div>
</template>

<script>

const EmitEjecuta = 'seccionMenus';

export default {
    name:       "FormMenus",
    props:      [''],
    components: { },
    data() {
        return {
            emmit:           EmitEjecuta,
            ConfigList: {
                ShowTitleFirst:    false,
                Title:             "Menús Prestamos",
                IsModal:           false,
                BtnNewShow:        false,
                BtnReturnShow:     false,
                TypeBody:          "Form",
                ShowFiltros:       false,
                ShowFiltrosInline: false,
                ShowTitleInline:   false,
                ShowPaginador:     false,
                EmitSeccion:       EmitEjecuta
            },
            ListApartados: [],
			VerMenu: 0,
        }
    },
    methods:{
        VerificarPermisos()
		{
			this.$http.get(
				'panelmenuGetMenus',{}
			).then((res) => {
				this.ListApartados = res.data.Apartados;
				this.VerMenu = res.data.VerMenu;
			});
		},
        VerificaApartadosVisible(Clave)
		{
			let Val = this.ListApartados.find( item => item.Clave == Clave);
			if(this.VerMenu>0){
				return true;
			}
			if (!Val) return false;

            return true;
		}
    },
    created() {
		this.VerificarPermisos();
	},
}
</script>
