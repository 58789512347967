<template>
    <CTabla @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
        <template slot="header">
            <th class="td-sm"></th>
            <th width="10%" class="td-sm">IdPrestamoPag</th>
            <th width="10%" class="td-sm">IdMulta</th>
            <th width="13%" class="text-center">F. Emisión</th>
            <th width="10%" class="text-center">F. Real de Pago</th>
            <th width="10%" class="text-right">Monto Pagado</th>
            <th width="8%" class="text-center">Estado Actual</th>
            <th width="8%" class="text-center">Acciones</th>
        </template>

        <template slot="body">
            <tr v-for="(lista,index) in ListaArrayRows" :key="index" >
                <td class="td-sm">{{(index+1)}}</td>
                <td class="td-sm">{{lista.IdPrestamosPago}}</td>
                <td class="td-sm">{{lista.IdPrestamoMulta}}</td>
                <td class="td-sm">
                    <input type="text" class="form-control" v-model="lista.FechaEmision">
                </td>
                <td class="td-sm">{{lista.FechaPago}}</td>
                <td class="td-sm text-center">{{lista.MontoPagado}}</td>
                <td class="text-center">
                    <b-badge v-if="lista.Estatus == 'Pagada'" pill variant="success">{{ lista.Estatus }}</b-badge>
                    <b-badge v-if="lista.Estatus == 'NoPagada'" pill variant="danger">No Pagada</b-badge>
                    <b-badge v-if="lista.Estatus == 'Condonada'" pill variant="primary">{{ lista.Estatus }}</b-badge>
                </td>
                <td class="text-center">
                    <CBtnAccion :pShowBtnEdit="false" :pShowBtnDelete="true" :pIsModal="false" :pId="lista.IdPrestamoMulta" :pEmitSeccion="ConfigList.EmitSeccion" :segurity="segurity">
                        <template slot="btnaccion">
                            <button type="button" @click="SaveMulta(lista)" class="btn btn-success btn-icon ml-2">
                                <i class="fas fa-save fa-2x"></i>
                            </button>
                        </template>
                    </CBtnAccion>
                </td>
            </tr>
            <CSinRegistro :pContIF="ListaArrayRows.length" :pColspan="10"></CSinRegistro>
        </template>
    </CTabla>
</template>

<script>

const EmitEjecuta = 'seccionMultas';
import Configs    from '@/helpers/VarConfig.js';

export default {
    name: 'ListMultas',
    props: ['poBtnSave'],
    components: { },
    data() {
        return{
            ConfigLoad:{
                ShowLoader: false,
                ClassLoad: false,
            },
            ConfigList:{
                Title: 'Listado de Multas',
                ShowLoader: false,
                BtnNewShow: false,
                ShowTitleFirst: false,
                IsModal: true,
                EmitSeccion: EmitEjecuta,
            },
            Filtro:{
                Nombre: '',
                Pagina: 1,
                Entrada: 25,
                TotalItem: 0,
                Placeholder: 'Buscar..',
            },
            Emit: this.poBtnSave.EmitSeccion,
            errorvalidacion: [],
            ListaArrayRows: [],
            ListaHeader: [],
            Prestamo: {
                IdPrestamo: 0
            },
            segurity: {},
            obj: {},
        }
    },
    methods:{
        async Lista()
        {
            this.ConfigList.ShowLoader = true;

            await this.$http.get('prestamosmultas', {
                params:{
                    TxtBusqueda: this.Filtro.Nombre,
                    Entrada:     this.Filtro.Entrada,
                    Pag:         this.Filtro.Pagina,
                    IdPrestamo:  this.Prestamo.IdPrestamo,
                }
            }).then( (res) => {
                this.ListaArrayRows   = res.data.data.data;
                this.Filtro.Pagina    = res.data.data.current_page;
                this.Filtro.TotalItem = res.data.data.total;
                this.segurity.Delete = 1;
            }).finally(()=>{
                this.ConfigList.ShowLoader = false;
            });
        },
        Eliminar(Id) {
            this.$swal(Configs.configEliminar).then((result) => {
                if(result.value) {
                    this.$http.delete('prestamosmultas/'+Id)
                    .then( (res) => {
                        this.$swal(Configs.configEliminarConfirm);
                        this.Lista();
                    })
                    .catch( err => {
                        this.$toast.error(err.response.data.message);
                    });
                }
            });
        },
        SaveMulta(item)
        {
            let obj = {
                IdMulta: item.IdPrestamoMulta,
                FechaEmision: item.FechaEmision,
            }

            this.$http.put('prestamosmultas/'+obj.IdMulta,
                obj
            ).then((res)=>{                 
                this.$toast.success('Información Guardada');
            }).catch(err=>{   
                this.$toast.error(err.response.data.message);
            });
        }
    },
    created(){
        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(obj)=>
        {
            this.bus.$off('Delete_'+EmitEjecuta);

            this.bus.$on('Delete_'+EmitEjecuta,(Id)=> {
                this.Eliminar(Id);
            });

            this.Prestamo.IdPrestamo = obj
            this.Lista();
        });
    },
}

</script>
