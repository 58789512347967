<template>
    <div>
		<CList @FiltrarC="Lista()" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">	
            <template slot="Filtros">
				<div class="form-group">
					<select id="IdSucursal" v-model="IdSucursal" @change="ListaCobratarios();" v-show="ShowCmbSucursales" class="form-control form-select mr-2" :disabled="IsLoader.BloqCmb1">
						<option v-for="(item, index) in ListaSucursalesArray" :key="index" :value="item.IdSucursal" >
							{{ item.Nombre }}
						</option>
					</select>

					<div class="form-group mx-sm-2">
						<v-date-picker v-model="objFiltroFechas" @input="ListaCobratarios" :masks="masks" :popover="{ visibility: 'focus' }" locale="es" :max-date="new Date()" :disabled="IsLoader.BloqCmb3">
							<template v-slot="{ inputValue, inputEvents }">
								<input class="form-control cal" placeholder="Seleccione una Fecha" :value="inputValue" v-on="inputEvents" readonly/>
							</template>
						</v-date-picker>
					</div>

					<treeselect v-model="IdCobratario" @input="Lista()" :options="ListaUsers" :multiple="false" :value-consists-of="'ALL_WITH_INDETERMINATE'" class="vue-treeselect__control-2 mr-2" placeholder="Todos" :disabled="IsLoader.BloqCmb2"/>

					<button type="button" @click="getPdfCorteGenerado();" v-b-tooltip.hover.Top title="Imprimir Corte" class="btn btn-primary btn-sm mr-2">
						<i class="far fa-print"></i>
					</button>
					
					<button type="button" @click="Lista" v-b-tooltip.hover.Top title="Recargar" class="btn btn-primary btn-sm mr-2" :disabled="IsLoader.BtnLoad.Bloq">
						<i :class="IsLoader.BtnLoad.Icon"></i>
					</button>

					<template v-if="IdCobratario && EstatusDia=='EnCierre'">
						<button type="button" @click="GenerarCortaDelDia()" v-b-tooltip.hover.Top title="Cerrar Corte" class="btn btn-danger btn-sm mr-2" :disabled="IsLoader.BtnCorte.Bloq">
							<i :class="IsLoader.BtnCorte.Icon"></i>
						</button>
					</template>
				</div>
			</template>

			<template slot="Configuration">
				<div class="row justify-content-around">
					<div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mb-1">
						<div class="icon-card">
							<div class="icon primary">
								<i class="fad fa-user"></i>
							</div>	
							<div class="form-inline" v-if="IdCobratario === 0 || IdCobratario === undefined">
								<h3 class="text-h3 text-right">
									Cobratario: Todos
								</h3>	
							</div>
							<div class="form-inline" v-else>
								<h6 class="text-h6 mr-2">Cobratario</h6>		
								<div class="content form-inline" v-for="(lista, index) in ListaUsers" :key="index" :value="lista.id">	
									<h3 class="text-h3 text-right" v-if="IdCobratario === lista.id">
										{{ lista.label }}
									</h3>		
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mb-1">
						<div class="icon-card">
							<div class="icon primary">
								<i class="fad fa-dollar-sign"></i>
							</div>
							<div class="content form-inline">								
								<h6 class="text-h6 mr-2">Productividad</h6>
								<h3 class="text-h3 mr-2">
									{{ $formatNumber(objTotales.TotalProductividad1, '$') }} / {{ $formatNumber(objTotales.TotalProductividad2, '$') }} = 
									<span class="text-success" v-if="this.objTotales.PorcentajeProductividad > 50">
										{{ $formatNumber(objTotales.PorcentajeProductividad, '') }} %
									</span>
									<span class="text-danger" v-else>
										{{ $formatNumber(objTotales.PorcentajeProductividad, '') }} %
									</span>
								</h3>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mb-1">
						<div class="icon-card">		
							<div class="icon primary">
								<i class="fad fa-dollar-sign"></i>
							</div>
							<div class="content form-inline">
								<h6 class="text-h6 mr-2">Corte</h6>
								<h3 class="text-h3">{{ $formatNumber(objTotales.TotalCorte, '$') }} </h3>
							</div>
						</div>
					</div>
				</div>
			</template>

			<template slot="header">
				<th># Folio ({{ListaArrayRows.length}})</th>
				<th>Día</th>
				<th>Abono</th>
				<th>Pagos</th>
				<th>Multas Pagadas</th>
				<th>Pago Diario</th>
				<th>Cobrado por:</th>
				<th>Saldo</th>
				<th>Pago Requerido</th>
				<th>Pago del Dia</th>
				<th>Total Pagado</th>
				<th>Acciones</th>
			</template>

			<template slot="body">
				<tr v-for="(lista, index) in ListaArrayRows" :key="index">
					<td class="cursor" @click="irAEstadoDeCuenta(lista)"> 
						<CAvatar v-if="lista.NombreCompleto!=''" :fullname="lista.NombreCompleto" :size="25" :radius="50" v-b-tooltip.hover.Top :title="lista.NombreCompleto" style="margin-bottom: 0px;"/>&nbsp;
                        <b>{{ lista.Folio }}</b>
                    </td>
					<td>{{ lista.NumDia }}</td>
					<td>{{ lista.PNumPagoCubierto }} / {{lista.NumPagos}}</td>
					<td>{{ lista.NumPagoActual }}</td>
					<td>
						({{ lista.NumMultasPagadas }}) 
						{{$formatNumber((lista.MontoMultasPagadas !='' && lista.MontoMultasPagadas !=null)?lista.MontoMultasPagadas:0, '$')}}
					</td>
					<td>{{ $formatNumber(lista.MontoDiario,'$') }}</td>
					<td>
						<span v-if="lista.UsuarioCobro !== null && lista.IdUsuarioCobro != 0">
							<CAvatar v-if="lista.IdCobratario != lista.IdUsuarioCobro" :fullname="lista.UsuarioCobro" :size="25" :radius="50" v-b-tooltip.hover.Top :title="lista.UsuarioCobro" style="margin-bottom: 0px;"/>
						</span>
					</td>
					<td>{{ $formatNumber(lista.SaldoAlDia,'$') }}</td>
					<td>{{ $formatNumber(lista.PagoRequerido,'$') }}</td>
					<td>{{ $formatNumber(lista.PagoDelDia,'$') }}</td>
					<td>{{ $formatNumber(parseFloat(lista.TotalPagado),'$') }}</td>
					<td>
						<template v-if="(EstatusDia != 'AlDia' && EstatusDia != '' && lista.CorteRealizado == 0)">
							<button type="button" @click="RealizarLiquidacion(lista)" class="btn btn-sm btn-primary btn-icon mr-1" v-b-tooltip.hover.Top title="Corregir Pagos">
								<i class="fal fa-file-alt"></i>
							</button>
						</template>
						<button type="button" v-if="parseInt(lista.comentario_count) > 0" class="btn btn-info btn-icon mr-1" @click="viewsComentarios(lista)" v-b-tooltip.hover.Top title="Ver comentario del pago">
                            <i class="fas fa-comment-dots"></i>
                        </button>		
					</td>
				</tr> 
				<CSinRegistros :pContIF="ListaArrayRows.length" :pColspan="12" />
			</template>
		</CList>

		<CModal :pConfigModal="ConfigModalComentarios">
			<template slot="Form">
				<ComentariosPagos :poBtnSave="poBtnSaveComentarios" :pSitio="Origen" />
			</template>
		</CModal>

		<CModal :pConfigModal="ConfigModalLiq" :poBtnSave="poBtnSaveLiq">
			<template slot="Form">
				<FormCorreccion :poBtnSave="poBtnSaveLiq" :pSitio="Origen" />
			</template>
		</CModal>
	</div>
</template>

<script>

import CList from "../../../../components/CList";
import CSinRegistros from "../../../../components/CSinRegistros";
import ComentariosPagos from "@/views/modulos/crm/clientes/ComentariosPagos.vue";
import FormCorreccion from '@/views/modulos/cobranza/diaSiguiente/FormPagosCorreccion.vue';
import moment from 'moment';
const  EmitEjecuta	= "seccionCortedeDia";
const  EmitEjecuta2	= "seccionEstadoCuentaComentarios";
const EmitEjecuta5	= "seccionLiquidacion";

export default {
	name: "CortedeDia",
	props:['sFiltros'],
	components: {
		CList,
		CSinRegistros,
		ComentariosPagos,
		FormCorreccion
	},	
	data() {
		return {	
			FiltroFecha: '',
			errorvalidacion: [],
			ListaArrayRows: [],
			arreglo: [],
			objTotales: {
				TotalProductividad1: 0,
				TotalProductividad2: 0,
				TotalCorte: 0,
				PorcentajeProductividad: 0,
			},	
			objFiltroFechas: {},
			segurity: {},
			obj: {},
			masks: {
				input: "YYYY-MM-DD",
			},
			ConfigList: {
				ShowTitleFirst:	false,
				Title:			"Cobratarios - Corte del Dia",
				IsModal:		false,
				BtnNewShow:		false,
				TypeBody:		"ListCobranza",
				ShowLoader:		true,
				EmitSeccion:	EmitEjecuta,
				ShowSearch:		false,
				ShowEntradas:	false,
			},
			ConfigBtn: {
				BtnNewPaymentShow: true,
			},
			Filtro: {
				Nombre: 	  "",
				Pagina: 	  1,
				Entrada: 	  100,
				TotalItem: 	  0,
				Placeholder:  "Buscar..",
				EstatusP: 	  "Cobranza",
			},
			objPrestamo:{
				IdCliente:		 0,
				Prospecto:		 0,
				Estatus:		 '',
				MotivoRechazo:	 '',
				FechaRechazo:	 '',
				IdPrestamo:		 0,
				clienteRechazo:	 '',
				PrestamoRechazo: '',
				IdCobratario:	 0
			},
			ListaUsers: 		  [],	
			ListaSucursalesArray: [],
			IdCobratario: 		  null,
			IdSucursal: 		  0,
			ShowCmbSucursales: 	  false,
			isVisible:		   	  false,
			ConfigModalComentarios: {
                ModalTitle:  "Comentarios del Pago",
                ModalNameId: 'ModalForm3',
                EmitSeccion: EmitEjecuta2,
                ModalSize:   'md',
                ShowFooter: false,
            },
            poBtnSaveComentarios: {
                toast:       0,
                IsModal:     true,
                DisableBtn:  false,
                EmitSeccion: EmitEjecuta2,
                ShowBtnSave: true
            },
			ConfigModalLiq: {
                ModalTitle:  "Corrección Pagos",
                ModalNameId: 'ModalForm5',
                EmitSeccion: EmitEjecuta5,
                ModalSize:   'md',
            },
            poBtnSaveLiq:{
                toast:       0,
                IsModal:     true,
                DisableBtn:  false,
                EmitSeccion: EmitEjecuta5,
            },
			objFiltrados:{
				IdSucursal: 0,
				IdCobratario: 0,
				Fecha: '',
				ShowSuc: '',
			},
			arrayGestores: [],
			EstatusDia: '',
			Origen: 'Corte',
			IsLoader:{
				BtnCorte:{
					Bloq: false,
					Icon: 'far fa-flag',
					Orig: 'far fa-flag',
				},
				BtnLoad:{
					Blod: false,
					Icon: 'far fa-redo',
					Orig: 'far fa-redo',
				},
				DefaultSpiner: 'fa fa-spinner fa-pulse fa-1x fa-fw',
				DefaultText: ' Espere...',
				BloqCmb1: true,
				BloqCmb2: true,
				BloqCmb3: true,
			}
		};
	},
	methods: {
		async Lista()
		{
			this.Bloquea(1);
			this.IsLoader.BtnLoad.Icon = this.IsLoader.DefaultSpiner;
			this.IsLoader.BtnCorte.Icon = this.IsLoader.DefaultSpiner;
			
			if(this.IdCobratario == null){
				this.objPrestamo.IdCobratario = 0;
			}
			else{
				this.objPrestamo.IdCobratario = this.IdCobratario;
			}

			this.ConfigList.ShowLoader = true;
			this.FiltroFecha = moment(this.objFiltroFechas).format('YYYY-MM-DD');

			let IdRuta = this.GetRuta();

			await this.$http.get("cobranzalistadocorte", {
				params: {
					TxtBusqueda:    this.Filtro.Nombre,
					Entrada: 	    this.Filtro.Entrada,
					Pag: 		    this.Filtro.Pagina,
					IdCobratario:   this.objPrestamo.IdCobratario,
					Fecha: 			this.FiltroFecha,
					IdSucursal: 	this.IdSucursal,
					IdRuta: 		IdRuta,
				},
			}).then( (res) => {
				const RESP 				       	 	 	= res.data;
				this.objTotales.TotalProductividad1 	= RESP.data.TotalProductividad1;
				this.objTotales.TotalProductividad2 	= RESP.data.TotalProductividad2;
				this.objTotales.TotalCorte 	 			= RESP.data.TotalCorte;
				this.objTotales.PorcentajeProductividad = RESP.data.PorcentajeProductividad;
				this.ListaArrayRows   			 	 	= res.data.lista;
				// this.Filtro.Pagina 	  			 	 	= res.data.data.current_page;
				// this.Filtro.TotalItem 			 	 	= res.data.data.total;
				this.EstatusDia							= (res.data.estatus != null)?res.data.estatus.EstatusCobranzaUser:'';
				
			}).finally(()=>{
				this.ConfigList.ShowLoader = false;
				this.GuardarFiltros('SET');
				this.Bloquea(2);
				this.IsLoader.BtnLoad.Icon = this.IsLoader.BtnLoad.Orig;
				this.IsLoader.BtnCorte.Icon = this.IsLoader.BtnCorte.Orig;
			});
		},
		getPdfCorteGenerado()
		{
			let IdRuta = this.GetRuta();

			let urlPdf = `${this.$http.defaults.baseURL}/getPdfCorteDia?Cobrat=${this.objPrestamo.IdCobratario}&Fecha=${this.FiltroFecha}&Suc=${this.IdSucursal}&Ruta=${IdRuta}`;

			window.open(urlPdf,'_blank');
		},
		irAEstadoDeCuenta(item)
		{
			this.$router.push({name:'estadoCuentaCobranza',params:{objPrestamo:item,pSitio:'Corte'}});
		},
		ListaSucursales()
		{
			this.$http.get("sucursales", {
				params: {
					simple: 1
				},
			})
			.then((res) => {
				let response = res.data.dataSuc;
				this.ListaSucursalesArray = res.data.data;

				if(this.objFiltrados.IdSucursal == 0)
				{
					if(response.EsAdmin == 0)
					{
						this.IdSucursal = response.IdSucursal;
					}
					else
					{
						this.ShowCmbSucursales = true;

						if(this.ListaSucursalesArray.length>0)
						{
							this.IdSucursal = this.ListaSucursalesArray[0].IdSucursal;
						}
					}
				}
				else
				{
					this.IdSucursal = this.objFiltrados.IdSucursal;
					this.ShowCmbSucursales = this.objFiltrados.ShowSuc;
				}
				//this.Lista();
			}).finally(()=>{
				this.ListaCobratarios();
			});
		},
		ListaCobratarios()
		{
			this.FiltroFecha = moment(this.objFiltroFechas).format('YYYY-MM-DD');
			//this.$http.get("getUsuariosCobranza", {
			this.$http.get("getUsuariosCobranzaCorte", {
				params: {
					IdSucursal: this.IdSucursal,
					Fecha: this.FiltroFecha,
				},
			})
			.then((res) => {
				this.IdCobratario = null;
				//console.log(res.data.data)
				this.arrayGestores = res.data.data;

				this.ListaUsers = res.data.data.map( item =>({
					id:    item.IdUsuario,
					label: item.NombreCompleto+' - ('+item.NombreRuta+')'
				}));

				if(this.objFiltrados.IdCobratario != 0)
				{
					let IdCobratario = this.objFiltrados.IdCobratario;
					let exist = this.ListaUsers.filter(function(item,index){
						if(IdCobratario == item.id)
						{
							return item;
						}
						else{
							return '';
						}
					});

					if(exist[0]){
						this.IdCobratario = this.objFiltrados.IdCobratario;
					}
				}
			}).finally(()=>{
				this.Lista();
			});
		},
		MostrarFiltro() {
			this.isVisible = true;
		},
		OcultarFiltro() {
			this.isVisible = false;
		},
		viewsComentarios(item) {
			this.bus.$emit('NewModal_'+EmitEjecuta2,item);
		},
		GuardarFiltros(Tipo)
		{
			if(Tipo == 'SET')
			{
				this.objFiltrados.IdSucursal = this.IdSucursal;
				this.objFiltrados.IdCobratario = this.objPrestamo.IdCobratario;
				this.objFiltrados.Fecha = this.objFiltroFechas;//this.FiltroFecha;
				this.objFiltrados.ShowSuc = this.ShowCmbSucursales;

				sessionStorage.setItem('CorteFiltros', JSON.stringify(this.objFiltrados));
			}
			else
			{
				this.objFiltrados = JSON.parse(sessionStorage.getItem('CorteFiltros'));
				this.objFiltroFechas = this.objFiltrados.Fecha;
			}
		},
		GetRuta()
		{
			let Gestor = this.IdCobratario;
			let exist = this.arrayGestores.filter(function(item,index){
				if(Gestor == item.IdUsuario){
					return item;
				}
				else{
					return '';
				}
			});

			let IdRuta = 0;

			if(exist[0]){
				IdRuta = exist[0].IdRuta;
			}

			return IdRuta;
		},
		RealizarLiquidacion(item)
		{
			this.bus.$emit('NewModal_'+EmitEjecuta5,item);
		},
		GenerarCortaDelDia()
		{
			this.IsLoader.BtnCorte.Bloq = true;
			this.IsLoader.BtnCorte.Icon = this.IsLoader.DefaultSpiner;

			let IdRuta = this.GetRuta();

			this.$swal({
				title: '<h2 style="font-size:25px; font-weight: 700;">¿Desea cerrar corte del día?</h2>',
				text: '¡Esta accion no se podrá revertir y ya no podra realizar correcciones!',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar',
				showCloseButton: true,
				showLoaderOnConfirm: true,
				confirmButtonColor: '#0096ED',
				cancelButtonColor: '#b92e27',
			}).then((result) => {
				if (result.value)
				{
					let FechaCorte = moment(this.objFiltroFechas).format('YYYY-MM-DD');
					//console.log(FechaCorte);
					this.ConfigList.ShowLoader = true;
					this.$http.get("cobranzacortedeldia", {
						params: {
							Fecha: FechaCorte,//moment(new Date()).format('YYYY-MM-DD'),
							IdRuta: IdRuta,
						},
					}).then( (res) => {
						this.$toast.success('Corte del día generado');
						this.Lista();
					}).catch(err=>{
						this.$toast.error(err.response.data.message);
					}).finally(()=>{
						this.ConfigList.ShowLoader = false;
						this.IsLoader.BtnCorte.Bloq = false;
						this.IsLoader.BtnCorte.Icon = this.IsLoader.BtnCorte.Orig;
					});
				}
				else{
					this.IsLoader.BtnCorte.Bloq = false;
					this.IsLoader.BtnCorte.Icon = this.IsLoader.BtnCorte.Orig;
				}
			});
		},
		Bloquea(Tipo)
		{
			if(Tipo == 1)
			{
				this.IsLoader.BtnLoad.Bloq = true;
				this.IsLoader.BtnCorte.Bloq = true;
				this.IsLoader.BloqCmb1 = true;
				this.IsLoader.BloqCmb2 = true;
				this.IsLoader.BloqCmb3 = true;
			}
			else
			{
				this.IsLoader.BtnLoad.Bloq = false;
				this.IsLoader.BtnCorte.Bloq = false;
				this.IsLoader.BloqCmb1 = false;
				this.IsLoader.BloqCmb2 = false;
				this.IsLoader.BloqCmb3 = false;
			}
		}
	},
	created() {
		this.ListaSucursales();

		if(this.sFiltros===undefined) {
            this.GuardarFiltros('GET');
        }
		//this.ListaCobratarios();
	},
	mounted() {
		this.Bloquea(1);
		//this.Lista();
		this.bus.$on('List_'+EmitEjecuta,()=> {
            this.Lista();
        });
	},
	beforeDestroy() {
		sessionStorage.setItem('IdGeneral',0);
	}
};

</script>
