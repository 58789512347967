
import MenuReportes       from "../views/modulos/reportes/MenuReportes.vue";
import ReporteCorteDia       from "../views/modulos/reportes/CorteDia.vue";
import ReporteVentasSemanales from "../views/modulos/reportes/VentasSemanales.vue";
import ReportProductividadSemanales from "../views/modulos/reportes/ProductividadSemanales.vue"

const VarRutasReportes = [
    {
        path: "/menureportes",
        name: "menureportes",
        component: MenuReportes,
        props: true,
    },
    {
        path: "/reportecortedia",
        name: "reportecortedia",
        component: ReporteCorteDia,
        props: true,
    },
    {
        path: "/reporteventasemanales",
        name: "reporteventasemanales",
        component: ReporteVentasSemanales,
        props: true,
    },
    {
        path: "/reportproductividadsemanales",
        name: "reportproductividadsemanales",
        component: ReportProductividadSemanales,
        props: true,
    },
];

export default {
    VarRutasReportes
}