<template>
    <div>
        <div class="form-row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 justify-content-center">

                <div class="avatar-upload">
                    <div class="avatar-edit">
                        <input :disabled="blockEdit" id="file1" @change="$uploadImagePreview($event,ValidElement, Array('Img','imagePreview1'))"
                            ref="file1" type="file" name="file1" accept=".png, .jpg, .jpeg">
                        <label for="file1"></label>
                    </div>
                    <div class="avatar-preview">
                        <div id="imagePreview1" :style="'background-image: url('+objEvidenciaEntrega.RutaEntrega+objEvidenciaEntrega.Evidencia+');'" :src="objEvidenciaEntrega.RutaEntrega+objEvidenciaEntrega.Evidencia"></div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

    import Configs     from "@/helpers/VarConfig.js";
    import CValidation from '@/components/CValidation.vue'

    export default {
        name:  "FormModificacionImgsEntrega",
        props: ['poBtnSave','Operacion'],
        components: {CValidation},
        data() {
            return {
                blockEdit:      false,
                ValidElement:   Configs.validImage2m,
                Emit:           this.poBtnSave.EmitSeccion,
                errorvalidacion: [],

                objEvidenciaEntrega:{
                    IdPrestamosEvidencia:   0,
                    IdPrestamo:             0,
                    IdUsuario: 0,
                    Evidencia: '',
                    TipoEvidencia: '',
                    Etapa:'',
                    Anio:'',
                    RutaEntrega:''
                },

            };
        },
        methods: {
            Guardar() {
                this.poBtnSave.toast      = 0;
                this.poBtnSave.DisableBtn = true;
                this.errorvalidacion      = [];

                let formData = new FormData();
                formData.set("IdPrestamosEvidencia", this.objEvidenciaEntrega.IdPrestamosEvidencia);
                formData.set("IdPrestamo",           this.objEvidenciaEntrega.IdPrestamo);
                formData.set("EvidenciaPrevia",      this.objEvidenciaEntrega.Evidencia);
                formData.set("TipoEvidencia",        this.objEvidenciaEntrega.TipoEvidencia);
                formData.set("Etapa",                this.objEvidenciaEntrega.Etapa);
                formData.set("Anio",                 this.objEvidenciaEntrega.Anio);
                formData.set("RutaEntrega",          this.objEvidenciaEntrega.RutaEntrega);

                // EVIDENCIA 1
                let Imagen1 = this.$refs.file1.files[0] !== undefined ? this.$refs.file1.files[0] : '' ;
                formData.append("Evidencia", Imagen1);


                this.$http.post('modevidenciaentrega', formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then( (res) => {
                    this.EjecutaConExito(res);
                }).catch((err) => {
                    this.EjecutaConError(err);
                });
            },

            EjecutaConExito(res) {
                this.poBtnSave.DisableBtn = false;
                this.poBtnSave.toast      = 1;
                this.bus.$emit('CloseModal_' + this.Emit);
                this.bus.$emit('Recovery_seccionEntregasPrestamo',this.objEvidenciaEntrega.IdPrestamo);
            },

            EjecutaConError(err) {
                this.poBtnSave.DisableBtn = false;

                if (err.response.data.errors) {
                    this.errorvalidacion = err.response.data.errors;
                    this.poBtnSave.toast = 2;
                } else {
                    this.$toast.error(err.response.data.message);
                }

            },
            
            limpiar() {
                this.objEvidenciaEntrega = {
                    IdPrestamosEvidencia:   0,
                    IdPrestamo:             0,
                    IdUsuario: 0,
                    Evidencia: '',
                    TipoEvidencia: '',
                    Etapa:'',
                    Anio:'',
                    RutaEntrega:''
                }
            },

        },
        created() {
            this.limpiar();
            this.poBtnSave.toast = 0;
            this.bus.$off('Recovery_' + this.Emit);
            this.bus.$on('Recovery_' + this.Emit, (obj) => {

                this.bus.$off('Save_' + this.Emit);
                this.bus.$on('Save_' + this.Emit, () => {
                    this.Guardar();
                });

                if (obj !== '') {

                    this.objEvidenciaEntrega = obj;

                }

            });
        },
    }

</script>
