<template>
	<div>
		<CList :pConfigList="ConfigList" :segurity="segurity">					
		
			<template slot="Filtros">
				<div class="form-group">
					<input type="text" @keyup.enter="Recuperar" v-model="Folio" class="form-control">
				</div>
				<div class="form-group ml-3">
					<button type="button" @click="deleteFiltro" class="btn btn-sm btn-danger">
						<i class="fa fa-trash"></i>
					</button>
				</div>
			</template>

			<template slot="bodyForm">
				<CLoader :pConfigLoad="ConfigLoad">
					<template slot="BodyFormLoad">
						<div class="form-group form-row">
							<div class="col-12 col-sm-12 col-md-6 col-lg-1">
								<label class="col-form-label">IdPrestamo</label>
								<input type="text" v-model="Prestamo.IdPrestamo" class="form-control">
							</div>
							<div class="col-12 col-sm-12 col-md-6 col-lg-1">
								<label class="col-form-label">IdCliente</label>
								<input type="text" v-model="Prestamo.IdCliente" class="form-control">
							</div>
							<div class="col-12 col-sm-12 col-md-6 col-lg-1">
								<label class="col-form-label">IdRuta</label>
								<input type="text" v-model="Prestamo.IdRuta" class="form-control">
							</div>
							<div class="col-12 col-sm-12 col-md-6 col-lg-1">
								<label class="col-form-label">IdCobratario</label>
								<input type="text" v-model="Prestamo.IdCobratario" class="form-control">
							</div>
							<div class="col-12 col-sm-12 col-md-6 col-lg-1">
								<label class="col-form-label">Monto Entreg</label>
								<input type="text" v-model="Prestamo.MontoEntregado" class="form-control">
							</div>
							<div class="col-12 col-sm-12 col-md-6 col-lg-1">
								<label class="col-form-label">Monto Devol</label>
								<input type="text" v-model="Prestamo.TotalDevolverPrestamo" class="form-control">
							</div>
							<div class="col-12 col-sm-12 col-md-6 col-lg-1">
								<label class="col-form-label">Monto Diario</label>
								<input type="text" v-model="Prestamo.MontoDiario" class="form-control">
							</div>
							<div class="col-12 col-sm-12 col-md-6 col-lg-1">
								<label class="col-form-label">Estatus</label>
								<input type="text" v-model="Prestamo.Estatus" class="form-control">
							</div>
							<div class="col-12 col-sm-12 col-md-6 col-lg-1">
								<label class="col-form-label">Est Entrega</label>
								<input type="text" v-model="Prestamo.EstatusEntrega" class="form-control">
							</div>
							<div class="col-12 col-sm-12 col-md-6 col-lg-1">
								<label class="col-form-label">Dias Trans</label>
								<input type="text" v-model="Prestamo.DiasTranscurridos" class="form-control">
							</div>
							<div class="col-12 col-sm-12 col-md-6 col-lg-1">
								<label class="col-form-label">N° Pago Act</label>
								<input type="text" v-model="Prestamo.NumPagoActual" class="form-control">
							</div>
							<div class="col-12 col-sm-12 col-md-6 col-lg-1">
								<label class="col-form-label">N° Pago Cub</label>
								<input type="text" v-model="Prestamo.NumPagoCubierto" class="form-control">
							</div>
						</div>

						<div class="form-group form-row">
							<div class="col-12 col-sm-12 col-md-6 col-lg-1">
								<label class="col-form-label">Total Multas</label>
								<input type="text" v-model="Prestamo.TotalMultas" class="form-control">
							</div>
							<div class="col-12 col-sm-12 col-md-6 col-lg-1">
								<label class="col-form-label">N° de Multas</label>
								<input type="text" v-model="Prestamo.NumMultas" class="form-control">
							</div>
							<div class="col-12 col-sm-12 col-md-6 col-lg-1">
								<label class="col-form-label">Saldo a Favor</label>
								<input type="text" v-model="Prestamo.SaldoFavor" class="form-control">
							</div>
							<div class="col-12 col-sm-12 col-md-6 col-lg-1">
								<label class="col-form-label">N° Pagos Adel</label>
								<input type="text" v-model="Prestamo.NumPagosAdelantados" class="form-control">
							</div>
							<div class="col-12 col-sm-12 col-md-6 col-lg-4"></div>
							<div class="col-12 col-sm-12 col-md-6 col-lg-4 text-right">
								<button type="button" @click="ShowMultas" class="mt-4 btn btn-sm btn-danger mr-2">
									<i class="fa fa-list"></i> Multas
								</button>
								<button type="button" @click="formatearPagos" class="mt-4 btn btn-sm btn-primary">
									<i class="fa fa-redo"></i> Resetear
								</button>
							</div>
						</div>

						<hr>
						<h4>Pagos</h4>
						<div class="form-group form-row">
							<div class="col-12 col-sm-12 col-md-12 col-lg-12">
			<table class="table">
				<thead>
				<tr>
					<th width="5%">IdPago</th>
					<th width="12%" class="text-center">Dias</th>
					<th width="16%" class="text-center">Montos</th>
					<th width="14%" class="text-center">Fechas</th>
					<th width="14%" class="text-center"></th>
					<th width="16%" class="text-center"></th>
					<th>Estatus</th>
					<th>Estatus P</th>
					<th>Mult</th>
				</tr>
				</thead>
				<tbody>
					<tr v-for="(item,index) in Prestamo.Pagos" :key="index">
						<td>{{item.IdPrestamosPago}}</td>
						<td>
							<div class="form-row">
								<div class="col-12 col-sm-12 col-md-6 col-lg-6">
									<div class="row">
										<div class="col-12">
											<label class="col-form-label">Dia</label>
										</div>
										<div class="col-12">
											<label class="col-form-label">Pago</label>
										</div>
										<div class="col-12">
											<label class="col-form-label">Cubierto</label>
										</div>
									</div>
								</div>
								<div class="col-12 col-sm-12 col-md-6 col-lg-6">
									<div class="row">
										<div class="col-12 mb-2">
											<input type="text" v-model="item.NumDia" class="form-control form-control-sm">
										</div>
										<div class="col-12 mb-2">
											<input type="text" v-model="item.NumPago" class="form-control form-control-sm">
										</div>
										<div class="col-12 mb-2">
											<input type="text" v-model="item.NumPagoCubierto" class="form-control form-control-sm">
										</div>
									</div>
								</div>
							</div>
						</td>
						<td>
							<div class="form-row">
								<div class="col-12 col-sm-12 col-md-6 col-lg-6">
									<div class="row">
										<div class="col-12">
											<label class="col-form-label">Monto Pago</label>
										</div>
										<div class="col-12">
											<label class="col-form-label">Monto Real</label>
										</div>
										<div class="col-12">
											<label class="col-form-label">Monto Cub</label>
										</div>
									</div>
								</div>
								<div class="col-12 col-sm-12 col-md-6 col-lg-6">
									<div class="row">
										<div class="col-12 mb-2">
											<input type="text" v-model="item.MontoPago" class="form-control form-control-sm">
										</div>
										<div class="col-12 mb-2">
											<input type="text" v-model="item.MontoRealPagado" class="form-control form-control-sm">
										</div>
										<div class="col-12 mb-2">
											<input type="text" v-model="item.MontoCubierto" class="form-control form-control-sm">
										</div>
									</div>
								</div>
							</div>
						</td>
						<td>
							<div class="form-row">
								<div class="col-12 col-sm-12 col-md-6 col-lg-12">
									<div class="row">
										<div class="col-12">
											<label class="">Fecha Pago</label>
											<input type="text" v-model="item.FechaEstimadoPago" class="form-control form-control-sm">
										</div>
										<div class="col-12">
											<label class="">Fecha Real</label>
											<input type="text" v-model="item.FechaRealPago" class="form-control form-control-sm">
										</div>
									</div>
								</div>
							</div>
						</td>
						<td>
							<div class="form-row">
								<div class="col-12 col-sm-12 col-md-6 col-lg-12">
									<div class="row">
										<div class="col-12">
											<label class="">Latitud</label>
											<input type="text" v-model="item.LatitudPago" class="form-control form-control-sm">
										</div>
										<div class="col-12">
											<label class="">Longitud</label>
											<input type="text" v-model="item.LongitudPago" class="form-control form-control-sm">
										</div>
									</div>
								</div>
							</div>
						</td>
						<td>
							<div class="form-row">
								<div class="col-12 col-sm-12 col-md-6 col-lg-6">
									<div class="row">
										<div class="col-12">
											<label class="col-form-label">Saldo Prend</label>
										</div>
										<div class="col-12">
											<label class="col-form-label">Saldo Prest</label>
										</div>
										<div class="col-12">
											<label class="col-form-label">Saldo Mult</label>
										</div>
									</div>
								</div>
								<div class="col-12 col-sm-12 col-md-6 col-lg-6">
									<div class="row">
										<div class="col-12 mb-2">
											<input type="text" v-model="item.SaldoPendiente" class="form-control form-control-sm">
										</div>
										<div class="col-12 mb-2">
											<input type="text" v-model="item.SaldoPrestamo" class="form-control form-control-sm">
										</div>
										<div class="col-12 mb-2">
											<input type="text" v-model="item.SaldoMultas" class="form-control form-control-sm">
										</div>
									</div>
								</div>
							</div>
						</td>
						<td>
							<div class="form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12">
									<div class="form-check mb-1">
										<input class="form-check-input" type="radio" id="defaultCheck1" :name="'defaultCheck['+index+']'" style="transform:scale(1.5)" v-model="item.Estatus" value="Pendiente">
										<label class="form-check-label" for="defaultCheck1">
											Pendiente
										</label>
									</div>
									<div class="form-check mb-1">
										<input class="form-check-input" type="radio" id="defaultCheck2" :name="'defaultCheck['+index+']'" style="transform:scale(1.5)" v-model="item.Estatus" value="Atrasado">
										<label class="form-check-label" for="defaultCheck2">
											Atrasado
										</label>
									</div>
									<div class="form-check mb-1">
										<input class="form-check-input" type="radio" id="defaultCheck3" :name="'defaultCheck['+index+']'" style="transform:scale(1.5)" v-model="item.Estatus" value="Pagado">
										<label class="form-check-label" for="defaultCheck3">
											Pagado
										</label>
									</div>
									<div class="form-check mb-1">
										<input class="form-check-input" type="radio" id="defaultCheck4" :name="'defaultCheck['+index+']'" style="transform:scale(1.5)" v-model="item.Estatus" value="Adelantado">
										<label class="form-check-label" for="defaultCheck4">
											Adelantado
										</label>
									</div>
								</div>
							</div>
						</td>
						<td>
							<div class="form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12">
									<div class="form-check mb-1">
										<input class="form-check-input" type="radio" id="defaultCheck5" :name="'defaultCheckP['+index+']'" style="transform:scale(1.5)" v-model="item.EstatusPago" value="Pendiente">
										<label class="form-check-label" for="defaultCheck5">
											Pendiente
										</label>
									</div>
									<div class="form-check mb-1">
										<input class="form-check-input" type="radio" id="defaultCheck6" :name="'defaultCheckP['+index+']'" style="transform:scale(1.5)" v-model="item.EstatusPago" value="Atrasado">
										<label class="form-check-label" for="defaultCheck6">
											Atrasado
										</label>
									</div>
									<div class="form-check mb-1">
										<input class="form-check-input" type="radio" id="defaultCheck7" :name="'defaultCheckP['+index+']'" style="transform:scale(1.5)" v-model="item.EstatusPago" value="Cubierto">
										<label class="form-check-label" for="defaultCheck7">
											Cubierto
										</label>
									</div>
									<div class="form-check mb-1">
										<input class="form-check-input" type="radio" id="defaultCheck8" :name="'defaultCheckP['+index+']'" style="transform:scale(1.5)" v-model="item.EstatusPago" value="Adelantado">
										<label class="form-check-label" for="defaultCheck8">
											Adelantado
										</label>
									</div>
								</div>
							</div>
						</td>
						<td :style="(item.Multa.TieneMulta)?'background-color: #fc6e6e66':''">
							<div class="form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12">
									<div class="form-check mb-1">
										<input class="form-check-input" type="checkbox" id="defaultCheck9" name="pagada" style="transform:scale(1.5)" v-model="item.Multa.TieneMulta">
										<label class="form-check-label" for="defaultCheck9">
											Tien
										</label>
									</div>
								</div>
								<div class="col-12 col-sm-12 col-md-12 col-lg-12">
									<div class="form-check mb-1">
										<input class="form-check-input" type="radio" id="defaultCheck10" :name="'defaultCheckM['+index+']'" style="transform:scale(1.5)" v-model="item.Multa.EstatusMult" value="Pagada">
										<label class="form-check-label" for="defaultCheck10">
											SI
										</label>
									</div>
									<div class="form-check mb-1">
										<input class="form-check-input" type="radio" id="defaultCheck11" :name="'defaultCheckM['+index+']'" style="transform:scale(1.5)" v-model="item.Multa.EstatusMult" value="NoPagada">
										<label class="form-check-label" for="defaultCheck11">
											NO
										</label>
									</div>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
							</div>
						</div>
						<!--
						<div class="row">
							<div class="col-3">
								<input type="text" v-model="ghi">
							</div>
							<div class="col-3">
								<button type="button" @click="sqls" class="btn btn-sm btn-warning">
									enviar
								</button>
							</div>
						</div>

						<div class="row">
							<div class="col-6">
								<textarea v-model="abc" class="form-control" rows="5"></textarea>
							</div>
							<div class="col-6">
								<textarea v-model="def" class="form-control" rows="5"></textarea>
							</div>
						</div>-->
						<CBtnSave :poBtnSave="oBtnSave" class="mt-3"/>
					</template>
				</CLoader>
			</template>

		</CList>

		<CModal :pConfigModal="ConfigModal" :poBtnSave="oBtnSaveM">
			<template slot="Form">
				<Form :poBtnSave="oBtnSaveM"></Form>
			</template>
		</CModal>

	</div>
</template>

<script>

import CSinRegistros from "@/components/CSinRegistros";
import Form from '@/views/modulos/cobranza/FormMultas.vue';
const  EmitEjecuta = "seccionEstadoDeCuenta";
const  EmitEjecuta2 = "seccionMultas";
const baseTitle = 'Estado de Cuenta';

export default {
	name: "ListaEstadoDeCuenta",
	props: ['sFiltros','pSitio'],
	components: { CSinRegistros,Form },
	data() {
		return {
			ConfigList: {
				Title: baseTitle,
				IsModal: false,
				ShowLoader: false,
				BtnReturnShow: false,
				EmitSeccion: EmitEjecuta,
				GoRoute: "",
				ShowTitleFirst: false,
				BtnNewShow: false,
				ShowEntradas: false,
				ShowPaginador: false,
				ShowSearch: false,
				TypeBody:'Form'
			},
			ConfigLoad: {
				ShowLoader: true,
				ClassLoad:  false,
			},
			oBtnSave: {
				toast:         0,
				IsModal:       false,
				ShowBtnSave:   true,
				ShowBtnCancel: false,
				DisableBtn:    false,
				EmitSeccion:   EmitEjecuta,
			},
			Prestamo:{},
			ListaArrayRows: [],
			segurity: 		{},
			Folio: '',
			ConfigModal:{
				ModalTitle: "Formulario Multas",
				ModalNameId: 'ModalForm2',
				EmitSeccion: EmitEjecuta2,
				ModalSize: 'xl',
				ShowFooter:  false,
			},
			oBtnSaveM: {
				toast: 0,
				IsModal: true,
				DisableBtn: false,
				EmitSeccion: EmitEjecuta2,
			},
			abc: '',
			def: '',
			ghi: '',
		};
	},
	methods: {
		Recuperar() {
			this.ConfigLoad.ShowLoader = true;

			if(this.Folio!=''){

				this.$http.get("correcciongeneral", {
					params: {
						Folio: this.Folio,
					},
				})
				.then((res) => {
					this.segurity = res.data.segurity;
					//console.log(res.data)

					this.Prestamo = res.data.Prestamo;
					this.ConfigList.Title = baseTitle+': '+this.Prestamo.Cliente.NombreCompleto;
				})
				.finally(() => {
					this.ConfigLoad.ShowLoader = false;
				});
			}
			else{
				this.ConfigLoad.ShowLoader = false;
			}
		},
		async Guardar(){
			this.errorvalidacion 	 = [];
			let formData = new FormData();
			formData.set("IdPrestamo", this.Prestamo.IdPrestamo);
			formData.set("IdCliente", this.Prestamo.IdCliente);
			formData.set("IdRuta", this.Prestamo.IdRuta);
			formData.set("IdCobratario", this.Prestamo.IdCobratario);
			formData.set("MontoEntregado", this.Prestamo.MontoEntregado);
			formData.set("TotalDevolverPrestamo", this.Prestamo.TotalDevolverPrestamo);
			formData.set("MontoDiario", this.Prestamo.MontoDiario);
			formData.set("Estatus", this.Prestamo.Estatus);
			formData.set("EstatusEntrega", this.Prestamo.EstatusEntrega);
			formData.set("DiasTranscurridos", this.Prestamo.DiasTranscurridos);
			formData.set("NumPagoActual", this.Prestamo.NumPagoActual);
			formData.set("NumPagoCubierto", this.Prestamo.NumPagoCubierto);
			formData.set("TotalMultas", this.Prestamo.TotalMultas);
			formData.set("NumMultas", this.Prestamo.NumMultas);
			formData.set("SaldoFavor", this.Prestamo.SaldoFavor);
			formData.set("NumPagosAdelantados", this.Prestamo.NumPagosAdelantados);
			formData.set("updated_at", this.Prestamo.updated_at);
			formData.append('ListaPagos', JSON.stringify(this.Prestamo.Pagos));

			this.oBtnSave.DisableBtn = true;

			await this.$http.post("guardarcorreccion",formData,{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}).then((res) => {
				this.EjecutaConExito(res);
				//this.objCliente = res.data.data.cliente;
			})
			.catch((err) => {
				console.log(err);
				this.EjecutaConError(err);
			});
		},
		EjecutaConExito(res) {
			this.oBtnSave.DisableBtn = false;
			this.bus.$emit('RunAlerts_'+this.ConfigList.EmitSeccion,1);
			//this.Regresar();
		},
		EjecutaConError(err) {
			this.oBtnSave.DisableBtn = false;

			if (err.response.data.errors) {
				this.errorvalidacion = err.response.data.errors;
				this.oBtnSave.toast = 2;
			} else {
				this.$toast.error(err.response.data.message);
			}
		},
		deleteFiltro()
		{
			this.Folio = '';
			this.Prestamo = '';
		},
		formatearPagos()
		{
			this.ConfigLoad.ShowLoader = true;
			
			let formData = new FormData();
			formData.set("IdPrestamo", this.Prestamo.IdPrestamo);
			formData.set("IdCliente", this.Prestamo.IdCliente);
			formData.set("IdRuta", this.Prestamo.IdRuta);
			formData.set("IdCobratario", this.Prestamo.IdCobratario);
			formData.set("MontoEntregado", this.Prestamo.MontoEntregado);
			formData.set("TotalDevolverPrestamo", this.Prestamo.TotalDevolverPrestamo);
			formData.set("MontoDiario", this.Prestamo.MontoDiario);
			formData.set("Estatus", this.Prestamo.Estatus);
			formData.set("EstatusEntrega", this.Prestamo.EstatusEntrega);
			formData.set("DiasTranscurridos", this.Prestamo.DiasTranscurridos);
			formData.set("NumPagoActual", this.Prestamo.NumPagoActual);
			formData.set("NumPagoCubierto", this.Prestamo.NumPagoCubierto);
			formData.set("TotalMultas", this.Prestamo.TotalMultas);
			formData.set("NumMultas", this.Prestamo.NumMultas);
			formData.set("SaldoFavor", this.Prestamo.SaldoFavor);
			formData.set("NumPagosAdelantados", this.Prestamo.NumPagosAdelantados);
			formData.set("updated_at", this.Prestamo.updated_at);
			formData.append('ListaPagos', JSON.stringify(this.Prestamo.Pagos));

			this.oBtnSave.DisableBtn = true;

			this.$http.post("corregirpagos",formData,{
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}).then((res) => {
				//this.EjecutaConExito(res);
				let resp = res.data.prestamo;
				this.Prestamo.NumPagoActual = resp.NumPagoActual;
				this.Prestamo.NumPagoCubierto = resp.NumPagoCubierto;
				this.Prestamo.TotalMultas = resp.TotalMultas;
				this.Prestamo.NumMultas = resp.NumMultas;
				this.Prestamo.SaldoFavor = resp.SaldoFavor;
				this.Prestamo.NumPagosAdelantados = resp.NumPagosAdelantados;
				this.Prestamo.Pagos = res.data.pagos;
				console.log(res.data)
			})
			.catch((err) => {
				console.log(err);
				//this.EjecutaConError(err);
			})
			.finally(() => {
				this.ConfigLoad.ShowLoader = false;
				this.oBtnSave.DisableBtn = false;
			});
		},
		ShowMultas()
		{
			let obj = this.Prestamo.IdPrestamo;
			this.bus.$emit('NewModal_'+EmitEjecuta2,obj);
		},
		sqls() {
			
			this.$http.get("obtensql", {
				params: {
					IdRuta: this.ghi
				},
			})
			.then((res) => {
				this.abc = res.data.Prestamo;
				this.def = res.data.Cliente;
			})
			.finally(() => {
				this.ConfigLoad.ShowLoader = false;
			});
		},
	},
	created() {

		this.bus.$off("Save_"+EmitEjecuta);
	},
	mounted() {
		this.Recuperar();
		this.oBtnSave.DisableBtn = false;

		this.bus.$on("Save_"+EmitEjecuta, () => {
			this.Guardar();
		});

	},
};
</script>
<style scoped>
.mas{
	background-color: #fc6e6e66;
}
</style>
