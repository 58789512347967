<template>
    <div>
        <div class="form-group form-row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-2 col-xl-6" >
                <div class="box-imagen">
                    <div class="avatar-upload image-button">
                        <label class="" style="text-align: left;">
                            <template v-if="objImagenEntrega.TipoEvidencia == 'ImgIne'">
                                INE
                            </template>
                            <template v-else-if="objImagenEntrega.TipoEvidencia == 'ImgPersonal'">
                                Personal
                            </template>
                            <template v-else-if="objImagenEntrega.TipoEvidencia == 'ImgEstablecimiento1'">
                                Establecimiento 1
                            </template>
                            <template v-else-if="objImagenEntrega.TipoEvidencia == 'ImgEstablecimiento2'">
                                Establecimiento 2
                            </template>
                            <template v-else-if="objImagenEntrega.TipoEvidencia == 'ImgPagare'">
                                Documento de Crédito
                            </template>
                            <template v-else-if="objImagenEntrega.TipoEvidencia == 'ImgHojaReferencia1'">
                                Hoja de Referencias 1
                            </template>
                            <template v-else-if="objImagenEntrega.TipoEvidencia == 'ImgHojaReferencia2'">
                                Hoja de Referencias 2
                            </template>
                            <template v-else-if="objImagenEntrega.TipoEvidencia == 'ImgTerminosCondiciones'">
                                Terminos y Condiciones
                            </template>
                            <template v-else-if="objImagenEntrega.TipoEvidencia == 'ImgReciboDinero'">
                                Recibo de Dinero
                            </template>
                        </label>
                        <div class="avatar-preview">
                            <div :style="'background-image: url('+RutaEviEntrega+objImagenEntrega.Evidencia+');'" :src="RutaEviEntrega+objImagenEntrega.Evidencia"></div>
                        </div>


                        <div class="icons-button">

                        </div>

                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">


                <div class="box-imagen">
                    <div class="avatar-upload image-button">
                        <label class="" style="text-align: left;">
                            Imagen Nueva
                        </label>
                        <div class="avatar-edit">
                            <input id="file1" @change="$uploadImagePreview($event,ValidElement, Array('ImagenCambio','imgPreview'))" ref="file1" type="file" name="file" accept=".png, .jpg, .jpeg">
                            <label for="file1"></label>
                        </div>
                        <div class="avatar-preview">
                            <div id="imgPreview" ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group form-row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <label for="ComentarioCambio">Motivo de cambio de la imagen</label>
                <textarea v-model="ComentarioCambio" class="form-control" placeholder="Ingresar un comentario" rows="5"></textarea>
                <CValidation v-if="this.errorvalidacion.ComentarioCambio" :Mensaje="'*'+errorvalidacion.ComentarioCambio[0]"/>
            </div>
        </div>
    </div>
</template>
<script>
import Configs 	  from '@/helpers/VarConfig.js';
export default {
    name: "FormModEvidenciaEntrega",
    props: ['poBtnSave'],
    components: {},

    data() {
        return {
            ValidElement: 				Configs.validImage5m,
            Emit: this.poBtnSave.EmitSeccion,
            RutaEviEntrega: '',
            ComentarioCambio:'',
            errorvalidacion:[],
            objImagenEntrega:{
                Anio: "",
                Etapa: "",
                Evidencia: "",
                IdPrestamo: 0,
                IdPrestamosEvidencia: 0,
                IdUsuario: 0,
                TipoEvidencia: "",
                created_at: "",
                updated_at: "",
            },
            ConfigLoad: {
                ShowLoader: false,
                ClassLoad:  false,
            },
        }
    },
    methods: {
        async Guardar() {
            this.errorvalidacion      = [];



            let formData = new FormData();
            formData.set('IdPrestamosEvidencia',  this.objImagenEntrega.IdPrestamosEvidencia);
            formData.set('IdPrestamo', 			this.objImagenEntrega.IdPrestamo);
            formData.set("ComentarioCambio", 		this.ComentarioCambio);

            let ImagenCambio = this.$refs.file1.files[0];
            if (ImagenCambio == undefined) {
                this.$toast.warning("Seleccione una Imagen para realizar el cambio");
                return false;
            }
            formData.append("ImagenCambio", ImagenCambio);

            this.poBtnSave.toast      = 0;
            this.poBtnSave.DisableBtn = true;
            this.$http.post('prestamosevidenciasupdate',formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res)=>{
                this.EjecutaConExito(res);
            }).catch(err=>{
                this.EjecutaConError(err);
            });
        },
        EjecutaConExito(res) {
            this.poBtnSave.DisableBtn = false;
            this.poBtnSave.toast      = 1;
            this.bus.$emit('CloseModal_'+this.Emit);
            this.bus.$emit('RechargerPrestamoVisor');
        },
        EjecutaConError(err) {
            this.poBtnSave.DisableBtn = false;

            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.poBtnSave.toast = 2;
            } else {
                this.$toast.error(err.response.data.message);
            }
        },
    },
    computed: {},
    created() {

    },
    mounted() {
        this.poBtnSave.toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(obj)=> {

            this.bus.$off('Save_'+this.Emit);
            this.bus.$on('Save_'+this.Emit,()=> {
                this.Guardar();
            });

            if(obj !== undefined){
                this.RutaEviEntrega = obj.ImgRuta;
                this.objImagenEntrega = obj.pImagen;
            }




        });
    }

}
</script>
