<template>
    <div>
        <CTabla :pConfigList="ConfigList">
            <template slot="bodyForm">
                <CLoader :pConfigLoad="ConfigLoad">
                    <template slot="BodyFormLoad">

                        <CTablita :pConfigList="ConfigListDetAbonos">
                            <template slot="header">

                                <th class="text-center">Fecha de Emisión</th>
                                <th class="text-center">Monto Pagado</th>
                                <th class="text-center">Tipo</th>

                            </template>

                            <template slot="body">
                                <tr v-for="(item,index) in listaAbonos" :key="index">

                                    <td class="text-center">{{$getCleanDate(item.FechaAbono,true)}}</td>
                                    <td class="text-center">{{$formatNumber(item.Monto,'$')}}</td>
                                    <td class="text-center">
                                        <b-badge v-if="item.Tipo === 'AB'" pill variant="info">Abono</b-badge>
                                        <b-badge v-if="item.Tipo === 'AD'" pill variant="success">Adelanto</b-badge>

                                    </td>

                                </tr>
                                <CSinRegistros :pContIF="listaAbonos.length" :pColspan="3"/>
                            </template>
                        </CTablita>









                    </template>
                </CLoader>

            </template>
        </CTabla>
    </div>

</template>

<script>
import CList from "../../../../components/CList";
import CLoader from "../../../../components/CLoader";
import CTablita from "../../../../components/CTablita";
import CSinRegistros from "../../../../components/CSinRegistros";

const EmitEjecuta = "seccionDetallePagos";

export default {
    name: "DetallePagos",
    props:["poBtnSave"],
    components:{
        CList,
        CLoader,
        CTablita,
        CSinRegistros
    },

    data(){
        return {
            Emit: this.poBtnSave.EmitSeccion,

            pagoPrestamo: {
                IdPrestamo: 0,
                IdPrestamosPago: 0,
            },

            ConfigList:{
                ShowTitleFirst: 	false,
                Title: 				" Titulo",
                ShowTitleEnd:       false,
                ShowLoader: 		false,
                IsModal: 			false,
                BtnNewShow: 		false,
                BtnReturnShow: 		false,
                TypeBody: 			"Form",
                ShowFiltros: 		false,
                ShowFiltrosInline: 	false,
                ShowTitleInline: 	false,
                ShowPaginador: 		false,
                ShowBreadcrumb:     false,
                EmitSeccion: 		EmitEjecuta,
            },

            ConfigLoad: {
                ShowLoader: false,
                ClassLoad:  false,
            },

            listaMultas: [],
            ConfigListDetMultas: {
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecuta,
            },

            listaAbonos: [],
            ConfigListDetAbonos:{
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecuta,
            },


        }
    },
    methods: {
        recoverylistMultas () {
            this.$http.get("prestamosmultas",{
                params:{
                    IdPrestamosPago: this.pagoPrestamo.IdPrestamosPago,
                    IdPrestamo: this.pagoPrestamo.IdPrestamo,
                    Entrada:50,
                    Pag:1
                }
            }).then((res) => {
                this.listaMultas = res.data.data.data;


            }).finally(() => {
                this.recoverylistAbonos();
            });
        },

        recoverylistAbonos () {
            this.$http.get("prestamoabonos",{
                params:{
                    IdPrestamosPago: this.pagoPrestamo.IdPrestamosPago,
                    IdPrestamo: this.pagoPrestamo.IdPrestamo,
                    Entrada:50,
                    Pag:1
                }
            }).then((res) => {
                this.listaAbonos = res.data.data.data;

            }).finally(() => {

            });
        }
    },
    created() {
        this.bus.$off('Save_' + this.Emit);
        this.bus.$off('Recovery_' + this.Emit);

    },
    mounted() {
        this.bus.$on('Recovery_' + this.Emit, (obj) => {

            if (obj !== '') {
                this.pagoPrestamo = obj;
                this.recoverylistMultas();
            }

        });
    }
}
</script>

<style scoped>

</style>
