<template>
    <CTablita @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
        <template slot="header">
            <th class="td-sm"></th>
            <th>#</th>
            <th>Folio</th>
            <th>Nombre</th>
            <th>Monto Entregado</th>
            <th>Pago Diario</th>
            <th>Fecha Inicio Cobro</th>
            <th class="text-center"></th>
        </template>

        <template slot="body">
            <tr v-for="(lista,index) in ListaArrayRows" :key="index" >
                <td class="td-sm"></td>
                <td>{{(index+1)}}</td>
                <td>{{lista.Folio }}</td>
                <td>{{lista.NombreCompleto}}</td>
                <td>{{lista.MontoEntregado}}</td>
                <td>{{lista.MontoDiario}}</td>
                <td>{{lista.FechaInicioCobro}}</td>
                <td class="text-center"></td>
            </tr>
            <CSinRegistro :pContIF="ListaArrayRows.length" :pColspan="8"></CSinRegistro>
        </template>
    </CTablita>
</template>

<script>

    export default {
        name:  "ListPrestamosNuevos",
        props: ['poBtnSave'],
        data() {
            return {
                ConfigList:{
                    Title: 'Listado Perfiles',
                    IsModal: false,
                    ShowLoader: true,
                    BtnReturnShow: false,
                    BtnNewShow: false,
                    EmitSeccion: this.poBtnSave.EmitSeccion,
                    ShowFiltros: false,
                    ShowTitleFirst: false,
                    ShowTitleEnd: false,
                },
                Filtro:{
                    Nombre: '',
                    Pagina: 1,
                    Entrada: 25,
                    TotalItem: 0,
                    Placeholder: 'Buscar..',
                },
                ListaArrayRows: [],
                ListaHeader: [],
                errorvalidacion: [],
                segurity: {},
                Emit: this.poBtnSave.EmitSeccion,
                objUser:{},
            }
        },
        methods: {
            async Lista() {
                this.ConfigList.ShowLoader = true;

                await this.$http.get('prestamosnuevos', {
                    params:{
                        TxtBusqueda: this.Filtro.Nombre,
                        Entrada:     this.Filtro.Entrada,
                        Pag:         this.Filtro.Pagina,
                        IdRuta:      this.objUser.IdRuta,
                    }
                }).then( (res) => {
                    this.ListaArrayRows     = res.data.data.data;
                    this.Filtro.Pagina      = res.data.data.current_page;
                    this.Filtro.TotalItem   = res.data.data.total;
                    this.segurity           = res.data.segurity;
                }).finally(()=>{
                    this.ConfigList.ShowLoader = false;
                });
            },
        },
        created() {
            this.bus.$off('Recovery_'+this.Emit);
            this.bus.$on('Recovery_'+this.Emit,(item) => {
                this.objUser = item
                this.Lista();
            });
        },
    }

</script>