<template>
<div class="col-12">
    <input type="hidden" :value="ValidatePres">
    <CTablita :pConfigList="ConfigListPagos">
        <template slot="header">

            <th class="text-center"># Dia</th>
            <th class="text-center"># Abono</th>
            <th class="text-center"># Pago</th>
            <th class="text-center">F. Estimada Cobro</th>
            <th class="text-center">F. Real de Pago</th>
            <th class="text-right">Monto de Pago</th>
            <th class="text-right">Monto Cobrado</th>
            <th class="text-center">Estado</th>
            <th class="text-center">Estado Actual</th>
            <th class="text-center">$ Multa Pendiente</th>
            <th class="text-center">Estatus Multa</th>
            <th class="text-center">Accciones</th>

        </template>

        <template slot="body">
            <tr v-if="showFotter && Orden != 'ASC'">
                <td class="text-center"><b>{{PagoTemporal.NumDia}}</b></td>
                <td class="text-center"><b>{{PagoTemporal.NumPago}}</b></td>
                <td class="text-center"><b>{{PagoTemporal.NumPagoCubierto}}</b></td>
                <td class="text-center">{{$getCleanDate(PagoTemporal.FechaEstimadoPago,false)}}</td>
                <td class="text-center">
                    <template v-if="PagoTemporal.FechaRealPago !== '' && PagoTemporal.FechaRealPago != null">
                        {{ $getCleanDate(PagoTemporal.FechaRealPago,true) }}
                    </template>
                </td>
                <td class="text-right">{{$formatNumber(PagoTemporal.MontoPago,'$')}}</td>
                <td class="text-right">{{$formatNumber(PagoTemporal.MontoRealPagado,'$')}}</td>
                <td class="text-center" colspan="1">
                    <b-badge v-if="PagoTemporal.EstatusPago == 'Atrasado'" pill variant="danger">{{ PagoTemporal.EstatusPago }}</b-badge>
                    <b-badge v-if="PagoTemporal.EstatusPago == 'Adelantado'" pill variant="primary">{{ PagoTemporal.EstatusPago }}</b-badge>
                    <b-badge v-if="PagoTemporal.EstatusPago == 'Pendiente'" pill variant="secondary">{{ PagoTemporal.EstatusPago }}</b-badge>
                </td>
                <td class="text-center">
                    <b-badge v-if="PagoTemporal.Estatus == 'Pendiente'" pill variant="secondary">{{ PagoTemporal.Estatus }}</b-badge>
                    <b-badge v-if="PagoTemporal.Estatus == 'Pagado'" pill variant="success">{{ PagoTemporal.Estatus }}</b-badge>
                    <b-badge v-if="PagoTemporal.Estatus == 'Atrasado'" pill variant="danger">{{ PagoTemporal.Estatus }}</b-badge>
                    <b-badge v-if="PagoTemporal.Estatus == 'Adelantado'" pill variant="primary">{{ PagoTemporal.Estatus }}</b-badge>
                </td>

                <td class="text-center" colspan="1">
                    <span></span>
                </td>
                <td class="text-center" colspan="1">
                    <span></span>
                </td>

                <td class="text-center">
                    <div class="btn-group">
                        <button @click="ModalUbicacionPago(PagoTemporal)" type="button" class="btn btn-sm btn-primary btn-icon float-right">
                            <i class="fas fa-map-marker-alt"></i>
                        </button>
                    </div>
                </td>
            </tr>

            <tr v-for="(item,index) in listPagosPrestamo" :key="index">

                <td class="text-center"><b>{{item.NumDia}}</b></td>
                <td class="text-center"><b>{{item.NumPago}}</b></td>
                <td class="text-center"><b>{{item.NumPagoCubierto}}</b></td>
                <td class="text-center">{{$getCleanDate(item.FechaEstimadoPago,false)}}</td>
                <td class="text-center">
                    <template v-if="item.FechaRealPago !== '' && item.FechaRealPago != null">
                        {{ $getCleanDate(item.FechaRealPago,true) }}
                    </template>
                </td>
                <td class="text-right">{{$formatNumber(item.MontoPago,'$')}}</td>
                <td class="text-right">{{$formatNumber(item.MontoRealPagado,'$')}}</td>
                <td class="text-center">
                    <b-badge v-if="item.EstatusPago == 'Atrasado'" pill variant="danger">{{ item.EstatusPago }}</b-badge>
                    <b-badge v-if="item.EstatusPago == 'Adelantado'" pill variant="primary">{{ item.EstatusPago }}</b-badge>
                    <b-badge v-if="item.EstatusPago == 'Pendiente'" pill variant="secondary">{{ item.EstatusPago }}</b-badge>
                </td>
                <td class="text-center">
                    <b-badge v-if="item.Estatus == 'Pendiente'" pill variant="secondary">{{ item.Estatus }}</b-badge>
                    <b-badge v-if="item.Estatus == 'Pagado'" pill variant="success">{{ item.Estatus }}</b-badge>
                    <b-badge v-if="item.Estatus == 'Atrasado'" pill variant="danger">{{ item.Estatus }}</b-badge>
                    <b-badge v-if="item.Estatus == 'Adelantado'" pill variant="primary">{{ item.Estatus }}</b-badge>
                </td>
                <td class="text-center">
                    <template v-if="item.ContMultas>0">
                        {{$formatNumber(parseFloat((item.ObjMultas.Multa)-(item.ObjMultas.MontoPagado)),'$')}}
                    </template>
                </td>
                <td class="text-center">
                    <template v-if="item.ContMultas>0">
                        <b-badge v-if="item.ObjMultas.Estatus == 'Pagada'" pill variant="success">{{ item.ObjMultas.Estatus }}</b-badge>
                        <b-badge v-else-if="item.ObjMultas.Estatus == 'Condonada'" pill variant="success">{{ item.ObjMultas.Estatus }}</b-badge>
                        <b-badge  v-else pill variant="danger">No Pagada</b-badge>
                    </template>
                </td>
                <td class="text-right">
                    <div class="btn-group">
                        <button v-if="parseInt(item.abonosdelantos_count) > 0" type="button"
                                class="btn btn-warning btn-icon mr-1" @click="viewsDetails(item)" title="Ver detalles del pago">
                            <i class="fas fa-eye"></i>
                        </button>

                        <button type="button" v-if="parseInt(item.comentario_count) > 0"
                                class="btn btn-info btn-icon mr-1" @click="viewsComentarios(item)" title="Ver comentario del pago">
                            <i class="fas fa-comment-dots"></i>
                        </button>

                        <button @click="ModalUbicacionPago(item)" type="button" class="btn btn-sm btn-primary btn-icon mr-1">
                            <i class="fas fa-map-marker-alt"></i>
                        </button>

                        <button v-if="parseFloat(item.MontoRealPagado) > 0" @click="imprimirTicketPago(item)" v-b-tooltip.hover.Top title="Imprimir Ticket" type="button" class="btn btn-warning btn-icon">
                            <i class="fas fa-ticket-alt"></i>
                        </button>

                    </div>
                </td>
            </tr>

            <!--<CSinRegistros :pContIF="listPagosPrestamo.length" :pColspan="13"/> -->

            <tr v-if="showFotter && Orden == 'ASC'">
                <td class="text-center"><b>{{PagoTemporal.NumDia}}</b></td>
                <td class="text-center"><b>{{PagoTemporal.NumPago}}</b></td>
                <td class="text-center"><b>{{PagoTemporal.NumPagoCubierto}}</b></td>
                <td class="text-center">{{$getCleanDate(PagoTemporal.FechaEstimadoPago,false)}}</td>
                <td class="text-center">
                    <template v-if="PagoTemporal.FechaRealPago !== '' && PagoTemporal.FechaRealPago != null">
                        {{ $getCleanDate(PagoTemporal.FechaRealPago,true) }}
                    </template>
                </td>
                <td class="text-right">{{$formatNumber(PagoTemporal.MontoPago,'$')}}</td>
                <td class="text-right">{{$formatNumber(PagoTemporal.MontoRealPagado,'$')}}</td>
                <td class="text-center" colspan="1">
                    <b-badge v-if="PagoTemporal.EstatusPago == 'Atrasado'" pill variant="danger">{{ PagoTemporal.EstatusPago }}</b-badge>
                    <b-badge v-if="PagoTemporal.EstatusPago == 'Adelantado'" pill variant="primary">{{ PagoTemporal.EstatusPago }}</b-badge>
                    <b-badge v-if="PagoTemporal.EstatusPago == 'Pendiente'" pill variant="secondary">{{ PagoTemporal.EstatusPago }}</b-badge>
                </td>
                <td class="text-center">
                    <b-badge v-if="PagoTemporal.Estatus == 'Pendiente'" pill variant="secondary">{{ PagoTemporal.Estatus }}</b-badge>
                    <b-badge v-if="PagoTemporal.Estatus == 'Pagado'" pill variant="success">{{ PagoTemporal.Estatus }}</b-badge>
                    <b-badge v-if="PagoTemporal.Estatus == 'Atrasado'" pill variant="danger">{{ PagoTemporal.Estatus }}</b-badge>
                    <b-badge v-if="PagoTemporal.Estatus == 'Adelantado'" pill variant="primary">{{ PagoTemporal.Estatus }}</b-badge>
                </td>

                <td class="text-center" colspan="1">
                    <span></span>
                </td>
                <td class="text-center" colspan="1">
                    <span></span>
                </td>

                <td class="text-right">
                    <div class="btn-group">
                        <button @click="ModalUbicacionPago(PagoTemporal)" type="button" class="btn btn-sm btn-primary btn-icon float-right mr-1">
                            <i class="fas fa-map-marker-alt"></i>
                        </button>

                        <button v-if="PagoTemporal.MontoRealPagado > 0 " @click="imprimirTicketPago(PagoTemporal)" v-b-tooltip.hover.Top title="Imprimir Ticket" type="button" class="btn btn-warning btn-icon">
                            <i class="fas fa-ticket-alt"></i>
                        </button>
                    </div>
                </td>
            </tr>
            
            <template v-if="showFotter == false">
                <CSinRegistros :pContIF="listPagosPrestamo.length" :pColspan="12" />
            </template>
            

        </template>
    </CTablita>



    <CModal :pConfigModal="ConfigModalDetalles">
        <template slot="Form">
            <DetallePagos :poBtnSave="poBtnSaveDetallePagos" />
        </template>
    </CModal>

    <CModal :pConfigModal="ConfigModalComentarios">
        <template slot="Form">
            <ComentariosPagos :poBtnSave="poBtnSaveComentarios" />
        </template>
    </CModal>

    <CModal :pConfigModal="ConfigModalUbicacionPago" :poBtnSave="oBtnSave4">
        <template slot="Form">
            <FormUbicacionPago :po-btn-save="oBtnSave4"></FormUbicacionPago>
        </template>
    </CModal>

</div>
</template>

<script>
    import CTablita          from "../../../../components/CTablita";
    import CSinRegistros     from "../../../../components/CSinRegistros";
    import CModal            from "../../../../components/CModal";
    import FormUbicacionPago from "./FormUbicacionPago.vue";
    import DetallePagos      from "./DetallePagos";
    import ComentariosPagos  from "./ComentariosPagos";
import Template from '../../../template/Template.vue';
    const EmitEjecuta        =    "seccionEstadoCuenta";
    const EmitEjecuta2       =    "seccionEstadoCuentaMultas";
    const EmitEjecuta3       =    "seccionEstadoCuentaComentarios";
    const EmitEjecuta4       =    "seccionUbicacionPago";

export default {
    name: "EstadoCuentaPrestamo.vue",
    props:["pIdPrestamo"],
    components:{
        DetallePagos,
        CTablita,
        CSinRegistros,
        CModal,
        ComentariosPagos,
        FormUbicacionPago,
        Template
    },
    data() {
        return {
            Orden: 'ASC',
            listPagosPrestamo: [],
            Prestamo:{
                IdPrestamo:0
            },
            ConfigListPagos: {
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecuta,
            },
            filtroPagos:{
                Nombre:      '',
                Pagina:      1,
                Entrada:     25,
                TotalItem:   0,
                Placeholder: 'Buscar..',
            },
            ConfigModalDetalles:{
                ModalTitle:  "Abonos / Adelantos: ",
                ModalNameId: 'ModalForm',
                EmitSeccion: EmitEjecuta2,
                ModalSize:   'lg',
                ShowFooter: false,
            },
            poBtnSaveDetallePagos:{
                toast:       0,
                IsModal:     true,
                DisableBtn:  false,
                EmitSeccion: EmitEjecuta2,
                ShowBtnSave: true
            },
            ConfigModalComentarios: {
                ModalTitle:  "Comentarios del Pago",
                ModalNameId: 'ModalForm',
                EmitSeccion: EmitEjecuta3,
                ModalSize:   'lg',
                ShowFooter: false,
            },
            poBtnSaveComentarios:{
                toast:       0,
                IsModal:     true,
                DisableBtn:  false,
                EmitSeccion: EmitEjecuta3,
                ShowBtnSave: true
            },
            oBtnSave4:{
                toast:       0,
                IsModal:     true,
                DisableBtn:  false,
                EmitSeccion: EmitEjecuta4,
            },
            ConfigModalUbicacionPago: {
                ModalTitle:  "Ubicación del Pago",
                ModalNameId: 'ModalForm3',
                EmitSeccion: EmitEjecuta4,
                ModalSize:   'xl',
                ShowFooter:  false,
            },

            // ANEXO COBRANZA DIARIA
            showFotter: false,
            PagoTemporal: {
                NumDia: 0,
                NumPago: 0,
                NumPagoCubierto: 0,
                FechaEstimadoPago: '',
                FechaRealPago: '',

                MontoPago: 0,
                MontoRealPagado: 0,
                Estatus: '',
                EstatusPago: '',
                EsLiquidacion: '',
                EstatusCorte: '',
                CorteRealizado: 0,
            }
        }
    },
    methods: {
        listaPagos() {
            this.ConfigListPagos.ShowLoader = true;
            this.PagoTemporal = {};
            this.showFotter = false;

            this.$http.get("prestamospagos",{
                params:{
                    IdPrestamo: this.Prestamo.IdPrestamo,
                    Entrada:50,
                    Pag:1,
                    Orden: this.Orden
                }
            }).then((res) => {
                this.listPagosPrestamo = res.data.data.data;
                if(res.data.data2 != undefined && res.data.data2.showFooter){
                    this.showFotter =  res.data.data2.showFooter;
                    this.PagoTemporal = res.data.data2.ultimoPago;
                }

            }).finally(() => {
                this.ConfigListPagos.ShowLoader = false;
            });
        },
        viewsDetails(item) {
            //recupera las multas en formato de recovery
            this.bus.$emit('NewModal_'+EmitEjecuta2,item);
        },
        viewsComentarios(item) {
            this.bus.$emit('NewModal_'+EmitEjecuta3,item);
        },
        ModalUbicacionPago(item) {
            this.bus.$emit('NewModal_'+EmitEjecuta4,item);
        },

        imprimirTicketPago(item) {
            let newRequest = {
                PrestamoPago: item
            };

            this.$http.post('rptpdfticketpago',newRequest).then((res)=>{     
                
                let pdfWindow = window.open("");
                pdfWindow.document.write(
                    "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                    encodeURI(res.data.pdf) + "'></iframe>"
                );
                
            }).catch(err=>{   
               console.log(err);
            });
        },

    },
    created() {
        /* if(this.pIdPrestamo !== undefined){
            this.Prestamo.IdPrestamo = this.pIdPrestamo;
            this.listaPagos();
        } */
        this.bus.$off("CargaDeNuevo");
    },
    mounted() {
        this.bus.$on("CargaDeNuevo", (Orden) => {
            this.Orden = Orden;
            this.listaPagos();
        });
    },
    computed: {
        ValidatePres()
        {
            if(this.pIdPrestamo !== undefined){
                this.Prestamo.IdPrestamo = this.pIdPrestamo;
                this.listaPagos();
            }

            return this.Prestamo.IdPrestamo;
        }
    },
}
</script>

<style scoped>

</style>
