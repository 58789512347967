<template>
    <div>
        <CList :pConfigList="ConfigList" :segurity="segurity">
            <template slot="accHeader">
                <button type="button" @click="recargaForzada()" v-b-tooltip.hover.Top title="Recargar" class="btn btn-primary btn-sm mr-2">
                    <i class="far fa-redo"></i>
                </button>
            </template>
            <template slot="bodyForm">
                <CLoader :pConfigLoad="ConfigLoad">
                    <template slot="BodyFormLoad">

                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">
                                    Información General
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">
                                    Evidencias
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab3-tab" data-toggle="tab" href="#tab3" role="tab" aria-controls="tab3" aria-selected="false">
                                    Pagos
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab4-tab" data-toggle="tab" href="#tab4" role="tab" aria-controls="tab4" aria-selected="false">
                                    Multas
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab5-tab" data-toggle="tab" href="#tab5" role="tab" aria-controls="tab5" aria-selected="false">
                                    Comentarios
                                </a>
                            </li>
                            <li v-if="Prestamo.Estatus == 'Cancelado' || parseInt(Prestamo.entregas_canceladas_count) > 0 " class="nav-item" role="presentation">
                                <a class="nav-link" id="tab6-tab" data-toggle="tab" href="#tab6" role="tab" aria-controls="tab6" aria-selected="false">
                                    Cancelaciones
                                </a>
                            </li>
                            <li v-if="listLiquidacionPrestamo.length" class="nav-item" role="presentation">
                                <a class="nav-link" id="tab7-tab" data-toggle="tab" href="#tab7" role="tab" aria-controls="tab7" aria-selected="false">
                                    Comentarios Liquidación
                                </a>
                            </li>
                        </ul>

                        <!-- CONTENIDO TABLAS -->
                        <div class="tab-content shadow-sm" id="myTabContent">

                            <!--Parte uno Informacion  General -->
                            <div class="tab-pane fade active show" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-12 mt-3">

                                            <!-- DATOS DE GENERALES -->
                                            <fieldset>
                                                <legend class="col-form-label">Datos Generales </legend>
                                                <div class="form-row mt-4">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                                        <div class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="Folio">Folio</label>
                                                                <input class="form-control" id="Folio" v-model="Prestamo.Folio" type="text" disabled />
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="Estatus">Estatus</label>
                                                                <input class="form-control" id="Estatus" v-model="Prestamo.Estatus" type="text" v-bind:style="colorInput(Prestamo.Estatus)" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="cobratario">Gestor Asignado</label>
                                                                <input id="cobratario" class="form-control" v-model="Prestamo.cobratario.NombreCompleto" type="text" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div class="form-group form-row">

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="MontoEntregado">Importe Entregado</label>
                                                                <input id="MontoEntregado" class="form-control" :value="$formatNumber(Prestamo.F_ImportePrestado,'$')" type="text" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="MontoDiario">Abono Diario</label>
                                                                <input id="MontoDiario" :value="$formatNumber(Prestamo.F_PagoDiario,'$')" type="text" class="form-control" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="FechaInicioCobro">Fecha Inicial de Cobro</label>
                                                                <input id="FechaInicioCobro" :value="Prestamo.FechaInicioCobro != null ? $getCleanDate(Prestamo.FechaInicioCobro,false) : '' " type="text" class="form-control" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="DiasTranscurridos">Dias Transcurridos</label>
                                                                <input id="DiasTranscurridos" :value="Prestamo.DiasTranscurridos !== null ? Prestamo.DiasTranscurridos : 0" type="text" class="form-control" disabled/>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div class="form-group form-row">

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="NumPagoActual">Pagos Realizados</label>
                                                                <input id="NumPagoActual" :value="Prestamo.NumPagoActual" type="text" class="form-control" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="NumPagoCubierto">Pagos Cubiertos</label>
                                                                <input id="NumPagoCubierto" :value="Prestamo.NumPagoCubierto" type="text" class="form-control" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="Telefono">Total Multas Impuestas</label>
                                                                <input id="Telefono" :value="Prestamo.multas_count" type="text" placeholder="Ingrese Teléfono" class="form-control" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="NumMultas">Multas Sin Pagar</label>
                                                                <input id="NumMultas" :value="Prestamo.F_MultaSinPagar" type="text" class="form-control" disabled/>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <hr>
                                                <div class="form-row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="Telefono">Monto a devolver</label>
                                                                <input id="TotalDevolverPrestamo" :value="$formatNumber(Prestamo.F_TotalDevolver,'$')" type="text" class="form-control" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="TotalPagado">Total Pagado</label>
                                                                <input id="TotalPagado" :value="$formatNumber(Prestamo.F_TotalPagado,'$')" type="text" class="form-control" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="SaldoPendiente">Saldo Pendiente</label>
                                                                <input id="SaldoPendiente" :value="$formatNumber(Prestamo.F_SaldoPendiente,'$')" type="text" class="form-control" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="SaldoFavor">Saldo a Favor</label>
                                                                <input id="SaldoFavor" :value="$formatNumber(Prestamo.F_SaldoFavor,'$')" type="text" class="form-control" disabled/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>

                                            <!-- DATOS DE COBRANZA -->
                                            <fieldset class="mt-2">
                                                <legend class="col-form-label">Datos de Cobranza </legend>
                                                <div class="form-row mt-4">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="sucursal">Sucursal</label>
                                                                <input id="sucursal" v-model="Prestamo.sucursal.Nombre" type="text" class="form-control" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="Ruta">Ruta</label>
                                                                <input id="Ruta" class="form-control" v-model="Prestamo.ruta.NombreRuta" type="text" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="Creador">Generó Solicitud</label>
                                                                <input id="Creador" class="form-control" v-model="Prestamo.creo.NombreCompleto" type="text" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                            </div>

                                                        </div>

                                                        <div class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="MontoSolicitado">Monto Solicitado</label>
                                                                <input id="MontoSolicitado" :value="$formatNumber(Prestamo.F_MontoSolicitado,'$')" type="text" class="form-control" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="Fechacreacion">Fecha Creación</label>
                                                                <input id="Fechacreacion" :value="Prestamo.created_at != null ? $getCleanDate(Prestamo.created_at,true) : '' " type="text" class="form-control" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="autorizo">Autorizó</label>
                                                                <input id="autorizo" class="form-control" v-model="Prestamo.autorizo.NombreCompleto" type="text" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="FechaAutorizacion">Fecha Autorización</label>
                                                                <input id="FechaAutorizacion" :value="Prestamo.FechaAutorizacion != null ? $getCleanDate(Prestamo.FechaAutorizacion,true) : '' " type="text" class="form-control" disabled/>
                                                            </div>
                                                        </div>

                                                        <div class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="Entrego">Entregó</label>
                                                                <input id="Entrego" class="form-control" v-model="Prestamo.entregador.NombreCompleto" type="text" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="MontoEntrega">Monto Entregado</label>
                                                                <input id="MontoEntrega" :value="$formatNumber(Prestamo.F_ImportePrestado,'$')" type="text" class="form-control" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="FechaEntrega">Fecha Entrega</label>
                                                                <input id="FechaEntrega" :value="Prestamo.FechaEntrega != null ? $getCleanDate(Prestamo.FechaEntrega,true) : '' " type="text" class="form-control" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="EstatusEntrega">Estatus Entrega</label>
                                                                <input class="form-control" id="EstatusEntrega" v-model="Prestamo.EstatusEntrega" type="text" v-bind:style="colorInput(Prestamo.EstatusEntrega)" disabled/>
                                                            </div>
                                                        </div>

                                                        <div v-if="Prestamo.rechazo.IdUsuario > 1" class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="rechazo">Rechazó</label>
                                                                <input id="rechazo" class="form-control" style="border-color:#f80031;" v-model="Prestamo.rechazo.NombreCompleto" type="text" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="FechaRechazo">Fecha Rechazo</label>
                                                                <input id="FechaRechazo" style="border-color:#f80031;" :value="Prestamo.FechaRechazo != null ? $getCleanDate(Prestamo.FechaRechazo,true) : '' " type="text" class="form-control" disabled/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <label for="PrestamoMotRechazo">Motivo Rechazo</label>
                                                                <textarea id="PrestamoMotRechazo" v-model="Prestamo.PrestamoMotRechazo" style="border-color:#f80031;" class="form-control" rows="1" disabled></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>

                                            <!-- DATOS DE LIQUIDACIÓN -->
                                            <!--Prestamo.Estatus !== 'Liquidado' && Prestamo.Estatus !== 'Cancelado' && Prestamo.Estatus !== 'Rechazado'-->
                                            <fieldset class="mt-2" v-if="Prestamo.Estatus == 'Cobranza' && ocultarBtnGuardar!==false">
                                                <legend class="col-form-label">Desgloce de Liquidación</legend>
                                                <div v-if="segurity.GenerarLiquidacion" class="form-row mt-4 position-relative">

                                                    <div class="checkbox mb-n2 float_checkbox" >
                                                        <label class="color-01">
                                                            <input type="checkbox" v-model="Prestamo.EsBonificado" @change="funcioncheck()" true-value="SI" false-value="NO"  name="optionsCheckboxes" >
                                                            <span class="checkbox-material-green">
                                                                <span class="check"></span>
                                                            </span>
                                                            Aplicar Bonificación ({{ Prestamo.NumBonificaciones }})
                                                        </label>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                                        <div class="form-group form-row">

                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                                                <div class="form-row">
                                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                        <label for="TotalPagados">Total Pagado</label>
                                                                        <input class="form-control" id="TotalPagados" :value="$formatNumber(PrestamoLiquidacion.M_TotalPagado,'$')" type="text" disabled />
                                                                    </div>

                                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                        <label for="SaldoP">Saldo Pendiente</label>
                                                                        <input id="SaldoP" :value="$formatNumber(PrestamoLiquidacion.M_SaldoPendiente,'$')" type="text" class="form-control" disabled/>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                                                <div class="form-row">
                                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                        <label for="MultaSinPagar">Multas</label>
                                                                        <input id="MultaSinPagar" v-model="PrestamoLiquidacion.M_MultaSinPagar" type="text" class="form-control" disabled/> &nbsp;
                                                                    </div>
                                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                        <label for="MultaSinPagar">Pendientes</label>
                                                                        <div class="form-control">
                                                                            <button @click="FuncionDinamico('MENOS')" type="button" class="btn-sm border-0 rounded-0 py-0" style="background: #FFFFFF;">
                                                                                <svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="dash" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-dash b-icon bi">
                                                                                    <g transform="translate(8 8) scale(1.25 1.25) translate(-8 -8)">
                                                                                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"></path>
                                                                                    </g>
                                                                                </svg>
                                                                            </button>
                                                                            <input v-model="Prestamo.NumMultas" @input="$number_decimal($event,Prestamo,'NumMultas')" type="text" class="text-center rounded-0 py-0 border-bottom-0 border-top-0 border-left border-right width-77">
                                                                            <button @click="FuncionDinamico('MAS')" type="button" class="btn-sm border-0 rounded-0 py-0" style="background: #FFFFFF;">
                                                                                <svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="plus" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-plus b-icon bi">
                                                                                    <g transform="translate(8 8) scale(1.25 1.25) translate(-8 -8)">
                                                                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                                                                                    </g>
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-9 col-lg-5 col-xl-5">
                                                                <div class="form-row">
                                                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                        <label for="Bonificacion">Bonificación</label>
                                                                        <input id="Bonificacion" :value="$formatNumber(Prestamo.Bonificacion,'$')" @input="$number_decimal($event,Prestamo,'Bonificacion'); funcioncheck()" type="text" placeholder="$ 0.00" class="form-control" disabled/>
                                                                    </div>
                                                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                        <label for="Subtotal">Subtotal</label>
                                                                        <input id="Subtotal" :value="$formatNumber(PrestamoLiquidacion.M_SubTotal,'$')" type="text" placeholder="Ingrese Teléfono" class="form-control" disabled/>
                                                                    </div>
                                                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                        <label for="liquidar">Total para liquidar</label>
                                                                        <div class="input-group mb-3">
                                                                            <input id="liquidar" :disabled="this.disabled" v-model="PrestamoLiquidacion.TotalxLiquidar" @input="$number_decimal($event,PrestamoLiquidacion,'TotalxLiquidar')" type="text" class="form-control">
                                                                            <div class="input-group-append">
                                                                                <button v-if="segurity.EditSettlement" @click="ModalTotalaLiquidar(PrestamoLiquidacion)" v-b-tooltip.hover.Top title="Editar" class="btn btn-primary py-0" type="button" id="button-addon2">
                                                                                    <i class="fas fa-pencil"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div v-show="ocultarBtnGuardar" :class="(this.disabled === false)?'col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6':'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'">
                                                        <label for="Comentario">Comentario de Liquidación</label>
                                                        <textarea class="form-control" v-model="PrestamoLiquidacion.ComentarioLiquidacion" name="Comentario" placeholder="Ingrese un Comentario" cols="0" rows="5"></textarea>
                                                        <CValidation v-if="this.errorvalidacion.ComentarioLiquidacion" :Mensaje="'*'+errorvalidacion.ComentarioLiquidacion[0]"/>
                                                    </div>

                                                    <div v-show="this.disabled === false" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                        <label for="Comentario">Motivo de cambio de monto total</label>
                                                        <textarea v-model="PrestamoModificado.ComentarioBitacora" class="form-control" placeholder="Ingresar un comentario" rows="5"></textarea>
                                                        <CValidation v-if="this.errorvalidacion.ComentarioBitacora" :Mensaje="'*'+errorvalidacion.ComentarioBitacora[0]"/>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
                                                        <CBtnSave :poBtnSave="oBtnSave" v-show="ocultarBtnGuardar" />
                                                    </div>
                                                </div>
                                            </fieldset>

                                            <fieldset v-if="ocultarBtnGuardar == false">
                                                <legend class="col-form-label">Liquidación</legend>
                                                <div v-if="ocultarBtnGuardar == false" class="col-12 alert alert-warning alert-dismissible fade show text-center" role="alert">
                                                    El día de hoy ya se ha generado una propuesta de liquidación al prestamo de <strong>{{ this.Prestamo.cliente.NombreCompleto }}</strong> con Folio: <b>#{{ this.Prestamo.Folio }}</b>
                                                    <button @click="cierreAlerta" type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--Parte Dos Evidencias -->
                            <div class="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">

                                            <!-- EVIDENCIA ENTREGA -->
                                            <fieldset>
                                                <legend class="col-form-label">&nbsp;Evidencia de Entrega&nbsp;</legend>
                                                <template v-if="ListaEviEntrega == null || ListaEviEntrega == ''" >
                                                    <div class="form-group form-row">
                                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center" >
                                                            <div class="box-imagen">
                                                                <h1 class="col-form-h1 pt-1">&nbsp;Sin Evidencia de Entrega &nbsp;</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>


                                                <template v-else>
                                                    <div class="form-group form-row">
                                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
                                                            <button @click="ModalUbicacionEntrega(Prestamo)" type="button" class="btn btn-sm btn-primary mr-1" title="Ubicación de Entrega">
                                                                <i class="fas fa-map-marker-alt"></i>
                                                            </button>

                                                            <!--<button type="button" class="btn btn-sm btn-primary" title="Historial de Imagenes">
                                                                <i class="fas fa-history"></i>
                                                            </button>-->
                                                        </div>
                                                    </div>
                                                    <div class="form-group form-row">
                                                        <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2" v-for="(item,index) in ListaEviEntrega" :key="index" >
                                                            <div class="box-imagen">
                                                                <div class="avatar-upload image-button">
                                                                    <label class="" style="text-align: left;">
                                                                        <template v-if="item.TipoEvidencia == 'ImgIne'">
                                                                            INE
                                                                        </template>
                                                                        <template v-else-if="item.TipoEvidencia == 'ImgPersonal'">
                                                                            Personal
                                                                        </template>
                                                                        <template v-else-if="item.TipoEvidencia == 'ImgEstablecimiento1'">
                                                                            Establecimiento 1
                                                                        </template>
                                                                        <template v-else-if="item.TipoEvidencia == 'ImgEstablecimiento2'">
                                                                            Establecimiento 2
                                                                        </template>
                                                                        <template v-else-if="item.TipoEvidencia == 'ImgPagare'">
                                                                            Documento de Crédito
                                                                        </template>
                                                                        <template v-else-if="item.TipoEvidencia == 'ImgHojaReferencia1'">
                                                                            Hoja de Referencias 1
                                                                        </template>
                                                                        <template v-else-if="item.TipoEvidencia == 'ImgHojaReferencia2'">
                                                                            Hoja de Referencias 2
                                                                        </template>
                                                                        <template v-else-if="item.TipoEvidencia == 'ImgTerminosCondiciones'">
                                                                            Terminos y Condiciones
                                                                        </template>
                                                                        <template v-else-if="item.TipoEvidencia == 'ImgReciboDinero'">
                                                                            Recibo de Dinero
                                                                        </template>
                                                                    </label>
                                                                    


                                                                    <div class="avatar-edit2">
                                                                        <button type="button" class="mr-1" @click="previewImagen(RutaEviEntrega,item)">
                                                                            <i class="fas fa-search"></i>
                                                                        </button>
                                                                    </div>
                                                                    <div class="avatar-edit3">
                                                                        <button type="button" @click="cambiarEvidencia(RutaEviEntrega,item);">
                                                                            <i class="far fa-pen"></i>
                                                                        </button>
                                                                    </div>

                                                                    <div class="avatar-preview">
                                                                        <div :id="'imagePreviewIne_'+index" :style="'background-image: url('+RutaEviEntrega+item.Evidencia+');'" :src="RutaEviEntrega+item.Evidencia"></div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>

                                            </fieldset>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--Parte Tres Pagos -->
                            <div class="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
                                <div class="container-fluid">
                                    <div class="row mt-2">
                                        <EstadoCuentaPrestamo :pIdPrestamo="Prestamo.IdPrestamo" />
                                    </div>
                                </div>
                            </div>

                            <!--Parte Cuatro Multas -->
                            <div class="tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="tab4-tab">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-12 mt-3">
                                            <CTablita :pConfigList="ConfigListMultas">
                                                <template slot="header">
                                                    <th class="text-center">F. Emisión</th>
                                                    <th class="text-center">F. Real de Pago</th>
                                                    <th class="text-right">Monto Pagado</th>
                                                    <th class="text-center">Estado Actual</th>
                                                </template>

                                                <template slot="body">
                                                    <tr v-for="(item,index) in listMultasPrestamo" :key="index">
                                                        <td class="text-center">{{$getCleanDate(item.FechaEmision,false)}}</td>
                                                        <td class="text-center">
                                                            <template v-if="item.FechaPago !== '' && item.FechaPago != null">
                                                                {{ $getCleanDate(item.FechaPago,true) }}
                                                            </template>
                                                        </td>
                                                        <td class="text-right">{{$formatNumber(item.MontoPagado,'$')}}</td>
                                                        <td class="text-center">
                                                            <b-badge v-if="item.Estatus == 'Pagada'" pill variant="success">{{ item.Estatus }}</b-badge>
                                                            <b-badge v-if="item.Estatus == 'NoPagada'" pill variant="danger">No Pagada</b-badge>
                                                            <b-badge v-if="item.Estatus == 'Condonada'" pill variant="primary">{{ item.Estatus }}</b-badge>
                                                        </td>
                                                    </tr>
                                                    <CSinRegistros :pContIF="listMultasPrestamo.length" :pColspan="4"/>
                                                </template>
                                            </CTablita>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--Parte Cinco Comentarios -->
                            <div class="tab-pane fade" id="tab5" role="tabpanel" aria-labelledby="tab5-tab">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-12 mt-3">
                                            <fieldset>
                                                <legend class="col-form-label">&nbsp;Comentarios&nbsp;</legend>
                                                <div class="row">
                                                    <div class="col-md-12 jutify-content-end">
                                                        <button type="button" @click="modalComentarioPrestamo()" class="btn btn-primary btn-sm float-right">
                                                            <i class="fas fa-plus-circle"></i> Nuevo Comentario
                                                        </button>
                                                    </div>
                                                </div>

                                                <CTablita :pConfigList="ConfigListComentarios">
                                                <template slot="header">
                                                    <th class="text-center">Evento</th>
                                                    <th class="text-center">Fecha</th>
                                                    <th class="text-center">Usuario</th>
                                                    <th class="text-center">Comentario</th>
                                                </template>

                                                <template slot="body">
                                                    <tr v-for="(item,index) in listComentariosPrestamo" :key="index">
                                                        <td class="text-center">
                                                            <template v-if="item.TipoComentario == 'Entrega'">
                                                                Entrega de Credito
                                                            </template>
                                                            <template v-else-if="item.TipoComentario == 'Pago'">
                                                                Pagos
                                                            </template>
                                                            <template v-else-if="item.TipoComentario == 'Liquidacion'">
                                                                Liquidación
                                                            </template>
                                                            <template v-else-if="item.TipoComentario == 'Otro'">
                                                                Otros
                                                            </template>
                                                            <template v-else-if="item.TipoComentario == 'CorreccionPago'">
                                                                Corrección de Pago
                                                            </template>
                                                            <template v-else-if="item.TipoComentario == 'CorreccionEntrega'">
                                                                Corrección de Entrega
                                                            </template>
                                                        </td>
                                                        <td class="text-center">{{$getCleanDate(item.Fecha,true)}}</td>
                                                        <td class="text-center">{{item.NombreCompleto}}</td>
                                                        <td class="text-center">{{item.Comentario}}</td>
                                                    </tr>
                                                    <CSinRegistros :pContIF="listComentariosPrestamo.length" :pColspan="4"/>
                                                </template>
                                            </CTablita>

                                            </fieldset>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--Parte Seis Cancelaciones -->
                            <div class="tab-pane fade" id="tab6" role="tabpanel" aria-labelledby="tab6-tab">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">

                                            <CTablita :pConfigList="ConfigListCancelaciones">
                                                <template slot="header">
                                                    <th class="text-center">Canceló</th>
                                                    <th class="text-center">Fecha</th>
                                                    <th class="text-center">Motivo</th>
                                                    <th>Acciones</th>
                                                </template>

                                                <template slot="body">
                                                    <tr v-for="(item,index) in listCancelacionesPrestamo" :key="index">
                                                        <td class="text-center"><CAvatar :fullname="item.NombreCompleto" :size="25" :radius="50"/> {{ item.NombreCompleto }}</td>
                                                        <td class="text-center">{{$getCleanDate(item.created_at,true)}}</td>
                                                        <td class="text-center">{{$limitCharacters(item.MotivoCancelacionEntrega)}}</td>
                                                        <td>
                                                            <button @click="viewCancelacionEntrega(item)" class="btn btn-warning btn-icon mr-1" title="ver evidencias"><i class="fa fa-picture-o"></i></button>
                                                        </td>
                                                    </tr>
                                                    <CSinRegistros :pContIF="listCancelacionesPrestamo.length" :pColspan="4"/>
                                                </template>
                                            </CTablita>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--Parte Siete Comentarios Liquidación -->
                            <div class="tab-pane fade" id="tab7" role="tabpanel" aria-labelledby="tab7-tab">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">

                                            <CTablita :pConfigList="ConfigListLiquidacion">
                                                <template slot="header">
                                                    <th class="text-center">Evento</th>
                                                    <th class="text-center">Liquidó</th>
                                                    <th class="text-center">Fecha</th>
                                                    <th class="text-center">Motivo</th>
                                                    <th class="text-center">Acciones</th>
                                                </template>

                                                <template slot="body">
                                                    <tr v-for="(element,index3) in listLiquidacionPrestamo" :key="index3">
                                                        <td class="text-center">
                                                           <span v-if="element.Tipo == 'Comentario'"> Liquidacion</span>
                                                           <span v-if="element.Tipo == 'Bitacora'"> Correccion Monto</span>
                                                        </td>
                                                        <td class="text-center">{{ element.NombreCompleto }}</td>
                                                        <td class="text-center">{{$getCleanDate(element.created_at,true,true)}}</td>
                                                        <td class="text-center">{{element.ComentarioLiquidacion}}</td>
                                                        <td class="text-center">
                                                            <button type="button" v-b-tooltip.hover.Top title="Ver comentario" class="btn btn-primary btn-icon" @click="ModalComentarioLiquidacion(element)">
                                                                <i class="fas fa-eye"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <CSinRegistros :pContIF="listLiquidacionPrestamo.length" :pColspan="4"/>
                                                </template>
                                            </CTablita>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </template>
                </CLoader>
            </template>
        </CList>

        <CModal :pConfigModal="ConfigModalEvidencia">
            <template slot="Form">
                <FormEvidencia :po-btn-save="poBtnSaveEvidencia" />
            </template>
        </CModal>

        <CModal :pConfigModal="ConfigModalLiquidacion" :poBtnSave="oBtnSave2">
			<template slot="Form">
				<FormLiquidacion :po-btn-save="oBtnSave2"/>
			</template>
		</CModal>

        <CModal :pConfigModal="ConfigModalTotalaLiquidar" :poBtnSave="oBtnSave3">
			<template slot="Form">
				<FormTotalaLiquidar :po-btn-save="oBtnSave3"/>
			</template>
		</CModal>

        <CModal :pConfigModal="ConfigModalUbicacionEntrega" :poBtnSave="oBtnSave5">
			<template slot="Form">
				<FormUbicacionEntrega :po-btn-save="oBtnSave5"/>
			</template>
		</CModal>

        <CModal :pConfigModal="ConfigModalEvidenciaEntrega" :poBtnSave="oBtnSave6">
            <template slot="Form">
                <FormModEvidenciaEntrega :poBtnSave="oBtnSave6"/>
            </template>
        </CModal>

        <CModal :pConfigModal="ConfigModalComentPrestamo" :poBtnSave="oBtnSave7">
            <template slot="Form">
                <ComentarioPrestamos :poBtnSave="oBtnSave7" />
            </template>
        </CModal>

    </div>
</template>

<script>

import CList                from "../../../../components/CList.vue";
import CLoader              from "../../../../components/CLoader.vue";
import CTablita             from "../../../../components/CTablita.vue";
import CSinRegistros        from "../../../../components/CSinRegistros.vue";
import Template             from "../../../template/Template.vue";
import CAvatar              from "../../../../components/CAvatar.vue";
import CBtnAccion           from "../../../../components/CBtnAccion.vue";
import CModal               from "../../../../components/CModal.vue";
import FormEvidencia        from "./FormEvidencia.vue";
import EstadoCuentaPrestamo from "./EstadoCuentaPrestamo.vue";
import FormLiquidacion      from "./FormComentarioLiquidacion.vue";
import FormTotalaLiquidar   from "./FormTotalaLiquidar.vue";
import FormUbicacionEntrega from "./FormUbicacionEntrega.vue";
import FormModEvidenciaEntrega from "./FormModEvidenciaEntrega";
import ComentarioPrestamos from "./ComentarioPrestamos";
import Configs 				from "@/helpers/VarConfig.js";
import moment               from "moment";

const  EmitEjecuta          =    "seccionDetPrestamo";
const  EmitEjecuta2         =    "seccionDetCancelEntrega";
const  EmitEjecuta3         =    "seccionLiquidacion";
const  EmitEjecuta4         =    "seccionTotalaLiquidar";
const  EmitEjecuta5         =    "seccionUbicacionEntrega";
const  EmitEjecuta6         =    "seccionEvidenciaEntrega";
const  EmitEjecuta7         =    "seccionComentariosPrestamos";

export default {
    name: "FormDetallePrestamo",
    props:['Id','pSitio','objPrestamo'],
    components:{
        EstadoCuentaPrestamo,
        Template,
        CList,
        CLoader,
        CTablita,
        CSinRegistros,
        CAvatar,
        CBtnAccion,
        CModal,
        FormEvidencia,
        FormLiquidacion,
        FormTotalaLiquidar,
        FormUbicacionEntrega,
        FormModEvidenciaEntrega,
        ComentarioPrestamos
    },
    data() {
        return {
            ocultarBtnEditar:           false,
            ocultarBtnGuardar:          true,
            varplus:                    0,
            deshabilitado:              false,
            disabled:                   true,
            RutaEviEntrega:             '',
            RutaEviCancelacionEntrega:  '',
            RutaEviSol:                 '',
            Origen:                     '',
            FechaActual:                moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
            ArrayBase:                  [],
            ArrayDinamico:              [],
            errorvalidacion:            [],
            listMultasPrestamo:         [],
            listComentariosPrestamo:    [],
            listCancelacionesPrestamo:  [],
            listLiquidacionPrestamo:    [],
            ListaEviEntrega:            [],
            segurity:                   {},
            ObjUsuario:                 {},
            masks: { input: 'YYYY-MM-DD'},
            ConfigList:{
                ShowTitleFirst: 	false,
                Title: 				"Detalles Préstamo de: Cargando...",
                ShowLoader: 		false,
                IsModal: 			false,
                BtnNewShow: 		false,
                BtnReturnShow: 		true,
                TypeBody: 			"Form",
                ShowFiltros: 		false,
                ShowFiltrosInline: 	true,
                ShowTitleInline: 	false,
                ShowPaginador: 		false,
                EmitSeccion: 		EmitEjecuta,
                ShowTitleEnd:       false,
                ShowTitleEndRoute:  true,
            },
            ConfigLoad: {
                ShowLoader: false,
                ClassLoad:  false,
            },
            Prestamo:{
                IdPrestamo:0,
                IdCliente:0,
                IdSucursal:0,
                IdRuta:0,
                Creador:0,
                IdCobratario:0,
                IdAutorizo:0,
                IdEntrego:0,
                IdPrestamoxCancelacion:0,
                IdRechazo:0,

                Folio:'',
                Origen:'',

                FechaAutorizacion:'',
                FechaEntrega:'',
                FechaInicioCobro:'',
                FechaLiquidacion:'',
                FechaRechazo:'',

                MontoSolicitud:0,
                MontoEntregado:0,

                Estatus:'',
                EstatusEntrega:'',

                Observaciones:'',
                PrestamoMotRechazo:'',

                TazaInteres:0,
                MontoDiario:0,
                TotalDevolverPrestamo:0,
                NumPagos:0,
                TotalGlobal:0,

                DiasTranscurridos:0,
                NumPagoActual:0,
                NumPagoCubierto:0,

                NumMultas:0,
                TotalMultas:0,
                SaldoFavor:0,
                TotalAbonos:0,

                F_MontoSolicitado:0,
                F_TotalPagado: 0,
                F_MultaSinPagar: 0,
                F_TotalDevolver: 0,
                F_PagoDiario:0,
                F_ImportePrestado: 0,
                F_SaldoFavor: 0,
                SubTotal: 0,
                EsBonificado: '',

                creo:{
                    IdUsuario:0,
                    NombreCompleto:""
                },
                autorizo:{
                    IdUsuario:0,
                    NombreCompleto:""
                },
                entregador:{
                    IdUsuario:0,
                    NombreCompleto:""
                },
                cobratario:{
                    IdUsuario:0,
                    NombreCompleto:""
                },
                rechazo:{
                    IdUsuario:0,
                    NombreCompleto:""
                },
                ruta:{
                    IdRuta:0,
                    NombreRuta: ""
                },
                sucursal:{
                    IdSucursal:	0,
                    Nombre:	""
                },
                evidencia_entrega:[],
                entregas_canceladas_count:0,
            },
            PrestamoLiquidacion: {
                M_TotalPagado:         0,
                M_SaldoPendiente:      0,
                M_SubTotal:            0,
                M_MultaSinPagar:       0,
                Bonificacion:          0,
                TotalxLiquidar:        0,
                NumMultas:             0,
                ComentarioLiquidacion: '',
                EsBonificado:          '',
                DisableCheck:          false,
            },
            PrestamoModificado: {
                ComentarioBitacora: '',
                Coincidencia:       0,
                NuevoMonto:         0,
            },
            ConfigListMultas: {
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecuta,
            },
            filtroMultas:{
                Nombre:      '',
                Pagina:      1,
                Entrada:     25,
                TotalItem:   0,
                Placeholder: 'Buscar..',
            },
            ConfigListComentarios: {
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecuta,
            },
            ConfigListLiquidacion: {
                ShowLoader:     true,
                IsModal:        true,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     true,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecuta3,
            },
            filtroComentarios:{
                Nombre:      '',
                Pagina:      1,
                Entrada:     25,
                TotalItem:   0,
                Placeholder: 'Buscar..',
            },
            ConfigListCancelaciones: {
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecuta2,
            },
            filtroCancelaciones:{
                Nombre:      '',
                Pagina:      1,
                Entrada:     25,
                TotalItem:   0,
                Placeholder: 'Buscar..',
            },
            ConfigModalEvidencia:{
                ModalTitle:  "Evidencias de Cancelacion de Entrega",
                ModalNameId: 'ModalForm',
                EmitSeccion: EmitEjecuta2,
                ModalSize:   'lg',
                ShowFooter: false,
            },
            poBtnSaveEvidencia:{
                toast:       0,
                IsModal:     true,
                DisableBtn:  false,
                EmitSeccion: EmitEjecuta2,
                ShowBtnSave: true
            },
            oBtnSave: {
                toast:         0,
                IsModal:       false,
                ShowBtnSave:   true,
                ShowBtnCancel: false,
                DisableBtn:    false,
                EmitSeccion:   EmitEjecuta2,
            },
            ConfigModalLiquidacion: {
                ModalTitle:  "Comentarios Liquidación",
                ModalNameId: 'ModalForm3',
                EmitSeccion: EmitEjecuta3,
                ModalSize:   'lg',
            },
            oBtnSave2:{
                toast:       0,
                IsModal:     true,
                DisableBtn:  false,
                EmitSeccion: EmitEjecuta3,
                ShowBtnSave: false,
            },
            ConfigModalTotalaLiquidar: {
                ModalTitle:  "Seguridad",
                ModalNameId: 'ModalForm4',
                EmitSeccion: EmitEjecuta4,
                ModalSize:   'sm',
            },
            oBtnSave3:{
                toast:       0,
                IsModal:     true,
                DisableBtn:  false,
                EmitSeccion: EmitEjecuta4,
            },
            ConfigModalUbicacionEntrega: {
                ModalTitle:  "Ubicación de la Entrega",
                ModalNameId: 'ModalForm5',
                EmitSeccion: EmitEjecuta5,
                ModalSize:   'xl',
                ShowFooter:  false,
            },
            oBtnSave5:{
                toast:       0,
                IsModal:     true,
                DisableBtn:  false,
                EmitSeccion: EmitEjecuta5,
            },

            ConfigModalEvidenciaEntrega: {
                ModalTitle:  "Cambiar Evidencia ",
                ModalNameId: 'ModalForm6',
                EmitSeccion: EmitEjecuta6,
                ModalSize:   'lg',
                ShowFooter:  true,
            },

            oBtnSave6:{
                toast:       0,
                IsModal:     true,
                DisableBtn:  false,
                EmitSeccion: EmitEjecuta6,
            },

            ConfigModalComentPrestamo: {
                ModalTitle:  "Comentario Prestamos",
                ModalNameId: 'ModalForm7',
                EmitSeccion: EmitEjecuta7,
                ModalSize:   'lg',
                ShowFooter:  true,
            },

            oBtnSave7:{
                toast:       0,
                IsModal:     true,
                DisableBtn:  false,
                EmitSeccion: EmitEjecuta7,
            },
        }
    },
    methods:{
        Guardar() {
            this.oBtnSave.toast = 0;
            this.$swal(Configs.configLiquidacion)
            .then((result) => {
                if(result.value)
                {
                    this.errorvalidacion     = [];
                    this.oBtnSave.DisableBtn = true
                    let formData                          = new FormData();
                    formData.set('IdPrestamo',            this.Prestamo.IdPrestamo);
                    formData.set('MultaSinPagar',         this.Prestamo.NumMultas);
                    formData.set('MontoMulta',            this.Prestamo.TotalMultas);
                    formData.set('Bonificacion',          this.Prestamo.Bonificacion);
                    formData.set('EsBonificado',          this.Prestamo.EsBonificado);
                    formData.set('SaldoPendiente',        this.PrestamoLiquidacion.M_SaldoPendiente);
                    formData.set('ComentarioLiquidacion', this.PrestamoLiquidacion.ComentarioLiquidacion);
                    formData.set('SubTotal',              this.PrestamoLiquidacion.M_SubTotal);
                    formData.set('TotalPagado',           this.PrestamoLiquidacion.M_TotalPagado);
                    formData.set('TotalxLiquidar',        this.PrestamoLiquidacion.TotalxLiquidar);
                    formData.set('ComentarioBitacora',    this.PrestamoModificado.ComentarioBitacora);
                    formData.set('Coincidencia',          this.PrestamoModificado.Coincidencia);
                    formData.set('NuevoMonto',            this.PrestamoLiquidacion.TotalxLiquidar);
                    formData.set('Visible',               this.disabled);

                    this.$http.post('liquidacionxPrestamo', formData,{
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((res)=>{
                        this.EjecutaConExito(res);
                    }).catch(err=>{
                        this.EjecutaConError(err);
                    });
                }
            });
        },

        EjecutaConExito(res) {
            this.oBtnSave.DisableBtn = false;
            this.disabled = true;
            this.limpiar();
            this.recoveryPrestamo();
            //this.listaLiquidacion();
        },

        EjecutaConError(err) {
            this.oBtnSave.DisableBtn = false;

            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.oBtnSave.toast  = 2;
            } else {
                this.$toast.error(err.response.data.message);
            }
        },

        recargaForzada(){
            this.bus.$emit('Busqueda', this.Prestamo.IdPrestamo)
        },

        recoveryPrestamo() {
            this.ConfigLoad.ShowLoader = true;

            this.$http.get(
                "prestamos/"+this.Prestamo.IdPrestamo)
            .then((res) => {
              
                this.Prestamo               = res.data.data;
                this.Prestamo.EsBonificado  = res.data.EsBonificado;
                this.RutaEviSol             = res.data.RutaEvidSol;
                this.RutaEviEntrega         = res.data.RutaEvidEntre;
                this.segurity               = res.data.segurity;
                let ArrayMultas             = res.data.multas;
                this.Prestamo.NumMultas     = ArrayMultas.length;

                // SETTER BASE
                this.Prestamo.F_PagoDiario      = this.Prestamo.MontoDiario;
                this.Prestamo.F_MontoSolicitado = isNaN(parseFloat(this.Prestamo.MontoSolicitud)) ? 0 : this.Prestamo.MontoSolicitud;
                this.Prestamo.F_ImportePrestado = isNaN(parseFloat(this.Prestamo.MontoEntregado)) ? 0 : this.Prestamo.MontoEntregado;
                this.Prestamo.F_MultaSinPagar   = '('+this.Prestamo.NumMultas+') '+this.$formatNumber(this.Prestamo.TotalMultas,'$');
                this.Prestamo.F_TotalDevolver   = this.Prestamo.TotalDevolverPrestamo;

                let totalPagado                 = ( parseFloat(this.Prestamo.TotalPagado) );
                let saldoPendiente              = ( parseFloat(this.Prestamo.TotalDevolverPrestamo) - totalPagado);

                /*if(saldoPendiente <= 0){
                    saldoPendiente = 0.00;
                }*/
                // let subTotal                 = ( parseFloat(saldoPendiente) + parseFloat(this.Prestamo.TotalMultas) );

                this.Prestamo.F_TotalPagado     = isNaN(totalPagado) ? 0 : totalPagado;
                this.Prestamo.F_SaldoPendiente  = isNaN(saldoPendiente) ? 0 : saldoPendiente;
                this.Prestamo.F_SaldoFavor      = isNaN(this.Prestamo.SaldoFavor) ? 0 : this.Prestamo.SaldoFavor;
                // this.Prestamo.SubTotal       = isNaN(subTotal) ? 0 : subTotal;

                let newTotalPagado = (this.Prestamo.F_TotalPagado > this.Prestamo.F_TotalDevolver)?this.Prestamo.F_TotalDevolver : this.Prestamo.F_TotalPagado;
                let newPagoCubierto = Math.trunc(newTotalPagado/this.Prestamo.F_PagoDiario);
                this.Prestamo.NumPagoCubierto =   isNaN(newPagoCubierto) ? 0 : newPagoCubierto;

                // COLOCAR EL NUMERO DE PAGO REALIZADO - TEMPORAL / FINAL
                const data2 = res.data.data2;               
                if(data2.showFooter == true) {
                    console.log('Esta en temporal');

                    let numPagoEcho = data2.ultimoPago
                    if(numPagoEcho.Estatus != 'Pendiente') {

                        if(parseFloat(numPagoEcho.MontoRealPagado) > 20){
                            this.Prestamo.NumPagoActual = numPagoEcho.NumPago;
                            console.log('Ya ha sido cobrado el pago del dia y es mayor a 0');
                        }/*else {
                            console.log('Ya ha sido marcado, pero cobro $0');
                        }*/
                        
                    }/*else {
                        console.log('Aun no se ha cobrado el pago del dia');
                    }*/
                }/*else {
                    console.log('Esta en cerrado el dia');
                }*/
                

                // SETEAR BASE PARA LIQUIDACIÓN
                this.PrestamoLiquidacion.M_TotalPagado    = isNaN(totalPagado) ? 0 : totalPagado;
                this.PrestamoLiquidacion.M_SaldoPendiente = isNaN(saldoPendiente) ? 0 : saldoPendiente;
                // this.PrestamoLiquidacion.M_SubTotal       = isNaN(subTotal) ? 0 : subTotal;
                this.PrestamoLiquidacion.M_MultaSinPagar  = '('+this.Prestamo.NumMultas+') '+this.Prestamo.TotalMultas;
                this.ListaEviEntrega                      = this.Prestamo.evidencia_entrega;

                if(this.Prestamo.cliente === null){
                    this.Prestamo.cliente = {
                        IdCliente:0,
                        NombreCompleto:"",
                        NombreNegocio:""
                    };
                }

                this.ConfigList.Title = "Detalles Préstamo de: " +this.Prestamo.cliente.NombreCompleto+' ('+this.Prestamo.cliente.NumCliente+')'

                if(this.Prestamo.creo === null){
                    this.Prestamo.creo = {
                        IdUsuario:0,
                        NombreCompleto:""
                    };
                }

                if(this.Prestamo.autorizo === null){
                    this.Prestamo.autorizo = {
                        IdUsuario:0,
                        NombreCompleto:""
                    };
                }
            
                if(this.Prestamo.entregador	=== null){
                    this.Prestamo.entregador = {
                        IdUsuario:0,
                        NombreCompleto:""
                    };
                }

                if(this.Prestamo.cobratario	=== null){
                    this.Prestamo.cobratario = {
                        IdUsuario:0,
                        NombreCompleto:""
                    };
                }

                if(this.Prestamo.rechazo	=== null){
                    this.Prestamo.rechazo = {
                        IdUsuario:0,
                        NombreCompleto:""
                    };
                }

                if(this.Prestamo.ruta	=== null){
                    this.Prestamo.ruta = {
                        IdRuta:0,
                        NombreRuta: ""
                    };
                }

                if(this.Prestamo.sucursal === null){
                    this.Prestamo.sucursal = {
                        IdSucursal:	0,
                        Nombre:	""
                    };
                }

                if(this.Prestamo.multas_count > 0) {
                    this.PrestamoLiquidacion.EsBonificado = 'NO';
                    this.deshabilitado                    = true;
                } else {
                    this.PrestamoLiquidacion.EsBonificado = 'SI';
                    this.deshabilitado                    = false;
                }

                this.varplus        = ArrayMultas.length;
                this.ArrayBase      = ArrayMultas;
                this.ArrayDinamico  = ArrayMultas;
                this.functionrecovery(this.varplus);
                this.funcioncheck();

            })
            .finally(() => {
                this.listaMultas();
                //this.ConfigLoad.ShowLoader = false;
            });
        },

        async listaMultas() {
            await this.$http.get("prestamosmultas",{
                params:{
                    IdPrestamo: this.Prestamo.IdPrestamo,
                    simple: 1,
                }
            }).then((res) => {
                this.listMultasPrestamo = res.data.data;

            }).finally(() => {
                this.listaComentarios();

                if(parseInt(this.Prestamo.entregas_canceladas_count) > 0) {
                    this.listaCancelaciones();
                }
            });
        },

        listaCancelaciones() {
            this.$http.get("listprestamoscancelaciones",{
                params:{
                    IdPrestamo: this.Prestamo.IdPrestamo,
                    Entrada:50,
                    Pag:1
                }
            }).then((res) => {
                this.listCancelacionesPrestamo = res.data.data.data;
                this.RutaEviCancelacionEntrega = res.data.urlFilesCancelacion;
            }).finally(() => {
            });
        },

        listaComentarios() {
            this.$http.get("prestamocomentarios",{
                params: {
                    IdPrestamo: this.Prestamo.IdPrestamo,
                    Entrada: 50,
                    Pag: 1,
                    SinEntrega:1
                }
            }).then((res) => {
                this.listComentariosPrestamo = res.data.data.data;
            }).finally(() => {
                this.listaLiquidacion();
            });
        },

        listaLiquidacion() {
            this.ConfigListLiquidacion.ShowLoader = true;
            this.ocultarBtnGuardar = true;
            this.$http.get("liquidacioncomentarios",{
                params:{
                    IdPrestamo: this.Prestamo.IdPrestamo
                }
            }).then((res) => {

                this.listLiquidacionPrestamo = res.data.data;
    
                this.listLiquidacionPrestamo.forEach(element => {

                    if(element.Tipo == 'Comentario'){
                        if(this.FechaActual == element.FechaComentario){
                            this.ocultarBtnEditar = true;
                            this.ocultarBtnGuardar = false;
                        } else {
                            this.ocultarBtnGuardar = true;
                        }
                    }else {
                       // this.ocultarBtnGuardar = false;
                    }
                    
                    
                });
            }).finally(() => {
                this.ConfigListLiquidacion.ShowLoader = false;
                this.ConfigLoad.ShowLoader = false;
            });
        },

        previewImagen(ruta,item) {
            window.open(ruta+item.Evidencia,'Nueva Ventana',"width=600,height=800");
        },

        colorInput(type) {
            let color  = 'color: #fff; background-color: #6c757d;';

            switch (type) {
                case 'Rechazado':
                case 'Cancelado':
                case 'Cancelada':
                    // ROJO
                    color  = 'color: #f80031; background-color: #fcacbc; font-weight:bold; border-color:#f80031;';
                break;
                case 'Pendiente':
                    // AZUL
                    color = 'color: #0096ed; background-color: rgba(0, 150, 237, 0.3); font-weight:bold; border-color:#0096ed;';
                break;
                case 'Asignado':
                case 'Cobranza':
                case 'Realizada':
                case 'Entregado':
                    // VERDE
                    color = 'color: #1aae6f; background-color: #afe2cc; font-weight:bold; border-color:#1aae6f;';
                break;
                case 'PreAutorizado':
                    // AMARILLO
                    color = 'color: #212529; background-color: #ffc107; font-weight:bold; border-color:#212529;';
                break;
                case 'Liquidado':
                    // MORADO
                    color = 'color:#9264d7; background-color: rgba(146, 100, 215, 0.3);font-weight:bold; border-color:#9264d7;';
                break;
            }

            return color

        },

        viewCancelacionEntrega(item) {
            item.pRutaImg = this.RutaEviCancelacionEntrega
            this.bus.$emit('NewModal_'+EmitEjecuta2,item);
        },

        FuncionDinamico(Tipo) {
            let contador = this.Prestamo.NumMultas;

            if(Tipo == 'MAS') {
                contador = contador+1;
            } else {
                contador = contador-1;
            }

            this.functionrecovery(contador);
        },

        functionrecovery(contador) {
            let arrayT = [];
            let multas = 0;

            if(contador >= 0 && contador <= (this.ArrayBase.length))
            {
                for(var i=0; i<contador; i++) {
                    let variable = this.ArrayBase[i];
                    let Resta    = (variable.Multa) - (variable.MontoPagado);
                    multas += isNaN(Resta) ? 0 : Resta;

                    arrayT.push(variable);
                }

                this.ArrayDinamico = arrayT;
                this.Prestamo.NumMultas = contador;
                this.Prestamo.TotalMultas = isNaN(multas) ? 0 : multas;
                this.PrestamoLiquidacion.M_MultaSinPagar = '('+this.Prestamo.NumMultas+') '+this.$formatNumber(this.Prestamo.TotalMultas,'$');

                let subTotal = ( parseFloat(this.PrestamoLiquidacion.M_SaldoPendiente) + multas);
                let bonificacion = this.Prestamo.Bonificacion;
                this.PrestamoLiquidacion.M_SubTotal = subTotal;
                let Liquidacion = subTotal;

                if (this.Prestamo.EsBonificado != 'NO') {
                    Liquidacion =  subTotal - bonificacion;
                }

                this.PrestamoLiquidacion.TotalxLiquidar = Liquidacion;
            }

            this.funcioncheck();
        },

        funcioncheck() {
            let bonificacion = this.Prestamo.Bonificacion;
            let subtotal     = this.PrestamoLiquidacion.M_SubTotal;
            let TotalSI      = (parseFloat(subtotal)) - (parseFloat(bonificacion))
            let TotalNO      = (parseFloat(subtotal))

            if(this.Prestamo.EsBonificado == 'SI') {
                this.PrestamoLiquidacion.TotalxLiquidar = isNaN(TotalSI) ? 0 :TotalSI;
                this.PrestamoModificado.Coincidencia    = isNaN(TotalSI) ? 0 :TotalSI;
            } else {
                this.PrestamoLiquidacion.TotalxLiquidar = isNaN(TotalNO) ? 0 :TotalNO;
                this.PrestamoModificado.Coincidencia    = isNaN(TotalNO) ? 0 :TotalNO;
            }
        },

        cierreAlerta() {
            this.$swal(Configs.configLiquidacionAlerta).then((result) => {
                if (result.value) {
                }
            });
        },

        limpiar() {
            this.PrestamoLiquidacion = {
                IdPrestamo:             '',
                MultaSinPagar:          '',
                MontoMulta:             '',
                SaldoPendiente:         '',
                Bonificacion:           '',
                ComentarioLiquidacion:  '',
                SubTotal:               '',
                TotalPagado:            '',
                EsBonificado:           '',
                TotalxLiquidar:         '',
            }
            this.PrestamoModificado = {
                ComentarioBitacora: '',
                Coincidencia:       '',
                NuevoMonto:         '',
            }
        },

        ModalComentarioLiquidacion(item) {
            if(item.Tipo == 'Comentario' && this.ocultarBtnEditar){
                this.oBtnSave2.ShowBtnSave = true;
            }else {
                this.oBtnSave2.ShowBtnSave = false; 
            }
            this.bus.$emit('NewModal_'+EmitEjecuta3,item);
        },

        ModalTotalaLiquidar(item) {
            this.$swal(Configs.configBitacoraLiquidacion).then((result) => {
                if (result.value) {
                    this.bus.$emit('NewModal_'+EmitEjecuta4,item);
                }
            });
        },

        ModalUbicacionEntrega(item) {
            this.bus.$emit('NewModal_'+EmitEjecuta5,item);
        },

        cambiarEvidencia(ruta,imagen) {
            let obj = {
                pImagen: imagen,
                ImgRuta: ruta
            }
            //this.ConfigModalEvidenciaEntrega.ModalTitle = 'Cambiar Evidencia: '+imagen.TipoEvidencia
            this.bus.$emit('NewModal_'+EmitEjecuta6,obj);
        },

        modalComentarioPrestamo() {
            let IdPrestamo = this.Prestamo.IdPrestamo;
            this.bus.$emit('NewModal_'+EmitEjecuta7,IdPrestamo);
        },

        Regresar() {
            switch (this.Origen) {
                case 'CLIENTES' :
                    this.$router.push({name: 'formularioclientesactivos', params: {Id: this.Prestamo.IdCliente}});
                break;

                case 'AUTORIZADOS' :
                    this.$router.push({name: 'autorizados', params: {} });
                break;

                case 'ENTREGAS' :
                    this.$router.push({name: 'porentregar', params: {} });
                break;

                case 'GENERAL' :
                    this.$router.push({name: 'formularioclientesactivos', params: {Id: this.Prestamo.IdCliente} });
                break;

                case 'ESTADOCUENTA' :
                    //this.$router.push({name: 'formularioclientesactivos', params: {Id: this.Prestamo.IdCliente} });
                    this.$router.push({name: 'listEstadoCuenta', params: {} });
                break;

                case 'DIASIGUIENTE' :
                    this.$router.push({name: 'diasiguiente', params: {} });
                break;
            }
        },

        verCliente(){
            this.$router.push({name: 'formularioclientesactivos', params: {Id:this.Prestamo.IdCliente} });
        },

    },
    created() {
        this.bus.$off("EmitReturn");
        this.bus.$off("EmitGoRoute");
        this.bus.$off("Save_");
        this.bus.$off('pVisible');
        this.bus.$off('Busqueda');
        this.bus.$off('RechargerPrestamoVisor');
        this.bus.$off('List_'+EmitEjecuta7);

        this.ConfigLoad.ShowLoader = true;

        this.ObjUsuario = JSON.parse(sessionStorage.getItem("user"));

        if(this.Id !== undefined){
            sessionStorage.setItem('IdPrestamoR',this.Id);
        }

        this.Prestamo.IdPrestamo = sessionStorage.getItem('IdPrestamoR');


        if(this.pSitio !== undefined){
            this.Origen = this.pSitio;
        }else {
            this.Origen = 'CLIENTES';
        }
    },
    mounted() {
        this.bus.$on("EmitReturn", () => {
            this.Regresar();
        });

        this.bus.$on("EmitGoRoute", () => {
            this.verCliente();
        });

        this.bus.$on("RechargerPrestamoVisor", () => {

            this.recoveryPrestamo();
        });

        this.bus.$on('Save_'+this.oBtnSave.EmitSeccion,()=> {
            this.Guardar();
        });

        this.bus.$on('pVisible',(obj)=> {
            this.disabled = obj;
        });

        this.bus.$on('List_'+EmitEjecuta7,()=> {
           this.listaComentarios();
        });

        if(this.Prestamo.IdPrestamo > 0)
        {
            this.recoveryPrestamo();
            //this.listaLiquidacion();
        }

        this.bus.$on('Busqueda',(Id)=> {
            sessionStorage.setItem('IdPrestamoR',Id);
            this.Prestamo.IdPrestamo = sessionStorage.getItem('IdPrestamoR');

            if(this.Prestamo.IdPrestamo > 0)
            {
                this.recoveryPrestamo();
            }
        });
    },
}
</script>
