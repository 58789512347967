<template>
    <div>
        <CList :pConfigList="ConfigList" >

            <template slot="accHeader">

                <form onsubmit="return false" class="form-inline float-right">
                    <div class="form-group">
                        <select id="IdSucursal" v-model="IdSucursalGlob" v-show="ShowCmbSucursales" @change="blockPrint()" class="form-control form-select mr-1" :disabled="IsLoader.BloqCmb1">
                            <option v-for="(item, index) in ListaSucursalesArray" :key="index" :value="item.IdSucursal" >
                                {{ item.Nombre }}
                            </option>
                        </select>

                        <div class="form-group mx-sm-2">
                            <v-date-picker v-model="objFiltroFechasGlob" :masks="masks" @input="blockPrint()" :popover="{ visibility: 'focus' }" :disabled-dates='{ weekdays: [1] }' locale="es" :max-date="new Date()" :disabled="IsLoader.BloqCmb1">
                                <template v-slot="{ inputValue, inputEvents }">
                                    <input class="form-control cal" placeholder="Seleccione una Fecha" :value="inputValue" v-on="inputEvents" readonly/>
                                </template>
                            </v-date-picker>
                        </div>

                        <button type="button" @click="checkComentarioDiario()" v-b-tooltip.hover.Top title="Cargar Reporte" class="btn btn-primary btn-sm mr-2">
                            <i v-show="(!IsLoader.BtnReporte.Bloq)" class="fas fa-file-chart-line"></i>
                            

                            <i v-show="(IsLoader.BtnReporte.Bloq)" :class="IsLoader.DefaultSpiner"></i>
                            {{IsLoader.BtnReporte.Icon}}
                        </button>

                        <button v-if="showImprimirPdf" type="button" v-b-tooltip.hover.Top @click="viewPdfReporte()" title="Descargar Reporte PDF" class="btn btn-danger btn-sm mr-2">
                            <i class="far fa-print"></i>    
                        </button>
                    </div>
                </form>

            </template>
                        
            <template slot="bodyForm">

                <CLoader :pConfigLoad="ConfigLoad">
                    <template slot="BodyFormLoad">

                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">
                                    Corte del Dia
                                </a>
                            </li>
                            
                        </ul>
                        <div class="tab-content shadow-sm" id="myTabContent">

                            <!--Parte Dos Prestamo -->
                            <div class="tab-pane fade active show" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div class="container-fluid">

                                    <!-- Creditos Entregado -->
                                    <fieldset>

                                        <legend class="col-form-label">&nbsp;Creditos a Entregar&nbsp;</legend>
                                        
                                        <CTablita :pConfigList="ConfigListEntregados">
                                            <template slot="header">
                                                <th>#</th>
                                                <th>Gestor</th>
                                                <th class="text-right">Monto Credito</th>
                                                <th class="text-right">Comision</th>
                                                <th class="text-right">Monto Entregado</th>
                                                <th class="text-right">Folio Nuevo</th>
                                                <th class="text-center">Tipo</th>
                                                
                                                <th class="text-right">Folio Anterior</th>
                                                <th class="text-right">Monto Anterior</th>
                                            </template>
                                            <template slot="body">
                                                <tr  v-for="(item,index) in listaPrestamoEntregados"  :key="index">
                                                    <td>{{ (index+1) }}</td>
                                                    <td><strong>{{item.NombreGestor}}</strong></td>
                                                    <td class="text-right">{{ $formatNumber(item.MontoCreditoActual,'$') }} 
                                                        <i v-if="parseFloat(item.MontoCreditoActual) > parseFloat(item.MontoCreditoAnterior)" class="fas fa-arrow-up text-success"></i>
                                                        <i v-if="parseFloat(item.MontoCreditoActual) == parseFloat(item.MontoCreditoAnterior)" class="fas fa-repeat-alt text-info"></i>
                                                    
                                                        <i v-if="parseFloat(item.MontoCreditoAnterior) > parseFloat(item.MontoCreditoActual)" class="fas fa-arrow-down text-danger"></i>
                                                    </td>
                                                    <td class="text-right">{{ $formatNumber(item.ComisionEntrega,'$') }}</td>
                                                    <td class="text-right">{{ $formatNumber(item.MontoRealEntregado,'$') }}</td>
                                                    <td class="text-right">{{item.FolioActual}}</td>
                                                    <td class="text-center">
                                                        <span v-if="item.TipoRenovacion == 'Inicial'"           class="badge badge-primary" style="width: 80px;">{{ item.TipoRenovacion }}</span>
                                                        <span v-else-if="item.TipoRenovacion == 'Consecutivo'"  class="badge badge-success" style="width: 80px;">{{ item.TipoRenovacion }}</span>
                                                        <span v-else-if="item.TipoRenovacion == 'Recuperado'"   class="badge badge-warning" style="width: 80px;">{{ item.TipoRenovacion }}</span>
                                                        <span v-else class="badge badge-secondary">{{ item.TipoRenovacion }}</span>
                                                    </td>
                                                   
                                                    <td class="text-right">{{ item.FolioAnterior }}</td>
                                                    <td class="text-right">{{ $formatNumber(item.MontoCreditoAnterior,'$') }}</td>
                                                
                                                </tr>
                                                <CSinRegistros :pContIF="listaPrestamoEntregados.length" :pColspan="9" />
                                            </template>
                                            <template slot="fotter">
                                                <tr v-if="listaPrestamoEntregados.length > 0" style="background-color: #e9ecef;">
                                                    <td></td>
                                                    <td class="text-right"><strong>Totales</strong> </td>
                                                    <td class="text-right"><strong>{{ $formatNumber(entregadosTotales.TotalMontoEntregados,'$') }}</strong></td>
                                                    <td class="text-right"><strong>{{ $formatNumber(entregadosTotales.TotalComisiones,'$') }}</strong></td>
                                                    <td class="text-right"><strong>{{ $formatNumber(entregadosTotales.TotalRealEntregado,'$') }}</strong></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="text-right"><strong>{{ $formatNumber(entregadosTotales.MontoEntregaAnterior,'$') }}</strong></td>
                                                </tr>
                                            </template>
                                        </CTablita>
                                    </fieldset>

                                    <!-- Clientes, Porcentajes y Cobranza -->
                                    <fieldset class="mt-4">

                                        <legend class="col-form-label">&nbsp;Clientes, Porcentajes y Cobranza&nbsp;</legend>

                                        <CTablita :pConfigList="ConfigListProductividad">
                                            <template slot="header">
                                                <th>#</th>
                                                <th>Ruta</th>
                                                <th>Gestor</th>
                                                <th>Estatus Dia</th>
                                                <th class="text-right">No. Creditos</th>
                                                <th class="text-right">% Productividad</th>
                                                <th class="text-right">Total Cobranza</th>
                                            </template>

                                            <template slot="body">
                                                <tr  v-for="(item,index) in listaProductividadGeneral"  :key="index">
                                                    <td>{{ (index+1) }}</td>
                                                    <td><strong>{{item.NombreRuta}}</strong></td>
                                                    <td><strong>{{item.NombreCompleto}}</strong></td>
                                                    <td>
                                                            <span v-if="item.EstatusDia == ''" class="badge badge-secondary">No Generado</span>
                                                            <span v-else-if="item.EstatusDia == 'Pendiente'" class="badge badge-primary">Pendiente</span>
                                                            <span v-else-if="item.EstatusDia == 'EnCurso'" class="badge badge-success">En Cobranza</span>
                                                            <span v-else-if="item.EstatusDia == 'EnCierre'" class="badge badge-dark">En Precorte</span>
                                                            <span v-else-if="item.EstatusDia == 'EnCorte'" class="badge badge-warning">En Corte</span>
                                                            <span v-else-if="item.EstatusDia == 'AlDia'" class="badge badge-danger">Cerrado</span>
                                                        </td>
                                                    <td class="text-right">{{item.TotalElementos}}</td>
                                                    <td class="text-right">{{ item.PorcentProductividad+'%' }}</td>
                                                    <td class="text-right">{{ $formatNumber(item.MontoRealCobrado,'$') }}</td>
                                                
                                                </tr>
                                                <CSinRegistros :pContIF="listaProductividadGeneral.length" :pColspan="7" />
                                            </template>

                                            <template slot="fotter">
                                                <tr v-if="listaProductividadGeneral.length > 0" style="background-color: #e9ecef;">
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="text-right"><strong>Totales</strong> </td>
           

                                                    <td class="text-right"><strong>{{ prodGeneralTotales.NumTotalPagosDia }}</strong></td>
                                                    <td class="text-right"><strong>{{ prodGeneralTotales.PromedioProductividad+'%' }}</strong></td>
                                                
                                               
                                                    
                                                    <td class="text-right"><strong>{{ $formatNumber(prodGeneralTotales.MontoGlobalRealCobrado,'$') }}</strong></td>

                                                
                                                </tr>
                                            </template>

                                        </CTablita>
                                    </fieldset>

                                    <!-- Consulta de Liquidaciones || Creditos Liquidados -->
                                    <div class="row">
                                        <div class="col-6">
                                            <fieldset class="mt-4">
                                                <legend class="col-form-label">&nbsp;Consulta de Liquidaciones&nbsp;</legend>

                                                <CTablita :pConfigList="ConfigListConsLiquidacion">
                                                    <template slot="header">
                                                        <th>#</th>
                                                        <th>Folio</th>
                                                        <th class="text-right">Cantidad</th>
                                                        <th>Gestor</th>
                                                    </template>

                                                    <template slot="body">
                                                        <tr  v-for="(item,index) in listConsultaLiquidacion"  :key="index">
                                                            <td>{{ (index+1) }}</td>
                                                            <td><strong>{{item.Folio}}</strong></td>
                                                            <td class="text-right">{{ $formatNumber(item.TotalxLiquidar,'$') }}</td>
                                                            <td><span v-if="item.cobratario">{{item.cobratario.NombreCompleto}}</span></td>
                                                        
                                                        </tr>
                                                        <CSinRegistros :pContIF="listConsultaLiquidacion.length" :pColspan="9" />
                                                    </template>

                                                    <template slot="fotter">
                                                        <tr v-if="listConsultaLiquidacion.length > 0" style="background-color: #e9ecef;">
                                                            <td></td>
                                                            <td class="text-right"><strong>Totales</strong> </td>
    
                                                            <td class="text-right"><strong>{{ $formatNumber(ConsultaLiquidacionTotales.TotalCosultas,'$') }}</strong></td>
                                                            <td></td>
                                                        
                                                        </tr>
                                                    </template>

                                                </CTablita>
                                    
                                            </fieldset>
                                        </div>
                                        <div class="col-6">
                                            <fieldset class="mt-4">
                                                <legend class="col-form-label">&nbsp;Creditos Liquidados&nbsp;</legend>

                                                <CTablita :pConfigList="ConfigListCreditosLiquidados">
                                                    <template slot="header">
                                                        <th>#</th>
                                                        <th>Gestor</th>
                                                        <th>Folio</th>
                                                        <th class="text-right">Pago</th>
                                                        <th class="text-right">Mont. Credito</th>
                                                        <th class="text-right">Bono</th>
                                                    </template>

                                                    <template slot="body">
                                                        <tr  v-for="(item,index) in listCreditosLiquidados"  :key="index">
                                                            <td>{{ (index+1) }}</td>
                                                            <td><strong v-if="item.cobratario">{{item.cobratario.NombreCompleto}}</strong></td>
                                                            <td>{{item.Folio}}</td>
                                                            <td class="text-right">{{ $formatNumber(item.TotalxLiquidar,'$') }}</td>
                                                            <td class="text-right">{{ $formatNumber(item.MontoEntregado,'$') }}</td>
                                                            <td class="text-right">{{ $formatNumber(item.BonoPago,'$') }}</td>
                                                        
                                                        </tr>
                                                        <CSinRegistros :pContIF="listCreditosLiquidados.length" :pColspan="9" />
                                                    </template>

                                                    <template slot="fotter">
                                                        <tr v-if="listCreditosLiquidados.length > 0" style="background-color: #e9ecef;">
                                                            <td></td>
                                                            <td></td>
                                                            <td class="text-right"><strong>Totales</strong> </td>
                

                                                            <td class="text-right"><strong>{{ $formatNumber(CreditosLiquidadosTotales.TotalPagos,'$') }}</strong></td>
                                                            <td></td>
                                                            <td class="text-right"><strong>{{ $formatNumber(CreditosLiquidadosTotales.TotalBonos,'$') }}</strong></td>

                                                        
                                                        </tr>
                                                    </template>

                                                </CTablita>
                                    
                                            </fieldset>
                                        </div>
                                    </div>
                                        
                                    <fieldset>
                                        <legend class="col-form-label">&nbsp;Observaciones del dia&nbsp;</legend>
                                        <div class="form-group row">
                                            <div v-if="ComentarioDia.redactor != null" class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                <label for="IdCreador">Redacto</label>
                                                <input v-model="ComentarioDia.redactor.NombreCompleto" type="text"  class="form-control" id="IdCreador" placeholder="Sin Usuario" disabled="true"/>
                                              
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <label for="Observaciones">Observaciones</label>
                                                <textarea v-model="ComentarioDia.Observaciones" class="form-control" id="Observaciones" placeholder="Sin Observaciones" rows="3" disabled="true"></textarea>
                                          
                                            </div><!--fin col-12-->
                                        </div>
                                    </fieldset>


                                </div>
                            </div>

                        </div>
                    </template>
                </CLoader>

            </template>
        </CList>

        <CModal :pConfigModal="ConfigModal" :poBtnSave="oBtnSaveModal">
			<template slot="Form">
				<FormComentarioReporte :poBtnSave="oBtnSaveModal"/>
			</template>
		</CModal>


    
    </div>
</template>


<script>
import moment from "moment";
import CSinRegistros from "../../../components/CSinRegistros";
import CLoader       from "../../../components/CLoader";
import CList         from "../../../components/CList";
import CTablita         from "../../../components/CTablita";
import FormComentarioReporte from "./FormComentarioReporte.vue";

const EmitEjecuta    = "seccionReporteCorteDia";
const EmitComentario    = "seccionComentarioReporte";

export default {
    name:  "ListaInicio",
    props: [],
    components: {
        CSinRegistros,
        CLoader,
        CList,
        CTablita,
        FormComentarioReporte
       
    },
    data() {
        return {
            ListaSucursalesArray:[],
            IdSucursalGlob:0,
            ShowCmbSucursales: false,

            masks: {
                input: "YYYY-MM-DD",
            },
            objFiltroFechasGlob: {},
            FiltroFechaGlob: '',
            
            listEntregados: [],
            totalesEntregados: {},
            listLiquidados: [],
            listConsultaLiquidacion: [],
            listProductividad: [],

            ConfigLoad:{
                ShowLoader: false,
                ClassLoad:  true,
            },
            showImprimirPdf: false,
            IsLoader:{
				BtnReporte:{
					Bloq: false,
					Icon: 'Cargar Reporte',
					Orig: 'Cargar Reporte',
				},
				DefaultSpiner: 'fa fa-spinner fa-pulse fa-1x fa-fw',
				DefaultText: ' Espere...',
				BloqCmb1: true,
				
			},

            ConfigList: {
                ShowTitleFirst:    true,
                TitleFirst:        "Menu Reportes",
                Title:             "Reporte Corte del Dia",
                ShowLoader:        true,
                IsModal:           false,
                BtnNewShow:        false,
                BtnReturnShow:     false,
                TypeBody:          "Form",
                ShowFiltros:       false,
                ShowFiltrosInline: true,
                ShowTitleInline:   false,
                ShowPaginador:     false,
            },
            oBtnSave: {
                toast:         0,
                IsModal:       false,
                ShowBtnSave:   true,
                ShowBtnCancel: false,
                DisableBtn:    false,
                EmitSeccion:   EmitEjecuta,
            },

            listaPrestamoEntregados:[],
            entregadosTotales: {
                MontoEntregaAnterior: 0,
                TotalComisiones: 0,
                TotalMontoEntregados: 0,
                TotalRealEntregado: 0
            },
            ConfigListEntregados: {
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecuta,
                GoRoute: 'formdetalleprestamo'
            },

            listaProductividadGeneral:[],
            prodGeneralTotales:{
                MontoGlobalRealCobrado: 0,
                MontoGlobalProductividad: 0,
                NumTotalPagosDia: 0,
                PromedioProductividad: 0,
            },
           
            ConfigListProductividad: {
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecuta,
                GoRoute: 'formdetalleprestamo'
            },

            listConsultaLiquidacion:[],
            ConsultaLiquidacionTotales:{
                TotalCosultas:0
            },
            ConfigListConsLiquidacion:{
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecuta,
                GoRoute: 'formdetalleprestamo'
            },

            listCreditosLiquidados:[],
            CreditosLiquidadosTotales:{
                TotalPagos: 0,
                TotalBonos: 0
            },
            ConfigListCreditosLiquidados:{
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecuta,
                GoRoute: 'formdetalleprestamo' 
            },

            ComentarioDia:{
                Observaciones:'',
                redactor:{
                    NombreCompleto:''
                }
            },

            ConfigModal:{
                ModalTitle:  "Observaciones del dia",
                ModalNameId: 'ModalForm',
                EmitSeccion:  EmitComentario,
                ModalSize:   'md',
            },
            oBtnSaveModal: {
                toast: 0,
                IsModal: true,
                DisableBtn: false,
                EmitSeccion: EmitComentario,
            },
            
        }
    },
    methods: {

        ListaSucursales() {
            this.$http.get("sucursales", {
                params: {
                    simple: 1
                },
            })
                .then((res) => {
                    let response = res.data.dataSuc;
                    this.ListaSucursalesArray = res.data.data;

                    if(response.EsAdmin == 0)
                    {
                        this.IdSucursalGlob = response.IdSucursal;
                    }
                    else
                    {
                        this.ShowCmbSucursales = true;

                        if(this.ListaSucursalesArray.length>0)
                        {
                            this.IdSucursalGlob = this.ListaSucursalesArray[0].IdSucursal;
                        }
                    }
                }).finally(()=>{
                    this.IsLoader.BloqCmb1 = false;
            });
        },

        blockPrint(){
            this.showImprimirPdf = false;
        },

        async getInforme() {
            this.ConfigLoad.ShowLoader = true;
            this.IsLoader.BtnReporte.Bloq = true;
            this.IsLoader.BtnReporte.Icon = this.IsLoader.DefaultText;
            this.IsLoader.BloqCmb1 = true;
            this.showImprimirPdf = false;
            
           
            this.FiltroFechaGlob = moment(this.objFiltroFechasGlob).format('YYYY-MM-DD');
            await this.$http.get("rptresumendia", {
				params: {
					IdSucursal: this.IdSucursalGlob,
                    Fecha: this.FiltroFechaGlob
				},
			})
			.then((res) => {
                console.log(res.data.data);

                // ENTREGADOS
                this.listaPrestamoEntregados        = res.data.data.CreditosEntregar;
                this.entregadosTotales              = res.data.data.CreditosEntregarTotales;
                
                // PRODUCTIVIDAD
                this.listaProductividadGeneral      = res.data.data.Productividad.productividadRuta;
                this.prodGeneralTotales.MontoGlobalRealCobrado      = res.data.data.Productividad.MontoGlobalRealCobrado;
                this.prodGeneralTotales.MontoGlobalProductividad    = res.data.data.Productividad.MontoGlobalProductividad;
                this.prodGeneralTotales.NumTotalPagosDia            = res.data.data.Productividad.NumTotalPagosDia;
                this.prodGeneralTotales.PromedioProductividad       = res.data.data.Productividad.PromedioProductividad;

                //CONSULTA LIQUIDACION
                this.listConsultaLiquidacion    = res.data.data.ConsultaLiquidacion;
                this.listCreditosLiquidados     = res.data.data.PrestamosLiquidados;
                this.ComentarioDia              = res.data.data.Observaciones;

                let totalPago = 0;
                let totalBono = 0;

                this.listCreditosLiquidados.forEach(element => {
                    totalPago = parseFloat(totalPago) + parseFloat(element.TotalxLiquidar);
                    totalBono = parseFloat(totalBono) + parseFloat(element.BonoPago);
                });

                this.CreditosLiquidadosTotales.TotalBonos = totalBono;
                this.CreditosLiquidadosTotales.TotalPagos = totalPago;

                let totalConsultas = 0;
                this.listConsultaLiquidacion.forEach(element => {
                    totalConsultas = parseFloat(totalConsultas) + parseFloat(element.TotalxLiquidar);
                });
                this.ConsultaLiquidacionTotales.TotalCosultas = totalConsultas;


                this.showImprimirPdf    = true;

                

            }).finally(() => {
                this.ConfigLoad.ShowLoader = false;
                this.IsLoader.BtnReporte.Bloq = false;
				this.IsLoader.BtnReporte.Icon = this.IsLoader.BtnReporte.Orig;
                this.IsLoader.BloqCmb1 = false;
            });
        },

        async checkComentarioDiario() {
            console.log(this.objFiltroFechasGlob);
            this.limpiar();
            this.ConfigLoad.ShowLoader = true;
            this.IsLoader.BtnReporte.Bloq = true;
            this.IsLoader.BtnReporte.Icon = this.IsLoader.DefaultText;
            this.IsLoader.BloqCmb1 = true;
            this.showImprimirPdf = false;

            this.FiltroFechaGlob = moment(this.objFiltroFechasGlob).format('YYYY-MM-DD');

            await this.$http.get("rptdiariocomencheck", {
                params: {
                    FechaReporte: this.FiltroFechaGlob
                },
            })
                .then((res) => {
                    let response = res.data.Ecode;
                    if(res.data.status && (res.data.Ecode == 1001 || res.data.Ecode == 1002) ) {
                        this.getInforme();

                    }else if( !res.data.status &&  res.data.Ecode == 1003) {
                        this.$toast.info('Se requiere de colocar observaciones del dia para emitir el reporte.');
                        this.bus.$emit('NewModal_'+EmitComentario,this.FiltroFechaGlob);
                        this.ConfigLoad.ShowLoader = false;
                        this.IsLoader.BtnReporte.Bloq = false;
                        this.IsLoader.BtnReporte.Icon = this.IsLoader.BtnReporte.Orig;
                    }
                    
                }).finally(()=>{
                    
                    this.IsLoader.BloqCmb1 = false;
            });
        },

        viewPdfReporte(){
            let newRequest = {
                CreditosEntregar: this.listaPrestamoEntregados,
				CreditosEntregarTotales: this.entregadosTotales,
				PrestamosLiquidados: this.listCreditosLiquidados ,
				ConsultaLiquidacion: this.listConsultaLiquidacion,
				Productividad: this.listaProductividadGeneral,
                ProductividadTotales: this.prodGeneralTotales,
                IdSucursal: this.IdSucursalGlob,
                Fecha: this.FiltroFechaGlob,
                Observaciones: this.ComentarioDia
            }

            this.$http.post('rptpdfcortediario',newRequest).then((res)=>{     
                
                let pdfWindow = window.open("");
                pdfWindow.document.write(
                    "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                    encodeURI(res.data.pdf) + "'></iframe>"
                );
                
            }).catch(err=>{   
               console.log(err);
            });

          
        },

        limpiar() {
            this.FiltroFechaGlob='';
            // ENTREGADOS
            this.listaPrestamoEntregados        = [];
            this.entregadosTotales              =  {
                MontoEntregaAnterior: 0,
                TotalComisiones: 0,
                TotalMontoEntregados: 0,
                TotalRealEntregado: 0
            };
                
            // PRODUCTIVIDAD
            this.listaProductividadGeneral      = [];
            this.prodGeneralTotales = {
                MontoGlobalRealCobrado: 0,
                MontoGlobalProductividad: 0,
                NumTotalPagosDia: 0,
                PromedioProductividad: 0,
            };

            //CONSULTA LIQUIDACION
            this.listConsultaLiquidacion    = [];
            this.listCreditosLiquidados    = [];
        },

        Regresar(){
            this.$router.push({name:"menureportes"});
        }
    },
    created() {
        this.bus.$off('EmitReturn');
        this.bus.$off('getReporteDiario');
        this.ListaSucursales();

    },
    mounted(){
        this.bus.$on('getReporteDiario', () => {
            this.getInforme();
		});
        this.bus.$on('EmitReturn',()=> {
			this.Regresar();
		});
    }

};

</script>