<template>
    <div>
        <CList :pConfigList="ConfigList" >
            <template slot="bodyForm">
                <input type="hidden" :value="setLocation">

                <CLoader :pConfigLoad="ConfigLoad">
                    <template slot="BodyFormLoad">
                        
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">
                                    General
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab2-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">
                                    Préstamos
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab3-tab" data-toggle="tab" href="#tab3" role="tab" aria-controls="tab3" aria-selected="false">
                                    Evidencias
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab4-tab" data-toggle="tab" href="#tab4" role="tab" aria-controls="tab4" aria-selected="false">
                                    Comentarios
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content shadow-sm" id="myTabContent">
                            <!-- Parate Uno General -->
                            <div class="tab-pane fade active show" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-12 mt-3">
                                            <fieldset>
                                                <legend class="col-form-label">&nbsp;Datos Personales&nbsp;</legend>
                                                <div class="form-row mt-4">
                                                    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
                                                        <div class="avatar-upload">
                                                            <div class="avatar-edit">
                                                                <input id="file" @change="$uploadImagePreview($event,ValidElement, Array('Img','imagePreview'))" ref="file" type="file" name="file" accept=".png, .jpg, .jpeg">
                                                                <label for="file"></label>
                                                            </div>
                                                            <div class="avatar-edit2" v-if="this.objCliente.UrlImg !== 'picture.png'">
                                                                <button type="button" @click="previewCliente(RutaFile,objCliente.UrlImg)">
                                                                    <i class="fas fa-search"></i>
                                                                </button>
                                                            </div>
                                                            <div class="avatar-preview">
                                                                <div id="imagePreview" :style="'background-image: url('+RutaFile+objCliente.UrlImg+');'" :src="RutaFile+objCliente.UrlImg">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
                                                        <!-- <legend class="col-form-label">Datos personales</legend> -->
                                                        <div class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="Nombre">Nombre <span class="text-danger">*</span> </label>
                                                                <b-form-input id="Nombre" v-model="objCliente.Nombre" type="text" placeholder="Ingrese Nombre" ></b-form-input>
                                                                <CValidation v-if="this.errorvalidacion.Nombre" :Mensaje="'*' + errorvalidacion.Nombre[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="ApellidoPat">Apellido Paterno <span class="text-danger">*</span></label>
                                                                <b-form-input id="ApellidoPat" v-model="objCliente.ApellidoPat" type="text" placeholder="Ingrese Apellido Paterno" ></b-form-input>
                                                                <CValidation v-if="this.errorvalidacion.ApellidoPat" :Mensaje="'*' + errorvalidacion.ApellidoPat[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                                <label for="ApellidoMat">Apellido Materno <span class="text-danger">*</span></label>
                                                                <b-form-input id="ApellidoMat" v-model="objCliente.ApellidoMat" type="text" placeholder="Ingrese Apellido Materno" ></b-form-input>
                                                                <CValidation v-if="this.errorvalidacion.ApellidoMat" :Mensaje="'*' + errorvalidacion.ApellidoMat[0]"/>
                                                            </div>
                                                        </div>

                                                        <div class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="FechaNacimiento">Fecha de Nacimiento <span class="text-danger">*</span></label>
                                                                <v-date-picker :masks="masks" :popover="{ visibility: 'focus' }" locale="mx" v-model="objCliente.FechaNacimiento">
                                                                    <template v-slot="{ inputValue, inputEvents }">
                                                                        <input class="form-control cal" placeholder="0000-00-00" :value="inputValue" v-on="inputEvents" readonly/>
                                                                    </template>
                                                                </v-date-picker>
                                                                <CValidation v-if="this.errorvalidacion.FechaNacimiento" :Mensaje="'*' + errorvalidacion.FechaNacimiento[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="Telefono">Teléfono <span class="text-danger">*</span></label>
                                                                <input
                                                                    id="Telefono"
                                                                    v-model="objCliente.Telefono"
                                                                    type="text"
                                                                    placeholder="Ingrese Teléfono"
                                                                    @input="$onlyNums($event,objCliente,'Telefono');"
                                                                    class="form-control"/>
                                                                <CValidation v-if="this.errorvalidacion.Telefono" :Mensaje="'*' + errorvalidacion.Telefono[0]"/>
                                                            </div>

                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="Correo">Correo Electronico</label>
                                                                <b-form-input id="Correo" v-model="objCliente.Correo" type="email" placeholder="Ingrese Correo " ></b-form-input>
                                                                <CValidation v-if="this.errorvalidacion.Correo" :Mensaje="'*' + errorvalidacion.Correo[0]"/>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="Curp">CURP <span class="text-danger">*</span></label>
                                                                <input type="text" v-model="objCliente.Curp" placeholder="Ingresa el Curp" class="form-control">
                                                                <CValidation v-if="this.errorvalidacion.Curp" :Mensaje="'*' + errorvalidacion.Curp[0]"/>
                                                            </div>
                                                        </div>
                                                        <div class="form-group form-row">
                                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                                                <label for="NumCliente">Numero Cliente</label>
                                                                <input class="form-control" id="Correo" v-model="objCliente.NumCliente" type="text" placeholder="" disabled/>
                                                                <CValidation v-if="this.errorvalidacion.NumCliente" :Mensaje="'*' + errorvalidacion.NumCliente[0]"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                                    </div>
                                                </div>
                                            </fieldset>

											<fieldset class="mt-2">
                                                <legend class="col-form-label">&nbsp;Negocio&nbsp;</legend>
                                                <div class="form-group form-row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                                        <label for="NombreNegocio">Nombre del Negocio <span class="text-danger">*</span></label>
                                                        <input  v-model="objCliente.NombreNegocio" type="text" placeholder="Ingrese Nombre del Negocio" class="form-control" />
                                                        <CValidation v-if="this.errorvalidacion.NombreNegocio" :Mensaje="'*' + errorvalidacion.NombreNegocio[0]"/>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                        <label for="TipoNegocio">Tipo de Negocio <span class="text-danger">*</span></label>
                                                        <treeselect v-model="objCliente.TipoNegocio" :options="Negocio" :multiple="false" :show-count="true" :value-consists-of="'ALL_WITH_INDETERMINATE'" placeholder="Seleccione un Tipo de Negocio"/>
                                                        <CValidation v-if="this.errorvalidacion.TipoNegocio" :Mensaje="'*' + errorvalidacion.TipoNegocio[0]"/>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3" v-if="objCliente.TipoNegocio == 1">
                                                        <label for="NuevoNegocio">Nuevo Negocio</label>
                                                        <input v-model="objCliente.Negocio" type="text" placeholder="Ingrese Nuevo Negocio" class="form-control" />
                                                        <CValidation v-if="this.errorvalidacion.Negocio" :Mensaje="'*' + errorvalidacion.Negocio[0]"/>
                                                    </div>
                                                </div>


                                                <div class="form-group form-row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <label for="DescripcionNegocio">Descripción del Negocio <span class="text-danger">*</span></label>
                                                        <b-form-textarea id="DescripcionNegocio" v-model="objCliente.DescripcionNegocio" placeholder="Ingrese Descripción del Negocio" ></b-form-textarea>
                                                        <CValidation v-if="this.errorvalidacion.DescripcionNegocio" :Mensaje="'*' + errorvalidacion.DescripcionNegocio[0]"/>
                                                    </div>
                                                </div>

                                            </fieldset>

                                            <fieldset class="mt-2">
                                                <legend class="col-form-label">&nbsp;Domicilio&nbsp;</legend>
                                                <div class="form-group form-row">
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                        <label for="Calle">Calle <span class="text-danger">*</span></label>
                                                        <b-form-input id="Calle" v-model="objCliente.Calle" type="text" placeholder="Ingrese Calle" ></b-form-input>
                                                        <CValidation v-if="this.errorvalidacion.Calle" :Mensaje="'*' + errorvalidacion.Calle[0]"/>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                                        <label for="NoExt">Número Exterior <span class="text-danger">*</span></label>
                                                        <b-form-input id="NoExt" v-model="objCliente.NoExt" type="text" placeholder="Ingrese Número Exterior" ></b-form-input>
                                                        <CValidation v-if="this.errorvalidacion.NoExt" :Mensaje="'*' + errorvalidacion.NoExt[0]"/>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                                        <label for="NoInt">Número Interior</label>
                                                        <b-form-input id="NoInt" v-model="objCliente.NoInt" type="text" placeholder="Ingrese Número Interior" ></b-form-input>
                                                        <CValidation v-if="this.errorvalidacion.NoInt" :Mensaje="'*' + errorvalidacion.NoInt[0]"/>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                                        <label for="Cp">Código Postal <span class="text-danger">*</span></label>
                                                        <input id="Cp" v-model="objCliente.Cp" type="text" placeholder="Ingrese Código Postal" @input="$onlyNums($event,objCliente,'Cp');" class="form-control" />
                                                        <CValidation v-if="this.errorvalidacion.Cp" :Mensaje="'*' + errorvalidacion.Cp[0]"/>
                                                    </div>
                                                </div>

                                                <div class="form-group form-row">
                                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                        <label for="Cruzamiento1">Cruzamiento 1 <span class="text-danger">*</span></label>
                                                        <b-form-input id="Cruzamiento1" v-model="objCliente.Cruzamiento1" placeholder="Ingrese Cruzamiento1"> </b-form-input>
                                                        <CValidation v-if="this.errorvalidacion.Cruzamiento1" :Mensaje="'*' + errorvalidacion.Cruzamiento1[0]"/>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                        <label for="Cruzamiento2">Cruzamiento 2 <span class="text-danger">*</span></label>
                                                        <b-form-input id="Cruzamiento2" v-model="objCliente.Cruzamiento2" placeholder="Ingrese Cruzamiento2"> </b-form-input>
                                                        <CValidation v-if="this.errorvalidacion.Cruzamiento2" :Mensaje="'*' + errorvalidacion.Cruzamiento2[0]"/>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                        <label for="Colonia">Colonia <span class="text-danger">*</span></label>
                                                        <b-form-input id="Colonia" v-model="objCliente.Colonia" type="text" placeholder="Ingrese Colonia" ></b-form-input>
                                                        <CValidation v-if="this.errorvalidacion.Colonia" :Mensaje="'*' + errorvalidacion.Colonia[0]"/>
                                                    </div>
                                                </div>

                                                <div class="form-group form-row">
                                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                        <label for="Estado">Estado <span class="text-danger">*</span></label>
                                                        <select id="Estado" class="form-control form-select" v-model="objCliente.IdEstado" @change="ListaMunicipios()">
                                                            <option :value="0">--Seleccionar--</option>
                                                            <option v-for="(item, index) in ListaEstadosArray" :key="index" :value="item.IdEstado">
                                                                {{ item.Nombre }}
                                                            </option>
                                                        </select>
                                                        <CValidation v-if="this.errorvalidacion.IdEstado" :Mensaje="'*' + errorvalidacion.IdEstado[0]"/>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                        <label for="Municipio">Municipio <span class="text-danger">*</span></label>
                                                        <select v-model="objCliente.IdMunicipio" id="Municipio" class="form-control form-select">
                                                            <option :value="0">--Seleccionar--</option>
                                                            <option v-for="(item, index) in ListaMunicipiosArray" :key="index" :value="item.IdMunicipio" >
                                                                {{ item.Nombre }}
                                                            </option>
                                                        </select>
                                                        <CValidation v-if="this.errorvalidacion.IdMunicipio" :Mensaje="'*' + errorvalidacion.IdMunicipio[0]"/>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                                                        <label for="Referencias">Referencias</label>
                                                        <b-form-textarea id="Referencias" v-model="objCliente.Referencias" placeholder="Ingrese Referencias" ></b-form-textarea>
                                                        <CValidation v-if="this.errorvalidacion.Referencias" :Mensaje="'*' + errorvalidacion.Referencias[0]"/>
                                                    </div>
                                                </div>
                                            </fieldset>

                                            <fieldset class="mt-2">
                                                <legend class="col-form-label">&nbsp;Ubicación&nbsp;</legend>

                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <CMapa v-if="this.isReady" :pConfigMapa="ConfigMapa" :oLatLng="coordenadas" />
                                                    </div>
                                                </div>

                                            </fieldset>

                                            <!--GESTOR ASIGNADO-->
                                            <fieldset  class="mt-2">
                                                <legend class="col-form-label">&nbsp;Datos de Cobranza&nbsp;</legend>
                                                <div class="form-group form-row">
                                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                        <label>Sucursal</label>
                                                        <b-form-input readonly v-model="Sucursales" type="text" ></b-form-input>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                        <label>Ruta</label>
                                                        <b-form-input readonly v-model="NombreRuta" type="text" ></b-form-input>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                        <label>Cobratario</label>
                                                        <b-form-input readonly v-model="NombreCompleto" type="text" ></b-form-input>
                                                    </div>

                                                </div>
                                                <!-- <label >Ruta</label><label for="">{{NombreRuta}}</label> -->
                                                <!-- <input type="text" name="" v-model="NombreRuta" id=""> -->

                                            </fieldset>
                                        </div>
                                    </div>
                                    <CBtnSave :poBtnSave="oBtnSave" />

                                </div>
                            </div>

                            <!--Parte Dos Prestamo -->
                            <div class="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                                <div class="container-fluid">
                                    <fieldset>

                                        <legend class="col-form-label">&nbsp;Préstamos&nbsp;</legend>
                                        <div class="row">
                                            <div class="col-md-12 jutify-content-end">
                                                <button v-if="getNuevoPrestamo" type="button" @click="openPrestamo()" class="btn btn-primary btn-sm float-right mt-1 mb-3">
                                                    <i class="fas fa-plus-circle"></i> Nuevo Prestamo
                                                </button>
                                            </div>
                                        </div>
                                        <CTablita :pConfigList="ConfigList3">
                                            <template slot="header">
                                                <th>Folio</th>
                                                <th>Estatus</th>
                                                <th class="text-center">Fecha de Entrega</th>
                                                <th class="text-center">F. Inicio Cobro</th>
                                                <th class="text-right">Monto Entregado</th>
                                                <th class="text-right">Pago Diario</th>
                                                <th class="text-center">Acciones</th>
                                            </template>
                                            <template slot="body">
                                                <tr  v-for="(item,index) in ListaPrestamoCliente"  :key="index">
                                                    <td><strong>{{item.Folio}}</strong></td>
                                                    <td>
                                                        <b-badge :class="['badge', (
                                                                item.Estatus == 'Entregado'     ? 'badge-success':
                                                                item.Estatus == 'Cobranza'      ? 'badge-success':
                                                                item.Estatus == 'Asignado'      ? 'badge-success':
                                                                item.Estatus == 'Rechazado'     ? 'badge-danger':
                                                                item.Estatus == 'Pendiente'     ? 'badge-primary':
                                                                item.Estatus == 'PreAutorizado' ? 'badge-warning':
                                                                item.Estatus == 'Cancelado'     ? 'badge-danger':
                                                                item.Estatus == 'Liquidado'     ? 'badge-purple':
                                                                'badge-secondary'
                                                            )]">
                                                            {{ item.Estatus }}
                                                        </b-badge>
                                                    </td>

                                                    <td class="text-center">{{ $getCleanDate(item.FechaEntrega) }}</td>
                                                    <td class="text-center">{{ item.FechaInicioCobro }}</td>

                                                    <td class="text-right" v-if="item.Estatus == 'Pendiente'">{{ $formatNumber(item.MontoSolicitud,'$') }}</td>
                                                    <td class="text-right" v-else>{{ $formatNumber(item.MontoEntregado,'$') }}</td>

                                                    <td class="text-right">{{ $formatNumber(item.MontoDiario,'$') }}</td>

                                                    <td class="text-center">
                                                        <CBtnAccion :pGoRoute="ConfigList3.GoRoute" :pShowBtnEdit="true" :pShowBtnDelete="true" :pIsModal="false" :pId="item.IdPrestamo" :pEmitSeccion="ConfigList3.EmitSeccion" :segurity="segurity">
                                                            <template slot="btnaccion">

                                                                <button v-if="parseInt(item.comentarios_count) > 0" type="button" v-b-tooltip.hover.Top title="Ver comentarios" @click="viewsComentarios(item)" class="btn btn-info btn-icon position-relative ml-1">
                                                                    <i class="fas fa-comment-dots"></i>
                                                                    <span class="text-alert d-none d-lg-block">
                                                                        <span class="numero-compra-02">{{parseInt(item.comentarios_count)}}</span>
                                                                    </span>
                                                                </button>

                                                            </template>
                                                        </CBtnAccion>
                                                    </td>

                                                </tr>
                                                <CSinRegistros :pContIF="ListaPrestamoCliente.length" :pColspan="7" />
                                            </template>
                                        </CTablita>
                                    </fieldset>
                                </div>
                            </div>

                            <!--Parte Tres Evidencia -->
                            <div class="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
                                <div class="container-fluid">
                                    <!-- EVIDENCIA SOLICITUD PRESTAMO -->
                                    <fieldset>
                                        <legend class="col-form-label">&nbsp;Solicitud del Prestamo &nbsp;</legend>
                                        <div class="form-group form-row">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center" v-if="ListaEviSolicitud == null || ListaEviSolicitud == ''">
                                                <div class="box-imagen">
                                                    <h1 class="col-form-h1 pt-1">&nbsp;Sin Evidencia en Solicitud del Prestamo &nbsp;</h1>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2" v-for="(item,index) in ListaEviSolicitud" :key="index" v-else>
                                                <div class="box-imagen" >
                                                    <div class="avatar-upload image-button">
                                                        <label style="text-align: left;">
                                                            <template v-if="item.TipoEvidencia == 'Ine'">
                                                                INE
                                                            </template>
                                                            <template v-else-if="item.TipoEvidencia == 'Domicilio'">
                                                                Comprobante Domicilio
                                                            </template>
                                                            <template v-if="item.TipoEvidencia == 'Empresa'">
                                                                Establecimiento
                                                            </template>
                                                        </label>
                                                        <div class="avatar-preview">
                                                            <div :id="'imagePreviewIne_'+index" :style="'background-image: url('+RutaEviSol+item.Evidencia+');'" :src="RutaEviSol+item.Evidencia"></div>
                                                        </div>

                                                        <div class="icons-button-2">
                                                            <button type="button" @click="previewImagen(RutaEviSol,item)">
                                                                <i class="fas fa-search"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </fieldset>
                                </div>
                            </div>

                            <!--Parte Cuatro Comentarios -->
                            <div class="tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="tab4-tab">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-12 mt-3">

                                            <fieldset>
                                                <legend class="col-form-label">&nbsp;Comentarios&nbsp;</legend>
                                                <div class="row">
                                                    <div class="col-md-12 jutify-content-end">
                                                        <button type="button" @click="openNewComentario()" class="btn btn-primary btn-sm float-right">
                                                            <i class="fas fa-plus-circle"></i> Nuevo Comentario
                                                        </button>
                                                    </div>
                                                </div>

                                                <CTablita :pConfigList="ConfigListComentarios">
                                                    <template slot="header">

                                                        <th class="text-center">Fecha</th>
                                                        <th class="text-center">Usuario</th>
                                                        <th class="text-center">Comentario</th>

                                                    </template>

                                                    <template slot="body">
                                                        <tr v-for="(item,index) in listComentariosPrestamo" :key="index">
                                                            <td class="text-center" >{{$getCleanDate(item.FechaComentario,true)}}</td>
                                                            <td class="text-center" >{{item.NombreCompleto}}</td>
                                                            <td class="text-left" >{{item.Comentario}}</td>

                                                        </tr>
                                                        <CSinRegistros :pContIF="listComentariosPrestamo.length" :pColspan="3"/>
                                                    </template>
                                                </CTablita>

                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </template>
                </CLoader>
            </template>
        </CList>

        <CModal :pConfigModal="ConfigModal" :poBtnSave="oBtnSave2">
            <template slot="Form">
                <FormPrestamo :poBtnSave="oBtnSave2"></FormPrestamo>
            </template>
        </CModal>

        <CModal :pConfigModal="ConfigModalComentarios">
            <template slot="Form">
                <Comentarios :poBtnSave="poBtnSaveComentarios" />
            </template>
        </CModal>
        <CModal :pConfigModal="ConfigModalComentCliente" :poBtnSave="poBtnSaveComentCliente">
            <template slot="Form">
                <ComentarioClientes :poBtnSave="poBtnSaveComentCliente" />
            </template>
        </CModal>
    </div>
</template>

<script>

    import CBtnSave      from "@/components/CBtnSave";
    import Configs       from "@/helpers/VarConfig.js";
    import CValidation   from "@/components/CValidation.vue"
    import FormPrestamo  from "@/views/modulos/crm/clientes/FormPrestamo.vue";
    import CSinRegistros from "@/components/CSinRegistros";
    import CBtnAccion    from "@/components/CBtnAccion";
    import CLoader       from "../../../../components/CLoader";
    import CList         from "../../../../components/CList";
    import CModal        from "../../../../components/CModal";
    import moment        from "moment";
    import Comentarios   from "./Comentarios";
    import ComentarioClientes from "./ComentarioClientes";

    const EmitEjecuta    = "seccionClienteActivo";
    const EmitEjecuta2   = "seccionClienteActivo2";
    const EmitEjecuta3   = "seccionComentariosPrestamo";
    const EmitEjecuta4   = "seccionComentariosCliente";

    export default {
        name:  "DetallesCliente",
        props: ["Id"],
        components: {
            Comentarios,
            CBtnSave,
            CValidation,
            FormPrestamo,
            CSinRegistros,
            CBtnAccion,
            CLoader,
            CList,
            CModal,
            ComentarioClientes
        },
        data() {
            return {
                RutaEviSol:                 "",
                NombreRuta:                 "",
                Sucursales:                 "",
                NombreCompleto:             "",
                Img:                        null,
                RutaFile:                   "",
                RutaEvid:                   '',
                MontoSeleccionado:          0,
                isReady:                    false,
                getNuevoPrestamo:           false,
                ValidElement:               Configs.validImage2m,
                ListaEviSolicitud:          [],
                IneArrayRows:               [],
                LocalArrayRows:             [],
                errorvalidacion:            [],
                ListaRutasArray:            [],
                ListaEstadosArray:          [],
                DomilicioArrayRows:         [],
                ListaMunicipiosArray:       [],
                ListaSucursalesArray:       [],
                ListaRutasEmpleadosArray:   [],
                ListaMontosPrestamosArray:  [],
                ListaINEArray:              [],
                ListaDomicilioArray:        [],
                ListaEmpresaArray:          [],
                ListaPrestamoCliente:       [],
                ListaRuta:                  [],
                Negocio:                    [],
                listComentariosPrestamo:    [],
                Prestamo:{
                    evidencia_solicitud:[],
                },
                segurity:                   {
                    Edit: true,
                },
                ConfigList: {
                    ShowTitleFirst:    false,
                    Title:             "Detalles Cliente",
                    ShowLoader:        true,
                    IsModal:           false,
                    BtnNewShow:        false,
                    BtnReturnShow:     true,
                    TypeBody:          "Form",
                    ShowFiltros:       false,
                    ShowFiltrosInline: true,
                    ShowTitleInline:   false,
                    ShowPaginador:     false,
                },
                oBtnSave: {
                    toast:         0,
                    IsModal:       false,
                    ShowBtnSave:   true,
                    ShowBtnCancel: false,
                    DisableBtn:    false,
                    EmitSeccion:   EmitEjecuta,
                },
                ConfigLoad: {
                    ShowLoader: true,
                    ClassLoad:  false,
                },
                ConfigLoad2: {
                    ShowLoader: false,
                    ClassLoad:  false,
                },
                objCliente: {
                    IdCliente: 	 		0,
					IdEstado: 	 		0,
					IdMunicipio: 		0,
					Nombre: 	 	 	"",
					ApellidoPat: 	 	"",
					ApellidoMat: 	 	"",
					FechaNacimiento: 	"",
					Correo: 		 	"",
					Telefono: 		 	"",
					NombreNegocio: 		"",
					Negocio: 			[],
					TipoNegocio: 		0,
					DescripcionNegocio: "",
					Calle: 	 	 		"",
					NoInt: 	 	 		"",
					NoExt: 	 	 		"",
					Colonia: 	 		"",
					Curp: 	 	 		"",
					Cp: 	 	 		"",
					Cruzamiento1:	 	"",
					Cruzamiento2:		"",
					Referencias: 		"",
					Prospecto: 	 		"",
					Estatus: 	 		"",
					Latitud:  			0,
					Longitud: 			0,
					Imagen: 	 		"",
                },
                masks: {
                    input: 'YYYY-MM-DD',
                },
                Filtro: {
                    Nombre:         "",
                    Pagina:         1,
                    Entrada:        25,
                    TotalItem:      0,
                    Placeholder:    "Buscar..",
                },
                oBtnSave2: {
                    toast:       0,
                    IsModal:     true,
                    DisableBtn:  false,
                    EmitSeccion: EmitEjecuta2,
                },
                ConfigList2:{
                    Title:         'Prestamos',
                    IsModal:       true,
                    ShowLoader:    true,
                    BtnReturnShow: false,
                    EmitSeccion:   EmitEjecuta2,
                },
                ConfigModal: {
                    ModalTitle:  "Nuevo Prestamo",
                    ModalNameId: "ModalForm",
                    EmitSeccion: EmitEjecuta2,
                    ModalSize:   "md",
                },
                ConfigList3: {
                    ShowLoader:     false,
                    IsModal:        false,
                    BtnReturnShow:  true,
                    ShowSearch:     false,
                    ShowPaginador:  false,
                    ShowEntradas:   false,
                    BtnNewShow:     false,
                    TypeBody:       'List',
                    ShowTitleFirst: false,
                    EmitSeccion:    EmitEjecuta,
                    GoRoute: 'formdetalleprestamo'
                },
                objRuta: {
                    NombreRuta: ""
                },
                ConfigMapa:{
                    ShowBuscador: true
                },
                coordenadas: {
                    Lat: 20.9673271,
                    Lng: -89.6249853,
                },
                ConfigModalComentarios: {
                    ModalTitle:  "Comentarios del Prestamo",
                    ModalNameId: 'ModalForm',
                    EmitSeccion: EmitEjecuta3,
                    ModalSize:   'lg',
                    ShowFooter: false,
                },
                poBtnSaveComentarios:{
                    toast:       0,
                    IsModal:     true,
                    DisableBtn:  false,
                    EmitSeccion: EmitEjecuta3,
                    ShowBtnSave: true
                },
                ConfigModalComentCliente: {
                    ModalTitle:  "Comentarios del Cliente",
                    ModalNameId: 'ModalForm',
                    EmitSeccion: EmitEjecuta4,
                    ModalSize:   'lg',
                    ShowFooter: true,
                },
                poBtnSaveComentCliente:{
                    toast:       0,
                    IsModal:     true,
                    DisableBtn:  false,
                    EmitSeccion: EmitEjecuta4,
                    ShowBtnSave: true
                },
                ConfigListComentarios: {
                    ShowLoader:     false,
                    IsModal:        false,
                    BtnReturnShow:  true,
                    ShowSearch:     false,
                    ShowPaginador:  false,
                    ShowEntradas:   false,
                    BtnNewShow:     false,
                    TypeBody:       'List',
                    ShowTitleFirst: false,
                    EmitSeccion:    EmitEjecuta,
                },
            };
        },
        methods: {
            async Guardar() {
                this.errorvalidacion     = [];
                this.oBtnSave.toast      = 0;
                this.oBtnSave.DisableBtn = true;

                let Fecha1 = '';
                if(this.objCliente.FechaNacimiento!=''){
                    Fecha1 = moment(this.objCliente.FechaNacimiento).format('YYYY-MM-DD');
                }

                // CLIENTE
                let formData = new FormData();
                formData.set("origin", "web");
                formData.set("IdCliente",           this.objCliente.IdCliente);
                formData.set("Nombre", 				this.objCliente.Nombre);
                formData.set("ApellidoPat", 		this.objCliente.ApellidoPat);
                formData.set("ApellidoMat", 		this.objCliente.ApellidoMat);
                formData.set("Telefono", 			this.objCliente.Telefono);
                formData.set("FechaNacimiento", 	Fecha1);
                formData.set("Correo", 				this.objCliente.Correo);
                formData.set("NombreNegocio", 		this.objCliente.NombreNegocio);
                formData.set("Negocio", 			this.objCliente.Negocio);
                formData.set("TipoNegocio", 		this.objCliente.TipoNegocio);
                formData.set("DescripcionNegocio", 	this.objCliente.DescripcionNegocio);
                formData.set("IdEstado", 			this.objCliente.IdEstado);
                formData.set("IdMunicipio", 		this.objCliente.IdMunicipio);
                formData.set("Calle", 				this.objCliente.Calle);
                formData.set("NoInt", 				this.objCliente.NoInt);
                formData.set("NoExt", 				this.objCliente.NoExt);
                formData.set("Colonia", 			this.objCliente.Colonia);
                formData.set("Curp", 				this.objCliente.Curp);
                formData.set("Cp", 					this.objCliente.Cp);
                formData.set("Cruzamiento1", 		this.objCliente.Cruzamiento1);
                formData.set("Cruzamiento2", 		this.objCliente.Cruzamiento2);
                formData.set("Referencias", 		this.objCliente.Referencias);
                formData.set("Prospecto", 			this.objCliente.Prospecto);
                formData.set("Estatus", 			this.objCliente.Estatus);
                formData.set("Latitud", 			this.objCliente.Latitud);
                formData.set("Longitud", 			this.objCliente.Longitud);
                formData.set("ImagenPrevious", 		this.objCliente.Imagen);

                let Imagen = this.$refs.file.files[0];
                formData.append("Imagen", Imagen);

                await this.$http.post("clientesActivosUp", formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res) => {
                    this.EjecutaConExito(res);
                    this.objCliente = res.data.data.cliente;
                })
                .catch((err) => {
                    this.EjecutaConError(err);
                });
            },
            EjecutaConExito(res) {
                this.oBtnSave.DisableBtn = false;
                this.oBtnSave.toast      = 1;
                this.Regresar();
            },
            EjecutaConError(err) {
                this.oBtnSave.DisableBtn = false;

                if (err.response.data.errors) {
                    this.errorvalidacion = err.response.data.errors;
                    this.oBtnSave.toast  = 2;
                } else {
                    this.$toast.error(err.response.data.message);
                }

            },
            Recuperar() {
                this.$http.get("clientesActivos/"+this.objCliente.IdCliente)
                    .then((res) => {
                        this.objCliente                 = res.data.data.Cliente;
                        this.RutaFile                   = res.data.RutaFile;
                        let Fecha1                      = this.objCliente.FechaNacimiento.replace(/-/g,'\/');
                        this.objCliente.FechaNacimiento = Fecha1;
                        this.getNuevoPrestamo           = parseInt(this.objCliente.obtenerNuevoPrestamo) > 0 ? true : false;
                        this.ListaMunicipios(this.objCliente.IdEstado);
                        this.setDefaultorCurrentLocation();
                        this.listaComentarios();
                        this.isReady = true;

                    }).finally(() => {
                        this.ConfigLoad.ShowLoader = false;
                    });
            },
            setDefaultorCurrentLocation() {
                this.coordenadas.Lng = parseFloat(this.objCliente.Longitud);
                this.coordenadas.Lat = parseFloat(this.objCliente.Latitud);
            },
            RecuperarPrestamos(){
                this.ConfigList3.ShowLoader =  true;
                this.$http.get("clientesPrestamosActivos/"+this.objCliente.IdCliente)
                    .then((res) => {
                    this.ListaPrestamoCliente   = res.data.data;
                    this.RutaEviSol             = res.data.RutaEvidSol;
                    this.ListaEviSolicitud      = res.data.cliente.evidencias;
                    this.ConfigList3.ShowLoader =  false;
                })
                .finally(() => {
                    this.ConfigList3.ShowLoader =  false;
                    this.ConfigLoad.ShowLoader  = false;
                });
            },
            RecuperarRutas() {
                this.$http.get("clientesRutasActivos/"+this.objCliente.IdCliente).then((res) => {
                    this.objRuta        = res.data.data.cliente;
                    this.NombreRuta     = this.objRuta[0].NombreRuta;
                    this.Sucursales     = this.objRuta[0].Nombre;
                    this.NombreCompleto = this.objRuta[0].NombreCompleto;
                })
            },
            viewsComentarios(item){
                this.bus.$emit('NewModal_'+EmitEjecuta3,item);
            },

            Limpiar() {
                this.objCliente = {
                    IdCliente: 	 		0,
					IdEstado: 	 		0,
					IdMunicipio: 		0,
					Nombre: 	 	 	"",
					ApellidoPat: 	 	"",
					ApellidoMat: 	 	"",
					FechaNacimiento: 	"",
					Correo: 		 	"",
					Telefono: 		 	"",
					NombreNegocio: 		"",
					Negocio: 			[],
					TipoNegocio: 		0,
					DescripcionNegocio: "",
					Calle: 	 	 		"",
					NoInt: 	 	 		"",
					NoExt: 	 	 		"",
					Colonia: 	 		"",
					Curp: 	 	 		"",
					Cp: 	 	 		"",
					Cruzamiento1: 	 	"",
					Cruzamiento2: 	 	"",
					Referencias: 		"",
					Prospecto: 	 		"",
					Estatus: 	 		"",
					Latitud:  			0,
					Longitud: 			0,
					Imagen: 	 		"",
                };
                this.listComentariosPrestamo = [];
            },
            Regresar() {
                this.$router.push({name:'clientesActivos',params:{}});
            },
            async ListaMunicipios(id) {
                if (typeof id != "undefined") {
                    this.objCliente.IdEstado = id;
                }
                await this.$http
                    .get("municipios", {
                        params: {
                            IdEstado: this.objCliente.IdEstado,
                        },
                    })
                    .then((res) => {
                        if (typeof id == "undefined") {
                            this.ListaMunicipiosArray   = []
                            this.objCliente.IdMunicipio = 0;
                        }
                        this.ListaMunicipiosArray = res.data.data;
                    });
            },
            listaComentarios() {
                this.$http.get("clientecomentarios",{
                    params:{
                        IdCliente: this.objCliente.IdCliente,
                    }
                }).then((res) => {
                    this.listComentariosPrestamo = res.data.data;
                });
            },
            openNewComentario() {
                let request =  this.objCliente.IdCliente;
                this.bus.$emit('NewModal_'+EmitEjecuta4,request);
            },

            openPrestamo() {
                this.$router.push({ name:'prospectosdetalles', params:{Id:this.objCliente.IdCliente, pOrigen:'NEWPRESTAMO'}})
            },
            formato(fecha){
                let formato = moment(fecha).format('DD-MM-YYYY');

                if(fecha!=null){
                    return formato;
                }

            },
			ListaNegocio() {
				this.$http.get("negocios").then((res) => {
					this.Negocio = res.data.data.map( item =>({
						id:    item.IdNegocios,
						label: item.Negocio
					}));
					// this.Negocio.push({ id: 0, label: "Seleccione un Tipo de Negocio"});
				});
			},
            previewImagen(ruta,item) {
                window.open(ruta+item.Evidencia,'Nueva Ventana',"width=600,height=800");
            },
            previewCliente(ruta,item) {
                window.open(this.RutaFile+this.objCliente.UrlImg,'Nueva Ventana',"width=600,height=800");
            },
        },
        created() {
            this.bus.$off("Save_"+EmitEjecuta);
            this.bus.$off("EmitReturn");
            this.bus.$off("List_" + EmitEjecuta2);
            this.bus.$off("List_" + EmitEjecuta4);

            this.Limpiar();

            if(this.Id !== undefined){
                sessionStorage.setItem('IdClienteR',this.Id);
            }

            this.objCliente.IdCliente = sessionStorage.getItem('IdClienteR');
            this.ListaEstadosArray = JSON.parse(sessionStorage.getItem("ListaEstadosArray"));
            this.oBtnSave.toast    = 0;
        },
        mounted() {
			this.ListaNegocio();
            this.oBtnSave.DisableBtn = false;

            if (this.objCliente.IdCliente > 0) {
                //this.objCliente.IdCliente = this.Id;
                //sessionStorage.setItem('IdGeneral',this.Id);
                this.Recuperar();
                this.RecuperarPrestamos();
                this.RecuperarRutas();
            } else {
                this.ConfigLoad.ShowLoader = false;
            }

            this.bus.$on("List_" + EmitEjecuta2, () => {
                this.RecuperarPrestamos();
            });
            this.bus.$on("List_" + EmitEjecuta4, () => {
                this.listaComentarios();
            });

            this.bus.$on("Save_"+EmitEjecuta, () => {
                this.Guardar();
            });

            this.bus.$on("EmitReturn", () => {
                this.Regresar();
            });
        },
        computed:{
            setLocation: function () {
                this.objCliente.Longitud = this.coordenadas.Lng;
                this.objCliente.Latitud  = this.coordenadas.Lat;
                return this.objCliente;
            }
        }
    };

</script>
