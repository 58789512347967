<template>
    <div>
        <CList :pConfigList="ConfigList">
            <template slot="bodyForm">
                <input type="hidden" :value="checkMontos">


                <CLoader :pConfigLoad="ConfigLoad">
                    <template slot="BodyFormLoad">
                        <div class="row">
                            <div class="col-12 mt-3">
                                <fieldset>
                                    <legend class="col-form-label">&nbsp;Cantidad a Entregar &nbsp;</legend>
                                    <div class="form-row mt-3">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <div class=" form-group form-row" v-if="showComent">
                                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                    <label for="MontoSolicitud">Monto solicitado</label>
                                                    <input class="form-control" type="text" v-model="localPropPrestamo.MontoSolicitud" disabled>
                                                </div>
                                            </div>

                                            <div class=" form-group form-row">
                                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                    <label for="MontoSolicitud">Monto Entregado</label>
                                                    <select :disabled="blockEntrega" @change="showMotivo()" id="MontoSolicitud" v-model="objEntrega.MontoEntregado" name="MontoSolicitud" class="form-control form-select">
                                                        <option value="0">--Seleccionar--</option>
                                                        <option v-for="(item,index) in ListaMontosPrestamosArray" :key="index" :value="item.Monto">{{item.Monto}}</option>
                                                    </select>
                                                    <label>
                                                        <CValidation v-if="this.errorvalidacion.MontoSolicitud" :Mensaje="'*' + errorvalidacion.MontoSolicitud[0]"></CValidation>
                                                    </label>
                                                </div>

                                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" v-if="showComent">
                                                    <label for="ComentariosEntrega">Motivo de cambio de cantidad <span class="text-danger">*</span></label>
                                                    <textarea :disabled="blockEntrega" class="form-control" v-model="objEntrega.ComentariosEntrega" placeholder="Ingrese Motivo de Cambio" rows="1"> </textarea>
                                                    <CValidation v-if="this.errorvalidacion.ComentariosEntrega" :Mensaje="'*' + errorvalidacion.ComentariosEntrega[0]"></CValidation>
                                                </div>

                                                <div v-if="blockEntrega" class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                    <label for="MontoSolicitud">Entregó</label>
                                                    <input class="form-control" type="text" v-model="objEntrega.NombreEntrego" disabled>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <fieldset>
                                    <legend class="col-form-label">&nbsp;Evidencias de Entrega &nbsp;</legend>

                                    <template v-if="!blockEntrega">
                                        <div class="form-row mt-1">

                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
                                                <div class="box-imagen">
                                                    <div class="avatar-upload image-button">
                                                        <label class="">Cliente <span class="text-danger">*</span></label>
                                                        <div class="avatar-edit">
                                                            <input id="file1" @change="$uploadImagePreview($event,ValidElement, Array('ImgPersonal','imagePreview1'))" ref="file1" type="file" name="file" accept=".png, .jpg, .jpeg">
                                                            <label for="file1"></label>
                                                        </div>
                                                        <div class="avatar-preview">
                                                            <div id="imagePreview1" :style="'background-image: url('+RutaEntrega+this.objEntrega.UrlImg+');'" :src="RutaEntrega+objEntrega.UrlImg"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
                                                <div class="box-imagen">
                                                    <div class="avatar-upload image-button">
                                                        <label class="">Documento de Crédito <span class="text-danger">*</span></label>
                                                        <div class="avatar-edit">
                                                            <input  id="file2" @change="$uploadImagePreview($event,ValidElement, Array('ImgPagare','imagePreview2'))" ref="file2" type="file" name="file" accept=".png, .jpg, .jpeg">
                                                            <label for="file2"></label>
                                                        </div>
                                                        <div class="avatar-preview">
                                                            <div id="imagePreview2" :style="'background-image: url('+RutaEntrega+this.objEntrega.UrlImg+');'" :src="RutaEntrega+objEntrega.UrlImg"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
                                                <div class="box-imagen">
                                                    <div class="avatar-upload image-button">
                                                        <label class="">Hoja de Referencias #1 <span class="text-danger">*</span></label>
                                                        <div class="avatar-edit">
                                                            <input  id="file3" @change="$uploadImagePreview($event,ValidElement, Array('ImgHojaReferencia1','imagePreview3'))" ref="file3" type="file" name="file" accept=".png, .jpg, .jpeg">
                                                            <label for="file3"></label>
                                                        </div>
                                                        <div class="avatar-preview">
                                                            <div id="imagePreview3" :style="'background-image: url('+RutaEntrega+this.objEntrega.UrlImg+');'" :src="RutaEntrega+objEntrega.UrlImg"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
                                                <div class="box-imagen">
                                                    <div class="avatar-upload image-button">
                                                        <label class="">Hoja de Referencias #2 <span class="text-danger">*</span></label>
                                                        <div class="avatar-edit">
                                                            <input  id="file4" @change="$uploadImagePreview($event,ValidElement, Array('ImgHojaReferencia2','imagePreview4'))" ref="file4" type="file" name="file" accept=".png, .jpg, .jpeg">
                                                            <label for="file4"></label>
                                                        </div>
                                                        <div class="avatar-preview">
                                                            <div id="imagePreview4" :style="'background-image: url('+RutaEntrega+this.objEntrega.UrlImg+');'" :src="RutaEntrega+objEntrega.UrlImg"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
                                                <div class="box-imagen">
                                                    <div class="avatar-upload image-button">
                                                        <label class="" >Términos y Condiciones <span class="text-danger">*</span></label>
                                                        <div class="avatar-edit">
                                                            <input  id="file5" @change="$uploadImagePreview($event,ValidElement, Array('ImgTerminosCondiciones','imagePreview5'))" ref="file5" type="file" name="file" accept=".png, .jpg, .jpeg">
                                                            <label for="file5"></label>
                                                        </div>
                                                        <div class="avatar-preview">
                                                            <div id="imagePreview5" :style="'background-image: url('+RutaEntrega+this.objEntrega.UrlImg+');'" :src="RutaEntrega+objEntrega.UrlImg"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
                                                <div class="box-imagen">
                                                    <div class="avatar-upload image-button">
                                                        <label class="" >Recibo de Dinero <span class="text-danger">*</span></label>
                                                        <div class="avatar-edit">
                                                            <input  id="file6" @change="$uploadImagePreview($event,ValidElement, Array('ImgReciboDinero','imagePreview6'))" ref="file6" type="file" name="file" accept=".png, .jpg, .jpeg">
                                                            <label for="file6"></label>
                                                        </div>
                                                        <div class="avatar-preview">
                                                            <div id="imagePreview6" :style="'background-image: url('+RutaEntrega+this.objEntrega.UrlImg+');'" :src="RutaEntrega+objEntrega.UrlImg"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                                                <div class="box-imagen">
                                                    <div class="avatar-upload image-button">
                                                        <label class="" >Identificación #1 <span class="text-danger">*</span></label>

                                                        <div class="avatar-edit">
                                                            <input  type="file" id="fileIne0" name="file7[]" ref="file7" @change="$uploadImagePreview($event,ValidElement, Array('ImgIne0','imagePreviewIne_0'))"  accept=".png, .jpg, .jpeg">
                                                            <label for="fileIne0" class="mr-1"></label>
                                                        </div>

                                                        <div class="avatar-preview">
                                                            <div id="imagePreviewIne_0" src="" style="'background-image: url();'"></div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                                                <div class="box-imagen">
                                                    <div class="avatar-upload image-button">
                                                        <label class="" >Identificación #2 <span class="text-danger">*</span></label>

                                                        <div class="avatar-edit">
                                                            <input  type="file" id="fileIne1" name="file7[]" ref="file7" @change="$uploadImagePreview($event,ValidElement, Array('ImgIne1','imagePreviewIne_1'))"  accept=".png, .jpg, .jpeg">
                                                            <label for="fileIne1" class="mr-1"></label>
                                                        </div>

                                                        <div class="avatar-preview">
                                                            <div id="imagePreviewIne_1" src="" style="'background-image: url();'"></div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
                                                <div class="box-imagen">
                                                    <div class="avatar-upload image-button">
                                                        <label class="" >Local #1 <span class="text-danger">*</span></label>
                                                        <div class="avatar-edit">
                                                            <input  id="file9" @change="$uploadImagePreview($event,ValidElement, Array('ImgEstablecimiento1','imagePreview9'))" ref="file9" type="file" name="file" accept=".png, .jpg, .jpeg">
                                                            <label for="file9"></label>
                                                        </div>
                                                        <div class="avatar-preview">
                                                            <div id="imagePreview9" :style="'background-image: url('+RutaEntrega+this.objEntrega.UrlImg+');'" :src="RutaEntrega+objEntrega.UrlImg"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
                                                <div class="box-imagen">
                                                    <div class="avatar-upload image-button">
                                                        <label class="" >Local #2 <span class="text-danger">*</span></label>
                                                        <div class="avatar-edit">
                                                            <input  id="file10" @change="$uploadImagePreview($event,ValidElement, Array('ImgEstablecimiento2','imagePreview10'))" ref="file10" type="file" name="file" accept=".png, .jpg, .jpeg">
                                                            <label for="file10"></label>
                                                        </div>
                                                        <div class="avatar-preview">
                                                            <div id="imagePreview10" :style="'background-image: url('+RutaEntrega+this.objEntrega.UrlImg+');'" :src="RutaEntrega+objEntrega.UrlImg"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </template>

                                    <template v-else>
                                        <div class="form-group form-row">
                                            <div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 mt-4" v-for="(item,index) in ListaEviEntrega" :key="index" >

                                                <div class="avatar-upload text-center">
                                                    <label class="">
                                                        <template v-if="item.TipoEvidencia == 'ImgIne'">
                                                            INE
                                                        </template>
                                                        <template v-else-if="item.TipoEvidencia == 'ImgPersonal'">
                                                            Personal
                                                        </template>
                                                        <template v-else-if="item.TipoEvidencia == 'ImgEstablecimiento1'">
                                                            Establecimiento 1
                                                        </template>
                                                        <template v-else-if="item.TipoEvidencia == 'ImgEstablecimiento2'">
                                                            Establecimiento 2
                                                        </template>
                                                        <template v-else-if="item.TipoEvidencia == 'ImgPagare'">
                                                            Documento de Crédito
                                                        </template>
                                                        <template v-else-if="item.TipoEvidencia == 'ImgHojaReferencia1'">
                                                            Hoja de Referencias 1
                                                        </template>
                                                        <template v-else-if="item.TipoEvidencia == 'ImgHojaReferencia2'">
                                                            Hoja de Referencias 2
                                                        </template>
                                                        <template v-else-if="item.TipoEvidencia == 'ImgTerminosCondiciones'">
                                                            Terminos y Condiciones
                                                        </template>
                                                        <template v-else-if="item.TipoEvidencia == 'ImgReciboDinero'">
                                                            Recibo de Dinero
                                                        </template>
                                                    </label>

                                                    <div class="avatar-edit3">
                                                        <button type="button" @click="modImgEntrega(item,RutaEviEntrega)">
                                                            <i class="fas fa-pen"></i>
                                                        </button>
                                                    </div>

                                                    <div class="avatar-edit2" v-if="item.Evidencia !== 'picture.png' && item.Evidencia !== undefined">
                                                        <button type="button" @click="previewEvidencia(RutaEviEntrega,item.Evidencia)">
                                                            <i class="fas fa-search"></i>
                                                        </button>  
                                                    </div>

                                                    <div class="avatar-preview">
                                                        <div :id="'imagePreviewIne_'+index" :style="'background-image: url('+RutaEviEntrega+item.Evidencia+');'" :src="RutaEviEntrega+item.Evidencia"></div>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </template>

                                </fieldset>


                                <div class="row mt-2">
                                    <div class="col-12">
                                        <CBtnSave v-if="!blockEntrega" :poBtnSave="oBtnSave" class="mt-3"/>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </template>
                </CLoader>
            </template>
        </CList>
    
        <CModal :pConfigModal="ConfigModalModImgs" :poBtnSave="oBtnSaveModImgs">
			<template slot="Form">
				<FormModImgEntrega :poBtnSave="oBtnSaveModImgs" :Operacion="pOperacionImgs"/>
			</template>
		</CModal>

    </div>
</template>
<script>
import moment from "moment";
import CList from "../../../../components/CList";
import CLoader from "../../../../components/CLoader";
import CValidation from "../../../../components/CValidation";
import Configs 	  from '@/helpers/VarConfig.js';
import CBtnSave from "../../../../components/CBtnSave";
import CModal from "../../../../components/CModal.vue";
import Template from "../../../template/Template";
import FormModImgEntrega from "./FormModImgEntrega.vue";

const EmitEjecuta = "seccionEntregasPrestamo";
const EmitEjecuta2 = "seccionModImgEntregas";
export default {
    name: "FormEntregas",
    props: ['pPrestamo'],
    components: {
        Template,
        CList,
        CLoader,
        CValidation,
        CBtnSave,
        CModal,
        FormModImgEntrega,
    },

    data() {
        return {
            ListaMontosPrestamosArray:[],
            errorvalidacion:[],
            RutaEntrega:'',
            ValidElement: 				Configs.validImage5m,
            blockEntrega: false,
            showComent:false,
            ListaEviEntrega:[],
            RutaEviEntrega:'',
            ConfigList: {
                ShowTitleFirst: 	false,
                Title: 				"",
                ShowLoader: 		true,
                IsModal: 	 		false,
                BtnNewShow: 		false,
                BtnReturnShow: 		true,
                TypeBody: 			"Form",
                ShowFiltros: 		false,
                ShowFiltrosInline: 	true,
                ShowTitleInline: 	false,
                ShowPaginador: 		false,
                EmitSeccion: EmitEjecuta
            },
            ConfigLoad: {
                ShowLoader: false,
                ClassLoad:  false,
            },
            localPropPrestamo:{
                status: "",
                EstatusP: "",
                FechaAsignacion: "",
                FechaEntrega: null,
                FechaPrestamo: "",
                Folio: "",
                IdCliente: 0,
                IdPrestamo: 0,
                IdUsuario: 0,
                MontoEntregado: "",
                MontoSolicitud: "",
                NomEntregador: "",
                NombreCompleto: "",
                NombreRuta:"",
                Origen: "",
                Telefono:""
            },
            oBtnSave: {
                toast:         0,
                IsModal:       false,
                ShowBtnSave:   true,
                ShowBtnCancel: false,
                DisableBtn:    false,
                EmitSeccion:   EmitEjecuta,
            },
            objPrestamo:{
                MontoSolicitud:0,
            },
            objEntrega: {
                IdPrestamo:0,
                IdCliente:0,
                MontoEntregado: '',
                ComentariosEntrega: '',
                ImgPersonal: '',
                NombreEntrego:'',
                LatitudEntrega: '',
                LongitudEntrega:'',
                OrigenEntrega: 'web'
            },
            ConfigModalModImgs:{
                ModalTitle:  "Correccion Evidencia de Entrega",
                ModalNameId: 'ModalForm3',
				ModalSize:   'md',
				EmitSeccion: EmitEjecuta2,
            },

			oBtnSaveModImgs: {
				toast: 	   	 0,
				IsModal: 	 true,
				DisableBtn:  false,
				EmitSeccion: EmitEjecuta2,
			},
            pOperacionImgs: ''
        }
    },
    methods: {
        ListaMontosPrestamo() {
            this.$http.get("prestamosmontos", {
                params: {
                    simple: 1
                },
            }).then((res) => {
                this.ListaMontosPrestamosArray = res.data.data;
            });
        },

        Regresar() {
            this.$router.push( { name: 'porentregar', params: {} } );

        },

        showMotivo(){
            if(this.localPropPrestamo.EstatusP == 'Asignado'){
                if(parseFloat(this.objEntrega.MontoEntregado) != parseFloat(this.localPropPrestamo.MontoSolicitud)) {
                    this.showComent = true;
                }else {
                    this.showComent = false;
                }
            }else{
                if(parseFloat(this.objPrestamo.MontoSolicitud) != parseFloat(this.objPrestamo.MontoEntregado)){
                    this.objEntrega.MontoEntregado = this.objPrestamo.MontoEntregado;
                    this.showComent = true;
                }
            }

        },

        VerPreviewImagen(item) {
            window.open(this.RutaEviEntrega+item.Evidencia,'Nueva Ventana',"width=600,height=800");
        },

        Guardar() {
            this.errorvalidacion 	 = [];
            this.oBtnSave.DisableBtn = true;
            let mensaje = Configs.configAlertaEntrega;


            // CLIENTE
            let formData = new FormData();
            formData.set('IdPrestamo', 			this.localPropPrestamo.IdPrestamo);
            formData.set("IdCliente", 			this.localPropPrestamo.IdCliente);

            formData.set('MontoEntregado', 		this.objEntrega.MontoEntregado);
            formData.set('ComentariosEntrega', 	this.objEntrega.ComentariosEntrega);
            formData.set('LatitudEntrega', 		"");
            formData.set('LongitudEntrega', 		"");
            formData.set('OrigenEntrega', 		"web");

            let ImgPersonal = this.$refs.file1.files[0];
            if (ImgPersonal == undefined) {
                this.$toast.warning("Seleccione una Imagen del Cliente");
                this.oBtnSave.DisableBtn = false;
                return false;
            }
            formData.append("ImgPersonal", ImgPersonal);

            let ImgPagare = this.$refs.file2.files[0];
            if (ImgPagare == undefined) {
                this.$toast.warning("Seleccione una Imagen del pagaré");
                this.oBtnSave.DisableBtn = false;
                return false;
            }
            formData.append("ImgPagare", ImgPagare);

            let ImgHojaReferencia1 = this.$refs.file3.files[0];
            if (ImgHojaReferencia1 == undefined) {
                this.$toast.warning("Seleccione una Imagen para la Hoja de Referencias");
                this.oBtnSave.DisableBtn = false;
                return false;
            }
            formData.append("ImgHojaReferencia1", ImgHojaReferencia1);

            let ImgHojaReferencia2 = this.$refs.file4.files[0];
            if (ImgHojaReferencia2 == undefined) {
                this.$toast.warning("Seleccione una Imagen para la Hoja de Referencias");
                this.oBtnSave.DisableBtn = false;
                return false;
            }
            formData.append("ImgHojaReferencia2", ImgHojaReferencia2);

            let ImgTerminosCondiciones = this.$refs.file5.files[0];
            if (ImgTerminosCondiciones == undefined) {
                this.$toast.warning("Seleccione una Imagen de los Terminos y Condiciones");
                this.oBtnSave.DisableBtn = false;
                return false;
            }
            formData.append("ImgTerminosCondiciones", ImgTerminosCondiciones);

            let ImgReciboDinero = this.$refs.file6.files[0];
            if (ImgReciboDinero == undefined) {
                this.$toast.warning("Seleccione una Imagen para el Recibo de Dinero");
                this.oBtnSave.DisableBtn = false;
                return false;
            }
            formData.append("ImgReciboDinero", ImgReciboDinero);

            let ImgEstablecimiento1 = this.$refs.file9.files[0];
            if (ImgEstablecimiento1 == undefined) {
                this.$toast.warning("Seleccione una Imagen del Establecimiento");
                this.oBtnSave.DisableBtn = false;
                return false;
            }
            formData.append("ImgEstablecimiento1", ImgEstablecimiento1);

            let ImgEstablecimiento2 = this.$refs.file10.files[0];
            if (ImgEstablecimiento2 == undefined) {
                this.$toast.warning("Seleccione una Imagen del Establecimiento");
                this.oBtnSave.DisableBtn = false;
                return false;
            }
            formData.append("ImgEstablecimiento2", ImgEstablecimiento2);



            let files7 = document.getElementsByName('file7[]');
            formData.append('ImgIne', 		JSON.stringify(this.ImgIne));

            for(var i=0;i<2;i++) {

                let file = (files7[i])?files7[i].files[0]:'';
                // PRIMERO VALIDAMOS QUE LA PRIMERA VEZ EXISTA IMAGEN COMO CAMPO OBLIGATORIO
                if(file===undefined) {
                    this.$toast.warning('El INE debe contener la fotografía del anverso y reverso');
                    this.oBtnSave.DisableBtn = false;
                    return false;
                } else {
                    if(file!=undefined){
                        formData.append('ImgIne[]',file);
                    }
                }
            }
            this.$swal(mensaje).then((result) => {
                if (result.value) {
                   this.$http.post('/prestamoswebentrega',formData,{
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                   }).then((res) => {
                       this.EjecutaConExito(res);

                   }).catch((err) => {
                       this.EjecutaConError(err);
                   });
                }else {
                    this.oBtnSave.DisableBtn = false;
                }
            });


        },

        EjecutaConExito(res) {
            this.oBtnSave.DisableBtn = false;
            this.bus.$emit('RunAlerts_'+this.ConfigList.EmitSeccion,1);
            this.Regresar();
        },
        EjecutaConError(err) {
            this.oBtnSave.DisableBtn = false;

            if (err.response.data.errors) {
                this.errorvalidacion = err.response.data.errors;
                this.oBtnSave.toast = 2;
            } else {
                this.$toast.error(err.response.data.message);
            }
        },

        recoveryPrestamo() {
            this.ConfigLoad.ShowLoader = true;

            this.$http.get("prestamos/"+this.localPropPrestamo.IdPrestamo)
                .then((res) => {
                    this.objPrestamo            = {};
                    this.objPrestamo            = res.data.data;
                    this.RutaEviEntrega         = res.data.RutaEvidEntre;
                    this.segurity               = res.data.segurity;
                    this.ListaEviEntrega        = this.objPrestamo.evidencia_entrega;
                    this.objEntrega.ComentariosEntrega = (this.objPrestamo.comentario_entrega != null) ?  this.objPrestamo.comentario_entrega.Comentario : '';
                    this.objEntrega.NombreEntrego = (this.objPrestamo.entregador != null) ?  this.objPrestamo.entregador.NombreCompleto : '';
                    this.showMotivo();

                })
                .finally(() => {
                    this.ConfigLoad.ShowLoader = false;
                });
        },

        modImgEntrega(item,rutaevidencia) {
            item.RutaEntrega = rutaevidencia;
            this.bus.$emit('NewModal_'+EmitEjecuta2,item);
        },

        previewEvidencia(ruta,imagen) {
                window.open(ruta+imagen,'Nueva Ventana',"width=600,height=800");
        },
    },
    computed: {
        checkMontos : function () {
            if(this.ListaMontosPrestamosArray.length > 0) {
                let montoSol = parseFloat(this.localPropPrestamo.MontoSolicitud);

                const newArray = this.ListaMontosPrestamosArray.filter(function (item) {
                    if (parseFloat(item.Monto) <= parseFloat(montoSol)) {
                        return item;
                    } else {
                        return '';
                    }
                });


                this.ListaMontosPrestamosArray = [];
                this.ListaMontosPrestamosArray = newArray;
            }
        },


    },
    created() {
        this.bus.$off("EmitReturn");
        this.bus.$off("Save_"+EmitEjecuta);
        this.bus.$off('Recovery_' +EmitEjecuta);
        this.ListaMontosPrestamo();
    },

    mounted() {
        this.bus.$on("EmitReturn", () => {
            this.Regresar();
        });

        this.bus.$on("Save_"+EmitEjecuta, () => {
            this.Guardar();
        });

        this.bus.$on('Recovery_' + EmitEjecuta, (obj) => {
           
            if (obj !== '') {

                this.localPropPrestamo.IdPrestamo = obj;
                this.recoveryPrestamo();
            }

        });

        if(this.pPrestamo !== undefined) {
            this.localPropPrestamo = this.pPrestamo;
            this.objEntrega.MontoEntregado = this.pPrestamo.MontoSolicitud;
            this.ConfigList.Title = 'Entrega de prestamo: '+this.localPropPrestamo.NombreCompleto+' - '+this.localPropPrestamo.Folio+'';

            if(this.localPropPrestamo.EstatusP == 'Entregado') {
                this.blockEntrega = true;
                this.recoveryPrestamo();
                this.ConfigList.Title = 'Detalle de Entrega: '+this.localPropPrestamo.NombreCompleto+' - '+this.localPropPrestamo.Folio+'';
            }


        }


    }

}
</script>
