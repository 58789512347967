<template>
	<div>

		<CList @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
			<template slot="Filtros">

                <v-date-picker v-model="range" mode="date" :model-config="modelConfig" is-range @input="Lista()" :timezone="timezone" :isUTC="true" :max-date="new Date()">
                    <template v-slot="{ inputValue, inputEvents }">
                        <div class="flex justify-center items-center">
                            <input :value="inputValue.start+' - '+inputValue.end" v-on="inputEvents.start" class="form-control mr-1"  readonly/>
                        </div>
                    </template>
                </v-date-picker>

                <select v-model="Filtro.IdUsuario" class="form-control form-select mr-2" name="Perfil" @change="Lista();">
                    <option value="0">--Seleccionar--</option>
                    <option v-for="(item, index) in listEntregadores" :key="index" :value="item.IdUsuario">
                        {{item.NombreCompleto}}
                    </option>
                </select>

                <select id="IdSucursal" v-model="Filtro.IdSucursal" @change="Lista();" v-show="ShowCmbSucursales" class="form-control form-select mr-2">
					<option v-for="(item, index) in ListaSucursalesArray" :key="index" :value="item.IdSucursal" >
						{{ item.Nombre }}
					</option>
				</select>

				<button type="button" v-b-tooltip.hover.Top title="Recargar" @click="Lista()" class="btn btn-primary btn-sm">
					<i class="fas fa-redo"></i>
				</button>
			</template>


			<template slot="header">
				<th class="td-sm"></th>
                <th>#</th>
                <th># Folio</th>
                <th>Cliente</th>
                <th>Monto Solicitado</th>
                <th>Fecha Asignado</th>
                <th>Fecha Entrega</th>
                <th>Monto Entregado</th>
                <th>Estatus</th>
                <th>Ruta</th>
                <th>Entregador</th>
                <th>Acciones</th>
			</template>
			<template slot="body">
				<tr v-for="(lista, index) in ListaArrayRows" :key="index">
					<td class="td-sm"></td>
                    <td>{{ $getNumItem(index) }}</td>
                    <td class="cursor" @click="irViewPrestamo(lista)" >
                        <i class="fa fa-info-circle fa-1x"></i>&nbsp;
                        <b>{{ lista.Folio }}</b>
                    </td>
                    <td>{{ $limitCharacters(lista.NombreCompleto,35) }}</td>
                    <td>{{ $formatNumber(lista.MontoSolicitud,'$') }}</td>
                    <td> {{ $getCleanDate(lista.FechaAsignacion,true) }}</td>
                    <td>
                        <template v-if="lista.FechaEntrega !== null">
                            {{ $getCleanDate(lista.FechaEntrega,true) }}
                        </template>
                        <template v-else>
                        </template>
                    </td>
                    <td>
                        <template v-if="lista.MontoEntregado !== null">
                            {{ $formatNumber(lista.MontoEntregado,'$') }}
                        </template>
                        <template v-else>
                            $0.00
                        </template>
                    </td>
                    <td>
                        <template v-if="lista.EstatusP == 'Entregado' && lista.IdUsuario != lista.IdEntrego ">
								<span :class="['badge', (lista.EstatusP == 'Entregado'	 ? 'badge-success': 'badge-secondary'),
									'position-relative']" v-b-tooltip.html :title="'<b>Entregó:</b>'+' '+lista.EntregoNombre">
									<span>
										{{ lista.EstatusP }}
									</span>
									<span class="badge badge-danger text-danger" style="position:absolute; top:-5px; border-radius:50%; border: 2px solid #fff;">
										.
									</span>
								</span>
                        </template>
                        <template v-else>
                            <b-badge v-if="lista.EstatusP === 'Asignado'" pill variant="info">{{ lista.EstatusP }} </b-badge>
                            <b-badge v-if="lista.EstatusP === 'Entregado'" pill variant="success">{{ lista.EstatusP }} </b-badge>
                        </template>


                    </td>

                    <td>{{ lista.NombreRuta }}</td>
                    <td><CAvatar :fullname="lista.NomEntregador" :size="25" :radius="50" :title="lista.NomEntregador" v-b-tooltip.hover.Top/></td>
                    <td>
                        <button v-if="lista.EstatusP === 'Asignado'" type="button" v-b-tooltip.hover.Top title="Realizar Entrega" @click="goDelivery(lista)" class="btn btn-warning btn-sm">
                            <i class="far fa-money-check-edit-alt"></i>
                        </button>
                        <button v-else type="button" v-b-tooltip.hover.Top title="Verificar Entrega" @click="goDelivery(lista)" class="btn btn-primary btn-sm">
                            <i class="far fa-file-invoice-dollar"></i>
                        </button>

                        <button v-if="segurity.ModMontoEntrega" type="button" v-b-tooltip.hover.Top @click="modEntrega(lista)" title="Modificar Monto Entregado" class=" ml-1 btn btn-secondary btn-sm">
                            <i class="fas fa-sack-dollar"></i>
                        </button>
                    </td>
				</tr>
				<CSinRegistros :pContIF="ListaArrayRows.length" :pColspan="12"></CSinRegistros>
			</template>
		</CList>

		<CModal :pConfigModal="ConfigModal" :poBtnSave="oBtnSave">
			<template slot="Form">
				<Form :poBtnSave="oBtnSave"></Form>
			</template>
		</CModal>

        <CModal :pConfigModal="ConfigModalCorreccionMonto" :poBtnSave="oBtnSaveCorreccionMonto">
			<template slot="Form">
				<FormModEntrega :poBtnSave="oBtnSaveCorreccionMonto"/>
			</template>
		</CModal>
	</div>
</template>

<script>

	import Form       	    from "@/views/modulos/crm/prestamos/FormReActivar.vue";
	import CValidation      from '@/components/CValidation.vue';
    import CList 		    from "../../../../components/CList";
    import CModal 		    from "../../../../components/CModal";
    import CSinRegistros    from "../../../../components/CSinRegistros";
    import CAvatar 		    from "../../../../components/CAvatar";
    import Template         from "../../../template/Template";
    import FormModEntrega   from "./FormModEntrega.vue";

    import moment           from "moment";
	const EmitEjecuta 	  =   "SeccionPorEntregar";
	const EmitEjecuta2 	  =   "SeccionModMontoEntrega";

	export default {
		name: "ListaClientes",
		components: {
            Template,
			Form,
            CValidation,
            CList,
            CModal,
            CSinRegistros,
            CAvatar,
            FormModEntrega
		},
		data() {
			return {
                counterField: 	1,
				ListaArrayRows:   [],
                listEntregadores: [],
				errorvalidacion:  [],
				segurity: 		  {},
				obj: 	  		  {},
                ShowCmbSucursales: false,
                ListaSucursalesArray: [],

				ConfigList: {
					Title: 			"Entregas de Prestamos",
					IsModal: 		true,
					ShowLoader: 	true,
					BtnReturnShow: 	true,
					BtnNewShow: 	true,
					BtnNewName: 	'Desasignar',
					EmitSeccion: 	EmitEjecuta,
					TitleFirst: 	'Menús Prestamos',
				},
				Filtro: {
					Nombre: 	 "",
					Pagina: 	 1,
					Entrada: 	 25,
					TotalItem: 	 0,
					Placeholder: "Buscar..",
                    IdUsuario: 	 0,
                    FechaI: '',
                    FechaF: '',
                    IdSucursal: 0,

				},
				ConfigModal: {
					ModalTitle:  "Desasignación",
                    ModalNameId: 'ModalForm',
					ModalSize:   'xl',
					EmitSeccion: EmitEjecuta,
				},
				oBtnSave: {
					toast: 	   	 0,
					IsModal: 	 true,
					DisableBtn:  false,
					EmitSeccion: EmitEjecuta,
				},

                ConfigModalCorreccionMonto: {
					ModalTitle:  "Correccion Monto Entregado",
                    ModalNameId: 'ModalForm2',
					ModalSize:   'md',
					EmitSeccion: EmitEjecuta2,
				},

				oBtnSaveCorreccionMonto: {
					toast: 	   	 0,
					IsModal: 	 true,
					DisableBtn:  false,
					EmitSeccion: EmitEjecuta2,
				},

                range: {

                },
                modelConfig: {
                    type: 'string',
                    mask: 'YYYY-MM-DD',
                },
                timezone:'America/Mexico_City'

			};
		},
		methods: {
            ListaSucursales() {
                this.$http.get("sucursales", {
                    params: {
                        simple: 1
                    },
                })
                    .then((res) => {
                        let response = res.data.dataSuc;
                        this.ListaSucursalesArray = res.data.data;

                        if(response.EsAdmin == 0)
                        {
                            this.Filtro.IdSucursal = response.IdSucursal;
                            sessionStorage.setItem('IdSucursal',this.Filtro.IdSucursal);
                        }
                        else
                        {
                            //this.ShowCmbSucursales = true;

                            if(this.ListaSucursalesArray.length>0)
                            {
                                this.Filtro.IdSucursal = this.ListaSucursalesArray[0].IdSucursal;
                                sessionStorage.setItem('IdSucursal',this.Filtro.IdSucursal);
                            }
                        }
                    }).finally(()=>{
                        this.ListaPerfil();
                        
                });
            },

            async ListaPerfil() {
                this.listEntregadores = [];

                await this.$http.get('getusersbyperfil', {
                    params:{
                        IdPerfil: 3,
                        IdSucursal: this.Filtro.IdSucursal,
                    }
                }).then( (res) => {
                    this.listEntregadores = res.data.data.map(item => ({
                        IdUsuario: item.IdUsuario,
                        NombreCompleto: this.$limitCharacters(item.NombreCompleto,20)
                    }));
                }).finally(() => {
                    this.setWeekValue();
                })
            },
			async Lista() {
				this.ConfigList.ShowLoader = true;

				await this.$http.get("prestamosasignado", {
                    params: {
                        TxtBusqueda: 	    this.Filtro.Nombre,
                        Entrada: 		    this.Filtro.Entrada,
                        Pag: 			    this.Filtro.Pagina,
                        EstatusPrestamoIn:  JSON.stringify(["Asignado","Entregado"]),
                        IdUsuario: 		    this.Filtro.IdUsuario,
                        IdSucursal: 		this.Filtro.IdSucursal,
                        FechaIni:           this.range.start !== null && this.range.start !== "" ? moment(this.range.start).format('YYYY-MM-DD') : '',
                        FechaEnd:           this.range.end !== null && this.range.end !== "" ? moment(this.range.end).format('YYYY-MM-DD') : '',
                    },
                }).then((res) => {
                    this.ListaArrayRows   = res.data.data.data;
                    this.Filtro.Pagina    = res.data.data.current_page;
                    this.Filtro.TotalItem = res.data.data.total;
					this.segurity         = res.data.segurity;
                    this.$setStartItem();

                }).finally(() => {
                    this.ConfigList.ShowLoader = false;
                });
			},

            setWeekValue() {
                //this.startDate = new Date(),
                //this.finishDate	=  moment(this.startDate).add(6, 'days').format('YYYY-MM-DD');

                //SE OBTIENE EL PRIMER DIA DEL MES
                let startDate = moment()
                    .startOf("month")
                    //.add(1, "days")
                    .toDate();

                //SE FORMATEA EL DIA ACTUAL
                let setDiaActual = moment(new Date())
                    //.endOf("week")
                    .toDate();

                let realFinishDate = moment(setDiaActual)
                    .format("YYYY-MM-DD");

                this.range = {
                    start: startDate,
                    end: realFinishDate
                }

                this.Lista();
            },

			Regresar() {
				this.$router.push({ name: 'menuprestamos', params: {}
				});
			},

            irViewPrestamo(item) {
                this.$router.push({name:'formdetalleprestamo',params:{Id:item.IdPrestamo,pSitio:'ENTREGAS'}});
            },

            goDelivery(item) {
                this.$router.push({name:'formentregas',params:{pPrestamo:item}});
            },

            modEntrega(item) {
                this.bus.$emit('NewModal_'+EmitEjecuta2,item);
            }

		},
		created() {
			this.bus.$off("List_" + EmitEjecuta);

		},
		mounted() {
            this.ListaSucursales();
            //this.Lista();

			this.bus.$on("List_" + EmitEjecuta, () => {
				this.Lista();
			});

			this.bus.$on('EmitReturn', () => {
				this.Regresar();
			});
		},
	};

</script>

