<template>
	<div>
		<CList @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
			<template slot="header">
				<th class="td-sm"></th>
				<th>#</th>
				<th>Monto de la Multa</th>
				<th class="text-center">Acciones</th>
			</template>

			<template slot="body">
				<tr v-for="(lista, index) in ListaArrayRows" :key="index">
					<td class="td-sm"></td>
					<td>{{ index + 1 }}</td>
					<td>{{ lista.MontoMulta }}</td>
					<td class="text-center">
						<CBtnAccion pGoRoute="corteDiaPagos" :pShowBtnEdit="true" :pShowBtnDelete="false" :pIsModal="true" :pId="lista.IdMultas" :pEmitSeccion="ConfigList.EmitSeccion" :segurity="segurity">
							<template slot="btnaccion">
								
							</template>
						</CBtnAccion>
					</td>
				</tr>
				<CSinRegistro :pContIF="ListaArrayRows.length" :pColspan="4" ></CSinRegistro>
			</template>
		</CList>

        <CModal :pConfigModal="ConfigModal" :poBtnSave="oBtnSave">
			<template slot="Form">
				<Form :poBtnSave="oBtnSave"></Form>
			</template>
		</CModal>
	</div>
</template>

<script>

	import Configs 	  from "@/helpers/VarConfig.js";
	import Form 	  from '@/views/catalogos/multas/Form.vue';
	const EmitEjecuta = "seccionMulta";

	export default {
		name: 		"ListaMulta",
		components: {Form},
		data() {
			return {
				ListaArrayRows: [],
				segurity: 		{},
				obj: 	  		{},
				ConfigList: {
					Title: 			"Listado de Multas",
					IsModal: 		true,
					ShowLoader: 	true,
					BtnReturnShow:  false,
					EmitSeccion: 	EmitEjecuta,
					TitleFirst:     'Inicio',
					BtnNewShow:     false,
				},
				Filtro: {
					Nombre: 	 "",
					Pagina: 	 1,
					Entrada: 	 25,
					TotalItem: 	 0,
					Placeholder: "Buscar..",
				},
				ConfigModal:{
					ModalTitle:  "Multas",
					ModalNameId: 'ModalForm',
					EmitSeccion:  EmitEjecuta,
				},
				oBtnSave: {
					toast: 		 0,
					IsModal: 	 true,
					DisableBtn:  false,
					EmitSeccion: EmitEjecuta,
				}, 
			};
		},
		methods: {
			async Lista() {
				this.ConfigList.ShowLoader = true;

				await this.$http.get("getMultas", {
					params: {
						TxtBusqueda: this.Filtro.Nombre,
						Entrada: 	 this.Filtro.Entrada,
						Pag: 		 this.Filtro.Pagina
					},
				})
				.then((res) => {
					this.ListaArrayRows   = res.data.data.Multas.data;
					this.Filtro.Pagina    = res.data.data.Multas.current_page;
					this.Filtro.TotalItem = res.data.data.Multas.total;
					this.segurity         = res.data.segurity;
				})
				.finally(() => {
					this.ConfigList.ShowLoader = false;
				});
			},
		},
		created() {
			this.bus.$off("List_" + EmitEjecuta);
		},
		mounted() {
			this.Lista();

			this.bus.$on("List_" + EmitEjecuta, () => {
				this.Lista();
			});
			
		},
	};

</script>
