<template>
    <div>
		<CList @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
            <template slot="Filtros">
                <select id="IdSucursal" v-model="Filtro.IdSucursal" @change="Lista();" v-show="ShowCmbSucursales" class="form-control form-select mr-2">
						<option v-for="(item, index) in ListaSucursalesArray" :key="index" :value="item.IdSucursal" >
							{{ item.Nombre }}
						</option>
					</select>

                <label  class="mr-1">Estatus</label>
				<select v-model="Filtro.Estatus" class="form-control form-select mr-2" @change="Lista" style="width: 130px;">
					<option value="Activo">Activo</option>
					<option value="Inactivo">Inactivo</option>
				</select>

                <button @click="VistaLista()" type="button" v-bind:class="[ConfigList.TypeBody == 'List' ? 'focus':'']" class="btn btn-sm btn-outline-control mr-1">
                    <i class="fas fa-th-list"></i>
                </button>

                <button @click="VistaCard()" type="button" class="btn btn-sm btn-outline-control mr-1" v-bind:class="[ConfigList.TypeBody == 'Form' ? 'focus':'']">
                    <i class="fas fa-th-large"></i>
                </button>

                <button @click="getPdfListado()" type="button" title="Imprimir Listado" class="btn btn-primary btn-sm">
                    <i class="far fa-print"></i>
                </button>

            </template>

            <template v-if="VistaLista" slot="header">

                <th>#</th>
                <th>Nombre Completo</th>
                <th>Correo</th>
                <th>Telefono</th>
                <th>Perfil</th>
                <th>Puesto</th>
                <th>Estatus</th>
                <th class="text-center">Acciones</th>
            </template>

            <template v-if="VistaLista" slot="body">
                <tr v-for="(lista,index) in ListaArrayRows" :key="index" >

                    <td>{{ $getNumItem(index) }}</td>
                    <td>{{lista.NombreCompleto }}</td>
                    <td>{{lista.Correo }}</td>
                    <td>{{lista.Telefono }}</td>
                    <td>{{lista.perfil.Nombre }}</td>
                    <td>{{lista.puesto.Nombre }}</td>
                    <td>
                        <b-badge pill variant="success" v-if="lista.Estatus === 'Activo'">{{ lista.Estatus }}</b-badge>
                        <b-badge pill variant="danger" v-else>{{ lista.Estatus }}</b-badge>
                    </td>
                    <td class="text-center">
                        <CBtnAccion :pShowBtnEdit="ValidatebuttonEditar(lista)" :pShowBtnDelete="Validatebuttons(lista)" :pIsModal="false" :pId="lista.IdUsuario" :pEmitSeccion="ConfigList.EmitSeccion" pGoRoute="empleadosave" :segurity="segurity">
							<template slot="btnaccion" >
                                <template v-if="segurity.ChangePass">
                                    <button type="button" v-if="Validatebuttons(lista)" @click="CambiarPassword(lista)" v-b-tooltip.hover.Top title="Cambiar Accesos" class="btn btn-success btn-icon mr-1">
                                        <i class="fa fa-key"></i>
                                    </button>
                                </template>

                                <template v-if="segurity.Unsubscribe">
                                    <button type="button" v-if="Validatebuttons(lista)" @click="DarDeBajaUsuario(lista)" v-b-tooltip.hover.Top title="Dar de Baja" class="btn btn-warning btn-icon mr-1">
                                        <i class="fa fa-user-slash"></i>
                                    </button>
                                </template>

                                <template v-if="segurity.Finiq">
                                    <template v-if="lista.Estatus=='Inactivo' && (lista.empleados.Finiquito=='' || lista.empleados.Finiquito==null)">
                                        <button type="button" @click="GenerarLiquidacion(lista)" class="btn btn-warning btn-icon mr-1" v-b-tooltip.hover.Top title="Liquidación">
                                            <i class="fa fa-money-bill"></i>
                                        </button>
                                    </template>
                                </template>

                                <template v-if="segurity.ReactEmpleado">
                                    <template v-if="lista.Estatus=='Inactivo' && (lista.empleados.Finiquito!='' || lista.empleados.Finiquito!=null)">
                                        <button type="button" @click="ReactivarUsuario(lista)" class="btn btn-success btn-icon mr-1" v-b-tooltip.hover.Top title="Reactivar">
                                            <i class="fa fa-unlock"></i>
                                        </button>
                                    </template>
                                </template>

                                <template v-if="segurity.CVEmpleado">
                                    <button type="button" @click="getPdfCurriculumVitae(lista);" class="btn btn-info btn-icon" v-b-tooltip.hover.Top title="Curriculum Vitae">
                                        <i class="fa fa-file-user"></i>
                                    </button>
                                </template>

                            </template>
						</CBtnAccion>
                    </td>
                </tr>
                <CSinRegistro :pContIF="ListaArrayRows.length" :pColspan="9"></CSinRegistro>
            </template>

            <template v-if="VistaCard" slot="bodyForm">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3" v-for="(lista,index) in ListaArrayRows" :key="index">
                        <div class="card card-max position-relative">
                            <CBtnAccionForm :pShowBtnEdit="ValidatebuttonEditar(lista)" :pShowBtnDelete="Validatebuttons(lista)" :pIsModal="false" :pId="lista.IdUsuario" :pEmitSeccion="ConfigList.EmitSeccion" pGoRoute="empleadosave" :segurity="segurity">
                                <template slot="btnaccion">
                                    <template v-if="segurity.ChangePass">
                                        <a class="dropdown-item" @click="CambiarPassword(lista)" v-if="Validatebuttons(lista)">
                                            <i class="fa fa-key text-success fa-1x fa-fw"></i>
                                            Cambiar Accesos
                                        </a>
                                    </template>
                                    <template v-if="segurity.Unsubscribe">
                                        <a class="dropdown-item" @click="DarDeBajaUsuario(lista)" v-if="Validatebuttons(lista)">
                                            <i class="fas fa-user-slash texto-warning fa-1x fa-fw"></i>
                                            Dar de Baja
                                        </a>
                                    </template>

                                    <template v-if="segurity.Finiq">
                                        <template v-if="lista.Estatus=='Inactivo' && (lista.empleados.Finiquito=='' || lista.empleados.Finiquito==null)">
                                            <a class="dropdown-item" @click="GenerarLiquidacion(lista)">
                                                <i class="fa fa-money-bill texto-warning fa-1x fa-fw"></i>
                                                Liquidación
                                            </a>
                                        </template>
                                    </template>

                                    <template v-if="segurity.ReactEmpleado">
                                        <template v-if="lista.Estatus=='Inactivo' && (lista.empleados.Finiquito!='' || lista.empleados.Finiquito!=null)">
                                            <a class="dropdown-item" @click="ReactivarUsuario(lista)">
                                                <i class="fa fa-unlock text-success fa-1x fa-fw"></i>
                                                Reactivar
                                            </a>
                                        </template>
                                    </template>

                                    <template v-if="segurity.CVEmpleado">
                                        <a class="dropdown-item" @click="getPdfCurriculumVitae(lista)">
                                            <i class="fa fa-file-user text-info fa-1x fa-fw"></i>
                                            Curriculum Vitae
                                        </a>
                                    </template>
                                </template>
                            </CBtnAccionForm>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 text-center">
                                        <img v-if="lista.UrlImg === ''" src="@/assets/style/image/avatar.png" alt="" class="img-fluid" style="height: 100px; max-width: 100%;">
                                        <img v-else :src="RutaFoto+lista.UrlImg" alt="" class="img-fluid" style="height: 100px; max-width: 100%;">
                                    </div>
                                    <div class="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 pl-0">
                                        <div class="form-group">
                                            <h2 class="titulo-nombre">{{$limitCharacters(lista.NombreCompleto,20)}}</h2>
                                        </div>
                                        <div class="form-group form-row">
                                            <div class="col">
                                                <span>
                                                    <i class="fas fa-user"></i>
                                                    {{ lista.username }}
                                                </span>
                                            </div>
                                            <div class="col">
                                                <span>
                                                    <i class="fad fa-mobile-alt"></i>
                                                    {{lista.Telefono }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <span>
                                                <i class="fas fa-envelope"></i>
                                                {{lista.Correo}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-if="ListaArrayRows == 0">
                        <h6 class="text-center col-form-h1">No contiene registros</h6>
                    </div>
                </div>
            </template>
        </CList>

        <CModal :pConfigModal="ConfigModal" :poBtnSave="oBtnSave">
			<template slot="Form">
				<FormBaja :poBtnSave="oBtnSave"></FormBaja>
			</template>
		</CModal>

        <CModal :pConfigModal="ConfigModalLiq" :poBtnSave="oBtnSaveLiq">
			<template slot="Form">
				<FormFiniquito :poBtnSave="oBtnSaveLiq"></FormFiniquito>
			</template>
		</CModal>

        <CModal :pConfigModal="ConfigModalPass" :poBtnSave="oBtnSavePass">
			<template slot="Form">
				<FormPassword :poBtnSave="oBtnSavePass"></FormPassword>
			</template>
		</CModal>
	</div>
</template>

<script>

    import Configs         from '@/helpers/VarConfig.js';
    import FormBaja        from '@/views/modulos/empresa/empleados/FormBaja.vue';
    import FormFiniquito   from '@/views/modulos/empresa/empleados/FormFiniquito.vue';
    import FormPassword    from '@/views/modulos/empresa/empleados/FormPassword.vue';
    const  EmitEjecuta     =    'seccionEmpleadosList';
    const  EmitBaja        =    'seccionDarDeBaja';
    const  EmitLiquidacion =    'seccionLiquidacion';
    const  EmitPassword    =    'seccionPassword';

    export default {
        name:  'ListaEmpleados',
        props: ['sFiltros'],
        components: {
            FormBaja,
            FormFiniquito,
            FormPassword,
        },
        data() {
            return {
                counterField: 	1,
                RutaFoto:       '',
                ListaArrayRows: [],
                ListaHeader:    [],
                segurity:       {},
                obj:            {},
                ConfigList:{
                    Title:         'Listado de Empleados',
                    IsModal:       false,
                    ShowLoader:    true,
                    BtnReturnShow: false,
                    EmitSeccion:   EmitEjecuta,
                    TypeBody:      'List',
                    GoRoute:       'empleadosave',
                },
                Filtro:{
                    Nombre:      '',
                    Pagina:      1,
                    Entrada:     25,
                    TotalItem:   0,
                    Placeholder: 'Buscar..',
                    Estatus:	 'Activo',
                    IdSucursal: 0
                },
                ConfigModal:{
                    ModalTitle:  "Dar de baja al Usuario",
                    ModalNameId: 'ModalForm',
                    EmitSeccion:  EmitBaja,
                    ModalSize:   'md',
                },
                oBtnSave: {
                    toast: 0,
                    IsModal: true,
                    DisableBtn: false,
                    EmitSeccion: EmitBaja,
                },
                ConfigModalLiq:{
                    ModalTitle:  "Generar Liquidación",
                    ModalNameId: 'ModalForm2',
                    EmitSeccion:  EmitLiquidacion,
                    ModalSize:   'sm',
                },
                oBtnSaveLiq: {
                    toast: 0,
                    IsModal: true,
                    DisableBtn: false,
                    EmitSeccion: EmitLiquidacion,
                },
                ConfigModalPass:{
                    ModalTitle:  "Cambiar Accesos",
                    ModalNameId: 'ModalForm3',
                    EmitSeccion:  EmitPassword,
                    ModalSize:   'md',
                },
                oBtnSavePass: {
                    toast: 0,
                    IsModal: true,
                    DisableBtn: false,
                    EmitSeccion: EmitPassword,
                },
                ListaSucursalesArray:[],
                ShowCmbSucursales: false,
            }
        },
        methods: {
            ListaSucursales() {
                this.$http.get("sucursales", {
                    params: {
                        simple: 1
                    },
                })
                    .then((res) => {
                        let response = res.data.dataSuc;
                        this.ListaSucursalesArray = res.data.data;

                        if(response.EsAdmin == 0)
                        {
                            this.Filtro.IdSucursal = response.IdSucursal;

                        }
                        else
                        {
                            this.ShowCmbSucursales = true;

                            if(this.ListaSucursalesArray.length>0)
                            {
                                this.Filtro.IdSucursal = this.ListaSucursalesArray[0].IdSucursal;
                            
                            }
                        }
                    }).finally(()=>{
                        this.Lista();
                });
            },

            async Lista(){
                this.ConfigList.ShowLoader = true;
                this.ListaArrayRows = [];
                console.log(this.Filtro.IdSucursal);

                if(this.Filtro.IdSucursal !== 0 && this.Filtro.IdSucursal !== ''){
                    await this.$http.get('users', {
                        params:{
                            TxtBusqueda: this.Filtro.Nombre,
                            Entrada:     this.Filtro.Entrada,
                            Pag:         this.Filtro.Pagina,
                            Estatus: 	 this.Filtro.Estatus,
                            IdSucursal:  this.Filtro.IdSucursal
                        }
                        }).then( (res) => {
                            this.ListaArrayRows   = res.data.data.usuarios.data;
                            this.Filtro.Pagina    = res.data.data.usuarios.current_page;
                            this.Filtro.TotalItem = res.data.data.usuarios.total;
                            this.RutaFoto         = res.data.RutaFoto;
                            this.segurity         = res.data.segurity;
                            this.$setStartItem();

                        }).finally(()=>{
                            this.ConfigList.ShowLoader = false;
                            this.$saveFilters('SET');
                        });
                }
                
            },

            Eliminar(Id){
                this.$swal(Configs.configEliminar).then((result) => {
                    if(result.value) {
                        this.$http.delete('users/'+Id).then( (res) => {
                            this.$swal(Configs.configEliminarConfirm);
                            this.Lista();
                        })
                        .catch( err => {
                            this.$toast.error(err.response.data.message);
                        });
                    }
                });
            },
            DarDeBajaUsuario(item) {
                /* this.ConfigModal.ModalSize = 'md';

                if(item.IdPerfil == 4){
                    this.ConfigModal.ModalSize = 'xl';
                } */

                this.bus.$emit('NewModal_'+EmitBaja,item);
            },
            VistaLista() {
                this.ConfigList.TypeBody      = 'List';
            },
            VistaCard() {
                this.ConfigList.TypeBody      = 'Form';
            },
            ValidatebuttonEditar(data) {
                let vEstatus = '';

                if (data.Estatus === 'Inactivo') {
                    vEstatus = false;
                    if(data.empleados.Finiquito !='' && data.empleados.Finiquito!=null)
                    {
                        vEstatus = true;
                    }
                } else if (data.Estatus === 'Activo') {
                    vEstatus = true;
                } else {
                    vEstatus = false;
                }

                return vEstatus;
            },
            Validatebuttons(data) {
                let vEstatus = '';

                if (data.Estatus === 'Inactivo') {
                    vEstatus = false;
                } else {
                    vEstatus = true;
                }

                return vEstatus;
            },
            GenerarLiquidacion(data)
            {
                this.bus.$emit('NewModal_'+EmitLiquidacion,data);
            },
            ReactivarUsuario(lista)
            {
                this.$swal({
                    title: '¿Reactivar Empleado?',
                    text: 'Esta a punto volver a activar al empleado, ¿desea continuar?',
                    icon: 'warning',
                    confirmButtonText: 'Continuar',
                    cancelButtonText: 'Cancelar',
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonColor: '#0096ED',
                    cancelButtonColor: '#b92e27',
                }).then((result) =>
                {
                    if(result.value)
                    {
                        this.$http.get('userReactivacion', {
                            params:{
                                IdUsuario: lista.IdUsuario,
                                IdEmpleado: lista.IdEmpleado,
                            }
                        }).then( (res) => {
                            this.$swal('Usuario Reactivado','','success');
                            this.Lista();
                        }).catch( err => {
                            this.$toast.error(err.response.data.message);
                        });
                    }
                });
            },
            CambiarPassword(data)
            {
                this.bus.$emit('NewModal_'+EmitPassword,data);
            },
            getPdfCurriculumVitae(lista){
                let urlPdf = `${this.$http.defaults.baseURL}/getPdfCurriculumVitae?IdUsuario=${lista.IdUsuario}`;
                window.open(urlPdf,'_blank');
            },
            getPdfListado(){

                let urlPdf = `${this.$http.defaults.baseURL}/getPdflistadoempleados?TxtBusqueda=${this.Filtro.Nombre}&Estatus=${this.Filtro.Estatus}`;
                window.open(urlPdf,'_blank');

            },
        },
        created() {

            if(this.sFiltros===undefined) {
                this.$saveFilters('GET');
            }

            this.bus.$off('Delete_'+EmitEjecuta);
            this.bus.$off('List_'+EmitEjecuta);
        },
        mounted() {
            this.ListaSucursales();
            

            this.bus.$on('Delete_'+EmitEjecuta,(Id)=> {
                this.Eliminar(Id);
            });

            this.bus.$on('List_'+EmitEjecuta,()=> {
                this.Lista();
            });
        },
    }

</script>
