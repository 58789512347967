<template>
    <div>
        <div class="form-row">


            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <label for="MontoEntregado">Monto de Entrega <span class="text-danger">*</span></label>
                    <select id="MontoEntregado" v-model="modificacionEntrega.MontoEntregado" name="MontoEntregado" class="form-control form-select">
                        <option value="0">--Seleccionar--</option>
                        <option v-for="(item,index) in ListaMontosPrestamosArray" :key="index" :value="item.Monto">{{item.Monto}}</option>
                    </select>
                    <label>
                        <CValidation v-if="this.errorvalidacion.MontoEntregado" :Mensaje="'*' + errorvalidacion.MontoEntregado[0]"></CValidation>
                    </label>
                </div>

            <div  class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <label> Motivo de cambio de Monto <span class="text-danger">*</span> </label>
                <textarea placeholder=" Coloque sus Observaciones" v-model="modificacionEntrega.MotivoCorreccion" class="form-control" cols="12" rows="3"></textarea>
                <CValidation v-if="this.errorvalidacion.MotivoCorreccion" :Mensaje="'*'+errorvalidacion.MotivoCorreccion[0]"></CValidation>
            </div>
        </div>
        
    </div>
</template>

<script>

    import Configs     from "@/helpers/VarConfig.js";
    import CValidation from '@/components/CValidation.vue'

    export default {
        name:  "FormModEntrega",
        props: ['poBtnSave'],
        components: {CValidation},
        data() {
            return {
                blockEdit:      false,
                RutaFile:       '',
                ValidElement:   Configs.validImage2m,
                Emit:           this.poBtnSave.EmitSeccion,
                errorvalidacion: [],
                ListaMontosPrestamosArray:[],

                objPrestamo:{
                    IdCliente: 0,
                    IdPrestamo: 0
                },

                modificacionEntrega: {
                    IdPrestamo:         0,
                    MontoEntregado:     0,
                    MotivoCorreccion:   "",
                },
            };
        },
        methods: {
            ListaMontosPrestamo() {
                this.$http.get("prestamosmontos", {
                    params: {
                        simple: 1
                    },
                }).then((res) => {
                    this.ListaMontosPrestamosArray = res.data.data;
                });
            },

            Guardar() {
                this.poBtnSave.toast      = 0;
                this.poBtnSave.DisableBtn = true;
                this.errorvalidacion      = [];

                this.modificacionEntrega.IdPrestamo     = this.objPrestamo.IdPrestamo;

                this.$http.post('modificaprestamoentrega', this.modificacionEntrega)
                .then( (res) => {
                    this.EjecutaConExito(res);
                }).catch((err) => {
                    this.EjecutaConError(err);
                });
            },
            EjecutaConExito(res) {
                this.poBtnSave.DisableBtn = false;
                this.poBtnSave.toast      = 1;
                this.bus.$emit('CloseModal_' + this.Emit);
                this.bus.$emit('List_SeccionPorEntregar');

            },
            EjecutaConError(err) {
                this.poBtnSave.DisableBtn = false;

                if (err.response.data.error) {
                    this.errorvalidacion = err.response.data.error;
                    this.poBtnSave.toast = 2;
                } else {
                    this.$toast.error(err.response.data.message);
                }

            },

            limpiar() {
               
                this.modificacionEntrega = {
                    IdPrestamo:         0,
                    MontoEntregado:     "",
                    MotivoCorreccion:   "",
                    TipoComentario:     ""
                };
            },
        },
        created() {
            this.limpiar();
            this.ListaMontosPrestamo();

            this.poBtnSave.toast = 0;

            this.bus.$off('Recovery_' + this.Emit);
            this.bus.$on('Recovery_' + this.Emit, (obj) => {

                this.bus.$off('Save_' + this.Emit);
                this.bus.$on('Save_' + this.Emit, () => {
                    this.Guardar();
                });

                if (obj !== '') {

                    this.objPrestamo = obj;
                    this.modificacionEntrega.MontoEntregado = (this.objPrestamo.MontoEntregado !== null && this.objPrestamo.MontoEntregado !== undefined) ? this.objPrestamo.MontoEntregado : 0; 

                }

            });
        },
    }

</script>
