<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
                <div class="container-fluid">
                    
                    <div class="form-group row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <label for="Observaciones">Observaciones</label>
							<textarea v-model="objComentario.Observaciones" class="form-control" id="Observaciones" placeholder="Ingresar Observaciones" rows="3"></textarea>
							<CValidation v-if="this.errorvalidacion.Observaciones" :Mensaje="'*'+errorvalidacion.Observaciones[0]"/>
                        </div><!--fin col-12-->
                    </div>
                    
                </div>
            </form>
        </template>
    </CLoader>
</template>

<script>
import moment from "moment"

export default {
    name:"FormComentarioReporte",
    props:['poBtnSave'],
    components:{
    },
    data() {
        return {
            Emit: this.poBtnSave.EmitSeccion,

            errorvalidacion: [],
            pFechaReporte:         {},
            ConfigLoad:{
                ShowLoader: true,
                ClassLoad:  false,
            },

            objComentario:{
                FechaReporte: '',
                IdCreador: 0,
                Observaciones: '',
            },
            masks: {
                input: "YYYY-MM-DD",
            },
        }
    },
    methods :
    {
        async Guardar()
        {
           /* let Fecha1 = '';
			if(this.reportesdiarioscomentarios.FechaBaja!=''){
                Fecha1 = moment(this.reportesdiarioscomentarios.FechaBaja).format('YYYY-MM-DD');
            }*/

            this.errorvalidacion      = [];
            this.poBtnSave.toast      = 0; 
            this.poBtnSave.DisableBtn = true;

            this.$http.post('reportesdiarioscomentarios',this.objComentario)
            .then((res)=>{
                this.bus.$emit('getReporteDiario');                 
                this.EjecutaConExito(res);
                
            }).catch(err=>{   
                this.EjecutaConError(err);
            });
        },
        EjecutaConExito(res)
        {
            this.poBtnSave.DisableBtn = false;  
            this.poBtnSave.toast      = 1;

            if(res.data.data == 1)
            {
                this.objComentario.IdReporteDiarioComentario = 0;
                this.bus.$emit('CloseModal_'+this.Emit);
                
            }
            else
            {
                this.bus.$emit('CloseModal_'+this.Emit);
               
            }
        },
        EjecutaConError(err)
        {
            this.poBtnSave.DisableBtn = false;
            
            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.poBtnSave.toast = 2;
            }
            else{
                this.$toast.error(err.response.data.message);
            }
        },
        Limpiar()
        {
            this.objComentario = {
                FechaReporte: '',
                IdCreador: 0,
                Observaciones: '',
            },
            this.errorvalidacion = [];
            this.poBtnSave.toast      = 0;
        },
    },
    created() {
        this.poBtnSave.toast = 0;
       

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(obj)=> {
            this.ConfigLoad.ShowLoader = true;    
            this.poBtnSave.DisableBtn  = false;

            this.bus.$off('Save_'+this.Emit);

            this.bus.$on('Save_'+this.Emit,()=> {
                this.Guardar();
            });

            this.Limpiar();
            this.pFechaReporte = obj;

            if(this.pFechaReporte != undefined && this.pFechaReporte != ''){
                this.objComentario.FechaReporte = this.pFechaReporte;
                this.ConfigLoad.ShowLoader = false;
                

            }else {
                this.ConfigLoad.ShowLoader = false;
            }
            
            
        });
    },
}
</script>