
import Cobratarios 	from "../views/modulos/cobranza/cobratarios/List.vue";
import DiaSiguiente from "../views/modulos/cobranza/diaSiguiente/List.vue";
import CortedelDia 	from "../views/modulos/cobranza/cortedeDia/List.vue";
import EstadoDeCuentaView from "../views/modulos/cobranza/ViewEstadoCuenta.vue";
import EstadoDeCuenta from "../views/modulos/cobranza/EstadoCuenta.vue";
import DiaCero from "../views/modulos/cobranza/DiaCero.vue";
import Suplencia from "../views/modulos/cobranza/DiaCero.vue";
import CorreccionPagos from "../views/modulos/cobranza/CorreccionPagos.vue";

const VarRutasCobranza = [
	{
		path: "/Cobratarios",
		name: "cobratarios",
		component: Cobratarios,
		props: true,
	}, {
		path: "/DiaSiguiente",
		name: "diasiguiente",
		component: DiaSiguiente,
		props: true,
	}, {
		path: "/CortedelDia",
		name: "cortedeldia",
		component: CortedelDia,
		props: true,
	}, {
		path: "/EstadoDeCuentaCobranza",
		name: "estadoCuentaCobranza",
		component: EstadoDeCuentaView,
		props: true,
	},
	{
		path: "/DiaInicial",
		name: "diaCero",
		component: DiaCero,
		props: true,
	},
	{
		path: "/Suplencia",
		name: "suplencia",
		component: Suplencia,
		props: true,
	},
	{
		path: "/EstadoDeCuentaGeneral",
		name: "listEstadoCuenta",
		component: EstadoDeCuenta,
		props: true,
	},
	{
		path: "/CorreccionPagos",
		name: "correccionpagos",
		component: CorreccionPagos,
		props: true,
	},
];

export default {
	VarRutasCobranza,
};
