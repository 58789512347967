<template>
    <div>
        <CList @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
            <template slot="Filtros">
				<div class="form-group" v-show="ShowCmbSucursales">
                    <select id="IdSucursal" @change="Lista" v-model="IdSucursal" class="form-control form-select mr-2">
						<option v-for="(item, index) in ListaSucursalesArray" :key="index" :value="item.IdSucursal" >
							{{ item.Nombre }}
						</option>
					</select>
                </div>
            </template>
            <template slot="header">
                <th class="td-sm"></th>
                <th>#</th>
                <th>Sucursal</th>
                <th>Ruta</th>
                <th>Gestor</th>
                <th>Estatus</th>
                <th class="text-center">Acciones</th>
            </template>

            <template slot="body">
                <tr v-for="(lista,index) in ListaArrayRows" :key="index" >
                    <td class="td-sm"></td>
                    <td>{{ $getNumItem(index) }}</td>
                    <td>{{ lista.sucursal.Nombre}}</td>
                    <td>{{ lista.NombreRuta}}</td>
                    <td>{{ lista.NombreCompleto}}</td>
                    <td>
                        <span class="badge badge-success" v-if="lista.EstatusAct>0">Activo</span>
                        <span class="badge badge-secondary" v-else>Inactivo</span>
                    </td>
                    <td class="text-center">
                        <CBtnAccion :pShowBtnEdit="true" :pShowBtnDelete="true" :pIsModal="true" :pId="lista.IdRuta" :pEmitSeccion="ConfigList.EmitSeccion" :segurity="segurity">
							<template slot="btnaccion">
                                <button type="button" v-if="segurity.ViewSection" @click="AsignarPersonal(lista);" v-b-tooltip.hover.Top title="Asignar Personal" class="btn btn-icon btn-warning mr-1">
                                    <i class="fa fa-biking"></i>
                                </button>

                                <button type="button" v-if="segurity.ViewSection" @click="SuplirPersonal(lista);" v-b-tooltip.hover.Top title="Suplencia" class="btn btn-icon btn-success">
                                    <i class="fa fa-flag"></i>
                                </button>
                            </template>
						</CBtnAccion>
                    </td>
                </tr>
                <CSinRegistro :pContIF="ListaArrayRows.length" :pColspan="6"></CSinRegistro>
            </template>
        </CList>

        <CModal :pConfigModal="ConfigModal" :poBtnSave="oBtnSave">
			<template slot="Form">
				<Form :poBtnSave="oBtnSave"></Form>
			</template>
		</CModal>

        <CModal :pConfigModal="ConfigModal2" :poBtnSave="oBtnSave2">
			<template slot="Form">
				<FormAsig :poBtnSave="oBtnSave2"></FormAsig>
			</template>
		</CModal>

        <CModal :pConfigModal="ConfigModal3" :poBtnSave="oBtnSave3">
			<template slot="Form">
				<FormSupl :poBtnSave="oBtnSave3"></FormSupl>
			</template>
		</CModal>
    </div>
</template>

<script>

    import Form       from '@/views/catalogos/rutas/Form.vue';
    import FormAsig   from '@/views/catalogos/rutas/FormAsignacion.vue';
    import FormSupl   from '@/views/catalogos/rutas/FormSuplencia.vue';
    import Configs    from '@/helpers/VarConfig.js';
    const EmitEjecuta = 'seccionRutas';
    const EmitAsigna  = 'seccionAsignacion';
    const EmitSuplencia  = 'seccionSuplencia';

    export default {
        name: 'ListaRutas',
        components: {
            Form,
            FormAsig,
            FormSupl,
        },
        data() {
            return {
                counterField:   1,
                IdSucursal: 	0,
                segurity:       {},
                obj:            {},
                ListaArrayRows: [],
                ListaHeader:    [],
                ListaSucursalesArray: [],
                ConfigList:{
                    Title:          'Listado Rutas',
                    IsModal:        true,
                    ShowLoader:     true,
                    BtnReturnShow:  false,
                    EmitSeccion:    EmitEjecuta,
                },
                Filtro:{
                    Nombre:      '',
                    Pagina:      1,
                    Entrada:     25,
                    TotalItem:   0,
                    Placeholder: 'Buscar..',
                },
                ConfigModal:{
                    ModalTitle:  "Formulario Rutas",
                    ModalNameId: 'ModalForm',
                    EmitSeccion:  EmitEjecuta,
                },
                oBtnSave: {
                    toast:       0,
                    IsModal:     true,
                    DisableBtn:  false,
                    EmitSeccion: EmitEjecuta,
                },
                ConfigModal2:{
                    ModalTitle:  "Asignación de Gestores a Ruta",
                    ModalNameId: 'ModalForm2',
                    EmitSeccion: EmitAsigna,
                    ModalSize:   'lg',
                },
                oBtnSave2: {
                    toast:       0,
                    IsModal:     true,
                    ShowBtnSave: true,
                    DisableBtn:  false,
                    EmitSeccion: EmitAsigna,
                },
                ConfigModal3:{
                    ModalTitle:  "Suplencia de ruta",
                    ModalNameId: 'ModalForm3',
                    EmitSeccion: EmitSuplencia,
                    ModalSize:   'md',
                },
                oBtnSave3: {
                    toast:       0,
                    IsModal:     true,
                    ShowBtnSave: true,
                    DisableBtn:  false,
                    EmitSeccion: EmitSuplencia,
                },
                ShowCmbSucursales: false,
            }
        },
        methods: {
            Lista() {
                this.ConfigList.ShowLoader = true;

                this.$http.get('rutas', {
                    params:{
                        TxtBusqueda: this.Filtro.Nombre,
                        Entrada:     this.Filtro.Entrada,
                        Pag:         this.Filtro.Pagina,
                        IdSucursal:  this.IdSucursal,
                    }
                }).then( (res) => {
                    this.ListaArrayRows     = res.data.data.data;
                    this.Filtro.Pagina      = res.data.data.current_page;
                    this.Filtro.TotalItem   = res.data.data.total;
                    this.segurity           = res.data.segurity;
                    this.$setStartItem();

                }).finally(()=>{
                    this.ConfigList.ShowLoader = false;
                });
            },
            Eliminar(Id) {
                this.$swal(Configs.configEliminar).then((result) => {
                    if(result.value) {
                        this.$http.delete('rutas/'+Id)
                        .then( (res) => {
                            this.$swal(Configs.configEliminarConfirm);
                            this.Lista();
                        })
                        .catch( err => {
                            this.$toast.error(err.response.data.message);
                        });
                    }
                });
            },
            async ListaSucursales() {
                await this.$http.get("sucursales", {
                    params: {
                        simple: 1
                    },
                })
                .then((res) => {
                    let response              = res.data.dataSuc;
                    this.ListaSucursalesArray = res.data.data;

                    if(response.EsAdmin == 0) {
                        this.IdSucursal = response.IdSucursal;

                    } else {

                        this.ShowCmbSucursales = true;

                        if(this.ListaSucursalesArray.length>0) {
                            this.IdSucursal = this.ListaSucursalesArray[0].IdSucursal;
                        }
                    }

                }).finally(()=>{
                    this.Lista();
                });
            },
            AsignarPersonal(item) {
                this.bus.$emit('NewModal_'+EmitAsigna,item);
            },
            SuplirPersonal(item) {
                this.bus.$emit('NewModal_'+EmitSuplencia,item);
            },
        },
        created() {
            this.bus.$off('Delete_'+EmitEjecuta);
            this.bus.$off('List_'+EmitEjecuta);
        },
        mounted() {
            this.ListaSucursales();

            this.bus.$on('Delete_'+EmitEjecuta,(Id)=> {
                this.Eliminar(Id);
            });

            this.bus.$on('List_'+EmitEjecuta,()=> {
                this.Lista();
            });

        },
    }

</script>
