<template>
	<CLoader :pConfigLoad="ConfigLoad">
		<template slot="BodyFormLoad">
			<form class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
				<div class="container-fluid">
					<CMapa v-if="this.isReady" :pConfigMapa="ConfigMapa" :oLatLng="coordenadas" class="mb-4"/>
                </div>
			</form>
		</template>
	</CLoader>
</template>

<script>

	export default {
		name:  "FormUbicacionEntrega",
		props: ["poBtnSave"],
		components: {   },
		data() {
			return {
                Emit:    this.poBtnSave.EmitSeccion,
                isReady: false,
                coordenadas: {
                    Lat: 20.967077728465405,
                    Lng: -89.6237726301208,

                },
                ConfigMapa:{
					ShowBuscador: false
                },
				ConfigLoad: {
					ShowLoader: false,
					ClassLoad:  false,
				},
                objUbiEntrega: {
					IdPrestamo: 	 0,
                    LatitudEntrega:  0,
                    LongitudEntrega: 0,
                }
			};
		},
		methods: {
            setDefaultorCurrentLocation() {
                this.coordenadas.Lng = parseFloat(this.objUbiEntrega.LongitudEntrega);
                this.coordenadas.Lat = parseFloat(this.objUbiEntrega.LatitudEntrega);
                this.isReady = true;
                this.ConfigLoad.ShowLoader = false;
            },
		},
		created() {
			this.poBtnSave.toast = 0;

            this.bus.$off('Recovery_'+this.Emit);
            this.bus.$on('Recovery_'+this.Emit,(obj)=> {
                this.ConfigLoad.ShowLoader = true;
                this.poBtnSave.DisableBtn  = false;

                this.objUbiEntrega = obj;

                if(this.objUbiEntrega.IdPrestamo!=='')  {
                    this.setDefaultorCurrentLocation();

                } else {
                    this.ConfigLoad.ShowLoader = false;
					this.isReady 			   = true;
                }

            });
		},
        computed:{
        }
	};

</script>
