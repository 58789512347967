<template>
	<CList :pConfigList="ConfigList" :segurity="segurity">
		<template slot="bodyForm">
			<CLoader :p-config-load="ConfigLoad">
				<template slot="BodyFormLoad">
					<div class="container-fluid">
						<div class="form-group row">
							<div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-3">
								<select id="IdSucursal" v-model="IdSucursal" @change="Lista();" class="form-control form-select mr-2" :disabled="ShowCmbSucursales">
									<option v-for="(item, index) in ListaSucursalesArray" :key="index" :value="item.IdSucursal" >
										{{ item.Nombre }}
									</option>
								</select>
							</div>
							<div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 mt-3">
								<select v-model="FechaEnviar"  @change="Lista();" class="form-control form-select mr-2">
									<option v-for="(item, index) in ListaFechas" :key="index" :value="item.Fecha" >
										{{ item.Fecha }}
									</option>
								</select>
							</div>
						</div>
						<hr>
						<div class="form-group row justify-content-center">
							<div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mt-3">
								<table class="table table-responsive-lg"> 
                                    <thead>
                                        <tr>
											<th>#</th>
											<th>Ruta</th>
											<th>Gestor</th>
											<th>Estatus Día</th>
											<th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
										<tr v-for="(lista, index) in ListaArrayRows" :key="index">
											<td>{{ (index)+1 }}</td>
											<td>{{ lista.NombreRuta }}</td>
											<td>{{ lista.NombreCompleto }}</td>
											<td>
												<span v-if="lista.EstatusDia == ''" class="badge badge-secondary">No Generado</span>
												<span v-else-if="lista.EstatusDia == 'Pendiente'" class="badge badge-primary">Pendiente</span>
												<span v-else-if="lista.EstatusDia == 'EnCurso'" class="badge badge-success">En Cobranza</span>
												<span v-else-if="lista.EstatusDia == 'EnCorte'" class="badge badge-warning">En Corte</span>
												<span v-else-if="lista.EstatusDia == 'EnCierre'" class="badge badge-dark">En PreCorte</span>
												<span v-else-if="lista.EstatusDia == 'AlDia'" class="badge badge-danger">Cerrado</span>
											</td>
											<td>
												<template v-if="segurity.InitialDay">
													<template v-if="lista.LiberaBtn == 'SI'">
														<button type="button" @click="SetearDiaInicial(lista)" class="btn btn-info btn-icon" v-b-tooltip.hover.Top title="Generar Día Inicial">
															<i class="fas fa-calendar-check"></i>
														</button>
													</template>
												</template>
											</td>
										</tr>
										<CSinRegistro :pContIF="ListaArrayRows.length" :pColspan="5"></CSinRegistro>
                                    </tbody>
                                </table>
							</div>
						</div>
					</div>
				</template>
			</CLoader>
		</template>
	</CList>

</template>

<script>

const EmitEjecuta = "seccionDiaCero";

export default {
	name: "ListaDiaCero",
	props: [],
	components: { },
	data() {
		return {
			ConfigList: {
				Title: "Primer Día Gestores",
				IsModal: false,
				ShowLoader: true,
				TypeBody: "Form",
				BtnReturnShow: false,
				BtnNewShow: false,
				ShowTitleFirst: false,
				ShowFiltros: false,
				ShowTitleInline: false,
				ShowPaginador: false,
				EmitSeccion: EmitEjecuta,
			},
			ConfigLoad: {
				ShowLoader: true,
				ClassLoad:  false,
			},
			segurity: {},
			obj: {},
			ListaArrayRows: [],
			ListaSucursalesArray: [],
			IdSucursal: 0,
			FechaEnviar: '',
			ListaFechas: [],
			ShowCmbSucursales: true,
		};
	},
	methods: {
		async Lista()
		{
			this.ConfigLoad.ShowLoader = true;

			await this.$http.get("getUsuariosDiaSiguiente", {
				params: {
					IdSucursal: this.IdSucursal,
					Dia: 1,
					Fecha: this.FechaEnviar
				},
			})
			.then((res) => {
				this.ListaArrayRows = res.data.data;
				this.segurity		= res.data.segurity;
			})
			.finally(() => {
				this.ConfigLoad.ShowLoader = false;
			});
		},
		ListaSucursales()
		{
			this.$http.get("sucursales", {
				params: {
					simple: 1
				},
			})
			.then((res) => {
				let response = res.data.dataSuc;
				this.ListaSucursalesArray = res.data.data;

				if(response.EsAdmin == 0)
				{
					this.IdSucursal = response.IdSucursal;
				}
				else
				{
					this.ShowCmbSucursales = false;

					if(this.ListaSucursalesArray.length>0)
					{
						this.IdSucursal = this.ListaSucursalesArray[0].IdSucursal;
					}
				}
			}).finally(()=>{
				this.ListaFechasDisponibles();
			});
		},
		ListaFechasDisponibles()
		{
			this.$http.get("getFechasDiaCero", {
				params: {
				},
			})
			.then((res) => {
				
				this.ListaFechas = res.data.Fechas;
				this.FechaEnviar = this.ListaFechas[0].Fecha;
				
			}).finally(()=>{
				this.Lista();
			});
		},
		SetearDiaInicial(lista)
		{
			this.$swal({
                title: '¿Generar Día Cerrado?',
                text: 'Es solo para gestores nuevos ¿desea continuar?',
                icon: 'warning',
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar',
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonColor: '#0096ED',
                cancelButtonColor: '#b92e27',
            }).then((result) =>
            {
                if(result.value)
                {
                    this.$http.get('setDiaCeroCobratario', {
                        params:{
                            IdCobratario: lista.IdUsuario,
                            IdRuta: lista.IdRuta,
                        }
                    }).then( (res) => {
                        this.$swal('Día Inicial Generado','','success');
                        this.Lista();
                    }).catch( err => {
                        this.$toast.error(err.response.data.message);
                    });
                }
            });
		},
	},
	created() {
		this.bus.$off("List_" + EmitEjecuta);
		this.ListaSucursales();
	},
	mounted() {
		this.bus.$on("List_" + EmitEjecuta, () => {
			this.Lista();
		});
	},
};
</script>