<template>
	<div>
		<CList @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">					
			<template slot="Filtros">
				<select id="IdSucursal" v-model="Filtro.IdSucursal" @change="Lista();" v-show="ShowCmbSucursales" class="form-control form-select mr-2">
					<option v-for="(item, index) in ListaSucursalesArray" :key="index" :value="item.IdSucursal" >
						{{ item.Nombre }}
					</option>
				</select>
			</template>
			<!-- <template slot="Filtros">
				<div class="form-group">
					<select v-model="FiltroFechas" class="form-control mr-2 ml-2" @change="Lista">
						<option v-for="(itemF,index) in ListaFechas" :key="index" :value="itemF.Fecha">{{itemF.Fecha}}</option>
					</select>
				</div>
			</template> -->

			<template slot="header">
				<th class="td-sm"></th>
				<th># Cliente</th>
				<th>Folio</th>
				<th>Nombre del Cliente</th>
				<th>Telefono</th>
				<th>Monto Entregado</th>
				<th>Estatus</th>
				<th class="text-center">Acciones</th>
			</template>

			<template slot="body">
				<tr v-for="(lista, index) in ListaArrayRows" :key="index">
					<td class="td-sm"></td>
					<td><b>{{ lista.NumCliente }}</b></td>
					<td>{{ lista.Folio }}</td>
					<td>{{ lista.NombreCompleto }}</td>
					<td>{{ lista.Telefono }}</td>
					<td>{{ $formatNumber(lista.MontoEntregado,'$') }}</td>
					<td><b-badge pill variant="success">{{ lista.Estatus }}</b-badge></td>
					<td class="text-center">
						<button type="button" @click="irADetalle(lista)" class="btn btn-sm btn-primary btn-icon mr-1" v-b-tooltip.hover.Top title="Ver Detalle">
							<i class="far fa-eye"></i>
						</button>
					</td>
				</tr>
                <CSinRegistros :pContIF="ListaArrayRows.length" :pColspan="8" />
			</template>
		</CList>

	</div>
</template>

<script>

    import CSinRegistros from "@/components/CSinRegistros";
	const  EmitEjecuta 	 = 	  "seccionEstadoDeCuenta";

	export default {
		name:  		"ListaEstadoDeCuenta",
        props: 		['sFiltros','pSitio'],
        components: { CSinRegistros },
		data() {
			return {
				counterField:	1,
				ListaArrayRows: [],
				segurity: 		{},
				obj: 	  		{},
				ShowCmbSucursales: false,
				ListaSucursalesArray: [],

				ConfigList: {
					Title: 			"Estado de Cuenta",
					IsModal: 		false,
					ShowLoader: 	false,
					BtnReturnShow:  false,
					EmitSeccion: 	EmitEjecuta,
					GoRoute: 		"",
					TitleFirst:     'Inicio',
					BtnNewShow:     false,
				},
				Filtro: {
					Nombre: 	 "",
					Pagina: 	 1,
					Entrada:     25,
					TotalItem:   0,
					Placeholder: "#Cliente / Folio / Nombre",
					IdSucursal: 0,
				},
			};
		},
		methods: {
			ListaSucursales() {
                this.$http.get("sucursales", {
                    params: {
                        simple: 1
                    },
                })
                    .then((res) => {
                        let response = res.data.dataSuc;
                        this.ListaSucursalesArray = res.data.data;

                        if(response.EsAdmin == 0)
                        {
                            this.Filtro.IdSucursal = response.IdSucursal;
                            sessionStorage.setItem('IdSucursal',this.Filtro.IdSucursal);
                        }
                        else
                        {
                            //this.ShowCmbSucursales = true;

                            if(this.ListaSucursalesArray.length>0)
                            {
                                this.Filtro.IdSucursal = this.ListaSucursalesArray[0].IdSucursal;
                                sessionStorage.setItem('IdSucursal',this.Filtro.IdSucursal);
                            }
                        }
                    }).finally(()=>{
                        this.Lista();
                        
                });
            },

			async Lista() {
				this.ConfigList.ShowLoader = true;

				await this.$http.get("buscadorclientes", {
						params: {
							TxtBusqueda: this.Filtro.Nombre,
							Entrada: 	 this.Filtro.Entrada,
							Pag: 		 this.Filtro.Pagina,
							IdSucursal:  this.Filtro.IdSucursal
						},
					})
					.then((res) => {
						this.Filtro.Pagina    = res.data.data.cliente.current_page;
						this.Filtro.TotalItem = res.data.data.cliente.total;
						this.ListaArrayRows   = res.data.data.cliente.data;
						this.segurity         = res.data.segurity;
						this.$setStartItem();
					})
					.finally(() => {
						this.ConfigList.ShowLoader = false;
						this.$saveFilters('SET');
					});
			},
			irADetalle(item) {
				this.$router.push({name:'formdetalleprestamo',params:{Id:item.IdPrestamo,pSitio:'ESTADOCUENTA'}});
			},
		},
		created() {

			if(this.sFiltros===undefined) {
                this.$saveFilters('GET');
            }

			this.bus.$off("List_" + EmitEjecuta);

            sessionStorage.setItem('IdGeneral','0');
            sessionStorage.setItem('IdSubGeneral','0');
		},
		mounted() {
			this.ListaSucursales();

			this.bus.$on("List_" + EmitEjecuta, () => {
				this.Lista();
			});

		},
	};

</script>
