<template>
    <div>
		<CList @FiltrarC="Lista()" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
			<template slot="Filtros">
				<div class="form-group">
					<select id="IdSucursal" v-model="IdSucursal" @change="ListaCobratarios();" v-show="ShowCmbSucursales" class="form-control form-select mr-2" :disabled="IsLoader.BloqCmb1">
						<option v-for="(item, index) in ListaSucursalesArray" :key="index" :value="item.IdSucursal" >
							{{ item.Nombre }}
						</option>
					</select>

					<treeselect v-model="IdRuta" @input="GetDiasDisponibles()" :options="ListaUsers" :multiple="false" :clearable="false" :value-consists-of="'ALL_WITH_INDETERMINATE'" class="vue-treeselect__control-2" placeholder="Todos" :disabled="IsLoader.BloqCmb2"/>

					<select v-model="FiltroFechas" class="form-control mr-2 ml-2" @change="Lista" :disabled="IsLoader.BloqCmb3">
						<option v-for="(itemF,index) in ListaFechas" :key="index" :value="itemF.Fecha">{{itemF.Fecha}}</option>
					</select>

					<template v-if="segurity.StartDay">
						<button type="button" v-show="((EstatusDia == 'Pendiente' || EstatusDia == 'EnCurso') && LiberaBtn == 'SI')" @click="IniciarTerminarDia()" v-b-tooltip.hover.Top 
						:title="(EstatusDia == 'Pendiente')?'Iniciar Día':'Terminar Día'" 
						:class="(EstatusDia == 'Pendiente')?'btn btn-success btn-sm mr-2':'btn btn-danger btn-sm mr-2'" :disabled="IsLoader.BtnDay.Bloq">
							<i :class="IsLoader.BtnDay.Icon"></i>
						</button>
					</template>

					<button type="button" @click="getPdfDiaGenerado()" v-b-tooltip.hover.Top title="Imprimir Dia" class="btn btn-primary btn-sm mr-2">
						<i class="far fa-print"></i>
					</button>

					<button type="button" @click="GetDiasDisponibles" v-b-tooltip.hover.Top title="Recargar" class="btn btn-primary btn-sm mr-2" :disabled="IsLoader.BtnLoad.Bloq">
						<i :class="IsLoader.BtnLoad.Icon"></i>
					</button>

					<button type="button" @click="getNuevosPrestamos()" v-b-tooltip.hover.Top title="Prestamos Nuevos" class="btn btn-primary btn-sm mr-2">
						<i class="far fa-file-invoice-dollar"></i>
						<span class="new-indicator text-alert d-none d-lg-block">
							<span class="numero-compra">{{ContadorPres}}</span>
						</span>
					</button>
					
					<template v-if="segurity.GenNextDay">
						<button type="button" @click="generaNuevoDia()" v-show="ConfigBtn.BtnNewPaymentShow" class="btn btn-primary btn-sm mr-2" :disabled="IsLoader.BtnNextDay.Bloq">
							<i v-show="(IsLoader.BtnNextDay.Bloq)" :class="IsLoader.DefaultSpiner"></i>
							{{IsLoader.BtnNextDay.Icon}}
						</button>
					</template>

					<template v-if="segurity.GenCorte">
						<button type="button" @click="generarCortaDelDia()" v-show="ConfigBtn.BtnClosePaymentShow" class="btn btn-danger btn-sm mr-1" :disabled="IsLoader.BtnCutDay.Bloq">
							<i v-show="(IsLoader.BtnCutDay.Bloq)" :class="IsLoader.DefaultSpiner"></i>
							{{IsLoader.BtnCutDay.Icon}}
						</button>
					</template>
				</div>			
			</template>
			
			<template slot="Configuration">
				<div class="row justify-content-around">
					<div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 my-1">
						<div class="icon-card">
							<div class="icon primary">
								<i class="fad fa-dollar-sign"></i>
							</div>
							<div class="content form-inline">
								<h6 class="text-h6 mr-2">Total de Multas</h6>
								<h3 class="text-h3">{{ $formatNumber(objTotales.TotaldeMultas, '$')}}</h3>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mb-1">
						<div class="icon-card">
							<div class="icon primary">
								<i class="fad fa-dollar-sign"></i>
							</div>
							<div class="content form-inline">								
								<h6 class="text-h6 mr-2">Saldo Total al Día</h6>
								<h3 class="text-h3 text-right">{{$formatNumber(objTotales.SaldoTotalAlDia, '$')}}</h3>		 
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mb-1">
						<div class="icon-card">
							<div class="icon primary">
								<i class="fad fa-dollar-sign"></i>
							</div>
							<div class="content form-inline">								
								<h6 class="text-h6 mr-2">Pago Total al Día</h6>
								<h3 class="text-h3">{{$formatNumber(objTotales.PagoTotalalDia, '$')}}</h3>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mb-1">
						<div class="icon-card">
							<div class="icon primary">
								<i class="fad fa-dollar-sign"></i>
							</div>
							<div class="content form-inline">
								<h6 class="text-h6 mr-2">Total a Pagar</h6>
								<h3 class="text-h3">{{$formatNumber(objTotales.TotalAPagar, '$')}}</h3>
							</div>
						</div>
					</div>
				</div>
			</template>

			<template slot="header">
				<!-- <th>#</th> -->
				<th># Folio ({{ListaArrayRows.length}})</th>
				<th>Día</th>
				<th>Pagos</th>
				<th>Abono</th>
				<th>Monto Entregado</th>
				<th>Multas</th>
				<th>Saldo al Día</th>
				<th>Pago del Día</th>
				<th>Total a Pagar</th>
				<th>Pagó</th>
				<th>Estatus</th>
				<th>E. Prestamo</th>
				<th>Acciones</th>
			</template>

			<template slot="body">
				<tr v-for="(lista, index) in ListaArrayRows" :key="index" :class="lista.Clase">
					<td class="cursor" @click="irAEstadoDeCuenta(lista)"> 
						<CAvatar :fullname="lista.NombreCompleto" :size="25" :radius="50" v-b-tooltip.hover.Top :title="lista.NombreCompleto" style="margin-bottom: 0px;"/>&nbsp;
                        <b>{{ lista.Folio }}</b>
                    </td>
					<td>
						<template v-if="segurity.ToPay">
							<template v-if="ListaFechas.length>0">
								<template v-if="ListaFechas[0].Fecha == lista.FechaEstimadoPago">
									<template v-if="(lista.EstatusPago!='Pagado' && lista.EstatusPago!='Atrasado')">
										<template v-if="(lista.MontoRealPagado == 0 || lista.MontoRealPagado == '0.00')">
											<i @click="RealizarPago(lista)" class="fa fa-money-bill cursor" style="color:green"></i>
										</template>
									</template>
								</template>
							</template>
						</template>
						{{ lista.DiasTranscurridos }}
					</td>
					<td>{{ lista.NumPagoCubierto }} / {{lista.NumPagos}}</td>
					<td>{{ lista.NumPagoActual }}</td>
					<td>{{ $formatNumber(lista.MontoEntregado, '$') }}</td>
					<td>({{ lista.NumMultas }}) {{$formatNumber(lista.TotalMultas, '$')}}</td>
					<td>{{ $formatNumber(lista.Saldo, '$') }}</td>
					<td>{{ $formatNumber(lista.PagoDelDia,'$') }}</td>
					<!-- <td>{{ $formatNumber((parseFloat(lista.Saldo)+parseFloat(lista.MontoDiario)),'$') }}</td> -->
					<td>{{ $formatNumber((parseFloat(lista.TotalAPagar)),'$') }}</td>
					<td>{{ $formatNumber(parseFloat(lista.MontoRealPagado),'$') }}</td>
					<td class="tol-base">
						<template v-if="lista.IdUsuarioCobro == null || lista.IdUsuarioCobro == 0">
							<span :class="['badge',
								(
									lista.EstatusDelPago == 'Liquidado'? 'badge-purple':
									lista.EstatusPago    == 'Pendiente'? 'badge-primary':
									'badge-secondary'
								)]">
								<span v-if="lista.EstatusDelPago == 'Liquidado'">
									{{lista.EstatusDelPago}}
								</span>
								<span v-else>
									{{ lista.EstatusPago }}
								</span>
							</span>
						</template>

						<template v-else-if="lista.IdUsuarioCobro != null && lista.IdUsuarioCobro !=0">
							<template v-if="lista.IdCobratario != lista.IdUsuarioCobro ">
								<span :class="['badge',
									(
										lista.EstatusDelPago == 'Liquidado'	 ? 'badge-purple':
										lista.EstatusPago 	 == 'Pagado'	 ? 'badge-warning':
										lista.EstatusPago 	 == 'Atrasado'	 ? 'badge-danger':
										lista.EstatusPago 	 == 'Adelantado' ? 'badge-success':
										'badge-secondary'
									),
									'position-relative']" v-b-tooltip.html :title="'<b>Cobró:</b>'+' '+lista.Cobratario.NombreCompleto">
									<span v-if="lista.EstatusDelPago == 'Liquidado'">
										{{lista.EstatusDelPago}}
									</span>
									<span v-else>
										{{ lista.EstatusPago }}
									</span>
									<span class="badge badge-danger text-danger" style="position:absolute; top:-5px; border-radius:50%; border: 2px solid #fff;">
										.
									</span>
								</span>
							</template>

							<template v-else>
								<span :class="['badge',
								(
										lista.EstatusDelPago == 'Liquidado'  ? 'badge-purple':
										lista.EstatusPago 	 == 'Pagado'	 ? 'badge-warning':
										lista.EstatusPago 	 == 'Atrasado'	 ? 'badge-danger':
										lista.EstatusPago 	 == 'Adelantado' ? 'badge-success':
										'badge-secondary'
									)]">
									<span v-if="lista.EstatusDelPago == 'Liquidado'">
										{{lista.EstatusDelPago}}
									</span>
									<span v-else>
										{{ lista.EstatusPago }}
									</span>
								</span>
							</template>
						</template>
					</td>
					<td> 						
						<span v-if="lista.EstatusPrestamo == 'Liquidado'" class="badge badge-purple mr-1">{{ lista.EstatusPrestamo }}</span>															
						<span v-else class="badge badge-success mr-1">{{ lista.EstatusPrestamo }}</span>															
					</td>					
					<td>
						<template v-if="segurity.Liquidate">
							<template v-if="(EstatusDia != 'AlDia') && lista.CorteRealizado == 0">
								<button type="button" @click="RealizarLiquidacion(lista)" class="btn btn-sm btn-primary btn-icon mr-1" v-b-tooltip.hover.Top title="Corregir Pagos">
									<i class="fal fa-file-alt"></i>
								</button>
							</template>
						</template>

						<button type="button" v-if="parseInt(lista.comentario_count) > 0" class="btn btn-info btn-icon mr-1" @click="viewsComentarios(lista)" v-b-tooltip.hover.Top title="Ver comentario del pago">
                            <i class="fas fa-comment-dots"></i>
                        </button>					
					</td>
				</tr>
				<CSinRegistros :pContIF="ListaArrayRows.length" :pColspan="13" />
			</template>
		</CList>

		<CModal :pConfigModal="ConfigModal" :poBtnSave="oBtnSave">
			<template slot="Form">
				<Form :poBtnSave="oBtnSave"></Form>
			</template>
		</CModal>

		<CModal :pConfigModal="ConfigModalComentarios">
			<template slot="Form">
				<ComentariosPagos :poBtnSave="poBtnSaveComentarios" :pSitio="Origen" />
			</template>
		</CModal>

		<CModal :pConfigModal="ConfigModalPagos" :poBtnSave="poBtnSavePagos">
			<template slot="Form">
				<FormPagos :poBtnSave="poBtnSavePagos" />
			</template>
		</CModal>

		<CModal :pConfigModal="ConfigModalLiq" :poBtnSave="poBtnSaveLiq">
			<template slot="Form">
				<FormLiquidacion :poBtnSave="poBtnSaveLiq" :pSitio="Origen" />
			</template>
		</CModal>
	</div>
</template>

<script>

import moment        	from 'moment';
import CSinRegistros 	from "../../../../components/CSinRegistros";
import Configs 	     	from "@/helpers/VarConfig.js";
import Form       	 	from '@/views/modulos/cobranza/diaSiguiente/Form.vue';
import FormPagos	 	from '@/views/modulos/cobranza/diaSiguiente/FormPagos.vue';
//import FormLiquidacion 	from '@/views/modulos/cobranza/diaSiguiente/FormLiquidacion.vue';
import FormLiquidacion 	from '@/views/modulos/cobranza/diaSiguiente/FormPagosCorreccion.vue';
import ComentariosPagos from "@/views/modulos/crm/clientes/ComentariosPagos.vue";

const EmitEjecuta 	= 'seccionCliente';
const EmitEjecutaC 	= 'seccionNuevos';
const EmitEjecuta3 	= "seccionEstadoCuentaComentarios";
const EmitEjecuta4 	= "seccionPagos";
const EmitEjecuta5 	= "seccionLiquidacion";

export default {
	name: "PrestamosCobranza",
	props:['objUser','sFiltros'],
	components: {
		CSinRegistros,
		Form,
		ComentariosPagos,
		FormPagos,
		FormLiquidacion,
	},
	data() {
		return {	
			errorvalidacion: [],
			ListaArrayRows:	 [],
			arreglo:		 [],
			segurity: 		 {},
			obj: 			 {},
			ConfigList: {
				ShowTitleFirst:  false,
				Title: 			 "Cobratarios - Dia Siguiente",
				IsModal: 		 false,
				BtnNewShow: 	 false,
				TypeBody: 		 "ListCobranza",
				ShowLoader: 	 true,
				BtnReturnShow: 	 true,
				EmitSeccion: 	 EmitEjecuta,
				ShowSearch:		 false,
				ShowEntradas:	 false,
			},
			ConfigBtn: {
				BtnNewPaymentShow: false,
				BtnClosePaymentShow: false,
			},
			Filtro: {
				Nombre: 	  "",
				Pagina: 	  1,
				Entrada: 	  100,
				TotalItem: 	  0,
				Placeholder:  "Buscar..",
				EstatusP: 	  "Cobranza",
			},
			objPrestamo:{
				IdCliente:		 0,
				Prospecto:		 0,
				Estatus:		 '',
				MotivoRechazo:	 '',
				FechaRechazo:	 '',
				IdPrestamo:		 0,
				clienteRechazo:	 '',
				PrestamoRechazo: '',
				IdCobratario:	 0,
				IdRuta: 0,
			},
			objTotales:{
				TotaldeMultas: 	0,
				SaldoTotalAlDia:0,
				PagoTotalalDia: 0,
				TotalAPagar: 	0,
			},
			ConfigModal:{
				ModalTitle: "Listado Prestamos Nuevos",
				ModalNameId: 'ModalForm',
				EmitSeccion: EmitEjecutaC,
				ModalSize: 'xl',
				ShowFooter: false,
			},
			oBtnSave: {
				toast: 0,
				IsModal: true,
				DisableBtn: false,
				EmitSeccion: EmitEjecutaC,
			},
			ConfigModalComentarios: {
                ModalTitle:  "Comentarios del Pago",
                ModalNameId: 'ModalForm3',
                EmitSeccion: EmitEjecuta3,
                ModalSize:   'md',
                ShowFooter: false,
            },
            poBtnSaveComentarios:{
                toast:       0,
                IsModal:     true,
                DisableBtn:  false,
                EmitSeccion: EmitEjecuta3,
                ShowBtnSave: true
            },
			ConfigModalPagos: {
                ModalTitle:  "Pagos",
                ModalNameId: 'ModalForm4',
                EmitSeccion: EmitEjecuta4,
                ModalSize:   'md',
            },
            poBtnSavePagos:{
                toast:       0,
                IsModal:     true,
                DisableBtn:  false,
                EmitSeccion: EmitEjecuta4,
            },
			ConfigModalLiq: {
                ModalTitle:  "Corrección Pagos",
                ModalNameId: 'ModalForm5',
                EmitSeccion: EmitEjecuta5,
                ModalSize:   'md',
            },
            poBtnSaveLiq:{
                toast:       0,
                IsModal:     true,
                DisableBtn:  false,
                EmitSeccion: EmitEjecuta5,
            },
			ListaFechas: 		  [],
			ListaUsers: 		  [],
			ListaSucursalesArray: [],
			FiltroFechas: 		  moment(new Date()).format('YYYY-MM-DD'),
			ContadorPres: 		  0,
			IdCobratario: 	      null,
			IdSucursal: 		  0,
			ShowCmbSucursales:    false,
			isVisible:		      false,
			EstatusDia: '',
			LiberaBtn: 'NO',
			IdRuta: null,
			objFiltrados:{
				IdSucursal: 0,
				IdRuta: 0,
				Fecha: '',
				ShowSuc: '',
			},
			IsLoader:{
				BtnDay:{
					Bloq: false,
					Icon: 'far fa-power-off',
					Orig: 'far fa-power-off',
				},
				BtnLoad:{
					Blod: false,
					Icon: 'far fa-redo',
					Orig: 'far fa-redo',
				},
				BtnNextDay:{
					Bloq: false,
					Icon: 'Día siguiente',
					Orig: 'Día siguiente',
				},
				BtnCutDay:{
					Bloq: false,
					Icon: 'Generar Corte',
					Orig: 'Generar Corte',
				},
				DefaultSpiner: 'fa fa-spinner fa-pulse fa-1x fa-fw',
				DefaultText: ' Espere...',
				BloqCmb1: true,
				BloqCmb2: true,
				BloqCmb3: true,
			},
			Origen: 'Siguiente',
		};
	},
	methods: {
		Lista()
		{
			this.Bloquea(1);
			this.IsLoader.BtnLoad.Icon = this.IsLoader.DefaultSpiner;

			if(this.IdRuta == null){
				this.objPrestamo.IdRuta = 0;
			}
			else{
				this.objPrestamo.IdRuta = this.IdRuta;
			}
			
			this.ConfigList.ShowLoader = true;

			if(this.objPrestamo.IdRuta>0)
			{
				this.$http.get("cobranzalistado", {
					params: {
						TxtBusqueda:    this.Filtro.Nombre,
						Entrada: 	    this.Filtro.Entrada,
						Pag: 		    this.Filtro.Pagina,
						IdRuta:			this.objPrestamo.IdRuta,
						estatusP: 		this.Filtro.EstatusP,
						Fecha: 			this.FiltroFechas,
					},
				}).then( (res) => {
					this.ListaArrayRows   			 	= res.data.data;
					this.Filtro.Pagina 	  			 	= res.data.data.current_page;
					this.Filtro.TotalItem 			 	= res.data.data.total;
					this.ConfigBtn.BtnNewPaymentShow 	= res.data.btnLibre;
					this.ConfigBtn.BtnClosePaymentShow 	= res.data.btnLibreC;
					this.segurity         				= res.data.segurity;
					this.ContadorPres					= res.data.ContPrestamos;
					this.EstatusDia						= res.data.EstatusDia;
					this.LiberaBtn						= res.data.LiberaBoton;

					if(this.Filtro.EstatusP == 'Entregado') {
						this.Filtro.Entrada = 25;
					} else {						
						this.Filtro.Entrada = 100;
					}
					//this.Totales();
					//console.log(res.data.SumaTotales);
					const RESP = res.data.SumaTotales;
					this.objTotales.TotaldeMultas  	= RESP.TotaldeMultas;
					this.objTotales.SaldoTotalAlDia	= RESP.SaldoTotalAlDia;
					this.objTotales.PagoTotalalDia 	= RESP.PagoTotalalDia;
					this.objTotales.TotalAPagar 	= RESP.TotalAPagar;

				}).finally(()=>{
					this.ConfigList.ShowLoader = false;
					this.GuardarFiltros('SET');
					this.Bloquea(2);
					this.IsLoader.BtnLoad.Icon = this.IsLoader.BtnLoad.Orig;
				});
			}
			else
			{
				this.ListaArrayRows = [];
				this.ConfigList.ShowLoader = false;
				//this.Totales();
			}
		},
		Regresar() {
			this.$router.push({name: 'cobratarios',params:{}});
		},
		async Totales()
		{
			this.ConfigList.ShowLoader = true;
			if(this.objPrestamo.IdRuta>0)
			{
				await this.$http.get("sumatoriasTotales", {
					params: {
						IdRuta: this.objPrestamo.IdRuta,
						Fecha: this.FiltroFechas,
					},
				}).then( (res) => {
					const RESP = res.data.data;
					this.objTotales.TotaldeMultas  	= RESP.TotaldeMultas;
					this.objTotales.SaldoTotalAlDia	= RESP.SaldoTotalAlDia;
					this.objTotales.PagoTotalalDia 	= RESP.PagoTotalalDia;
					this.objTotales.TotalAPagar 	= RESP.TotalAPagar;
				}).finally(()=>{
					this.ConfigList.ShowLoader = false;
				});
			}
			else
			{
				this.objTotales.TotaldeMultas  	= 0;
				this.objTotales.SaldoTotalAlDia	= 0;
				this.objTotales.PagoTotalalDia 	= 0;
				this.objTotales.TotalAPagar 	= 0;
				this.ConfigList.ShowLoader = false;
			}
		},
		generaNuevoDia()
		{
			this.IsLoader.BtnNextDay.Bloq = true;
			this.IsLoader.BtnNextDay.Icon = this.IsLoader.DefaultText;

			this.$swal(Configs.configGenerarDia).then((result) => {
				if (result.value) {
					this.errorvalidacion = [];

					let formData = new FormData();
					formData.set('IdRuta', this.objPrestamo.IdRuta);

					this.$http.post('generanuevodia', formData)
					.then((res)=>{
						this.GetDiasDisponibles();
					}).catch(err=>{
						this.$toast.error(err.response.data.message);
					}).finally(()=>{
						this.ConfigList.ShowLoader = false;
						this.IsLoader.BtnNextDay.Bloq = false;
						this.IsLoader.BtnNextDay.Icon = this.IsLoader.BtnNextDay.Orig;
					});
				}
				else{
					this.IsLoader.BtnNextDay.Bloq = false;
					this.IsLoader.BtnNextDay.Icon = this.IsLoader.BtnNextDay.Orig;
				}
			});
		},
		generarCortaDelDia()
		{
			this.IsLoader.BtnCutDay.Bloq = true;
			this.IsLoader.BtnCutDay.Icon = this.IsLoader.DefaultText;

			this.$swal({
				title: '<h2 style="font-size:25px; font-weight: 700;">¿Desea generar corte del día?</h2>',
				text: '¡Esta accion no se podrá revertir!',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: 'Cancelar',
				showCloseButton: true,
				showLoaderOnConfirm: true,
				confirmButtonColor: '#0096ED',
				cancelButtonColor: '#b92e27',
			}).then((result) => {
				if (result.value)
				{
					this.ConfigList.ShowLoader = true;
					//this.$http.get("cobranzacortedeldia", {
					this.$http.get("cambiarEstatusDiaCorte", {
						params: {
							Fecha: moment(new Date()).format('YYYY-MM-DD'),
							IdRuta: this.objPrestamo.IdRuta,
						},
					}).then( (res) => {
						this.$toast.success('Corte del día generado');
						this.Lista();
						sessionStorage.removeItem('PosicionCobro');
					}).catch(err=>{
						this.$toast.error(err.response.data.message);
					}).finally(()=>{
						this.ConfigList.ShowLoader = false;
						this.IsLoader.BtnCutDay.Bloq = false;
						this.IsLoader.BtnCutDay.Icon = this.IsLoader.BtnCutDay.Orig;
					});
				}
				else{
					this.IsLoader.BtnCutDay.Bloq = false;
					this.IsLoader.BtnCutDay.Icon = this.IsLoader.BtnCutDay.Orig;
				}
			});
		},
		getNuevosPrestamos() {
			this.bus.$emit('NewModal_'+EmitEjecutaC,this.objPrestamo);
		},
		getPdfDiaGenerado()
		{
			if(this.objPrestamo.IdRuta>0)
			{
				let urlPdf = `${this.$http.defaults.baseURL}/getPdfNuevoDia?Cobrat=${this.objPrestamo.IdRuta}&Fecha=${this.FiltroFechas}`;

				window.open(urlPdf,'_blank');
			}
			else
			{
				this.$toast.warning('Debe Seleccionar A Un Gestor');
			}
			/* this.$http.get("getPdfNuevoDia", {
				//Token: this.$store.Token,
				//responseType: 'blob',
				headers: {
					Authorization: `Bearer ${this.$store.Token}`,
					'Content-Type': 'application/json',
				},
				responseType: 'blob',
				params: {
					IdCobratario:	this.objPrestamo.IdCobratario,
					Fecha:			this.FiltroFechas,
				}
			})
			.then( (response) => {
				let pdfContent = response.data;
				let file       = new Blob([pdfContent], { type: 'application/pdf' });
				let fileUrl    = URL.createObjectURL(file);
				//const blocker = '#toolbar=0&navpanes=0';
				window.open(fileUrl);
			}); */
		},
		irAEstadoDeCuenta(item)
		{
			//this.$router.push({name:'estadoCuentaCobranza',params:{objPrestamo:item,pSitio:'Dia'}}); // 15062023
			this.$router.push({name:'formdetalleprestamo',params:{Id:item.IdPrestamo, pSitio:'DIASIGUIENTE'}});
		},

		viewsComentarios(item) {
			this.bus.$emit('NewModal_'+EmitEjecuta3,item);
		},
		ListaSucursales()
		{
			this.$http.get("sucursales", {
				params: {
					simple: 1
				},
			})
			.then((res) => {
				let response = res.data.dataSuc;
				this.ListaSucursalesArray = res.data.data;
				
				if(this.objFiltrados.IdSucursal == 0)
				{
					if(response.EsAdmin == 0)
					{
						this.IdSucursal = response.IdSucursal;
					}
					else
					{
						this.ShowCmbSucursales = true;

						if(this.ListaSucursalesArray.length>0)
						{
							this.IdSucursal = this.ListaSucursalesArray[0].IdSucursal;
						}
					}
				}
				else
				{
					this.IdSucursal = this.objFiltrados.IdSucursal;
					this.ShowCmbSucursales = this.objFiltrados.ShowSuc;
				}
			}).finally(()=>{
				this.ListaCobratarios();
			});
		},
		ListaCobratarios()
		{
			this.$http.get("getUsuariosDiaSiguiente", {
				params: {
					IdSucursal: this.IdSucursal,
				},
			})
			.then((res) => {
				this.IdRuta = null;
				
				this.ListaUsers = res.data.data.map( item =>({
					id:    item.IdRuta,
					label: item.Nombre+' '+item.ApellidoPat+' - '+item.NombreRuta
				}));

				if(this.objFiltrados.IdRuta == 0)
				{
					if(this.ListaUsers.length>0)
					{
						this.IdRuta = this.ListaUsers[0].id;
					}
				}
				else{
					this.IdRuta = this.objFiltrados.IdRuta;
				}

				//this.Lista();
				this.GetDiasDisponibles();
			});
		},
		GetDiasDisponibles()
		{
			this.$http.get("getFechasCobratario", {
				params: {
					IdRuta: this.IdRuta,
				},
			})
			.then((res) => {
				let FechaBef = res.data.Fechas.FechaHoy;
				let FechaPost = res.data.Fechas.FechaMan;

				this.ListaFechas = [];
				let objFecha = {
					Fecha: FechaBef,
				}
				this.ListaFechas.push(objFecha);

				if(FechaPost!=''){
					let objFecha2 = {
						Fecha: FechaPost,
					}
					this.ListaFechas.push(objFecha2);
					//this.ListaFechas = objFecha2;
				}

				if(this.objFiltrados.Fecha == '')
				{
					this.FiltroFechas = FechaBef;
				}
				else{
					this.FiltroFechas = this.objFiltrados.Fecha;
				}

			}).finally(()=>{
				this.Lista();
			});
		},
		RealizarPago(item)
		{
			this.bus.$emit('NewModal_'+EmitEjecuta4,item);
		},
		RealizarLiquidacion(item)
		{
			this.bus.$emit('NewModal_'+EmitEjecuta5,item);
		},
		IniciarTerminarDia()
		{
			this.IsLoader.BtnDay.Bloq = true;
			this.IsLoader.BtnDay.Icon = this.IsLoader.DefaultSpiner;
			let Iniciar = 1;

			if(this.EstatusDia == 'EnCurso'){
				Iniciar = 2;
			}

			if(this.IdRuta == null){
				this.objPrestamo.IdRuta = 0;
			}
			else{
				this.objPrestamo.IdRuta = this.IdRuta;
			}

			let mensaje = Configs.configConfirmacionCierre;

			if(Iniciar == 1){
				mensaje = Configs.configConfirmacionInicio;
			}

			this.$swal(mensaje).then((result) =>
			{
				if(result.value)
				{
					this.ConfigList.ShowLoader = true;
					this.$http.get("cambiarestatusdiaWeb", {
						params: {
							Tipo: Iniciar,
							IdRuta: this.objPrestamo.IdRuta,
						},
					}).then( (res) => {
						this.$toast.success(res.data.message);
						this.Lista();
					}).catch(err=>{
						this.$toast.error(err.response.data.message);
					}).finally(()=>{
						this.ConfigList.ShowLoader = false;
						this.IsLoader.BtnDay.Bloq = false;
						this.IsLoader.BtnDay.Icon = this.IsLoader.BtnDay.Orig;
					});
				}
				else
				{
					this.IsLoader.BtnDay.Bloq = false;
					this.IsLoader.BtnDay.Icon = this.IsLoader.BtnDay.Orig;
				}
			});
		},
		GuardarFiltros(Tipo)
		{
			if(Tipo == 'SET')
			{
				this.objFiltrados.IdSucursal = this.IdSucursal;
				this.objFiltrados.IdRuta = this.objPrestamo.IdRuta;
				this.objFiltrados.Fecha = this.FiltroFechas;
				this.objFiltrados.ShowSuc = this.ShowCmbSucursales;

				sessionStorage.setItem('DiaFiltros', JSON.stringify(this.objFiltrados));
			}
			else
			{
				this.objFiltrados = JSON.parse(sessionStorage.getItem('DiaFiltros'));
			}
		},
		Bloquea(Tipo)
		{
			if(Tipo == 1)
			{
				this.IsLoader.BtnDay.Bloq = true;
				this.IsLoader.BtnLoad.Bloq = true;
				this.IsLoader.BtnNextDay.Bloq = true;
				this.IsLoader.BtnCutDay.Bloq = true;
				this.IsLoader.BloqCmb1 = true;
				this.IsLoader.BloqCmb2 = true;
				this.IsLoader.BloqCmb3 = true;
			}
			else
			{
				this.IsLoader.BtnDay.Bloq = false;
				this.IsLoader.BtnLoad.Bloq = false;
				this.IsLoader.BtnNextDay.Bloq = false;
				this.IsLoader.BtnCutDay.Bloq = false;
				this.IsLoader.BloqCmb1 = false;
				this.IsLoader.BloqCmb2 = false;
				this.IsLoader.BloqCmb3 = false;
			}
		}
	},
	created() {
		this.bus.$off('List_'+EmitEjecuta);

		if(this.sFiltros===undefined) {
            this.GuardarFiltros('GET');
        }
		/* else
		{
			sessionStorage.removeItem('DiaFiltros')
		} */
	},
	mounted() {
		this.Bloquea(1);
		this.ListaSucursales();
		//this.ListaCobratarios();
		
		this.bus.$on('List_'+EmitEjecuta,()=> {
            this.Lista();
        });
	},
	beforeDestroy() {
		sessionStorage.setItem('IdGeneral',0);
	}
};
</script>