<template>
    <div>
        <CList :pConfigList="ConfigList" >

            <template slot="accHeader">

                <form onsubmit="return false" class="form-inline float-right">
                    <div class="form-group">
                        <select id="IdSucursal" v-model="IdSucursalGlob" v-show="ShowCmbSucursales" @change="blockPrint()" class="form-control form-select mr-1" :disabled="IsLoader.BloqCmb1">
                            <option v-for="(item, index) in ListaSucursalesArray" :key="index" :value="item.IdSucursal" >
                                {{ item.Nombre }}
                            </option>
                        </select>

                        <div class="form-group mx-sm-2">
                            <v-date-picker v-model="objFiltroFechasGlob" :masks="masks" @input="blockPrint()" :popover="{ visibility: 'focus' }" :disabled-dates='{ weekdays: [1,2,3,4,5,6] }' locale="es" 
                            :min-date="new Date('2024-04-29')" :max-date="new Date()" :disabled="IsLoader.BloqCmb1">
                                <template v-slot="{ inputValue, inputEvents }">
                                    <input class="form-control cal" placeholder="Seleccione una Fecha" :value="inputValue" v-on="inputEvents" readonly/>
                                </template>
                            </v-date-picker>
                        </div>

                        <button type="button" @click="getInforme()" v-b-tooltip.hover.Top title="Cargar Reporte" class="btn btn-primary btn-sm mr-2">
                            <i v-show="(!IsLoader.BtnReporte.Bloq)" class="fas fa-file-chart-line"></i>
                            

                            <i v-show="(IsLoader.BtnReporte.Bloq)" :class="IsLoader.DefaultSpiner"></i>
                            {{IsLoader.BtnReporte.Icon}}
                        </button>

                        <button v-if="showImprimirPdf" type="button" v-b-tooltip.hover.Top @click="viewPdfReporte()" title="Descargar Reporte PDF" class="btn btn-danger btn-sm mr-2">
                            <i class="far fa-print"></i>    
                        </button>
                    </div>
                </form>

            </template>
                        
            <template slot="bodyForm">

                <CLoader :pConfigLoad="ConfigLoad">
                    <template slot="BodyFormLoad">

                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">
                                    Cobranza Semanal                               
                                </a>
                            </li>
                            
                        </ul>
                        <div class="tab-content shadow-sm" id="myTabContent">

                            <!--Parte Dos Prestamo -->
                            <div class="tab-pane fade active show" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div class="container-fluid">

                                    <!-- Creditos Vigentes -->
                                    <fieldset>

                                        <legend class="col-form-label">&nbsp;Resultado de la semana {{$getCleanDate(semanaReporte.SemanaIni,false)}} - {{ $getCleanDate(semanaReporte.SemanaFin,false) }} &nbsp;</legend>
                                        
                                        <CTablita :pConfigList="ConfigListProductividad">
                                            <template slot="header">
                                                <th>Ruta</th>
                                                <th>Gestor</th>
                                                <th class="text-center">Lunes %</th>
                                                <th class="text-center">Lunes Cobrado</th>
                                                <th class="text-center">Martes %</th>
                                                <th class="text-center">Martes Cobrado</th>

                                                <th class="text-center">Miercoles %</th>
                                                <th class="text-center">Miercoles Cobrado</th>
                                                <th class="text-center">Jueves %</th>
                                                <th class="text-center">Jueves Cobrado</th>
                                                <th class="text-center">Viernes %</th>
                                                <th class="text-center">Viernes Cobrado</th>
                                                <th class="text-center">Sabado %</th>
                                                <th class="text-center">Sabado Cobrado</th>
                                                <th class="text-center">% Ruta</th>
                                                <th class="text-center">Cobranza Ruta</th>

                                            </template>
                                            <template slot="body">
                                                <tr  v-for="(item,index) in listProductividad"  :key="index">
                                                    <td><strong>{{item.NomRuta}}</strong></td>
                                                    <td>
                                                       
                                                        <CAvatar :fullname="item.Gestor" :size="25" :radius="50" v-b-tooltip.hover.Top :title="item.Gestor" style="margin-bottom: 0px;"/>&nbsp;
                                                      
                                                    
                                                    </td>
                                                    <td class="text-center">{{ item.PorcentDia1 }}%</td>
                                                    <td class="text-center">{{ $formatNumber(item.Dia1,'$') }}</td>

                                                    <td class="text-center">{{ item.PorcentDia2 }}%</td>
                                                    <td class="text-center">{{ $formatNumber(item.Dia2,'$') }}</td>

                                                    <td class="text-center">{{ item.PorcentDia3 }}%</td>
                                                    <td class="text-center">{{ $formatNumber(item.Dia3,'$') }}</td>

                                                    <td class="text-center">{{ item.PorcentDia4 }}%</td>
                                                    <td class="text-center">{{ $formatNumber(item.Dia4,'$') }}</td>

                                                    <td class="text-center">{{ item.PorcentDia5 }}%</td>
                                                    <td class="text-center">{{ $formatNumber(item.Dia5,'$') }}</td>

                                                    <td class="text-center">{{ item.PorcentDia6 }}%</td>
                                                    <td class="text-center">{{ $formatNumber(item.Dia6,'$') }}</td>

                                                    <td class="text-center"><strong>{{ item.PromSemanal }}%</strong></td>
                                                    <td class="text-center"><strong>{{ $formatNumber(item.SumSemanal,'$') }}</strong></td>
                                                   
                                                </tr>

                                                <CSinRegistros :pContIF="listProductividad.length" :pColspan="16" />
                                            </template>
                                            <template slot="fotter">
                                                <tr v-if="listProductividad.length > 0" style="background-color: #e9ecef;">
                                                    <td></td>
                                                    <td class="text-right"><strong>Totales</strong> </td>
                                                    <td class="text-center"><strong>{{ productividadTotales.TotPorcentDia1 }}%</strong></td>
                                                    <td class="text-center"><strong>{{ $formatNumber(productividadTotales.TotalDia1,'$') }}</strong></td>

                                                    <td class="text-center"><strong>{{ productividadTotales.TotPorcentDia2 }}%</strong></td>
                                                    <td class="text-center"><strong>{{ $formatNumber(productividadTotales.TotalDia2,'$') }}</strong></td>

                                                    <td class="text-center"><strong>{{ productividadTotales.TotPorcentDia3 }}%</strong></td>
                                                    <td class="text-center"><strong>{{ $formatNumber(productividadTotales.TotalDia3,'$') }}</strong></td>

                                                    <td class="text-center"><strong>{{ productividadTotales.TotPorcentDia4 }}%</strong></td>
                                                    <td class="text-center"><strong>{{ $formatNumber(productividadTotales.TotalDia4,'$') }}</strong></td>

                                                    <td class="text-center"><strong>{{ productividadTotales.TotPorcentDia5 }}%</strong></td>
                                                    <td class="text-center"><strong>{{ $formatNumber(productividadTotales.TotalDia5,'$') }}</strong></td>

                                                    <td class="text-center"><strong>{{ productividadTotales.TotPorcentDia6 }}%</strong></td>
                                                    <td class="text-center"><strong>{{ $formatNumber(productividadTotales.TotalDia6,'$') }}</strong></td>


                                                    <td class="text-center"><strong>{{ productividadTotales.PromRutaSemana }}%</strong></td>
                                                    <td class="text-center"><strong>{{ $formatNumber(productividadTotales.SumGranTotal,'$') }}</strong></td>
                                                </tr>
                                            </template>
                                        </CTablita>
                                    </fieldset>

                                   
                                   
                                        
                                    

                                </div>
                            </div>

                        </div>
                    </template>
                </CLoader>

            </template>
        </CList>

      
    </div>
</template>


<script>
import moment from "moment";
import CSinRegistros from "../../../components/CSinRegistros";
import CLoader       from "../../../components/CLoader";
import CList         from "../../../components/CList";
import CTablita         from "../../../components/CTablita";

const EmitEjecuta       = "seccionReporteResumenSemana";
const EmitComentario    = "seccionComentarioReporte";

export default {
    name:  "viewverVentasSemanales",
    props: [],
    components: {
        CSinRegistros,
        CLoader,
        CList,
        CTablita,
    },

    data() {
        return {
            ListaSucursalesArray:[],
            IdSucursalGlob:0,
            ShowCmbSucursales: false,
            masks: {
                input: "YYYY-MM-DD",
            },
            objFiltroFechasGlob: {},
            FiltroFechaGlob: '',

            ConfigLoad:{
                ShowLoader: false,
                ClassLoad:  true,
            },
            showImprimirPdf: false,
            IsLoader:{
				BtnReporte:{
					Bloq: false,
					Icon: 'Cargar Reporte',
					Orig: 'Cargar Reporte',
				},
				DefaultSpiner: 'fa fa-spinner fa-pulse fa-1x fa-fw',
				DefaultText: ' Espere...',
				BloqCmb1: true,
				
			},

            ConfigList: {
                ShowTitleFirst:    true,
                TitleFirst:        "Menu Reportes",
                Title:             "Reporte de Cobranza Semanal",
                ShowLoader:        true,
                IsModal:           false,
                BtnNewShow:        false,
                BtnReturnShow:     false,
                TypeBody:          "Form",
                ShowFiltros:       false,
                ShowFiltrosInline: true,
                ShowTitleInline:   false,
                ShowPaginador:     false,
            },
            oBtnSave: {
                toast:         0,
                IsModal:       false,
                ShowBtnSave:   true,
                ShowBtnCancel: false,
                DisableBtn:    false,
                EmitSeccion:   EmitEjecuta,
            },
            
            // Productividad
            listProductividad: [],
            productividadTotales: {
                TotalDia1: 0, ProcentDia1: 0,
                TotalDia2: 0, ProcentDia2: 0,
                TotalDia3: 0, ProcentDia3: 0, 
                TotalDia4: 0, ProcentDia4: 0,
                TotalDia5: 0, ProcentDia5: 0,
                TotalDia6: 0, ProcentDia6: 0,
                PromRutaSemana: 0,
                SumGranTotal: 0
            },
            semanaReporte:{
                SemanaIni: "",
                SemanaFin: ""
            },
            ConfigListProductividad: {
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecuta,
                GoRoute: 'formdetalleprestamo'
            },


        }
    },
    methods: {

        ListaSucursales() {
            this.$http.get("sucursales", {
                params: {
                    simple: 1
                },
            })
                .then((res) => {
                    let response = res.data.dataSuc;
                    this.ListaSucursalesArray = res.data.data;

                    if(response.EsAdmin == 0)
                    {
                        this.IdSucursalGlob = response.IdSucursal;
                    }
                    else
                    {
                        this.ShowCmbSucursales = true;

                        if(this.ListaSucursalesArray.length>0)
                        {
                            this.IdSucursalGlob = this.ListaSucursalesArray[0].IdSucursal;
                        }
                    }
                }).finally(()=>{
                    this.IsLoader.BloqCmb1 = false;
            });
        },

        blockPrint(){
            this.showImprimirPdf = false;
        },

        async getInforme() {
            this.limpiar();

            this.ConfigLoad.ShowLoader = true;
            this.IsLoader.BtnReporte.Bloq = true;
            this.IsLoader.BtnReporte.Icon = this.IsLoader.DefaultText;
            this.IsLoader.BloqCmb1 = true;
            this.showImprimirPdf = false;
            
           
            this.FiltroFechaGlob = moment(this.objFiltroFechasGlob).format('YYYY-MM-DD');
            await this.$http.get("rptproductividadsemanal", {
				params: {
					IdSucursal: this.IdSucursalGlob,
                    Fecha: this.FiltroFechaGlob
				},
			})
			.then((res) => {
                console.log(res.data.data);

                this.listProductividad       = res.data.data.productividad;
                this.productividadTotales    = res.data.data.productividadTotales;
                this.semanaReporte           = res.data.data.rangoSemana;
                this.showImprimirPdf        = true;
                this.$toast.success('Información Recuperada');
                
            }).catch(err=>{   
                this.$toast.error(err.response.data.message);

            }).finally(() => {
                this.ConfigLoad.ShowLoader = false;
                this.IsLoader.BtnReporte.Bloq = false;
				this.IsLoader.BtnReporte.Icon = this.IsLoader.BtnReporte.Orig;
                this.IsLoader.BloqCmb1 = false;
            });
        },

       

        viewPdfReporte(){
            let newRequest = {
                productividad: this.listProductividad,
                productividadTotales: this.productividadTotales,
                rangoSemana: this.semanaReporte,
                IdSucursal: this.IdSucursalGlob,
                Fecha: this.FiltroFechaGlob,
            }

            this.$http.post('rptpdfproductividadsemanal',newRequest).then((res)=>{     
                
                let pdfWindow = window.open("");
                pdfWindow.document.write(
                    "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                    encodeURI(res.data.pdf) + "'></iframe>"
                );
                
            }).catch(err=>{   
               console.log(err);
            });

          
        },

        limpiar() {
            this.listProductividad = [];
            this.productividadTotales = {
                TotalDia1: 0, ProcentDia1: 0,
                TotalDia2: 0, ProcentDia2: 0,
                TotalDia3: 0, ProcentDia3: 0, 
                TotalDia4: 0, ProcentDia4: 0,
                TotalDia5: 0, ProcentDia5: 0,
                TotalDia6: 0, ProcentDia6: 0,
                PromRutaSemana: 0,
                SumGranTotal: 0
            };
            this.semanaReporte = {
                SemanaIni: "",
                SemanaFin: ""
            };


        },

        Regresar(){
            this.$router.push({name:"menureportes"});
        }
    },
    created() {
        this.limpiar();
        this.bus.$off('getReporteDiario');
        this.ListaSucursales();

    },
    mounted(){
        this.bus.$on('getReporteDiario', () => {
            this.getInforme();
		});
        this.bus.$on('EmitReturn',()=> {
			this.Regresar();
		});
    }

};

</script>
<style>
.tooltip-inner {
    white-space: pre-wrap;
    max-width: 230px !important;
}
</style>