const MenuInicio = {
    Menu: 'Inicio',
    Icono: 'fas fa-home-lg-alt',
    Permiso: 'CLAVEINICIO',
    Router: 'inicio',
    Parametros: {sFiltros:'NO'},
    TipoBoton: 0,
    EsDeRoot: 0,
    SubMenus: []
};

const MenuReportes = {
    Menu: 'Reportes',
    Icono: 'fas fa-books',
    Permiso: 'CLAVEREPORTES',
    Router: 'menureportes',
    Parametros: {sFiltros:'NO'},
    TipoBoton: 0,
    EsDeRoot: 0,
    SubMenus: []
};

const MenuMiEmpresa = {
    Menu: 'Empresa',
    Icono: 'fas fa-building',
    Permiso: 'CLAVEEMPRESA',
    Router: '',
    Parametros: {sFiltros:'NO'},
    TipoBoton: 0,
    EsDeRoot: 0,
    SubMenus: [
        {
            NombreSubMenu: 'Mi Empresa',
            Icono: "",
            Permiso: 'CLAVEMIEMPRESA',
            Router: 'empresa',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        {
            NombreSubMenu: 'Empleados',
            Icono: "",
            Permiso: 'CLAVEEMPLEADOS',
            Router: 'empleados',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        }
    ]
};

const MenuCrm = {
    Menu: "CRM",
    Icono: "fas fa-chart-network",
    Permiso: 'CLAVECRM',
    Router: "",
    Parametros: {sFiltros:'NO'},
    TipoBoton: 0,
    EsDeRoot: 0,
    SubMenus: [
        {
            NombreSubMenu: "Prospectos",
            Permiso: 'CLAVEPROSPECTOS',
            Router: "prospectos",
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: [],
        },
        {
            NombreSubMenu: "Prestamos",
            Permiso: 'CLAVEPRESTAMOS',
            Router: "menuprestamos",
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: [],
        },
        {
            NombreSubMenu: "Clientes",
            Permiso: 'CLAVECLIENTES',
            Router: "clientesActivos",
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: [],
        },
        {
            NombreSubMenu: "Reasignación",
            Permiso: 'CLAVEREASIGNACION',
            Router: "reasignacion",
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: [],
        },
    ],
};

const MenuCobranza = {
    Menu: 'Cobranza',
    Icono: 'fas fa-usd-square',
    Permiso: 'CLAVECOBRANZA',
    Router: '',
    Parametros: {sFiltros:'NO'},
    TipoBoton: 0,
    EsDeRoot: 0,
    SubMenus: [
        {
            NombreSubMenu: 'Día Siguiente',
            Icono: "",
            Permiso: 'CLAVEDIASIGUIENTE',
            Router: 'diasiguiente',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        {
            NombreSubMenu: 'Corte del Día',
            Icono: "",
            Permiso: 'CLAVECORTEDELDIA',
            Router: 'cortedeldia',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        {
            NombreSubMenu: 'Estado de Cuenta',
            Icono: "",
            Permiso: 'CLAVEESTADOCUENTA',
            Router: 'listEstadoCuenta',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        {
            NombreSubMenu: 'Día Inicial',
            Icono: "",
            Permiso: 'CLAVEDIAINICIAL',
            Router: 'diaCero',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        /* {
            NombreSubMenu: 'Suplencia',
            Icono: "",
            Permiso: 'CLAVEESTADOCUENTA',
            Router: 'suplencia',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        }, */
        {
            NombreSubMenu: 'Correccion Pagos',
            Icono: "",
            Permiso: 'CLAVEDIAINICIAL',
            Router: 'correccionpagos',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 1,
            Apartados: []
        },
    ]
};

const MenuConfig =
{
    Menu: 'Configuración',
    Icono: 'fas fa-cog',
    Permiso: 'CLAVECONFIGURACION',
    Router: '',
    Parametros: {sFiltros:'NO'},
    TipoBoton: 0,
    EsDeRoot: 0,
    SubMenus: [
        {
            NombreSubMenu: 'Montos de Crédito',
            Icono: "",
            Permiso: 'CLAVEMONTOS',
            Router: 'montoscredito',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        {
            NombreSubMenu: 'Configurativo Multa',
            Icono: "",
            Permiso: 'CLAVEMULTA',
            Router: 'multa',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        {
            NombreSubMenu: 'Tazas de Interes',
            Icono: "",
            Permiso: 'CLAVETAZA',
            Router: 'taza',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },

        {
            NombreSubMenu: 'Tipos de Familiares',
            Icono: "",
            Permiso: 'CLAVETIPOFAM',
            Router: 'TipoFamiliar',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        {
            NombreSubMenu: 'Negocios',
            Icono: "",
            Permiso: 'CLAVENEGOCIOS',
            Router: 'negocios',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        {
            NombreSubMenu: 'Días Inhabiles',
            Icono: "",
            Permiso: 'CLAVEDIASINHABILES',
            Router: 'diasInhabiles',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        {
            NombreSubMenu: 'Equipos',
            Icono: "",
            Permiso: 'CLAVEEQUIPOS',
            Router: 'equipos',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        {
            NombreSubMenu: 'Perfiles',
            Icono: "",
            Permiso: 'CLAVEPERFILES',
            Router: 'perfiles',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: []
        },
        {
            NombreSubMenu: "Puestos",
            Icono: "",
            Permiso: 'CLAVEPUESTOS',
            Router: "puestos",
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: [],
        },
        {
            NombreSubMenu: 'Menus',
            Icono: "",
            Permiso: 'CLAVEMENUS',
            Router: 'paneles',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 1,
            Apartados: []
        },
        {
            NombreSubMenu: "Rutas",
            Icono: "",
            Permiso: 'CLAVERUTAS',
            Router: "rutas",
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: [],
        },
        {
            NombreSubMenu: 'Permisos',
            Icono: "",
            Permiso: 'CLAVEPERMISOS',
            Router: 'permisos',
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 1,
            Apartados: []
        },
        {
            NombreSubMenu: "Correo Electrónico",
            Icono: "",
            Permiso: 'CLAVECORREO',
            Router: "correo",
            Parametros: {sFiltros:'NO'},
            TipoBoton: 0,
            EsDeRoot: 0,
            Apartados: [],
        },
    ]
};

const ConfigMenus = [
    MenuInicio,
    MenuMiEmpresa,
    MenuCrm,
    MenuCobranza,
    MenuReportes,
    MenuConfig
];

export default {
    ConfigMenus,
}
