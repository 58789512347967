<template>
    <div>
        <CList :pConfigList="ConfigList" >

            <template slot="accHeader">

                <form onsubmit="return false" class="form-inline float-right">
                    <div class="form-group">
                        <select id="IdSucursal" v-model="IdSucursalGlob" v-show="ShowCmbSucursales" @change="blockPrint()" class="form-control form-select mr-1" :disabled="IsLoader.BloqCmb1">
                            <option v-for="(item, index) in ListaSucursalesArray" :key="index" :value="item.IdSucursal" >
                                {{ item.Nombre }}
                            </option>
                        </select>

                        <div class="form-group mx-sm-2">
                            <v-date-picker v-model="objFiltroFechasGlob" :masks="masks" @input="blockPrint()" :popover="{ visibility: 'focus' }" :disabled-dates='{ weekdays: [1,2,3,4,5,6] }' locale="es" 
                            :min-date="new Date('2024-01-01')" :max-date="new Date()" :disabled="IsLoader.BloqCmb1">
                                <template v-slot="{ inputValue, inputEvents }">
                                    <input class="form-control cal" placeholder="Seleccione una Fecha" :value="inputValue" v-on="inputEvents" readonly/>
                                </template>
                            </v-date-picker>
                        </div>

                        <button type="button" @click="getInforme()" v-b-tooltip.hover.Top title="Cargar Reporte" class="btn btn-primary btn-sm mr-2">
                            <i v-show="(!IsLoader.BtnReporte.Bloq)" class="fas fa-file-chart-line"></i>
                            

                            <i v-show="(IsLoader.BtnReporte.Bloq)" :class="IsLoader.DefaultSpiner"></i>
                            {{IsLoader.BtnReporte.Icon}}
                        </button>

                        <button v-if="showImprimirPdf" type="button" v-b-tooltip.hover.Top @click="viewPdfReporte()" title="Descargar Reporte PDF" class="btn btn-danger btn-sm mr-2">
                            <i class="far fa-print"></i>    
                        </button>
                    </div>
                </form>

            </template>
                        
            <template slot="bodyForm">

                <CLoader :pConfigLoad="ConfigLoad">
                    <template slot="BodyFormLoad">

                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab1-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">
                                    Ventas Semanales                                
                                </a>
                            </li>
                            
                        </ul>
                        <div class="tab-content shadow-sm" id="myTabContent">

                            <!--Parte Dos Prestamo -->
                            <div class="tab-pane fade active show" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                                <div class="container-fluid">

                                    <!-- Creditos Vigentes -->
                                    <fieldset>

                                        <legend class="col-form-label">&nbsp;Creditos Vigentes&nbsp;</legend>
                                        
                                        <CTablita :pConfigList="ConfigListVigentes">
                                            <template slot="header">
                                                <th>Ruta</th>
                                                <th>Gestor</th>
                                                <th class="text-center">Lunes</th>
                                                <th class="text-center">Martes</th>
                                                <th class="text-center">Miercoles</th>
                                                <th class="text-center">Jueves</th>
                                                <th class="text-center">Viernes</th>
                                                <th class="text-center">Sabado</th>
                                                <th class="text-center">Promedio Total</th>

                                            </template>
                                            <template slot="body">
                                                <tr  v-for="(item,index) in listCreditosVigentes"  :key="index">
                                                    <td><strong>{{item.NomRuta}}</strong></td>
                                                    <td><strong>{{item.Gestor}}</strong></td>
                                                    <td class="text-center">{{ item.Dia1 }}</td>
                                                    <td class="text-center">{{ item.Dia2 }}</td>
                                                    <td class="text-center">{{ item.Dia3 }}</td>
                                                    <td class="text-center">{{ item.Dia4 }}</td>
                                                    <td class="text-center">{{ item.Dia5 }}</td>
                                                    <td class="text-center">{{ item.Dia6 }}</td>
                                                    <td class="text-center"><strong>{{ item.PromRuta }}</strong></td>
                                                   
                                                </tr>

                                                <CSinRegistros :pContIF="listCreditosVigentes.length" :pColspan="9" />
                                            </template>
                                            <template slot="fotter">
                                                <tr v-if="listCreditosVigentes.length > 0" style="background-color: #e9ecef;">
                                                    <td></td>
                                                    <td class="text-right"><strong>Totales</strong> </td>
                                                    <td class="text-center"><strong>{{ creditosVigentesTotales.TotalDia1 }}</strong></td>
                                                    <td class="text-center"><strong>{{ creditosVigentesTotales.TotalDia2 }}</strong></td>
                                                    <td class="text-center"><strong>{{ creditosVigentesTotales.TotalDia3 }}</strong></td>
                                                    <td class="text-center"><strong>{{ creditosVigentesTotales.TotalDia4 }}</strong></td>
                                                    <td class="text-center"><strong>{{ creditosVigentesTotales.TotalDia5 }}</strong></td>
                                                    <td class="text-center"><strong>{{ creditosVigentesTotales.TotalDia6 }}</strong></td>
                                                    <td class="text-center"><strong>{{ creditosVigentesTotales.PromCreditos }}</strong></td>
                                                </tr>
                                            </template>
                                        </CTablita>
                                    </fieldset>

                                    <!-- Total de Creditos Entregados -->
                                    <fieldset class="mt-4">

                                        <legend class="col-form-label">&nbsp;Total de Creditos Entregados&nbsp;</legend>

                                        <CTablita :pConfigList="ConfigListEntregas">
                                            <template slot="header">
                                                <th>Ruta</th>
                                                <th>Gestor</th>
                                                <th class="text-center">Lunes</th>
                                                <th class="text-center">Martes</th>
                                                <th class="text-center">Miercoles</th>
                                                <th class="text-center">Jueves</th>
                                                <th class="text-center">Viernes</th>
                                                <th class="text-center">Sabado</th>
                                                <th class="text-center">Total</th>
                                            </template>

                                            <template slot="body">
                                                <tr  v-for="(item,index) in listEntregasGenerales"  :key="index">
                                                    <td><strong>{{item.NomRuta}}</strong></td>
                                                    <td><strong>{{item.Gestor}}</strong></td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia1 > 0" v-b-tooltip.hover.Top :title="item.listDia1"><b>{{ item.Dia1}}</b></span>
                                                        <span v-else> {{ item.Dia1}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia2 > 0" v-b-tooltip.hover.Top :title="item.listDia2"><b>{{ item.Dia2}}</b></span>
                                                        <span v-else> {{ item.Dia2}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia3 > 0" v-b-tooltip.hover.Top :title="item.listDia3"><b>{{ item.Dia3}}</b></span>
                                                        <span v-else> {{ item.Dia3}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia4 > 0" v-b-tooltip.hover.Top :title="item.listDia4"><b>{{ item.Dia4}}</b></span>
                                                        <span v-else> {{ item.Dia4}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia5 > 0" v-b-tooltip.hover.Top :title="item.listDia5"><b>{{ item.Dia5}}</b></span>
                                                        <span v-else> {{ item.Dia5}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia6 > 0" v-b-tooltip.hover.Top :title="item.listDia6"><b>{{ item.Dia6}}</b></span>
                                                        <span v-else> {{ item.Dia6}}</span>
                                                    </td>
                                                    <td class="text-center"><strong>{{ item.SumSemanal}}</strong></td>
                                                </tr>

                                                <CSinRegistros :pContIF="listEntregasGenerales.length" :pColspan="9" />
                                            </template>

                                            <template slot="fotter">
                                                <tr v-if="listEntregasGenerales.length > 0" style="background-color: #e9ecef;">
                                                    <td></td>
                                                    <td class="text-right"><strong>Totales</strong> </td>
                                                    <td class="text-center"><strong>{{ totalesEntregasGenerales.TotalDia1 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesEntregasGenerales.TotalDia2 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesEntregasGenerales.TotalDia3 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesEntregasGenerales.TotalDia4 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesEntregasGenerales.TotalDia5 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesEntregasGenerales.TotalDia6 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesEntregasGenerales.SumGranTotal  }} </strong></td>

                                                
                                                </tr>
                                            </template>

                                        </CTablita>
                                    </fieldset>

                                    <!-- Total de Creditos Iniciales -->
                                    <fieldset class="mt-4">

                                        <legend class="col-form-label">&nbsp;Creditos Nuevos Entregados&nbsp;</legend>

                                        <CTablita :pConfigList="ConfigListIniciales">
                                            <template slot="header">
                                                <th>Ruta</th>
                                                <th>Gestor</th>
                                                <th class="text-center">Lunes</th>
                                                <th class="text-center">Martes</th>
                                                <th class="text-center">Miercoles</th>
                                                <th class="text-center">Jueves</th>
                                                <th class="text-center">Viernes</th>
                                                <th class="text-center">Sabado</th>
                                                <th class="text-center">Total</th>
                                            </template>

                                            <template slot="body">
                                                <tr  v-for="(item,index) in listInicialesSemana"  :key="index">
                                                    <td><strong>{{item.NomRuta}}</strong></td>
                                                    <td><strong>{{item.Gestor}}</strong></td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia1 > 0" v-b-tooltip.hover.Top :title="item.listDia1"><b>{{ item.Dia1}}</b></span>
                                                        <span v-else> {{ item.Dia1}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia2 > 0" v-b-tooltip.hover.Top :title="item.listDia2"><b>{{ item.Dia2}}</b></span>
                                                        <span v-else> {{ item.Dia2}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia3 > 0" v-b-tooltip.hover.Top :title="item.listDia3"><b>{{ item.Dia3}}</b></span>
                                                        <span v-else> {{ item.Dia3}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia4 > 0" v-b-tooltip.hover.Top :title="item.listDia4"><b>{{ item.Dia4}}</b></span>
                                                        <span v-else> {{ item.Dia4}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia5 > 0" v-b-tooltip.hover.Top :title="item.listDia5"><b>{{ item.Dia5}}</b></span>
                                                        <span v-else> {{ item.Dia5}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia6 > 0" v-b-tooltip.hover.Top :title="item.listDia6"><b>{{ item.Dia6}}</b></span>
                                                        <span v-else> {{ item.Dia6}}</span>
                                                    </td>
                                                    <td class="text-center"><strong>{{ item.SumSemanal}}</strong></td>
                                                </tr>

                                                <CSinRegistros :pContIF="listInicialesSemana.length" :pColspan="9" />
                                            </template>

                                            <template slot="fotter">
                                                <tr v-if="listInicialesSemana.length > 0" style="background-color: #e9ecef;">
                                                    <td></td>
                                                    <td class="text-right"><strong>Totales</strong> </td>
                                                    <td class="text-center"><strong>{{ totalesEntregasIniciales.TotalDia1 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesEntregasIniciales.TotalDia2 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesEntregasIniciales.TotalDia3 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesEntregasIniciales.TotalDia4 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesEntregasIniciales.TotalDia5 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesEntregasIniciales.TotalDia6 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesEntregasIniciales.SumGranTotal  }} </strong></td>

                                                
                                                </tr>
                                            </template>

                                        </CTablita>
                                    </fieldset>

                                    <!-- Total de Creditos Consecutivos -->
                                    <fieldset class="mt-4">

                                        <legend class="col-form-label">&nbsp;Creditos Consecutivos Entregados&nbsp;</legend>

                                        <CTablita :pConfigList="ConfigListIniciales">
                                            <template slot="header">
                                                <th>Ruta</th>
                                                <th>Gestor</th>
                                                <th class="text-center">Lunes</th>
                                                <th class="text-center">Martes</th>
                                                <th class="text-center">Miercoles</th>
                                                <th class="text-center">Jueves</th>
                                                <th class="text-center">Viernes</th>
                                                <th class="text-center">Sabado</th>
                                                <th class="text-center">Total</th>
                                            </template>

                                            <template slot="body">
                                                <tr  v-for="(item,index) in listConsecutivosSemana"  :key="index">
                                                    <td><strong>{{item.NomRuta}}</strong></td>
                                                    <td><strong>{{item.Gestor}}</strong></td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia1 > 0" v-b-tooltip.hover.Top :title="item.listDia1"><b>{{ item.Dia1}}</b></span>
                                                        <span v-else> {{ item.Dia1}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia2 > 0" v-b-tooltip.hover.Top :title="item.listDia2"><b>{{ item.Dia2}}</b></span>
                                                        <span v-else> {{ item.Dia2}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia3 > 0" v-b-tooltip.hover.Top :title="item.listDia3"><b>{{ item.Dia3}}</b></span>
                                                        <span v-else> {{ item.Dia3}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia4 > 0" v-b-tooltip.hover.Top :title="item.listDia4"><b>{{ item.Dia4}}</b></span>
                                                        <span v-else> {{ item.Dia4}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia5 > 0" v-b-tooltip.hover.Top :title="item.listDia5"><b>{{ item.Dia5}}</b></span>
                                                        <span v-else> {{ item.Dia5}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia6 > 0" v-b-tooltip.hover.Top :title="item.listDia6"><b>{{ item.Dia6}}</b></span>
                                                        <span v-else> {{ item.Dia6}}</span>
                                                    </td>
                                                    <td class="text-center"><strong>{{ item.SumSemanal}}</strong></td>
                                                </tr>

                                                <CSinRegistros :pContIF="listConsecutivosSemana.length" :pColspan="9" />
                                            </template>

                                            <template slot="fotter">
                                                <tr v-if="listConsecutivosSemana.length > 0" style="background-color: #e9ecef;">
                                                    <td></td>
                                                    <td class="text-right"><strong>Totales</strong> </td>
                                                    <td class="text-center"><strong>{{ totalesEntregasConsecutivos.TotalDia1 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesEntregasConsecutivos.TotalDia2 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesEntregasConsecutivos.TotalDia3 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesEntregasConsecutivos.TotalDia4 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesEntregasConsecutivos.TotalDia5 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesEntregasConsecutivos.TotalDia6 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesEntregasConsecutivos.SumGranTotal  }} </strong></td>

                                                
                                                </tr>
                                            </template>

                                        </CTablita>
                                    </fieldset>
                                        
                                    <!-- Prospectos de Ventas -->
                                    <fieldset class="mt-4">

                                        <legend class="col-form-label">&nbsp;Prospectos de Ventas&nbsp;</legend>

                                        <CTablita :pConfigList="ConfigListProspectos">
                                            <template slot="header">
                                                <th>Ruta</th>
                                                <th>Gestor</th>
                                                <th class="text-center">Lunes</th>
                                                <th class="text-center">Martes</th>
                                                <th class="text-center">Miercoles</th>
                                                <th class="text-center">Jueves</th>
                                                <th class="text-center">Viernes</th>
                                                <th class="text-center">Sabado</th>
                                                <th class="text-center">Total</th>
                                            </template>

                                            <template slot="body">
                                                <tr  v-for="(item,index) in listProspectosSemana"  :key="index">
                                                    <td><strong>{{item.NomRuta}}</strong></td>
                                                    <td><strong>{{item.Gestor}}</strong></td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia1 > 0" v-b-tooltip.hover.Top :title="item.listDia1"><b>{{ item.Dia1}}</b></span>
                                                        <span v-else> {{ item.Dia1}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia2 > 0" v-b-tooltip.hover.Top :title="item.listDia2"><b>{{ item.Dia2}}</b></span>
                                                        <span v-else> {{ item.Dia2}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia3 > 0" v-b-tooltip.hover.Top :title="item.listDia3" ><b>{{ item.Dia3}}</b></span>
                                                        <span v-else> {{ item.Dia3}}</span>
                                                    </td>
                                                    <td class="text-center"> 
                                                        <span v-if="item.Dia4 > 0" v-b-tooltip.hover.Top :title="item.listDia4" ><b>{{ item.Dia4}}</b></span>
                                                        <span v-else> {{ item.Dia4}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia5 > 0" v-b-tooltip.hover.Top :title="item.listDia5"><b>{{ item.Dia5}}</b></span>
                                                        <span v-else> {{ item.Dia5}}</span>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.Dia6 > 0" v-b-tooltip.hover.Top :title="item.listDia6"><b>{{ item.Dia6}}</b></span>
                                                        <span v-else> {{ item.Dia6}}</span>
                                                    </td>
                                                    <td class="text-center"><strong>{{ item.SumSemanal}}</strong></td>
                                                </tr>

                                                <CSinRegistros :pContIF="listProspectosSemana.length" :pColspan="9" />
                                            </template>

                                            <template slot="fotter">
                                                <tr v-if="listProspectosSemana.length > 0" style="background-color: #e9ecef;">
                                                    <td></td>
                                                    <td class="text-right"><strong>Totales</strong> </td>
                                                    <td class="text-center"><strong>{{ totalesProspectosSemana.TotalDia1 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesProspectosSemana.TotalDia2 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesProspectosSemana.TotalDia3 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesProspectosSemana.TotalDia4 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesProspectosSemana.TotalDia5 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesProspectosSemana.TotalDia6 }} </strong></td>
                                                    <td class="text-center"><strong>{{ totalesProspectosSemana.SumGranTotal  }} </strong></td>

                                                
                                                </tr>
                                            </template>

                                        </CTablita>
                                    </fieldset>

                                    <!-- Historial Semanal y Crecimiento -->
                                    <fieldset class="mt-4">

                                        <legend class="col-form-label">&nbsp;Historial Semanal y Crecimiento&nbsp;</legend>
                                        <div class="row">
                                            <div class="col-4">
                                                <div class="card">
                                                    <div class="card-header">
                                                        Semana Anterior <strong> {{crecimientoSemanal.SemanaAnteriorIni}} </strong>  al <strong> {{ crecimientoSemanal.SemanaAnteriorFin }} </strong> 
                                                    </div>
                                                    <div class="card-body">
                                                        <h1 class="card-title text-center">{{ crecimientoSemanal.NumCreditosAnterior }}</h1>                                                       
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="card">
                                                    <div class="card-header">
                                                        Semana Actual <strong> {{crecimientoSemanal.SemanaActualIni}} </strong> al <strong> {{ crecimientoSemanal.SemanaActualFin }} </strong>
                                                    </div>
                                                    <div class="card-body">
                                                        <h1 class="card-title text-center">{{ crecimientoSemanal.NumCreditosActual }}</h1>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="card">
                                                    <div class="card-header">
                                                        Indice de Crecimiento
                                                    </div>
                                                    <div class="card-body">
                                                        <h1 v-if="parseInt(crecimientoSemanal.DiferenciaIntersemana) < 0" class="card-title text-center text-danger">{{ crecimientoSemanal.DiferenciaIntersemana }}</h1>
                                                        <h1 v-else-if="parseInt(crecimientoSemanal.DiferenciaIntersemana) == 0" class="card-title text-center text-info">{{ crecimientoSemanal.DiferenciaIntersemana }}</h1>
                                                        <h1 v-else-if="parseInt(crecimientoSemanal.DiferenciaIntersemana) > 0" class="card-title text-center text-success">{{ crecimientoSemanal.DiferenciaIntersemana }}</h1>
                                                        <h1 v-else class="card-title text-center">{{ crecimientoSemanal.DiferenciaIntersemana }}</h1>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>


                                    <!-- Prospectos de Ventas -->
                                    <fieldset class="mt-4">

                                        <legend class="col-form-label">&nbsp;Clientes No Renovados&nbsp;</legend>

                                        <CTablita :pConfigList="ConfigListNoRenovados">
                                            <template slot="header">
                                                <th>#</th>
                                                <th>Ruta</th>
                                                <th>Gestor</th>
                                                <th>Folio</th>
                                                <th class="text-center">Duracion Prestamo</th>
                                                <th class="text-center">Monto Prestamo</th>
                                                <th>Observaciones</th>
                                            </template>

                                            <template slot="body">
                                                <tr  v-for="(item,index) in listNoRenovados"  :key="index">
                                                    <td>{{ index+1 }}</td>
                                                    <td><strong>{{item.Ruta}}</strong></td>
                                                    <td><strong>{{item.Gestor}}</strong></td>
                                                    <td>{{item.FolioAnterior}}</td>
                                                    <td class="text-center">{{item.DuracionPrestamo}}</td>
                                                    <td class="text-center">{{$formatNumber(item.MontoPrestamo,'$')}}</td>
                                                    <td>{{item.Observaciones}}</td>
                                                </tr>

                                                <CSinRegistros :pContIF="listNoRenovados.length" :pColspan="7" />
                                            </template>


                                        </CTablita>
                                    </fieldset>


                                </div>
                            </div>

                        </div>
                    </template>
                </CLoader>

            </template>
        </CList>

      
    </div>
</template>


<script>
import moment from "moment";
import CSinRegistros from "../../../components/CSinRegistros";
import CLoader       from "../../../components/CLoader";
import CList         from "../../../components/CList";
import CTablita         from "../../../components/CTablita";

const EmitEjecuta       = "seccionReporteResumenSemana";
const EmitComentario    = "seccionComentarioReporte";

export default {
    name:  "viewverVentasSemanales",
    props: [],
    components: {
        CSinRegistros,
        CLoader,
        CList,
        CTablita,
    },

    data() {
        return {
            ListaSucursalesArray:[],
            IdSucursalGlob:0,
            ShowCmbSucursales: false,
            masks: {
                input: "YYYY-MM-DD",
            },
            objFiltroFechasGlob: {},
            FiltroFechaGlob: '',

            ConfigLoad:{
                ShowLoader: false,
                ClassLoad:  true,
            },
            showImprimirPdf: false,
            IsLoader:{
				BtnReporte:{
					Bloq: false,
					Icon: 'Cargar Reporte',
					Orig: 'Cargar Reporte',
				},
				DefaultSpiner: 'fa fa-spinner fa-pulse fa-1x fa-fw',
				DefaultText: ' Espere...',
				BloqCmb1: true,
				
			},

            ConfigList: {
                ShowTitleFirst:    true,
                TitleFirst:        "Menu Reportes",
                Title:             "Reporte de Ventas Semanales",
                ShowLoader:        true,
                IsModal:           false,
                BtnNewShow:        false,
                BtnReturnShow:     false,
                TypeBody:          "Form",
                ShowFiltros:       false,
                ShowFiltrosInline: true,
                ShowTitleInline:   false,
                ShowPaginador:     false,
            },
            oBtnSave: {
                toast:         0,
                IsModal:       false,
                ShowBtnSave:   true,
                ShowBtnCancel: false,
                DisableBtn:    false,
                EmitSeccion:   EmitEjecuta,
            },
            
            // CREDITOS VIGENTES
            listCreditosVigentes: [],
            creditosVigentesTotales: {
                TotalDia1: 0,
                TotalDia2: 0,
                TotalDia3: 0,
                TotalDia4: 0,
                TotalDia5: 0,
                TotalDia6: 0,
                PromCreditos: 0
            },

            ConfigListVigentes: {
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecuta,
                GoRoute: 'formdetalleprestamo'
            },

            // CREDITOS ENTREGADOS GENERALES
            listEntregasGenerales: [],
            totalesEntregasGenerales: {
                TotalDia1: 0,
                TotalDia2: 0,
                TotalDia3: 0,
                TotalDia4: 0,
                TotalDia5: 0,
                TotalDia6: 0,
                SumGranTotal: 0
            },

            ConfigListEntregas: {
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecuta,
                GoRoute: 'formdetalleprestamo'
            },

            // CREDITOS INICIALES
            listInicialesSemana:[],
            totalesEntregasIniciales:{
                TotalDia1: 0,
                TotalDia2: 0,
                TotalDia3: 0,
                TotalDia4: 0,
                TotalDia5: 0,
                TotalDia6: 0,
                SumGranTotal: 0
            },

            ConfigListIniciales: {
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecuta,
                GoRoute: 'formdetalleprestamo'
            },

            // CREDITOS CONSECUTIVOS
            listConsecutivosSemana:[],
            totalesEntregasConsecutivos:{
                TotalDia1: 0,
                TotalDia2: 0,
                TotalDia3: 0,
                TotalDia4: 0,
                TotalDia5: 0,
                TotalDia6: 0,
                SumGranTotal: 0
            },

            ConfigListConsecutivos: {
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecuta,
                GoRoute: 'formdetalleprestamo'
            },

            // PROSPECTOS SEMANA
            listProspectosSemana: [],
            totalesProspectosSemana: {
                TotalDia1: 0,
                TotalDia2: 0,
                TotalDia3: 0,
                TotalDia4: 0,
                TotalDia5: 0,
                TotalDia6: 0,
                SumGranTotal: 0
            },

            ConfigListProspectos: {
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecuta,
                GoRoute: 'formdetalleprestamo'
            },

            // CRECIMIENTO SEMANAL
            crecimientoSemanal: {
                SemanaActualIni: "",
                SemanaActualFin: "",
                NumCreditosActual: 0,
                SemanaAnteriorIni: "",
                SemanaAnteriorFin: "",
                NumCreditosAnterior: 0,
                DiferenciaIntersemana: 0
            },

            // PRESTAMOS NO RENOVADOS
            listNoRenovados:[],
            ConfigListNoRenovados: {
                ShowLoader:     false,
                IsModal:        false,
                BtnReturnShow:  true,
                ShowSearch:     false,
                ShowPaginador:  false,
                ShowEntradas:   false,
                BtnNewShow:     false,
                TypeBody:       'List',
                ShowTitleFirst: false,
                EmitSeccion:    EmitEjecuta,
                GoRoute: 'formdetalleprestamo'
            },

            

            
        }
    },
    methods: {

        ListaSucursales() {
            this.$http.get("sucursales", {
                params: {
                    simple: 1
                },
            })
                .then((res) => {
                    let response = res.data.dataSuc;
                    this.ListaSucursalesArray = res.data.data;

                    if(response.EsAdmin == 0)
                    {
                        this.IdSucursalGlob = response.IdSucursal;
                    }
                    else
                    {
                        this.ShowCmbSucursales = true;

                        if(this.ListaSucursalesArray.length>0)
                        {
                            this.IdSucursalGlob = this.ListaSucursalesArray[0].IdSucursal;
                        }
                    }
                }).finally(()=>{
                    this.IsLoader.BloqCmb1 = false;
            });
        },

        blockPrint(){
            this.showImprimirPdf = false;
        },

        async getInforme() {
            this.limpiar();

            this.ConfigLoad.ShowLoader = true;
            this.IsLoader.BtnReporte.Bloq = true;
            this.IsLoader.BtnReporte.Icon = this.IsLoader.DefaultText;
            this.IsLoader.BloqCmb1 = true;
            this.showImprimirPdf = false;
            
           
            this.FiltroFechaGlob = moment(this.objFiltroFechasGlob).format('YYYY-MM-DD');
            await this.$http.get("rptventasemanales", {
				params: {
					IdSucursal: this.IdSucursalGlob,
                    Fecha: this.FiltroFechaGlob
				},
			})
			.then((res) => {
                console.log(res.data.data);

                // VIGENTES
                this.listCreditosVigentes       = res.data.data.CreditosVigentes;
                this.creditosVigentesTotales    = res.data.data.TotalesCredVigentes;

                // ENTREGAS GENERALES
                this.listEntregasGenerales      = res.data.data.EntregasGenerales;
                this.totalesEntregasGenerales   = res.data.data.TotEntregasGenerales;

                // ENTREGAS INICIALES
                this.listInicialesSemana         = res.data.data.EntregasIniciales;
                this.totalesEntregasIniciales    = res.data.data.TotEntregasIniciales;

                // ENTREGAS CONSECUTIVOS
                this.listConsecutivosSemana      = res.data.data.EntregasConsecutivos;
                this.totalesEntregasConsecutivos = res.data.data.TotEntregasConsecutivos;

                // PROSPECTO DE LA SEMANA
                this.listProspectosSemana        = res.data.data.ProspectosSemanales;
                this.totalesProspectosSemana     = res.data.data.TotProspectosSemanales;

                // CRECIMIENTO INTER-SEMANA
                this.crecimientoSemanal          = res.data.data.CrecimientoSemanal;

                // NO RENOVADOS
                this.listNoRenovados             = res.data.data.NoRenovados;

                this.showImprimirPdf    = true;
                this.$toast.success('Información Recuperada');

                
            }).catch(err=>{   
                this.$toast.error(err.response.data.message);

            }).finally(() => {
                this.ConfigLoad.ShowLoader = false;
                this.IsLoader.BtnReporte.Bloq = false;
				this.IsLoader.BtnReporte.Icon = this.IsLoader.BtnReporte.Orig;
                this.IsLoader.BloqCmb1 = false;
            });
        },

       

        viewPdfReporte(){
            let newRequest = {
                creditosVigentes: this.listCreditosVigentes,
                creditosVigentesTotales: this.creditosVigentesTotales,
                EntregasGenerales: this.listEntregasGenerales,
                EntregasGeneralesTotales: this.totalesEntregasGenerales,
                InicialesSemana: this.listInicialesSemana,
                EntregasInicialesTotales: this.totalesEntregasIniciales,
                ConsecutivosSemana: this.listConsecutivosSemana,
                EntregasConsecutivosTotales: this.totalesEntregasConsecutivos,
                ProspectosSemana: this.listProspectosSemana,
                ProspectosSemanaTotales: this.totalesProspectosSemana,
                crecimientoSemanal: this.crecimientoSemanal,
                NoRenovados: this.listNoRenovados,
                IdSucursal: this.IdSucursalGlob,
                Fecha: this.FiltroFechaGlob,
            }

            this.$http.post('rptpdfventasemanales',newRequest).then((res)=>{     
                
                let pdfWindow = window.open("");
                pdfWindow.document.write(
                    "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                    encodeURI(res.data.pdf) + "'></iframe>"
                );
                
            }).catch(err=>{   
               console.log(err);
            });

          
        },

        limpiar() {
            // CREDITOS VIGENTES
            this.listCreditosVigentes = [];
            this.creditosVigentesTotales = {
                TotalDia1: 0,
                TotalDia2: 0,
                TotalDia3: 0,
                TotalDia4: 0,
                TotalDia5: 0,
                TotalDia6: 0,
                PromCreditos: 0
            };

            // CREDITOS ENTREGADOS GENERALES
            this.listEntregasGenerales = [];
            this.totalesEntregasGenerales = {
                TotalDia1: 0,
                TotalDia2: 0,
                TotalDia3: 0,
                TotalDia4: 0,
                TotalDia5: 0,
                TotalDia6: 0,
                SumGranTotal: 0
            };

            // CREDITOS INICIALES
            this.listInicialesSemana = [];
            this.totalesEntregasIniciales = {
                TotalDia1: 0,
                TotalDia2: 0,
                TotalDia3: 0,
                TotalDia4: 0,
                TotalDia5: 0,
                TotalDia6: 0,
                SumGranTotal: 0
            };

            // CREDITOS CONSECUTIVOS
            this.listConsecutivosSemana = [];
            this.totalesEntregasConsecutivos = {
                TotalDia1: 0,
                TotalDia2: 0,
                TotalDia3: 0,
                TotalDia4: 0,
                TotalDia5: 0,
                TotalDia6: 0,
                SumGranTotal: 0
            };

            // PROSPECTOS SEMANA
            this.listProspectosSemana = [];
            this.totalesProspectosSemana = {
                TotalDia1: 0,
                TotalDia2: 0,
                TotalDia3: 0,
                TotalDia4: 0,
                TotalDia5: 0,
                TotalDia6: 0,
                SumGranTotal: 0
            };


            // CRECIMIENTO SEMANAL
            this.crecimientoSemanal = {
                SemanaActualIni: "",
                SemanaActualFin: "",
                NumCreditosActual: 0,
                SemanaAnteriorIni: "",
                SemanaAnteriorFin: "",
                NumCreditosAnterior: 0,
                DiferenciaIntersemana: 0
            };

            // PRESTAMOS NO RENOVADOS
            this.listNoRenovados = [];

        },

        Regresar(){
            this.$router.push({name:"menureportes"});
        }
    },
    created() {
        this.limpiar();
        this.bus.$off('getReporteDiario');
        this.ListaSucursales();

    },
    mounted(){
        this.bus.$on('getReporteDiario', () => {
            this.getInforme();
		});
        this.bus.$on('EmitReturn',()=> {
			this.Regresar();
		});
    }

};

</script>
<style>
.tooltip-inner {
    white-space: pre-wrap;
    max-width: 230px !important;
}
</style>