<template>
    <div>

        <CTablita :pConfigList="ConfigListComentarios">
            <template slot="header">
                <th class="text-center">Origen</th>
                <th class="text-center">Fecha</th>
                <th class="text-center">Usuario</th>
                <th class="text-center">Comentario</th>
            </template>

            <template slot="body">
                <tr v-for="(item,index) in ComentariosPrestamo" :key="index">
                    <td class="text-center">
                        <template v-if="item.TipoComentario == 'Entrega'">
                            Entrega
                        </template>
                        <template v-else-if="item.TipoComentario == 'Liquidacion'">
                            Liquidacion
                        </template>
                    </td>
                    <td class="text-center">{{item.Fecha}}</td>
                    <td class="text-center">{{item.escribio.NombreCompleto}}</td>
                    <td class="text-left">{{item.Comentario}}</td>
                </tr>
                <CSinRegistros :pContIF="ComentariosPrestamo.length" :pColspan="4"/>
            </template>
        </CTablita>

    </div>
</template>

<script>

    import Template      from "../../../template/Template";
    import CSinRegistros from "@/components/CSinRegistros";

    export default {
        name: "Comentarios",
        props:["poBtnSave"],
        components:{
            Template,
            CSinRegistros,
        },

        data(){
            return {
                Emit: this.poBtnSave.EmitSeccion,
                Prestamo:{
                    IdPrestamo: 0
                },              
                ConfigListComentarios: {
                    ShowLoader:     false,
                    IsModal:        false,
                    BtnReturnShow:  true,
                    ShowSearch:     false,
                    ShowPaginador:  false,
                    ShowEntradas:   false,
                    BtnNewShow:     false,
                    TypeBody:       'List',
                    ShowTitleFirst: false,
                    // EmitSeccion:    EmitEjecuta,
                },
                ComentariosPrestamo: []
            }
        },

        methods: {
            listComentarios () {
                this.$http.get("getallprestamoscomentarios",{
                    params:{
                        IdPrestamo: this.Prestamo.IdPrestamo,
                    }
                }).then((res) => {
                    this.ComentariosPrestamo = res.data.data;

                }).finally(() => {

                });
            },
        },
        created() {
            this.bus.$off('Save_' + this.Emit);
            this.bus.$off('Recovery_' + this.Emit);
        },

        mounted() {
            this.bus.$on('Recovery_' + this.Emit, (obj) => {

                if (obj !== '') {
                    this.Prestamo = obj;
                    this.listComentarios();
                }

            });
        }

    }

</script>

