<template>
	<div>
		<CList @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity" >
			<template slot="header">
				<th class="td-sm"></th>
				<th>#</th>
				<th>Tipo</th>
				<th class="text-center">Acciones</th>
			</template>

			<template slot="body">
				<tr v-for="(lista, index) in ListaArrayRows" :key="index">
					<td class="td-sm"></td>
					<td>{{ $getNumItem(index) }}</td>
                    <td>{{lista.TipoFamiliar}}</td>
					<td class="text-center">
						<CBtnAccion :pShowBtnEdit="true" :pShowBtnDelete="true" :pIsModal="true" :pId="lista.IdTipoFamiliar" :pEmitSeccion="ConfigList.EmitSeccion" :segurity="segurity">
							<template slot="btnaccion">

							</template>
						</CBtnAccion>
					</td>
				</tr>
				<CSinRegistro :pContIF="ListaArrayRows.length" :pColspan="4" ></CSinRegistro>
			</template>
		</CList>

        <CModal :pConfigModal="ConfigModal" :poBtnSave="oBtnSave">
			<template slot="Form">
				<Form :poBtnSave="oBtnSave"></Form>
			</template>
		</CModal>
	</div>
</template>

<script>

import Configs 	  from "@/helpers/VarConfig.js";
import Form       from "./Form";
const EmitEjecuta = "seccionTipoFam";

export default {
	name: "ListaTiposFamiliares",
	components: {Form},
	data() {
		return {
            counterField: 	1,
			ListaArrayRows: [],
			segurity: {},
			obj: {},
			ConfigList: {
				Title: "Listado de Tipos de Familiares",
				IsModal: true,
				ShowLoader: true,
				EmitSeccion: EmitEjecuta,
				TitleFirst: 'Inicio',
			},
			Filtro: {
				Nombre: "",
				Pagina: 1,
				Entrada: 25,
				TotalItem: 0,
				Placeholder: "Buscar..",
			},
			ConfigModal:{
				ModalTitle: "Tipos de Familiares",
				ModalNameId: 'ModalForm',
				EmitSeccion:  EmitEjecuta,
			},
			oBtnSave: {
				toast: 0,
				IsModal: true,
				DisableBtn: false,
				EmitSeccion: EmitEjecuta,
			},
		};
	},
	methods: {
		async Lista()
		{
			this.ConfigList.ShowLoader = true;

			await this.$http.get("tiposfamiliares", {
				params: {
					TxtBusqueda: this.Filtro.Nombre,
					Entrada: 	 this.Filtro.Entrada,
					Pag: 		 this.Filtro.Pagina
				},
			})
			.then((res) => {
				this.ListaArrayRows		= res.data.data.data;
				this.Filtro.Pagina    	= res.data.data.current_page;
				this.Filtro.TotalItem 	= res.data.data.total;
				this.segurity           = res.data.segurity;
                this.$setStartItem();
			})
			.finally(() => {
				this.ConfigList.ShowLoader = false;
			});
		},

        Eliminar(Id) {
            this.$swal(Configs.configEliminar).then((result) => {
                if(result.value) {
                    this.$http.delete('tiposfamiliares/'+Id)
                        .then( (res) => {
                            this.$swal(Configs.configEliminarConfirm);
                            this.Lista();
                        })
                        .catch( err => {
                            this.$toast.error(err.response.data.message);
                        });
                }
            });
        },
	},
	created() {
		this.bus.$off("List_"+EmitEjecuta);
        this.bus.$off('Delete_'+EmitEjecuta);
	},
	mounted() {
		this.Lista();

        this.bus.$on('Delete_'+EmitEjecuta,(Id)=> {
            this.Eliminar(Id);
        });

		this.bus.$on("List_"+EmitEjecuta,()=>{
			this.Lista();
		});

	},
};

</script>
